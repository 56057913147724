import { Predicate, Operator } from '@ads/predicate-model';
import { FILTER_PREDICATE_NAMES } from '@ads/design-system';

/** *
 * SrAdvancedFilter needs a minimal structure with two 'COMPLEX' predicates.
 * This function generates a Predicate in the right format to be used by SrAdvancedFilter.
 */
export default function generatePredicateForSrAdvancedFilter(children: Predicate[], parentOperand: Operator = 'AND'): Predicate {
    return new Predicate({
        type: 'COMPLEX',
        operator: 'AND',
        children: [
            new Predicate({
                type: 'COMPLEX',
                operator: parentOperand,
                name: FILTER_PREDICATE_NAMES.FILTER,
                children,
            }),
        ],
    });
}

export const lineItemFormSteps = [
    {
        name: 'Basics',
        isValid: true,
    },
    {
        name: 'Strategy',
        isValid: true,
    },
    {
        name: 'Targetings',
        isValid: true,
    },
    {
        name: 'Creatives',
        isValid: true,
    },
];

const emptyTargetingCount = {
    audience: {
        count: 0,
        types: {
            geoTargetings: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            timeTargeting: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            locationTargetings: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            browserLanguageTargetings: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            sportCalendarTargetings: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
        },
    },
    content: {
        count: 2,
        types: {
            applicationNameLists: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            sspTargetings: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            dealTargetings: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            visibilityTargetings: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            videoPlayerSizeTargetings: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            domainLists: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            bundleListTargetings: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            categoryTargetings: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            publisherListTargetings: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            appOrWebTargeting: {
                count: 2,
                updateCount: 2,
                wasUpdated: false,
            },
            contextualCategoryTargeting: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
        },
    },
    technology: {
        count: 1,
        types: {
            devices: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            deviceTypeTargetings: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            browserTargetings: {
                count: 1,
                updateCount: 1,
                wasUpdated: false,
            },
            operatingSystems: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
        },
    },
    segments: {
        count: 0,
        types: {
            pixelSegmentTargeting: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
            fanIdTargeting: {
                count: 0,
                updateCount: 0,
                wasUpdated: false,
            },
        },
    },
};

export { emptyTargetingCount };

<template>
    <SelectableTree v-model="selectableTree" :source-nodes="browserLanguageNodes" :mode.sync="mode" :is-loading="isLoading" />
</template>

<script>
import SelectableTree from '@/components/SelectableTree/SelectableTree';
import { SelectedTree } from '@/components/SelectableTree/SelectedTree';
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import { Node } from '@/components/SelectableTree/Node';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import { browserLanguageService } from './services/BrowserLanguageService';
import BrowserLanguageTargetingTransformer from './services/BrowserLanguageTargetingTransformer';
import SelectedTreeNodesTransformer from './services/SelectedTreeNodesTransformer';

export default {
    name: 'BrowserLanguage',
    components: { SelectableTree },
    mixins: [LineItemModel, TargetingCountUpdate],
    data() {
        return {
            selectableTree: this.initTree(),
            mode: this.getInitialMode(),
            browserLanguageNodes: [],
            isLoading: false,
            browserLanguageService,
            browserLanguageTargetingTransformer: new BrowserLanguageTargetingTransformer(
                this.createSelectedBrowserLanguageCache(),
            ),
        };
    },
    watch: {
        selectableTree: {
            handler() {
                this.$set(
                    this.lineItem.targetings,
                    'browserLanguageTargetings',
                    this.browserLanguageTargetingTransformer.transform(this.selectableTree.getSelectedNodeTrees()),
                );
            },
            deep: true,
        },
    },
    async created() {
        await this.getBrowserLanguageNodes();
    },
    methods: {
        createSelectedBrowserLanguageCache() {
            if (!this.value.targetings?.browserLanguageTargetings?.length) {
                return [];
            }
            return [...this.value.targetings.browserLanguageTargetings];
        },
        async getBrowserLanguageNodes() {
            this.isLoading = true;
            const browserLanguages = await this.browserLanguageService.getAll();
            this.browserLanguageNodes = browserLanguages.map((browserLanguage) => new Node(browserLanguage));
            this.isLoading = false;
        },
        initTree() {
            const selectedTreeNodesTransformer = new SelectedTreeNodesTransformer();
            return new SelectedTree(selectedTreeNodesTransformer.transform(this.value.targetings?.browserLanguageTargetings));
        },
        getInitialMode() {
            if (this.value.targetings?.browserLanguageTargetings?.length) {
                if (this.value?.targetings?.browserLanguageTargetings[0].isExcluded) {
                    return SELECTION_MODE.EXCLUDE;
                }
            }
            return SELECTION_MODE.INCLUDE;
        },
    },
};
</script>

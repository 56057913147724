import { Node } from '@/components/SelectableTree/Node';
import { categoryService } from './service/CategoryService';

export type CategoryResponse = {
    id: number;
    name: string;
    code: string;
    hasChildren?: boolean;
    parent?: CategoryResponse;
};

export class Category extends Node {
    declare id: number;

    constructor(category: Partial<Category>) {
        super(category);
        this.id = category.id;
    }

    async fetchChildren(): Promise<Category[]> {
        const children = await categoryService.getSubcategories(this.id);
        return children.map(
            ({ id, name, code, hasChildren }) =>
                new Category({
                    id,
                    name: `${name} (${code})`,
                    parent: this,
                    children: hasChildren ? [] : undefined,
                }),
        );
    }
}

export type CategoryTargetingRequest = {
    category: {
        id: number;
    };
    isExcluded: boolean;
};

export type CategoryTargetingResponse = {
    category: CategoryResponse;
    isExcluded: boolean;
};

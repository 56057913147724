<template>
    <SrModalPage show :loading="isLoading" @close="close">
        <VForm slot="content" ref="form" v-model="isValid" :disabled="isFormDisabled">
            <SrHeadline level="1" weight="bold">
                {{ isEditForm ? 'Edit Deal' : 'New Deal' }}
            </SrHeadline>
            <VRow>
                <VCol xlg="4" lg="5" md="6" sm="12">
                    <SrHeadline level="3" weight="bold">Basic Details</SrHeadline>
                    <SrInput v-model="deal.dspId" label="DSP ID" disabled />
                    <SrInput
                        v-model="deal.name"
                        label="Deal name"
                        required
                        class="deal-name"
                        :rules="rules.name"
                        data-testid="DealForm__name"
                    />
                    <SrInput
                        v-model="deal.externalId"
                        label="Deal ID"
                        placeholder="Enter the deal id from SSP/network"
                        required
                        :rules="rules.id"
                        data-testid="DealForm__external_id"
                    />
                    <SrInput
                        v-model.number="deal.dealMultiplyCoefficient"
                        min="0"
                        type="number"
                        label="Deal multiplier coefficient"
                        placeholder="Enter the deal multiplier"
                        :rules="rules.multiplier"
                        class="deal-multiplier"
                        data-testid="DealForm__deal_multiplier"
                    />
                    <SrSelect
                        v-model="selectedHolding"
                        :items="selectableHoldings"
                        label="Select Holding"
                        searchable
                        clearable
                        :disabled="!isLoggedInAsAdmin"
                        sort-order="ASC"
                        data-testid="DealForm__holding"
                    />
                    <SrNotification
                        v-if="!isLoading && isGlobalDeal"
                        type="info"
                        title="Please select a holding, otherwise the deal will be public."
                    />
                    <SrHeadline level="3" weight="bold"> Description</SrHeadline>
                    <SrInput
                        v-model="deal.description"
                        label="Deal Description"
                        placeholder="Enter description"
                        :rules="rules.description"
                        data-testid="DealForm__description"
                    />
                </VCol>
                <VCol xlg="4" lg="5" md="6" sm="12" offset-lg="1">
                    <SrHeadline level="3" weight="bold"> Inventory Source</SrHeadline>

                    <SrSelect v-model="deal.ssp.id" :items="selectableSsps" :disabled="isEditForm" label="Select SSP" required />
                    <SrSelect
                        v-model="deal.network.id"
                        :items="networksBySspId"
                        :disabled="isEditForm"
                        label="Select Network"
                        required
                        searchable
                        sort-order="ASC"
                    />

                    <SrHeadline level="3" weight="bold"> Deal Source<span class="required-text"> *</span></SrHeadline>

                    <RadioButtonGroup v-model="deal.source" :items="sources" data-testid="DealForm__source" />

                    <SrHeadline level="3" weight="bold"> Type</SrHeadline>

                    <DealEnvironmentSelect v-model="deal.environment" data-testid="DealForm__environment" />

                    <DealFormatSelect v-model="deal.format" data-testid="DealForm__format" />

                    <CountrySelector v-model="deal.countries" data-testid="DealForm__country" />
                </VCol>
            </VRow>
        </VForm>
        <ErrorBox slot="footer" :show="hasErrorMessage" :message="errorMessage" />
        <div slot="footer" class="d-flex justify-end">
            <SrButton
                v-can="requiredPermission"
                :disabled="!isValid || isFormDisabled"
                :loading="isSaving"
                type="secondary"
                class="mt-6"
                @click="submit"
            >
                Submit
            </SrButton>
        </div>
    </SrModalPage>
</template>

<script>
import { SrButton, SrHeadline, SrInput, SrModalPage, SrNotification, SrSelect } from '@ads/design-system';
import RadioButtonGroup from '@/components/RadioButtonGroup/RadioButtonGroup';
import ErrorBox from '@/components/ErrorBox';
import { mapGetters } from 'vuex';
import BasicForm from '@/components/Form/BasicForm';
import UserMixin from '@/mixins/UserMixin';
import RequiresPermissionCheckMixin from '@/mixins/RequiredPermissionCheckMixin';
import CountrySelector from '@/components/CountrySelector/CountrySelector';
import DealEnvironmentSelect from '@/modules/deals/components/DealEnvironmentSelect';
import DealFormatSelect from '@/modules/deals/components/DealFormatSelect';
import DealErrorMessageFactory from './DealErrorMessageFactory';
import { rules, sources } from './config';

const errorMessageFactory = new DealErrorMessageFactory();

export default {
    name: 'DealForm',
    components: {
        DealEnvironmentSelect,
        DealFormatSelect,
        RadioButtonGroup,
        SrModalPage,
        SrHeadline,
        SrButton,
        SrNotification,
        SrSelect,
        SrInput,
        ErrorBox,
        CountrySelector,
    },
    extends: BasicForm,
    mixins: [RequiresPermissionCheckMixin, UserMixin],
    model: {
        prop: 'value',
        event: 'input',
    },
    data() {
        return {
            deal: this.value,
            sources,
            rules,
            isSaving: false,
            isValid: false,
            errorMessage: null,
        };
    },
    computed: {
        ...mapGetters({
            selectableHoldings: 'iam/selectableHoldings',
            selectableSsps: 'ssp/selectableSsps',
            selectableNetworksOfSsp: 'ssp/selectableNetworksOfSsp',
            countries: 'geo/countries',
        }),
        hasErrorMessage() {
            return Boolean(this.errorMessage);
        },
        isGlobalDeal() {
            return !this.selectedHolding;
        },
        // disables the form for users which are not admins and try to edit a global deal.
        isFormDisabled() {
            if (this.isEditForm && !this.isLoggedInAsAdmin) {
                return this.isGlobalDeal;
            }
            return false;
        },
        networksBySspId() {
            if (this.deal.ssp.id) {
                return this.selectableNetworksOfSsp(this.deal.ssp.id);
            }
            return [];
        },
        selectedHolding: {
            get() {
                return this.deal.holding?.id;
            },
            set(id) {
                this.deal.holding = { id };
            },
        },
    },
    watch: {
        'deal.ssp.id': function () {
            if (!this.isEditForm) {
                this.deal.network.id = null;
            }
        },
        value(deal) {
            this.deal = deal;
        },
    },
    methods: {
        async submit() {
            try {
                this.isSaving = true;
                if (!this.deal.dealMultiplyCoefficient) {
                    this.deal.dealMultiplyCoefficient = 1;
                }

                await this.$store.dispatch(this.submitAction, this.deal);
                await this.$router.push({
                    name: this.afterSubmitRoute,
                    params: this.afterSubmitRouteParams,
                });
            } catch (error) {
                this.errorMessage = errorMessageFactory.createFromResponseMessage(error?.response?.message || error);
            } finally {
                this.isSaving = false;
            }
        },
        async close() {
            await this.$router.push({ name: this.afterCloseRoute });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@ads/design-system/src/scss/variables';

.required-text {
    color: $brand-red;
}
</style>

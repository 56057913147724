<template>
    <div>
        <BundleListForm
            v-model="bundleList"
            :submit-action="actionType"
            :after-close-route="closingRoute"
            :after-close-route-params="closeRouteParams"
            :after-submit-route="closingRoute"
            :after-submit-route-params="afterSubmitParams"
            :load-error="errorMessage"
            :is-loading="isLoading"
            non-linear
        />
        <RouterView />
    </div>
</template>

<script>
import BundleListForm from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/BundleList/BundleListListForm';
import { bundleListService } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/BundleList/services/BundleListService';
import { ACTION_TYPES } from '@/modules/line-items/views/targetings/types';
import TargetingFormRoutes from '@/modules/line-items/views/targetings/TargetingFormRoutes';

export default {
    name: 'EditBundleList',
    components: {
        BundleListForm,
    },
    mixins: [TargetingFormRoutes],
    props: {
        lineItem: {
            type: Object,
            required: true,
        },
        listId: {
            type: [Number, String],
            required: true,
            default: null,
        },
    },
    data() {
        return {
            bundleList: {
                name: '',
                holdingId: null,
                bundles: [],
            },
            actionType: ACTION_TYPES.EDIT,
            bundleListService,
            errorMessage: null,
            isLoading: false,
        };
    },
    async created() {
        await this.loadBundleList();
    },
    methods: {
        async loadBundleList() {
            this.isLoading = true;
            try {
                this.bundleList = await this.bundleListService.getById(this.listId);
            } catch (error) {
                this.errorMessage = error?.response?.message || String(error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

import { TableActionTypes } from '@/components/types';

export const listHeaders = [
    {
        text: 'DSP ID',
        value: 'dspId',
        align: 'left',
        sortable: true,
        width: '90px',
    },
    {
        text: 'Name',
        value: 'name',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Campaign',
        value: 'campaign.name',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Budget',
        value: 'budgetStatistics',
        align: 'left',
        width: '120px',
        sort: (a: { spent: number }, b: { spent: number }) => a.spent - b.spent,
    },
    {
        text: 'Days Remaining',
        value: 'runtime.daysRemaining',
        align: 'left',
        sortable: false,
    },
    {
        text: 'Status',
        value: 'computedStatus',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Time Zone',
        value: 'runtime.timeZone',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Actions',
        value: 'actions',
        align: 'right',
        sortable: false,
    },
];

export const listActionRoutes = {
    [TableActionTypes.EDIT]: {
        name: TableActionTypes.EDIT,
        icon: 'Edit',
        routeName: 'line-items-update',
    },
    [TableActionTypes.HISTORY]: {
        name: TableActionTypes.HISTORY,
        icon: 'History',
        routeName: 'line-items-history',
    },
    [TableActionTypes.DUPLICATE]: {
        name: TableActionTypes.DUPLICATE,
        icon: 'Copy',
    },
};

export const listFilterStaticConfig = [
    {
        id: 0,
        type: 'text',
        label: 'ID',
        hint: null,
        placeholder: 'Ex. 1111',
        name: 'id',
    },
    {
        id: 1,
        type: 'text',
        label: 'Name',
        hint: null,
        placeholder: 'Line Item Name',
        name: 'name',
    },
];

import StringifiedNumberValidator from '@/components/NumberInput/validation/StringifiedNumberValidator';

type NumberFormatOptions = {
    locale: string;
    precision: number;
};

export default class NumberTransformer {
    private readonly stringifiedNumberValidator: StringifiedNumberValidator;

    private readonly numberFormatOptions: NumberFormatOptions;

    constructor(stringifiedNumberValidator: StringifiedNumberValidator, numberFormatOptions: NumberFormatOptions) {
        this.stringifiedNumberValidator = stringifiedNumberValidator;
        this.numberFormatOptions = numberFormatOptions;
    }

    public stringToFormattedOrSame(value: string): string {
        const numberValue = this.stringToNumber(value);
        if (numberValue === null) {
            return value;
        }

        return this.numberToFormatted(numberValue);
    }

    public stringToNumberOrSame(value: string): number | string {
        const transformedNumber = this.stringToNumber(value);

        if (transformedNumber === null) {
            return value;
        }

        return transformedNumber;
    }

    private numberToFormatted(value: number): string | null {
        if (value === null || value === undefined) {
            return null;
        }

        return Intl.NumberFormat(this.numberFormatOptions.locale, {
            style: 'decimal',
            minimumFractionDigits: this.numberFormatOptions.precision,
            maximumFractionDigits: this.numberFormatOptions.precision,
        }).format(value);
    }

    public stringToNumber(value: string): number | null {
        if (this.stringifiedNumberValidator.isValid(value)) {
            const numberWithDotDecimalSeparator = value.replaceAll(',', '.');
            return Number.parseFloat(numberWithDotDecimalSeparator);
        }

        return null;
    }
}

<template>
    <VRow no-gutters class="line-item-list">
        <ListHeader headline-name="Line Items" button-name="Create Line Item" route="line-items-create">
            <template #filter>
                <SrAdvancedFilter
                    v-if="ifConfigAndCampaignsExist"
                    v-model="advancedFilterPredicate"
                    :config="advancedFilterConfig"
                    class="mt-6 sr-search-input"
                />
            </template>
        </ListHeader>

        <Divider />

        <Table
            :headers="headers"
            :items="lineItems"
            :is-loading="isLineItemsLoading"
            :item-class="getItemClass"
            :selected.sync="selected"
            show-select
            :options.sync="options"
            entity-name="Line Item"
            :disabled-table-actions="!selected.length"
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50],
            }"
            :server-items-length="totalLineItems"
            aria-label="List of line items"
        >
            <template #top-actions>
                <SrListItem @click="showBulkArchiveModal">
                    <SrListItemIcon>
                        <SrIcon icon="Inventory" size="xs" />
                    </SrListItemIcon>
                    <SrListItemTitle> Archive </SrListItemTitle>
                </SrListItem>
                <SrListItem @click="showBulkDeleteModal">
                    <SrListItemIcon>
                        <SrIcon icon="Trash" size="xs" />
                    </SrListItemIcon>
                    <SrListItemTitle> Delete </SrListItemTitle>
                </SrListItem>
            </template>
            <template #name="item">
                <router-link :to="getUpdateRoute(item)" class="font-weight-bold">
                    {{ item.name }}
                </router-link>
            </template>

            <template #budgetStatistics="{ strategyConfig, budgetStatistics, campaign }">
                <BudgetStatistics
                    v-if="budgetStatistics"
                    :total-budget="strategyConfig.advertiserBudget"
                    :budget-statistics="budgetStatistics"
                    :currency-code="campaign.currencyCode"
                />
            </template>

            <template #[`runtime.daysRemaining`]="item">
                <HumanReadableRuntime :runtime="item.runtime" />
            </template>

            <template #computedStatus="{ item, index }">
                <ComputedStatus :value="item" @update="(updatedItem) => update(updatedItem, index)" />
            </template>

            <template #actions="{ item, index }">
                <LineItemListTableItemActions
                    :disabled="multipleRowsSelected"
                    :action-routes="actionRoutes"
                    :line-item="item"
                    @action="tableAction"
                    @update="onTableItemUpdated($event, index)"
                    @delete="onTableItemDeleted"
                />
            </template>
        </Table>

        <ErrorBox class="error-box" :show="showErrorMessage" :message="error" />

        <BulkArchiveModal v-model="showArchiveModal" @cancel="closeBulkArchiveModal" @confirm="bulkArchive" />

        <BulkDeleteModal
            v-model="showDeleteModal"
            :line-items="selected"
            @cancel="closeBulkDeleteModal"
            @completed="onBulkDeletionCompleted"
        />

        <DuplicateModal
            v-if="showDuplicateModal"
            v-model="showDuplicateModal"
            :line-item-id="lineItemIdToDuplicate"
            @cancel="closeDuplicateModal"
            @completed="onDuplicationCompleted"
        />

        <router-view />
    </VRow>
</template>

<script>
import Table from '@/components/Table/Table';
import ListHeader from '@/components/ListHeader/ListHeader';
import { TableActionTypes } from '@/components/types';
import { SrAdvancedFilter, SrIcon, SrListItem, SrListItemIcon, SrListItemTitle } from '@ads/design-system';
import Divider from '@/components/Divider/Divider';
import ComputedStatus from '@/modules/line-items/components/ComputedState/ComputedStatus';
import BudgetStatistics from '@/modules/line-items/components/BudgetStatistics';
import LINE_ITEM_STATUS from 'api-contracts/line-items/statuses/Status';
import LINE_ITEM_COMPUTED_STATUS from 'api-contracts/line-items/statuses/ComputedStatus';
import BulkArchiveModal from '@/modules/line-items/components/BulkArchiveModal';
import BulkDeleteModal from '@/modules/line-items/components/BulkDeleteModal';
import { OPERATORS } from '@ads/design-system/src/components/SrAdvancedFilter/types';
import HumanReadableRuntime from '@/components/HumanReadableRuntime';
import { Predicate } from '@ads/predicate-model';
import { lineItemsService } from '@/modules/line-items/services/LineItemsService';
import {
    fetchAndStoreAllCampaigns,
    getCampaignParameterValueLabel,
    getFilterConfigPredicates,
    getSelectableCampaigns,
} from '@/modules/line-items/views/list/lineItemsFilterUtils';
import DuplicateModal from '@/modules/line-items/components/DuplicateModal/DuplicateModal';
import ErrorBox from '@/components/ErrorBox';
import LineItemListTableItemActions from '@/modules/line-items/views/list/lineItemListTableItemActions';
import { generatePredicateForSrAdvancedFilter } from '@/shared/utils';
import { listActionRoutes, listHeaders } from './config';

export default {
    components: {
        LineItemListTableItemActions,
        DuplicateModal,
        BulkDeleteModal,
        BulkArchiveModal,
        BudgetStatistics,
        ComputedStatus,
        HumanReadableRuntime,
        Divider,
        ListHeader,
        Table,
        SrAdvancedFilter,
        SrIcon,
        SrListItem,
        SrListItemIcon,
        SrListItemTitle,
        ErrorBox,
    },
    model: {
        prop: 'filterParams',
        event: 'input',
    },
    props: {
        campaignId: {
            type: Number,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            headers: listHeaders,
            showDuplicateModal: false,
            actionRoutes: listActionRoutes,
            searchedLineItemName: null,
            advancedFilterPredicate: null,
            selected: [],
            showArchiveModal: false,
            showDeleteModal: false,
            isActionLoading: false,
            totalLineItems: 0,
            error: '',
            lineItems: [],
            isLineItemsLoading: false,
            options: {
                itemsPerPage: 10,
                page: 1,
                sortBy: ['id'],
                sortDesc: [true],
            },
            lineItemIdToDuplicate: null,
            advancedFilterConfig: null,
            lineItemsService,
        };
    },
    computed: {
        ifConfigAndCampaignsExist() {
            return this.advancedFilterConfig && this.campaigns.length > 0;
        },
        campaigns() {
            return this.$store.getters['campaign/campaigns'];
        },
        multipleRowsSelected() {
            return this.selected.length > 1;
        },
        showErrorMessage() {
            return Boolean(this.error);
        },
    },
    watch: {
        async $route(currentRoute, previousRoute) {
            const isRouteChange = currentRoute.name !== previousRoute.name;
            const isCurrentRouteLineItemList = currentRoute.name === 'line-items-list';
            if (isRouteChange && isCurrentRouteLineItemList) {
                await this.fetchLineItems();
            }
        },
    },
    async created() {
        try {
            await this.buildInitialFilter();
            await this.fetchLineItems();
        } finally {
            this.watchForLineItemRefetch();
        }
    },
    methods: {
        watchForLineItemRefetch() {
            this.$watch(
                'advancedFilterPredicate',
                async () => {
                    if (!this.isOnFirstPage()) {
                        // This will trigger the watcher for options,
                        // so we don't have to fetch line items here.
                        this.resetToFirstPage();
                        return;
                    }
                    await this.fetchLineItems();
                },
                { deep: true },
            );
            this.$watch('options', this.fetchLineItems, { deep: true });
        },
        async fetchLineItems() {
            try {
                this.isLineItemsLoading = true;
                const response = await this.lineItemsService.list(this.options, this.advancedFilterPredicate);
                this.lineItems = response.results;
                this.totalLineItems = response.totalCount;
            } catch (error) {
                this.error = error.response?.message ?? 'Could not get the list of line items.';
            } finally {
                this.isLineItemsLoading = false;
            }
        },
        tableAction(action, { id }) {
            if (action.name === TableActionTypes.DUPLICATE) {
                this.lineItemIdToDuplicate = id;
                this.showDuplicateModal = true;
            }
        },
        getItemClass(item) {
            if (item.alerts.some((alert) => alert.status === 'error')) {
                return 'item-with-alerts';
            }
            if (item.alerts.some((alert) => alert.status === 'warning')) {
                return 'item-with-warnings';
            }

            return '';
        },
        getUpdateRoute({ id }) {
            return {
                name: this.actionRoutes[TableActionTypes.EDIT].routeName,
                params: { id },
            };
        },
        showBulkArchiveModal() {
            const hasActive = this.selected.some((item) => item.computedStatus === LINE_ITEM_COMPUTED_STATUS.ACTIVE);

            if (hasActive) {
                this.showArchiveModal = true;
            } else {
                this.bulkArchive();
            }
        },
        showBulkDeleteModal() {
            this.showDeleteModal = true;
        },
        async bulkArchive() {
            this.closeBulkArchiveModal();
            this.isActionLoading = true;

            const lineItemIds = this.selected.filter((item) => item.status !== LINE_ITEM_STATUS.ARCHIVE).map((item) => item.id);
            if (lineItemIds.length > 0) {
                try {
                    await this.lineItemsService.bulkPartialEdit(lineItemIds, LINE_ITEM_STATUS.ARCHIVE);
                    this.selected = [];
                    await this.fetchLineItems();
                } catch ({ response }) {
                    console.error(response);
                }
            } else {
                this.selected = [];
            }

            this.isActionLoading = false;
        },
        closeBulkArchiveModal() {
            this.showArchiveModal = false;
        },
        onBulkDeletionCompleted() {
            this.selected = [];
            this.closeBulkDeleteModal();
            this.fetchLineItems();
        },
        closeBulkDeleteModal() {
            this.showDeleteModal = false;
        },
        closeDuplicateModal() {
            this.lineItemIdToDuplicate = null;
            this.showDuplicateModal = false;
        },
        onDuplicationCompleted() {
            this.closeDuplicateModal();
            this.fetchLineItems();
        },
        getCampaignIsCampaignIdFilter(campaignId) {
            return generatePredicateForSrAdvancedFilter(
                [
                    new Predicate({
                        type: 'ATOMIC',
                        predicateDefinition: 'IS',
                        filterCriteria: 'campaign.id',
                        name: 'Campaign',
                        parameterValue: campaignId,
                        toStringConfig: {
                            getParameterValueLabel: (predicate) =>
                                getCampaignParameterValueLabel(predicate, getSelectableCampaigns()),
                        },
                    }),
                ],
                'AND',
            );
        },
        getStatusIsNotArchiveFilter() {
            return generatePredicateForSrAdvancedFilter(
                [
                    new Predicate({
                        type: 'ATOMIC',
                        predicateDefinition: 'IS_NOT',
                        filterCriteria: '%line_item_computed_state%',
                        name: 'Status',
                        parameterValue: LINE_ITEM_COMPUTED_STATUS.ARCHIVE,
                    }),
                ],
                'AND',
            );
        },
        async buildInitialFilter() {
            await this.fetchCampaigns();

            this.advancedFilterConfig = {
                quickSearch: {
                    enabled: true,
                    fields: ['id', 'name', 'dspId'],
                },
                persistent: true,
                properties: getFilterConfigPredicates(),
                operators: OPERATORS,
            };

            if (this.campaignId != null) {
                this.advancedFilterPredicate = this.getCampaignIsCampaignIdFilter(this.campaignId);
            } else {
                this.advancedFilterPredicate = this.getStatusIsNotArchiveFilter();
            }
        },
        async fetchCampaigns() {
            try {
                this.isLineItemsLoading = true;
                await fetchAndStoreAllCampaigns();
            } finally {
                this.isLineItemsLoading = false;
            }
        },
        update(lineItem, index) {
            this.lineItems[index] = lineItem;
        },
        async onTableItemUpdated() {
            await this.fetchLineItems();
        },
        async onTableItemDeleted() {
            await this.fetchLineItems();
        },
        resetToFirstPage() {
            this.options.page = 1;
        },
        isOnFirstPage() {
            return this.options.page === 1;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@ads/design-system/src/scss/variables';
@import 'src/scss/searchInputMargin';

.line-item-list::v-deep {
    .item-with-alerts {
        border-left: 2px solid $red;
    }

    .item-with-warnings {
        border-left: 2px solid #f49b12;
    }

    table:first-of-type {
        border-collapse: collapse;
    }

    .error-box {
        width: 100%;
    }
}
</style>

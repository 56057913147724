// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stringifyError(error: any): string {
    const errorMessage = error?.message || error.response?.message;

    if (errorMessage) {
        return errorMessage;
    }

    if (typeof error === 'object') {
        return tryJSONStringify(error);
    }

    return `${error}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function tryJSONStringify(anything: any): string {
    try {
        return JSON.stringify(anything);
    } catch {
        return `${anything}`;
    }
}

<template>
    <div class="progress-bar-wrapper">
        <div class="progress-bar" :style="{ backgroundColor: backgroundColor }">
            <div
                class="current-area"
                :class="{ 'is-100-percent': valueAsPercent === 100 }"
                :style="{ width: `${valueAsPercent}%` }"
            >
                <SrTooltip bottom>
                    <template #activator="{ on }">
                        <div class="tooltip-activator" v-on="on" />
                        <span class="total-value" :style="{ color: totalColor }">
                            <slot name="total" :total="total">
                                {{ total }}
                            </slot>
                        </span>
                    </template>
                    <span class="tooltip-text">
                        <slot name="value" :value="value">
                            {{ value }}
                        </slot>
                    </span>
                </SrTooltip>
            </div>

            <SrTooltip
                bottom
                :style="{
                    width: '1px',
                    position: 'absolute',
                    left: `${thresholdAsPercent}%`,
                }"
            >
                <template #activator="{ on }">
                    <div
                        class="threshold-bar"
                        :style="{
                            left: `${thresholdAsPercent}%`,
                            borderColor: thresholdColor,
                        }"
                        v-on="on"
                    />
                </template>
                <span class="tooltip-text">
                    <slot name="threshold" :threshold="threshold">
                        {{ threshold }}
                    </slot>
                </span>
            </SrTooltip>
        </div>
    </div>
</template>

<script>
import { SrTooltip } from '@ads/design-system';

export default {
    name: 'ProgressBar',
    components: {
        SrTooltip,
    },
    props: {
        total: {
            type: Number,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        },
        threshold: {
            type: Number,
            default: 0,
        },
        backgroundColor: {
            type: String,
            default: '#B6BAD1',
        },
        thresholdColor: {
            type: String,
            default: 'var(--v-blue-base, #3367D6)',
        },
        totalColor: {
            type: String,
            default: '#FFFFFF',
        },
    },
    computed: {
        valueAsPercent() {
            return this.calculatePercentage(this.value, this.total);
        },
        thresholdAsPercent() {
            return this.calculatePercentage(this.threshold, this.total);
        },
    },
    methods: {
        calculatePercentage(value, total) {
            return Math.min((value / total) * 100, 100);
        },
    },
};
</script>

<style lang="scss">
@import '~@ads/design-system/src/scss/variables';

$border-radius: 3px;
$font-size: 12px;

@mixin reset-position {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
}

.progress-bar-wrapper {
    background: #b6bad1;
    border: 1px solid $gray;
    border-radius: $border-radius;
    height: $font-size * 2;
    width: 100%;

    > .progress-bar {
        position: relative;
        border-radius: $border-radius;
        width: 100%;
        height: 100%;
    }

    .current-area {
        height: 100%;
        background: $brand-blue;
        border-radius: $border-radius 0 0 $border-radius;
        max-width: 100%;
        position: absolute;

        &.is-100-percent {
            border-radius: $border-radius;
        }
    }

    .threshold-bar {
        @include reset-position;
        border-left: 2px dashed $blue;
        margin-left: -1px;
        width: 1px;
    }

    .total-value {
        margin-top: 2px;
        padding-left: 4px;
        font-size: $font-size;
    }

    .tooltip {
        &-text {
            font-size: $font-size;
        }

        &-activator {
            @include reset-position;
            height: 100%;
            width: 100%;
        }
    }

    .sr-base-tooltip-bottom::after {
        left: 50%;
        right: 50%;
    }
}
</style>

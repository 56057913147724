import { render, staticRenderFns } from "./FrequencyCappingForm.vue?vue&type=template&id=6cdfb352"
import script from "./FrequencyCappingForm.vue?vue&type=script&lang=js"
export * from "./FrequencyCappingForm.vue?vue&type=script&lang=js"
import style0 from "./FrequencyCappingForm.vue?vue&type=style&index=0&id=6cdfb352&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import { Browser } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Technology/Browser/types';

export default class BrowserService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'line-item',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async getAll(): Promise<Browser[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get('/browsers', options);
    }
}

export const browserService = new BrowserService(new RequestService({ baseUrl: API_URL }));

import { NODE_SELECTION_STATUS, SelectedNodeTree } from '@/components/SelectableTree/SelectedTree';

export type GeoLocation = {
    id: number;
    name: string;
    parent: GeoLocation | null;
    type: string | null;
};

export type GeoTargeting = {
    id: number | null;
    geoLocation: GeoLocation;
    isExcluded: boolean;
};

export class GeoTargetingController {
    static toSelectedNode(geoTargeting: GeoTargeting): SelectedNodeTree {
        let geoLocation = geoTargeting.geoLocation;
        let node = new SelectedNodeTree(
            geoLocation.id,
            geoLocation.name,
            geoTargeting.isExcluded ? NODE_SELECTION_STATUS.EXCLUDED : NODE_SELECTION_STATUS.INCLUDED,
            geoLocation.type,
        );

        while (geoLocation.parent) {
            geoLocation = geoLocation.parent;
            node = new SelectedNodeTree(geoLocation.id, geoLocation.name, NODE_SELECTION_STATUS.NONE, geoLocation.type, [node]);
        }

        return node;
    }

    static toGeoTargeting(selectedNode: SelectedNodeTree): GeoTargeting {
        const geoLocation = {
            id: selectedNode.getId(),
            name: selectedNode.getName(),
            parent: null,
            type: selectedNode.getType(),
        } as GeoLocation;
        return {
            geoLocation,
            isExcluded: selectedNode.isExcluded(),
        } as GeoTargeting;
    }
}

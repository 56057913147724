export const panelsConfig = [
    {
        name: 'geo',
        title: 'Geo Targeting',
        component: 'TargetingsGeoTargeting',
        key: 'geoTargetings',
    },
    {
        name: 'time',
        title: 'Time',
        component: 'TimeTargetingPicker',
        key: 'timeTargeting',
    },
    {
        name: 'location',
        title: 'Location Targeting',
        component: 'Location',
        key: 'locationTargetings',
    },
    {
        name: 'browserLanguage',
        title: 'Browser Language',
        component: 'BrowserLanguage',
        key: 'browserLanguageTargetings',
    },
    {
        name: 'sportCalendar',
        title: 'Sport Calendar',
        component: 'SportCalendar',
        key: 'sportCalendarTargetings',
    },
];

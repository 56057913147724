import store from '@/store';
import { OperatorTypes, SearchValueTypes } from '@/components/types';
import { SelectSortOrder } from '@ads/design-system/src/components/SrAdvancedFilter/types';
import { Predicate } from '@ads/predicate-model';
import LINE_ITEM_COMPUTED_STATUS from 'api-contracts/line-items/statuses/ComputedStatus';

type SelectValue = { value: string | number; label: string };

interface ISelectableFilter {
    name: string;
    filterCriteria: string;
    operatorType: OperatorTypes;
    searchValueType: SearchValueTypes;
    selectValues?: (SelectValue | string)[];
    selectSortOrder?: SelectSortOrder;
    selectSearchable?: boolean;
    toStringConfig?: {
        getParameterValueLabel: (predicate: Predicate) => string;
    };
}

async function fetchAndStoreAllCampaigns(): Promise<void> {
    await store.dispatch('campaign/fetchCampaigns');
}

function getSelectableCampaigns(): SelectValue[] {
    const campaigns = store.getters['campaign/campaigns'];
    return campaigns.map(({ id, name }) => ({
        value: id,
        label: name,
    }));
}

function getCampaignParameterValueLabel(predicate: Predicate, selectableCampaigns: SelectValue[]): string {
    if (!predicate) {
        return '';
    }
    const selectedCampaign = selectableCampaigns.find((campaign) => campaign.value === predicate.parameterValue);
    if (selectedCampaign) {
        return selectedCampaign.label;
    }
    return predicate.parameterValue as string;
}

function getFilterConfigPredicates(): ISelectableFilter[] {
    return [
        {
            name: 'DSP ID',
            filterCriteria: 'dspId',
            operatorType: OperatorTypes.NUMERIC,
            searchValueType: SearchValueTypes.TEXT,
        },
        {
            name: 'Name',
            filterCriteria: 'name',
            operatorType: OperatorTypes.ALPHABETIC,
            searchValueType: SearchValueTypes.TEXT,
        },
        {
            name: 'Campaign',
            filterCriteria: 'campaign.id',
            operatorType: OperatorTypes.BOOLEAN,
            searchValueType: SearchValueTypes.SELECT,
            selectValues: getSelectableCampaigns(),
            selectSortOrder: SelectSortOrder.ASC,
            selectSearchable: true,
            toStringConfig: {
                getParameterValueLabel: (predicate) => getCampaignParameterValueLabel(predicate, getSelectableCampaigns()),
            },
        },
        {
            name: 'Status',
            filterCriteria: '%line_item_computed_state%',
            operatorType: OperatorTypes.BOOLEAN,
            searchValueType: SearchValueTypes.SELECT,
            selectValues: Object.values(LINE_ITEM_COMPUTED_STATUS),
        },
    ];
}

export { getSelectableCampaigns, getFilterConfigPredicates, getCampaignParameterValueLabel, fetchAndStoreAllCampaigns };

<template>
    <div>
        <SspTargeting v-model="lineItem" :was-data-loaded.sync="wasDataLoaded" />
        <br />
        <DealTargeting v-show="showDealTargeting" :line-item="lineItem" />
    </div>
</template>

<script>
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import SspTargeting from './SspTargeting';
import DealTargeting from './DealTargeting';

export default {
    name: 'SspTargetingOverview',
    components: { SspTargeting, DealTargeting },
    mixins: [LineItemModel, TargetingCountUpdate],
    data() {
        return {
            showDealTargeting: false,
        };
    },
    watch: {
        'lineItem.targetings.sspTargetings': {
            handler() {
                this.showDealTargeting = this.lineItem.targetings.sspTargetings?.length > 0;
            },
            immediate: true,
        },
    },
};
</script>

<template>
    <div>
        <SrSelect
            :value="kpiType"
            :items="availableKpiTypes"
            item-text="name"
            item-value="value"
            label="KPI Type"
            :clearable="true"
            @change="onTypeChange"
        />
        <CurrencyInput
            v-if="shouldDisplayCurrencyInput"
            :value="kpiTarget"
            :currency="currency"
            :precision="2"
            :disabled="shouldDisableKpiTargetInput"
            label="KPI Target"
            @input="onTargetInput"
        />
        <NumberInput
            v-if="shouldDisplayPercentageInput"
            :value="kpiTarget"
            unit="%"
            :precision="2"
            :disabled="shouldDisableKpiTargetInput"
            :rules="percentageInputRules"
            label="KPI Target"
            @input="onTargetInput"
        />
    </div>
</template>
<script>
import KpiTypes from '@/modules/campaign/components/CampaignForm/CampaignFormStepBasic/KpiConfigurator/KpiTypes';
import { SrSelect } from '@ads/design-system';
import CurrencyInput from '@/components/CurrencyInput/CurrencyInput';
import NumberInput from '@/components/NumberInput/NumberInput';

export default {
    name: 'KpiConfigurator',
    components: {
        SrSelect,
        CurrencyInput,
        NumberInput,
    },
    props: {
        value: {
            type: Object,
            required: false,
        },
        currency: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            availableKpiTypes: KpiTypes.getAll(),
            percentageInputRules: [(v) => !v || v <= 100 || `Value should be between 0 and 100 (percentage)`],
        };
    },
    computed: {
        kpiType() {
            return this.value?.type ?? null;
        },
        kpiTarget() {
            return this.value?.target ?? null;
        },
        shouldDisableKpiTargetInput() {
            return this.kpiType == null;
        },
        shouldDisplayCurrencyInput() {
            return this.kpiType == null || this.isKpiTargetCurrency;
        },
        shouldDisplayPercentageInput() {
            return this.isKpiTargetPercentage;
        },
        isKpiTargetCurrency() {
            return KpiTypes.getTargetDataTypeOf(this.kpiType) === 'currency';
        },
        isKpiTargetPercentage() {
            return KpiTypes.getTargetDataTypeOf(this.kpiType) === 'percentage';
        },
    },
    methods: {
        onTypeChange(newType) {
            if (newType == null) {
                this.emitInput(null);
                return;
            }
            this.emitInput({
                type: newType,
                target: this.kpiTarget,
            });
        },
        onTargetInput(newTarget) {
            const targetToEmit = newTarget === '' ? null : newTarget;
            this.emitInput({
                type: this.kpiType,
                target: targetToEmit,
            });
        },
        emitInput(value) {
            this.$emit('input', value);
        },
    },
};
</script>

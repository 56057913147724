<template>
    <SelectableTree v-model="selectedTree" :source-nodes="categoryNodes" :mode.sync="mode" :is-loading="isLoading" />
</template>

<script>
import SelectableTree from '@/components/SelectableTree/SelectableTree';
import { SelectedTree } from '@/components/SelectableTree/SelectedTree';
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import { categoryService } from './service/CategoryService';
import { categoryTransformer } from './CategoryTransformer';

export default {
    name: 'Category',
    components: { SelectableTree },
    mixins: [LineItemModel, TargetingCountUpdate],
    data() {
        return {
            categoryNodes: [],
            isLoading: false,
            mode: this.getInitialMode(),
            selectedTree: null,
            categoryService,
            categoryTransformer,
        };
    },
    watch: {
        selectedTree: {
            deep: true,
            handler() {
                this.updateLineItemTargetings();
            },
        },
    },
    created() {
        this.getContentCategories();
        this.selectedTree = new SelectedTree(this.getSelectedContentCategories());
    },
    methods: {
        updateLineItemTargetings() {
            if (!this.lineItem.targetings) {
                this.lineItem.targetings = {};
            }

            const selectedNodes = this.selectedTree.getSelectedNodeTrees();
            this.$set(
                this.lineItem.targetings,
                'categoryTargetings',
                this.categoryTransformer.selectedNodesToCategoryTargetings(selectedNodes),
            );
        },
        async getContentCategories() {
            this.isLoading = true;
            try {
                this.categoryNodes = this.categoryTransformer.categoriesToSelectableNodes(await this.categoryService.getAll());
            } finally {
                this.isLoading = false;
            }
        },
        getSelectedContentCategories() {
            if (this.lineItem.targetings?.categoryTargetings?.length > 0) {
                return this.categoryTransformer.categoryTargetingsToSelectedNodes(this.lineItem.targetings.categoryTargetings);
            }
            return [];
        },
        getInitialMode() {
            if (this.value.targetings?.categoryTargetings?.length > 0) {
                const { isExcluded } = this.value.targetings.categoryTargetings[0];
                return isExcluded ? SELECTION_MODE.EXCLUDE : SELECTION_MODE.INCLUDE;
            }
            return SELECTION_MODE.INCLUDE;
        },
    },
};
</script>

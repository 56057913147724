<template>
    <VContainer v-if="isLoading" data-testid="ContextualTargeting__skeleton-container">
        <VRow>
            <VCol cols="12" class="pa-0">
                <VSkeletonLoader type="image" height="56" />
            </VCol>
        </VRow>
    </VContainer>
    <VContainer v-else data-testid="ContextualTargeting__container">
        <VForm v-model="isValid" @input="emitIsValid">
            <VRow>
                <VCol cols="12" class="pl-0 pr-0">
                    <ErrorBox :show="Boolean(errorMessage)" :message="errorMessage" />
                </VCol>
            </VRow>
            <VRow>
                <VCol cols="12" class="pa-0">
                    <SrInput v-model="inputValue" label="Peer39 Category ID" type="text" :rules="rules" />
                </VCol>
            </VRow>
            <VRow v-if="isCategorySelected">
                <VCol cols="12" class="pa-0">
                    <SrNotification
                        data-testid="ContextualTargeting__SrNotification_category"
                        :title="categoryName"
                        :description="categoryPriceDescription"
                    />
                    <SrNotification
                        v-if="doesCategoryHaveExpiration"
                        data-testid="ContextualTargeting__SrNotification_expiration"
                        :type="categoryExpirationWarningType"
                        :title="categoryExpiresAt"
                    />
                </VCol>
            </VRow>
        </VForm>
    </VContainer>
</template>

<script>
import { SrInput, SrNotification } from '@ads/design-system';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import { mapGetters } from 'vuex';
import ErrorBox from '@/components/ErrorBox/ErrorBox';
import { USER_LOCALE } from '@/config';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';

export default {
    name: 'ContextualTargeting',
    components: { ErrorBox, SrInput, SrNotification },
    mixins: [LineItemModel, TargetingCountUpdate],
    data() {
        const inputValue = this.getInitialCategoryId();
        return {
            inputValue,
            isValid: null,
        };
    },
    computed: {
        ...mapGetters({
            isLoading: 'contextual/isLoadingContextualCategories',
            errorMessage: 'contextual/contextualCategoriesFetchErrorMessage',
        }),
        selectedCategoryId() {
            const inputValueAsNumber = Number(this.inputValue);
            if (this.inputValue && !Number.isNaN(inputValueAsNumber)) {
                return inputValueAsNumber;
            }
            return null;
        },
        selectedCategory() {
            if (this.selectedCategoryId == null) {
                return null;
            }
            return this.$store.getters['contextual/contextualCategoryById'](this.selectedCategoryId);
        },
        isValidCategoryIdSelected() {
            return this.isCategorySelected || this.isInputEmpty;
        },
        isCategorySelected() {
            return Boolean(this.selectedCategory);
        },
        isInputEmpty() {
            return this.inputValue == null || this.inputValue?.length === 0;
        },
        doesCategoryHaveExpiration() {
            return Boolean(this.selectedCategory?.expiresAt);
        },
        isCategoryExpired() {
            return Boolean(this.selectedCategory?.expiresAt) && this.selectedCategory.expiresAt <= new Date();
        },
        willCategoryExpire() {
            return Boolean(this.selectedCategory?.expiresAt) && this.selectedCategory.expiresAt > new Date();
        },
        categoryName() {
            return this.selectedCategory?.name;
        },
        categoryPriceDescription() {
            return `CPM for the category is ${this.selectedCategory?.cpm} USD.`;
        },
        categoryExpiresAt() {
            const expirationDate = this.formatDate(this.selectedCategory?.expiresAt);
            if (this.isCategoryExpired) {
                return `This category expired on ${expirationDate}.`;
            }
            if (this.willCategoryExpire) {
                return `This category expires on ${expirationDate}.`;
            }
            return '';
        },
        categoryExpirationWarningType() {
            if (this.isCategoryExpired) {
                return 'error';
            }
            if (this.willCategoryExpire) {
                return 'warning';
            }
            return '';
        },
        rules() {
            if (this.isCategoryExpired) {
                return ['Expired category selected.'];
            }
            if (this.isValidCategoryIdSelected) {
                return [];
            }
            return ['Invalid category ID'];
        },
        currentCount() {
            if (this.selectedCategory) {
                return 1;
            }

            return 0;
        },
    },
    watch: {
        selectedCategory(value) {
            this.updateTargetingOnLineItem(value);
        },
    },
    created() {
        this.fetchContextualCategories();
    },
    methods: {
        getInitialCategoryId() {
            const contextualCategoryTargeting = this.value.targetings?.contextualCategoryTargeting;
            if (contextualCategoryTargeting) {
                return contextualCategoryTargeting.category.id;
            }
            return null;
        },
        updateTargetingOnLineItem(category) {
            if (category) {
                this.setTargetingOnLineItem({ category });
            } else {
                this.setTargetingOnLineItem(null);
            }
        },
        setTargetingOnLineItem(contextualCategoryTargeting) {
            this.lineItem.targetings.contextualCategoryTargeting = contextualCategoryTargeting;
        },
        fetchContextualCategories() {
            this.$store.dispatch('contextual/fetchContextualCategories');
        },
        emitIsValid(isValid) {
            this.$emit('update:isValid', isValid);
        },
        formatDate(date) {
            if (this.isDateInvalid(date)) {
                return `${date}`;
            }
            return Intl.DateTimeFormat(USER_LOCALE).format(date);
        },
        isDateInvalid(date) {
            if (date instanceof Date) {
                return Number.isNaN(date.getTime());
            }
            return true;
        },
    },
};
</script>

<template>
    <VContainer v-if="isLoading">
        <VRow>
            <VCol cols="12" class="pa-0">
                <VSkeletonLoader type="image" height="56" />
            </VCol>
        </VRow>
        <VRow class="pt-8">
            <VCol cols="6" class="pa-0 pr-1">
                <VSkeletonLoader type="image" height="300" />
            </VCol>
            <VCol cols="6" class="pa-0 pl-1">
                <VSkeletonLoader type="image" height="300" />
            </VCol>
        </VRow>
    </VContainer>
    <VContainer v-else>
        <VRow>
            <VCol v-if="canSelectModes" cols="2" class="pa-0 mr-2">
                <SrSelect
                    :value="mode"
                    :items="selectableModes"
                    class="mode"
                    hide-details
                    single-line
                    data-testid="Select_Specific_Dropdown_Option"
                    @change="onModeChange"
                />
            </VCol>
            <VCol class="pa-0">
                <SrSearchInput v-model.trim="search" label="Search" />
            </VCol>
            <VCol v-if="formMode" cols="2" class="d-flex pa-0 ml-2">
                <VSpacer />
                <SrButton
                    color="blue lighten-5 blue--text text--lighten-1"
                    elevation="0"
                    class="create-list-button"
                    data-testid="SelectableTree__create-list-button"
                    @click="create"
                >
                    Add New
                </SrButton>
            </VCol>
        </VRow>
        <VRow>
            <VCol class="pa-0 mr-1 source" data-testid="SelectableTree__source">
                <TreeNode
                    v-for="node in searchedSourceNodes"
                    :key="node.id"
                    :node="node"
                    :selected-tree="value"
                    :mode="mode"
                    :form-mode="formMode"
                    @edit="edit"
                />
            </VCol>
            <VCol class="pa-0 ml-1 selected" data-testid="SelectableTree__selected">
                <SelectedTreeNode
                    v-for="selectedNode in value.getChildren()"
                    :key="selectedNode.getId()"
                    :selected-node="selectedNode"
                    :selected-tree="value"
                    :mode="mode"
                    :form-mode="formMode"
                    @edit="edit"
                />
            </VCol>
        </VRow>
    </VContainer>
</template>

<script>
import { SrButton, SrSelect } from '@ads/design-system';
import { SelectedTree } from '@/components/SelectableTree/SelectedTree';
import SrSearchInput from '@/components/SrSearchInput';
import { SELECTION_MODE } from './types';
import TreeNode from './TreeNode';
import SelectedTreeNode from './SelectedTreeNode';

export default {
    name: 'SelectableTree',
    components: { TreeNode, SelectedTreeNode, SrSelect, SrButton, SrSearchInput },
    props: {
        sourceNodes: {
            type: Array,
            default: () => [],
        },
        value: {
            type: SelectedTree,
            default: () => new SelectedTree(),
        },
        mode: {
            type: String,
            default: SELECTION_MODE.COMBINED,
        },
        formMode: {
            type: Boolean,
            default: false,
        },
        disableModeSelection: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            search: '',
        };
    },
    computed: {
        searchedSourceNodes() {
            if (this.search) {
                const lowercaseSearchTerm = this.search.toLowerCase();
                return this.sourceNodes.filter((node) => node.name.toLowerCase().includes(lowercaseSearchTerm));
            }
            return this.sourceNodes;
        },
        selectableModes() {
            return [SELECTION_MODE.INCLUDE, SELECTION_MODE.EXCLUDE];
        },
        canSelectModes() {
            return !this.disableModeSelection && this.mode !== SELECTION_MODE.COMBINED;
        },
    },
    watch: {
        sourceNodes: {
            deep: true,
            handler(sourceNodes) {
                this.value.updateNodesFrom(sourceNodes);
            },
        },
        value: {
            deep: true,
            handler() {
                this.$emit('input', this.value);
            },
        },
    },
    methods: {
        create() {
            this.$emit('create');
        },
        edit(node) {
            this.$emit('edit', node);
        },
        onModeChange(newMode) {
            if (newMode === SELECTION_MODE.INCLUDE) {
                this.value.includeAllSelected();
            } else if (newMode === SELECTION_MODE.EXCLUDE) {
                this.value.excludeAllSelected();
            }
            this.$emit('update:mode', newMode);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@ads/design-system/src/scss/variables';
@import '~@ads/design-system/src/scss/mixins';

.create-list-button {
    width: 100% !important;
    height: 56px !important;
}

.source,
.selected {
    @include custom-scrollbar;
    flex-grow: 1;
    flex-shrink: 0;
    width: 0;
    min-width: 250px;
    height: 300px;
    overflow: auto;
    border: 1px solid $gray-light;
    border-radius: 3px;
}
</style>

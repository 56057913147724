import { SelectedNodeTree } from '@/components/SelectableTree/SelectedTree';

export default class TargetableTransformer {
    private readonly targetingProperty: string;

    private readonly cachedTargetings;

    constructor(targetingProperty: string, cachedTargetings) {
        this.targetingProperty = targetingProperty;
        this.cachedTargetings = cachedTargetings;
    }

    public transformSelectedNodeTreeToTargetable(selectedTreeNodes: SelectedNodeTree[]) {
        return selectedTreeNodes.map((treeNode) => {
            const cachedTargeting = this.cachedTargetings.find(
                (targeting) => targeting[this.targetingProperty].id === treeNode.getId(),
            );

            return this.createTargeting(treeNode, cachedTargeting);
        });
    }

    // noinspection JSMethodCanBeStatic
    private createTargeting(treeNode: SelectedNodeTree, cachedTargeting) {
        if (cachedTargeting) {
            return {
                id: cachedTargeting.id,
                [this.targetingProperty]: cachedTargeting[this.targetingProperty],
                isExcluded: treeNode.isExcluded(),
            };
        }
        return {
            id: null,
            [this.targetingProperty]: { id: treeNode.getId() },
            isExcluded: treeNode.isExcluded(),
        };
    }
}

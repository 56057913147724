<script>
export default {
    name: 'BasicForm',
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        /**
         * @model
         */
        value: {
            type: Object,
            required: true,
        },
        /**
         * Vuex store action to be called on submit
         */
        submitAction: {
            type: String,
            required: true,
        },
        afterSubmitRoute: {
            type: String,
            required: true,
        },
        afterSubmitRouteParams: {
            type: Object,
            default: () => {
                // intended
            },
        },
        afterCloseRoute: {
            type: String,
            default: '',
        },
        afterCloseRouteParams: {
            type: Object,
            default: () => {
                // intended
            },
        },
        nonLinear: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            errorMessage: null,
        };
    },
    computed: {
        isEditForm() {
            return Boolean(this.value.id);
        },
    },
    methods: {
        async save() {
            // eslint-disable-next-line no-console
            console.log('saves object.');
        },
        async submit(doneCallback) {
            try {
                await this.save();
                await this.$router.push({ name: this.afterSubmitRoute, params: this.afterSubmitRouteParams });
            } catch (error) {
                this.errorMessage = error?.response?.message || String(error);
            } finally {
                if (typeof doneCallback === 'function') {
                    doneCallback();
                }
            }
        },
        async close() {
            if (this.afterCloseRoute) {
                return this.$router.push({ name: this.afterCloseRoute, params: this.afterCloseRouteParams });
            }
            return this.$router.back();
        },
    },
};
</script>

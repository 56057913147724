export const appWebTargetings = [
    {
        key: 'isWeb',
        title: 'Web',
        label: 'Enable Websites',
        infoMessage: 'You can not select Websites if you have location targetings selected.',
    },
    {
        key: 'isApp',
        title: 'App',
        label: 'Enable Applications',
    },
];

<template>
    <VCol sm="5">
        <div class="d-flex">
            <SrHeadline level="3" class="mb-0" weight="bold"> Viewability Targeting </SrHeadline>
        </div>
        <SrCheckbox v-model="lineItem.isViewabilityTargetingEnabled" label="Apply Viewability Targeting" />
        <SrSelect
            v-model="lineItem.viewabilityTargetingThreshold"
            :disabled="isDisabled"
            label="Viewability Threshold"
            :items="selectableViewabilityTargetingThresholdValues"
            required
        />
    </VCol>
</template>

<script>
import { SrCheckbox, SrHeadline, SrSelect } from '@ads/design-system';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import {
    DEFAULT_LINE_ITEM_VIEWABILITY_TARGETING_THRESHOLD_VALUE,
    LINE_ITEM_VIEWABILITY_TARGETING_THRESHOLD_VALUES,
} from 'api-contracts/line-items/ViewabilityTargetingThreshold';

export default {
    name: 'ViewabilityTargeting',
    components: {
        SrHeadline,
        SrCheckbox,
        SrSelect,
    },
    mixins: [LineItemModel],
    computed: {
        isDisabled() {
            return !this.lineItem.isViewabilityTargetingEnabled;
        },
        isViewabilityTargetingThresholdValueValid() {
            return LINE_ITEM_VIEWABILITY_TARGETING_THRESHOLD_VALUES.includes(this.lineItem.viewabilityTargetingThreshold);
        },
        selectableViewabilityTargetingThresholdValues() {
            return LINE_ITEM_VIEWABILITY_TARGETING_THRESHOLD_VALUES.map((value) => ({
                label: `${value * 100}% or above`,
                value,
            }));
        },
    },
    watch: {
        'lineItem.isViewabilityTargetingEnabled': function () {
            this.$emit('input', this.lineItem);
        },
        'lineItem.viewabilityTargetingThreshold': function () {
            if (!this.isViewabilityTargetingThresholdValueValid) {
                this.setDefaultViewabilityTargetingThresholdValue();
            }

            this.$emit('input', this.lineItem);
        },
    },
    methods: {
        setDefaultViewabilityTargetingThresholdValue() {
            this.lineItem.viewabilityTargetingThreshold = DEFAULT_LINE_ITEM_VIEWABILITY_TARGETING_THRESHOLD_VALUE;
        },
    },
};
</script>

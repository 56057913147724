<template>
    <ActionModal
        :value="isOpen"
        title="Duplicate Campaign"
        :error="errorMessage"
        :should-close-on-click-outside-or-esc="false"
        :is-cancel-button-disabled="isDuplicating"
        :is-loading="isLoading"
        @cancel="cancelDuplication"
    >
        <template #content>
            <VRow>
                <VCol class="pt-0 pb-0">
                    <SrHeadline level="2" class="mt-3 mb-3" color="brand-red--text">
                        {{ originalCampaignName }}
                    </SrHeadline>
                </VCol>
            </VRow>
            <VRow>
                <VCol class="pt-0 pb-3">
                    <SrHeadline level="3" class="mt-3 mb-3 duplication-info-text" color="text--lighten-1">
                        {{ duplicationInfoText }}
                    </SrHeadline>
                </VCol>
            </VRow>
            <VRow>
                <VCol class="pt-0 pb-3">
                    <SrNotification
                        v-for="(notification, index) in campaignNotifications"
                        :key="index"
                        :title="notification.title"
                        :type="notification.type"
                        :description="notification.description"
                    />
                </VCol>
            </VRow>
            <CampaignDuplicateForm
                v-model="duplicationConfig"
                :original-runtime="originalCampaignRuntime"
                :disable-editing="isLoading"
                @isFormValid="onIsFormValidChange"
            />
        </template>
        <template #actions>
            <SrButton
                type="secondary"
                class="confirm"
                :loading="isDuplicating"
                :disabled="shouldDisableDuplicateButton"
                data-testid="CampaignDuplicateModal__duplicate_button"
                @click="duplicateCampaign"
            >
                Duplicate
            </SrButton>
        </template>
    </ActionModal>
</template>

<script>
import ActionModal from '@/components/ActionModal/ActionModal';
import { SrButton, SrHeadline, SrNotification } from '@ads/design-system';
import { campaignDuplicationService } from '@/modules/campaign/services/CampaignDuplicationService';
import CampaignDuplicateForm from '@/modules/campaign/components/CampaignDuplicateForm/CampaignDuplicateForm';
import { campaignService } from '@/modules/campaign/services/CampaignService';
import getInitialDuplicationConfigFor from '@/modules/campaign/views/getInitialDuplicationConfigFor';
import getEmptyCampaignDuplicationInfo from '@/modules/campaign/views/getEmptyCampaignDuplicationInfo';

export default {
    name: 'CampaignDuplicateModal',
    components: {
        CampaignDuplicateForm,
        ActionModal,
        SrButton,
        SrHeadline,
        SrNotification,
    },
    props: {
        originalCampaignId: {
            type: Number,
        },
    },
    data() {
        return {
            isOpen: true,
            duplicationConfig: getInitialDuplicationConfigFor(getEmptyCampaignDuplicationInfo()),
            originalCampaignName: '',
            originalCampaignRuntime: null,
            isFormValid: true,
            isLoadingCampaign: false,
            loadCampaignErrorMessage: null,
            isDuplicating: false,
            duplicateErrorMessage: null,
            duplicationInfoText:
                "Duplication of campaign involves creation of copies of the campaign's line items. " +
                'This can take up to a few minutes.',
            campaignNotifications: [],
        };
    },
    computed: {
        shouldDisableDuplicateButton() {
            return !this.isFormValid || this.isLoading || this.loadCampaignErrorMessage != null;
        },
        isLoading() {
            return this.isLoadingCampaign || this.isDuplicating;
        },
        errorMessage() {
            return this.loadCampaignErrorMessage != null ? this.loadCampaignErrorMessage : this.duplicateErrorMessage;
        },
    },
    created() {
        this.loadCampaign();
    },
    methods: {
        onIsFormValidChange(value) {
            this.isFormValid = value;
        },
        cancelDuplication() {
            this.isOpen = false;
            this.$emit('cancel');
        },
        handleDuplicationSuccess() {
            this.isOpen = false;
            this.$emit('completed');
        },
        async loadCampaign() {
            this.loadCampaignErrorMessage = null;
            this.isLoadingCampaign = true;
            try {
                const duplicationInfo = await campaignService.getDuplicationInfo(this.originalCampaignId);

                this.duplicationConfig = getInitialDuplicationConfigFor(duplicationInfo);
                this.originalCampaignName = duplicationInfo.name;
                this.originalCampaignRuntime = duplicationInfo.runtime;
                this.campaignNotifications = duplicationInfo.notifications;
            } catch (e) {
                this.loadCampaignErrorMessage = 'Could not get data from original campaign.';
            } finally {
                this.isLoadingCampaign = false;
            }
        },
        async duplicateCampaign() {
            this.duplicateErrorMessage = null;
            this.isDuplicating = true;
            try {
                await campaignDuplicationService.duplicate(this.originalCampaignId, this.duplicationConfig);
                this.handleDuplicationSuccess();
            } catch (error) {
                this.duplicateErrorMessage = error.message;
            } finally {
                this.isDuplicating = false;
            }
        },
    },
};
</script>

<style>
.duplication-info-text {
    line-height: 1.5;
}
</style>

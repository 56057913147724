export default {
    isLoading: (state) => state.isLoading,
    errorMessage: (state) => state.errorMessage,
    lineItemDefaultTargetings: (state) => {
        if (state.config?.lineItems?.targetings) {
            return state.config?.lineItems?.targetings;
        }
        return null;
    },
};

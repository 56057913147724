<template>
    <CampaignDuplicateModal
        :original-campaign-id="id"
        @cancel="onDuplicationCompletedOrCancelled"
        @completed="onDuplicationCompletedOrCancelled"
    />
</template>

<script>
import CampaignDuplicateModal from '@/modules/campaign/components/CampaignDuplicateModal';

export default {
    name: 'CampaignDuplicateView',
    components: { CampaignDuplicateModal },
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
    },
    methods: {
        onDuplicationCompletedOrCancelled() {
            this.navigateBack();
        },
        navigateBack() {
            this.$router.back();
        },
    },
};
</script>

<template>
    <SelectableTree
        v-model="selectedTargeting"
        :source-nodes="nodes"
        :mode.sync="mode"
        :form-mode="true"
        :disable-mode-selection="true"
        @create="navigateToCreateForm"
        @edit="navigateToEditForm"
    />
</template>

<script>
import { NODE_SELECTION_STATUS, SelectedNodeTree, SelectedTree } from '@/components/SelectableTree/SelectedTree';
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import { Node } from '@/components/SelectableTree/Node';
import SelectableTree from '../SelectableTree';
import TargetableTransformer from './TargetableTransformer';

export default {
    components: {
        SelectableTree,
    },
    props: {
        lineItem: {
            type: Object,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        formMode: {
            type: Boolean,
            default: false,
        },
        disableModeSelection: {
            type: Boolean,
            default: false,
        },
        targetingProperty: {
            type: String,
            required: true,
        },
        targeting: {
            type: String,
            required: true,
        },
        editRouteParam: {
            type: String,
            default: '',
        },
        routes: {
            type: Object,
            default: () => {
                /* ignore */
            },
        },
    },
    data() {
        return {
            selectedTargeting: this.initializeSelectedNodes(this.lineItem?.targetings[this.targeting]),
            targetingTransformer: new TargetableTransformer(
                this.targetingProperty,
                this.createSelectedTargetingCache(this.targeting),
            ),
            mode: this.getInitialMode(),
            nodes: [],
        };
    },
    watch: {
        items() {
            this.nodes = this.items.map((item) => new Node(item));
        },
        selectedTargeting: {
            handler() {
                const targetings = this.selectedTargeting.getSelectedNodeTrees();
                this.$set(
                    this.lineItem.targetings,
                    this.targeting,
                    this.targetingTransformer.transformSelectedNodeTreeToTargetable(targetings),
                );
            },
            deep: true,
        },
    },
    methods: {
        initializeSelectedNodes() {
            let selectedNodes = [];

            selectedNodes = this.lineItem?.targetings[this.targeting]?.map(
                (targeting) =>
                    new SelectedNodeTree(
                        targeting[this.targetingProperty].id,
                        targeting[this.targetingProperty].name,
                        NODE_SELECTION_STATUS.INCLUDED,
                    ),
            );

            return new SelectedTree(selectedNodes);
        },
        createSelectedTargetingCache(targeting) {
            if (!this.lineItem?.targetings[targeting]?.length) {
                return [];
            }
            return [...this.lineItem.targetings[targeting]];
        },
        getInitialMode() {
            if (this.lineItem.targetings[this.targeting]?.length) {
                if (this.lineItem.targetings[this.targeting][0].isExcluded) {
                    return SELECTION_MODE.EXCLUDE;
                }
            }
            return SELECTION_MODE.INCLUDE;
        },
        navigateToCreateForm() {
            if (this.lineItem.id) {
                return this.$router.push({
                    name: this.routes.existingCreateRoute,
                    params: {
                        id: this.lineItem.id,
                    },
                });
            }

            return this.$router.push({
                name: this.routes.createRoute,
            });
        },
        navigateToEditForm(node) {
            if (this.lineItem.id) {
                return this.$router.push({
                    name: this.routes.existingUpdateRoute,
                    params: {
                        id: this.lineItem.id,
                        [this.editRouteParam]: node.id,
                    },
                });
            }

            return this.$router.push({
                name: this.routes.updateRoute,
                params: {
                    [this.editRouteParam]: node.id,
                },
            });
        },
    },
};
</script>

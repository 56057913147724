var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-bar-wrapper"},[_c('div',{staticClass:"progress-bar",style:({ backgroundColor: _vm.backgroundColor })},[_c('div',{staticClass:"current-area",class:{ 'is-100-percent': _vm.valueAsPercent === 100 },style:({ width: `${_vm.valueAsPercent}%` })},[_c('SrTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"tooltip-activator"},on)),_c('span',{staticClass:"total-value",style:({ color: _vm.totalColor })},[_vm._t("total",function(){return [_vm._v(" "+_vm._s(_vm.total)+" ")]},{"total":_vm.total})],2)]}}],null,true)},[_c('span',{staticClass:"tooltip-text"},[_vm._t("value",function(){return [_vm._v(" "+_vm._s(_vm.value)+" ")]},{"value":_vm.value})],2)])],1),_c('SrTooltip',{style:({
                width: '1px',
                position: 'absolute',
                left: `${_vm.thresholdAsPercent}%`,
            }),attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"threshold-bar",style:({
                        left: `${_vm.thresholdAsPercent}%`,
                        borderColor: _vm.thresholdColor,
                    })},on))]}}])},[_c('span',{staticClass:"tooltip-text"},[_vm._t("threshold",function(){return [_vm._v(" "+_vm._s(_vm.threshold)+" ")]},{"threshold":_vm.threshold})],2)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
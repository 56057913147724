<template>
    <CampaignForm
        v-model="campaign"
        submit-action="campaign/edit"
        after-submit-route="campaign-list"
        :is-loading="isLoading"
        non-linear
    />
</template>

<script>
import { campaignService } from '@/modules/campaign/services/CampaignService';
import CampaignForm from '@/modules/campaign/components/CampaignForm';
import getEmptyCampaign from '@/modules/campaign/views/getEmptyCampaign';

export default {
    name: 'Edit',
    components: { CampaignForm },
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            campaign: { ...getEmptyCampaign(), id: this.id },
        };
    },
    created() {
        this.loadCampaign();
    },
    methods: {
        async loadCampaign() {
            this.isLoading = true;
            try {
                this.campaign = await campaignService.getCampaign(this.id);
            } catch (error) {
                this.error = error.response.message;
            }
            this.isLoading = false;
        },
    },
};
</script>

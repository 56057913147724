import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import IContextualCategoryResponse from 'api-contracts/contextual/IContextualCategoryResponse';
import { API_URL } from '@/config';

export default class ContextualCategoryService {
    private readonly requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async getContextualCategories(): Promise<IContextualCategoryResponse[]> {
        const options: IRequestOptions = {
            domain: 'programmatic-campaigns',
            resource: 'line-item',
            action: ACTION_NAME.READ,
        };

        return this.requestService.get('/contextual-categories', options);
    }
}

export const contextualCategoryService = new ContextualCategoryService(new RequestService({ baseUrl: API_URL }));

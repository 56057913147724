<template>
    <div>
        <DomainListForm
            v-model="domainList"
            :submit-action="actionType"
            :after-close-route="closingRoute"
            :after-close-route-params="closeRouteParams"
            :after-submit-route="closingRoute"
            :after-submit-route-params="afterSubmitParams"
            :load-error="errorMessage"
            :is-loading="isLoading"
            non-linear
        />
        <RouterView />
    </div>
</template>

<script>
import { domainListService } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/DomainList/services/DomainListService';
import DomainListForm from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/DomainList/DomainListForm/DomainListForm';
import TargetingFormRoutes from '@/modules/line-items/views/targetings/TargetingFormRoutes';
import { ACTION_TYPES } from '@/modules/line-items/views/targetings/types';

export default {
    name: 'EditApplicationNameList',
    components: {
        DomainListForm,
    },
    mixins: [TargetingFormRoutes],
    props: {
        lineItem: {
            type: Object,
            required: true,
        },
        listId: {
            type: [Number, String],
            required: true,
            default: null,
        },
    },
    data() {
        return {
            domainList: {
                name: '',
                description: '',
                holdingId: null,
                domains: [],
            },
            domainListService,
            isLoading: false,
            errorMessage: null,
            actionType: ACTION_TYPES.EDIT,
        };
    },
    async created() {
        await this.loadDomainLists();
    },
    methods: {
        async loadDomainLists() {
            this.isLoading = true;
            try {
                this.domainList = await this.domainListService.getById(this.listId);
            } catch (error) {
                this.errorMessage = error?.response?.message || String(error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

import { NODE_SELECTION_STATUS, SelectedNodeTree } from '@/components/SelectableTree/SelectedTree';
import { BrowserLanguageTargeting } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/BrowserLanguage/services/BrowserLanguage';

export default class SelectedTreeNodesTransformer {
    transform(browserLanguageTargetings: BrowserLanguageTargeting[]): SelectedNodeTree[] {
        if (!browserLanguageTargetings) {
            return [];
        }
        return this.getSelectedNodes(browserLanguageTargetings);
    }

    private getSelectedNodes(browserLanguageTargetings: BrowserLanguageTargeting[]): SelectedNodeTree[] {
        return browserLanguageTargetings.map(
            (browserLanguageTargeting) =>
                new SelectedNodeTree(
                    browserLanguageTargeting.browserLanguage.id,
                    browserLanguageTargeting.browserLanguage.name,
                    browserLanguageTargeting.isExcluded ? NODE_SELECTION_STATUS.EXCLUDED : NODE_SELECTION_STATUS.INCLUDED,
                    null,
                    [],
                ),
        );
    }
}

import { Country } from '@/modules/geo/types';

export type GeoState = {
    countries: Country[];
    isLoading: { [key in GEO_LOADING]: boolean };
};

export enum GEO_LOADING {
    COUNTRIES,
}

export default {
    countries: [],
    isLoading: {
        [GEO_LOADING.COUNTRIES]: false,
    },
};

import { ACTION_NAME, RequestService } from '@ads/iam-library';
import { CREATIVE_MANAGEMENT_API_URL } from '@/config';
import CREATIVE_TYPES from 'api-contracts/creatives/CreativeTypes';

export default class CreativePreviewService {
    private readonly requestService: RequestService;

    private readonly endpointByCreativeType = new Map<CREATIVE_TYPES, string>([
        [CREATIVE_TYPES.IMAGE, 'image-creatives'],
        [CREATIVE_TYPES.HTML_CREATIVE, 'html-creatives'],
        [CREATIVE_TYPES.HTML5_CREATIVE, 'html5-creatives'],
        [CREATIVE_TYPES.VIDEO, 'video-creatives'],
    ]);

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async getCreativePreview(creativeDspId: number, creativeType: CREATIVE_TYPES): Promise<string> {
        const creativesEndpoint = this.endpointByCreativeType.get(creativeType);

        if (!creativesEndpoint) {
            throw new Error(`Preview is not supported for creative type '${creativeType}'.`);
        }

        return this.requestService.get(`/${creativesEndpoint}/${creativeDspId}/preview`, {
            domain: 'creative-management',
            resource: 'creative',
            action: ACTION_NAME.READ,
        });
    }
}

export const creativePreviewService = new CreativePreviewService(new RequestService({ baseUrl: CREATIVE_MANAGEMENT_API_URL }));

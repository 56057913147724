/* eslint-disable no-await-in-loop */
import BackgroundProcessService, {
    backgroundProcessService,
} from '@/modules/background-process/services/BackgroundProcessService';
import DealService, { dealService } from '@/modules/deals/services/DealService';
import BACKGROUND_PROCESS_STATUS from 'api-contracts/background-process/Status';
import { sleep } from '@/shared/utils';
import { BackgroundProcessStatusResponse } from '@/modules/background-process/types';

class DealDeleteBackgroundService {
    private readonly dealService: DealService;

    private readonly pollDelayInMs: number;

    private readonly backgroundProcessService: BackgroundProcessService;

    constructor(_dealService: DealService, _backgroundProcessService: BackgroundProcessService, pollDelayInMs = 1000) {
        this.dealService = _dealService;
        this.backgroundProcessService = _backgroundProcessService;
        this.pollDelayInMs = pollDelayInMs;
    }

    public async delete(dealId: number): Promise<unknown> {
        const response = await this.dealService.delete(dealId);
        const result = await this.waitUntilBackgroundProcessFinishes(response.id);
        if (this.backgroundProcessFailed(result)) {
            throw this.createErrorFrom(response.id);
        }
        return result?.processData?.result;
    }

    private async waitUntilBackgroundProcessFinishes(backgroundProcessId: string): Promise<BackgroundProcessStatusResponse> {
        let result = await this.getBackgroundProcess(backgroundProcessId);
        while (this.isBackgroundProcessRunning(result)) {
            result = await this.getBackgroundProcess(backgroundProcessId);
            await this.sleep();
        }
        return result;
    }

    private async getBackgroundProcess(backgroundProcessId: string): Promise<BackgroundProcessStatusResponse> {
        return this.backgroundProcessService.getBackgroundProcess(backgroundProcessId);
    }

    private isBackgroundProcessRunning(result: BackgroundProcessStatusResponse): boolean {
        return result?.status === BACKGROUND_PROCESS_STATUS.RUNNING || result?.status === BACKGROUND_PROCESS_STATUS.QUEUED;
    }

    private backgroundProcessFailed(result: BackgroundProcessStatusResponse): boolean {
        return result.status === BACKGROUND_PROCESS_STATUS.FAILED;
    }

    private createErrorFrom(backgroundProcessId: string): Error {
        return new Error(`The long running process with ID ${backgroundProcessId} failed. Please try again.`);
    }

    private async sleep(): Promise<void> {
        await sleep(this.pollDelayInMs);
    }
}

const dealDeleteBackgroundService = new DealDeleteBackgroundService(dealService, backgroundProcessService);
export { DealDeleteBackgroundService, dealDeleteBackgroundService };

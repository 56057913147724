export default {
    setCountries(state, countries): void {
        state.countries = countries;
    },
    setLoading(state, { key, value }): void {
        state.isLoading = {
            ...state.isLoading,
            [key]: value,
        };
    },
};

import IFanIdSegmentResponse from 'api-contracts/fan-id/IFanIdSegmentResponse';
import { FanIdState } from '@/modules/fan-id/store/FanIdState';

const getters = {
    isLoadingFanIdSegments: (state: FanIdState): boolean => state.isLoadingFanIdSegments,
    fanIdSegmentsFetchErrorMessage: (state: FanIdState): string | null => state.fanIdSegmentsFetchErrorMessage,
    fanIdSegments: (state: FanIdState): IFanIdSegmentResponse[] => state.fanIdSegments || [],
    fanIdSegmentById:
        (state: FanIdState) =>
        (id: string): IFanIdSegmentResponse | null => {
            const fanIdSegment = state.fanIdSegments.find((segment) => segment.id === id);
            if (!fanIdSegment) {
                return null;
            }
            return fanIdSegment;
        },
};

export default getters;

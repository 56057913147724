<template>
    <VContainer>
        <SrHeadline level="3" weight="bold"> Frequency Capping </SrHeadline>
        <SrCheckbox v-model="canModifyFrequencyCappings" label="Only Deliver" @change="onOnlyDeliverChange($event)" />
        <SrEditableList
            v-slot="{ item, index }"
            class="mt-4"
            item-name="Frequency Capping"
            :min-items="1"
            :max-items="3"
            :items="frequencyCappingsValues"
            :disabled="areInputsDisabled"
            @addItem="addFrequencyCapping"
            @removeItem="removeFrequencyCapping"
        >
            <FrequencyCappingForm
                :frequency-capping="item"
                :disabled="areInputsDisabled"
                @update="(updatedItem) => updateFrequencyCapping(updatedItem, index)"
            />
        </SrEditableList>
    </VContainer>
</template>

<script>
import { SrCheckbox, SrHeadline, SrEditableList } from '@ads/design-system';
import FrequencyCappingForm from '@/components/FrequencyCappingForm';
import FrequencyCappings from '@/modules/line-items/components/LineItemForm/LineItemFormStepStrategy/FrequencyCappingList/FrequencyCappings';

export default {
    name: 'FrequencyCapping',
    components: { SrEditableList, SrHeadline, SrCheckbox, FrequencyCappingForm },
    props: {
        value: {
            type: Array,
            required: false,
            default: null,
        },
        isEnabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            frequencyCappings: new FrequencyCappings(this.value),
            canModifyFrequencyCappings: this.isEnabled,
        };
    },
    computed: {
        frequencyCappingsValues() {
            return this.frequencyCappings?.getValues() || [];
        },
        areInputsDisabled() {
            return !this.isEnabled;
        },
    },
    watch: {
        value: {
            handler(newValue) {
                this.frequencyCappings = new FrequencyCappings(newValue);
            },
            deep: true,
        },
        isEnabled(value) {
            this.canModifyFrequencyCappings = value;
            this.onOnlyDeliverChange();
        },
    },
    methods: {
        addFrequencyCapping() {
            this.frequencyCappings.addEmpty();
            this.emitUpdate();
        },
        removeFrequencyCapping(index) {
            this.frequencyCappings.remove(index);
            this.emitUpdate();
        },
        updateFrequencyCapping(updatedItem, index) {
            this.frequencyCappings.update(updatedItem, index);
            this.emitUpdate();
        },
        onOnlyDeliverChange() {
            if (!this.canModifyFrequencyCappings) {
                this.frequencyCappings.removeInvalid();
            } else if (this.frequencyCappings.isEmpty()) {
                this.frequencyCappings.addEmpty();
            }
            this.emitUpdate();
        },
        emitUpdate() {
            this.$emit('update', {
                frequencyCappings: this.frequencyCappings.getCopyOfValues(),
                isEnabled: this.canModifyFrequencyCappings,
            });
        },
    },
};
</script>

<template>
    <div>
        <VideoPlayerSizeForm
            v-model="videoPlayerSize"
            :submit-action="actionType"
            :after-close-route="closingRoute"
            :after-close-route-params="closeRouteParams"
            :after-submit-route="closingRoute"
            :after-submit-route-params="afterSubmitParams"
            :load-error="errorMessage"
            :is-loading="isLoading"
            non-linear
        />
        <RouterView />
    </div>
</template>

<script>
import { ACTION_TYPES } from '@/modules/line-items/views/targetings/types';
import VideoPlayerSizeForm from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/VideoPlayerSize/VideoPlayerSizeForm';
import { videoPlayerSizeService } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/VideoPlayerSize/service/VideoPlayerSizeService';
import TargetingFormRoutes from '@/modules/line-items/views/targetings/TargetingFormRoutes';

export default {
    name: 'EditVideoPlayerSize',
    components: {
        VideoPlayerSizeForm,
    },
    mixins: [TargetingFormRoutes],
    props: {
        lineItem: {
            type: Object,
            required: true,
        },
        videoPlayerSizeId: {
            type: [Number, String],
            required: true,
            default: null,
        },
    },
    data() {
        return {
            videoPlayerSize: {
                name: '',
                isEditable: false,
                sizeRanges: [videoPlayerSizeService.createEmptySizeRange()],
                excludedSizes: [videoPlayerSizeService.createEmptySize()],
            },
            actionType: ACTION_TYPES.EDIT,
            videoPlayerSizeService,
            errorMessage: null,
            isLoading: false,
        };
    },
    async created() {
        await this.fetchVideoPlayerSize();
    },
    methods: {
        async fetchVideoPlayerSize() {
            this.isLoading = true;
            try {
                this.videoPlayerSize = await this.videoPlayerSizeService.getById(this.videoPlayerSizeId);
                if (!this.videoPlayerSize.excludedSizes || this.videoPlayerSize.excludedSizes.length === 0) {
                    this.videoPlayerSize.excludedSizes = [this.videoPlayerSizeService.createEmptySize()];
                }
            } catch (error) {
                this.errorMessage = error?.response?.message || String(error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

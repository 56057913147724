<template>
    <SrModalPage type="basic" :show="show" @close="redirectToParentRoute">
        <VRow slot="content" no-gutters>
            <VCol>
                <SrHeadline weight="bold"> Changelog of "{{ entityName }}" </SrHeadline>
            </VCol>

            <Divider />

            <Table :headers="headers" :items="changelogItems" :is-loading="isLoading">
                <template #changes="{ changes }">
                    <ChangelogChanges :changes="changes" />
                </template>
            </Table>
        </VRow>
    </SrModalPage>
</template>

<script>
import { SrHeadline, SrModalPage } from '@ads/design-system';
import Table from '@/components/Table/Table';
import { changelogTableHeader } from '@/components/Changelog/config';
import { USER_LOCALE, USER_TIMEZONE } from '@/config';
import Divider from '@/components/Divider/Divider';
import ChangelogChanges from './ChangelogChanges';

export default {
    components: { Divider, ChangelogChanges, SrModalPage, Table, SrHeadline },
    props: {
        id: {
            type: Number,
            required: true,
        },
        entity: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            headers: changelogTableHeader,
            isLoading: false,
            show: true,
        };
    },
    computed: {
        changelogItems() {
            if (this.isLoading) {
                return [];
            }

            const changelogItems = this.$store.getters[`${this.entity}/changelog`];

            return changelogItems.map((changelogItem) => ({
                date: this.dateToLocalString(changelogItem.date),
                actionType: changelogItem.actionType,
                changes: changelogItem.changes,
            }));
        },
        entityName() {
            const campaign = this.$store.getters[`${this.entity}/campaignById`](this.id);
            if (!campaign) {
                return '';
            }
            return campaign.name;
        },
    },
    async created() {
        await this.fetchChangelog();
    },
    methods: {
        async fetchChangelog() {
            this.isLoading = true;
            await this.$store.dispatch(`${this.entity}/fetchChangelog`, this.id);
            this.isLoading = false;
        },
        async redirectToParentRoute() {
            const parentRoute = this.$route.matched[0];

            return this.$router.push(parentRoute);
        },
        dateToLocalString(date) {
            return new Date(date).toLocaleString(USER_LOCALE, { timeZone: USER_TIMEZONE });
        },
    },
};
</script>

<template>
    <div>
        <VideoPlayerSizeForm
            v-model="videoPlayerSize"
            :submit-action="actionType"
            :after-close-route="closingRoute"
            :after-close-route-params="closeRouteParams"
            :after-submit-route="closingRoute"
            :after-submit-route-params="afterSubmitParams"
        />
        <RouterView />
    </div>
</template>

<script>
import { ACTION_TYPES } from '@/modules/line-items/views/targetings/types';
import VideoPlayerSizeForm from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/VideoPlayerSize/VideoPlayerSizeForm';
import TargetingFormRoutes from '@/modules/line-items/views/targetings/TargetingFormRoutes';

export default {
    name: 'CreateVideoPlayerSizeForm',
    components: {
        VideoPlayerSizeForm,
    },
    mixins: [TargetingFormRoutes],
    props: {
        lineItem: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            actionType: ACTION_TYPES.CREATE,
            videoPlayerSize: {
                name: '',
                isEditable: true,
                sizeRanges: [
                    {
                        minWidth: null,
                        maxWidth: null,
                        minHeight: null,
                        maxHeight: null,
                    },
                ],
                excludedSizes: [
                    {
                        width: null,
                        height: null,
                    },
                ],
            },
        };
    },
};
</script>

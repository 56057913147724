import { USER_LOCALE } from '@/config';

export default function formatNumberToCurrency(value: number, currencyCode: string, locale: string = USER_LOCALE): string {
    const formatter = new Intl.NumberFormat(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        style: 'currency',
        currency: currencyCode,
    });
    return formatter.format(value).replace('\xa0', '');
}

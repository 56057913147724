import ErrorRuleFactory from '@/modules/config/ErrorRuleFactory';

export default class ApplicationNameListErrorRuleFactory extends ErrorRuleFactory {
    protected name = (nameHolding): [(v: any) => boolean | string] => {
        const [name] = nameHolding.split(',');
        return [(v): boolean | string => v !== name || `'${name}' already exists for this holding`];
    };

    protected mappings = {
        'name, holding_id': 'name',
    };
}

export const applicationNameListErrorRuleFactory = new ApplicationNameListErrorRuleFactory();

<template>
    <VRow>
        <VCol cols="4">
            <SrSelect
                :value="filter.sportId"
                :items="filterItems.sports"
                label="Sport"
                :loading="loadingState.isLoadingSports"
                sort-order="ASC"
                required
                searchable
                clearable
                @change="onSportIdChange"
            />
        </VCol>
        <VCol cols="4">
            <SrSelect
                :value="filter.countryId"
                :disabled="!filter.sportId"
                :items="filterItems.countries"
                label="Country"
                :loading="loadingState.isLoadingCountries"
                sort-order="ASC"
                required
                searchable
                clearable
                @change="onCountryIdChange"
            />
        </VCol>
        <VCol cols="4">
            <SrSelect
                v-model="filter.tournamentId"
                :disabled="!filter.countryId"
                :items="filterItems.tournaments"
                label="Tournament"
                :loading="loadingState.isLoadingTournaments"
                sort-order="ASC"
                searchable
                clearable
            />
        </VCol>
    </VRow>
</template>

<script>
import { SrSelect } from '@ads/design-system';

export default {
    name: 'SportCalendarFilter',
    components: {
        SrSelect,
    },
    props: {
        filterItems: {
            type: Object,
            required: true,
        },
        loadingState: {
            type: Object,
            default: () => ({
                isLoadingSports: false,
                isLoadingCountries: false,
                isLoadingTournaments: false,
            }),
        },
    },
    data() {
        return {
            filter: {
                tournamentId: null,
                countryId: null,
                sportId: null,
            },
        };
    },
    watch: {
        filter: {
            deep: true,
            handler() {
                this.$emit('filter-update', this.filter);
            },
        },
    },
    methods: {
        onCountryIdChange(countryId) {
            this.filter = {
                sportId: this.filter.sportId,
                countryId,
                tournamentId: null,
            };
        },
        onSportIdChange(sportId) {
            this.filter = {
                sportId,
                countryId: null,
                tournamentId: null,
            };
        },
    },
};
</script>

import { Pixel } from '@/modules/pixels/types';
import { Predicate } from '@ads/predicate-model';
import { PixelSegmentTreeNode } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Segment/PixelSegments/types';

// noinspection JSMethodCanBeStatic
export default class PixelSegmentsTree {
    pixelSegment: Predicate<number>;

    pixels: Pixel[];

    constructor(pixelSegment: Predicate<number>, pixels: Pixel[]) {
        this.pixelSegment = pixelSegment;
        this.pixels = pixels;
    }

    transformToTree(): PixelSegmentTreeNode[] {
        return this.transformPredicatesToTreeNode(this.pixelSegment.getChildren(), this.pixelSegment);
    }

    private transformPredicatesToTreeNode(predicates: Predicate<number>[], parent: Predicate<number>): PixelSegmentTreeNode[] {
        return predicates.map((predicate, index) => {
            const treeNode: PixelSegmentTreeNode = {
                value: predicate.parameterValue || this.randomId(),
                label: this.getLabel(predicate),
                inclusionLabel: this.getInclusionLabel(predicate, parent, index),
            };
            if (predicate.isComplex() && predicate.hasChildren()) {
                treeNode.children = this.transformPredicatesToTreeNode(predicate.getChildren(), predicate);
            }

            return treeNode;
        });
    }

    private getLabel(predicate: Predicate<number>): string {
        return this.pixels.find((pixel) => pixel.dspId === predicate.parameterValue)?.name;
    }

    private getInclusionLabel(predicate: Predicate<number>, parent: Predicate<number>, index: number): string {
        if (index === 0) {
            if (predicate.isNegated) {
                return 'NOT FOR';
            }
            return 'FOR';
        }

        let operator: string = parent.operator;
        if (predicate.isNegated) {
            operator += ' NOT';
        }
        return operator;
    }

    private randomId() {
        // @ts-ignore
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            // eslint-disable-next-line no-bitwise
            (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
        );
    }
}

<template>
    <div>
        <div v-for="pixelSegment in items" :key="pixelSegment.value" class="mt-2 ml-7">
            <div>
                <p class="mb-0 tree-operator">
                    {{ pixelSegment.inclusionLabel }}
                </p>
                <p v-if="pixelSegment.value" class="ma-0 pa-0 tree-value">
                    {{ pixelSegment.label }}
                </p>
                <PixelSegmentTree v-if="pixelSegment.children" :key="pixelSegment.value" :items="pixelSegment.children" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PixelSegmentTree',
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss">
@import '~@ads/design-system/src/scss/variables';

.tree-operator {
    font-size: 12px;
    color: $gray-border;
}
.tree-value {
    color: $brand-blue;
    font-size: 14px;
}
</style>

<template>
    <SelectableTree
        v-model="selectedTree"
        :source-nodes="domainLists"
        :mode.sync="mode"
        :form-mode="true"
        :is-loading="isLoading"
        @create="navigateToCreateForm"
        @edit="navigateToEditForm"
    />
</template>

<script>
import SelectableTree from '@/components/SelectableTree/SelectableTree';
import { domainListService } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/DomainList/services/DomainListService';
import DomainListViewModel from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/DomainList/DomainListViewModel';
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import './index.scss';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';

export default {
    name: 'DomainList',
    components: { SelectableTree },
    mixins: [LineItemModel, TargetingCountUpdate],
    data() {
        return {
            domainListViewModel: new DomainListViewModel(domainListService, this.$root.user),
            domainLists: [],
            mode: SELECTION_MODE.INCLUDE,
            selectedTree: undefined,
            isLoading: false,
        };
    },
    computed: {
        isExistingLineItem() {
            return this.domainListViewModel.isExisting(this.lineItem);
        },
    },
    watch: {
        async $route(currentRoute, previousRoute) {
            const hasBeenEditingDomainLists = previousRoute.name.includes('domain-list');
            if (hasBeenEditingDomainLists) {
                this.domainLists = await this.executeWithLoading(
                    this.domainListViewModel.getDomainListsBy(this.lineItem.campaign.id),
                );
            }
        },
        selectedTree: {
            deep: true,
            handler() {
                this.syncLineItemWithSelectedTree();
            },
        },
    },
    async created() {
        const initialDataPromise = this.domainListViewModel.loadInitialDataFor(this.lineItem);
        const { domainLists, domainListTargetings } = await this.executeWithLoading(initialDataPromise);
        this.domainLists = domainLists;
        if (domainListTargetings) {
            this.$set(this.lineItem.targetings, 'domainLists', domainListTargetings);
        }
        this.mode = this.domainListViewModel.getInitialTreeModeFrom(domainListTargetings);
        this.selectedTree = await this.domainListViewModel.createSelectedTreeFrom(this.lineItem.targetings.domainLists);
        // needed to check for changes
        this.wasDataLoaded = true;
    },
    methods: {
        async executeWithLoading(promise) {
            this.isLoading = true;
            try {
                // eslint-disable-next-line @typescript-eslint/return-await
                return await promise;
            } finally {
                this.isLoading = false;
            }
        },
        syncLineItemWithSelectedTree() {
            this.$set(
                this.lineItem.targetings,
                'domainLists',
                this.domainListViewModel.createDomainListTargetingsFrom(this.selectedTree),
            );
        },
        navigateToCreateForm() {
            if (this.isExistingLineItem) {
                return this.$router.push({
                    name: 'existing-domain-list-create',
                    params: {
                        id: this.lineItem.id,
                    },
                });
            }
            return this.$router.push({
                name: 'domain-list-create',
            });
        },
        navigateToEditForm(node) {
            if (this.isExistingLineItem) {
                return this.$router.push({
                    name: 'existing-domain-list-update',
                    params: {
                        id: this.lineItem.id,
                        listId: node.id,
                    },
                });
            }
            return this.$router.push({
                name: 'domain-list-update',
                params: {
                    listId: node.id,
                },
            });
        },
    },
};
</script>

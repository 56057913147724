<template>
    <SrModalPage type="basic" :loading="isLoading" :show="show" @close="close">
        <VForm v-if="publisherList" slot="content" ref="form" v-model="isValid">
            <SrHeadline level="1" weight="bold" class="mb-6">
                {{ isEditForm ? 'Edit Publisher List' : 'Create New Publisher List' }}
            </SrHeadline>
            <VRow>
                <VCol xlg="4" lg="5" md="6" sm="12">
                    <SrHeadline level="3" weight="bold" class="mt-3 mb-6"> Basic Details </SrHeadline>
                    <SrInput v-model.number="publisherList.dspId" label="DSP ID" disabled />
                    <SrInput
                        v-model="publisherList.name"
                        label="Name"
                        required
                        :rules="rules.name"
                        :disabled="isEditDisabled"
                        class="list-name mb-3"
                    />
                    <SrSelect
                        v-model="publisherList.holdingId"
                        :items="selectableHoldings"
                        label="Select Holding"
                        searchable
                        persistent-hint
                        :required="!canEditSharedLists"
                        :clearable="!isEditForm && canEditSharedLists"
                        :disabled="!canEditHolding || isEditDisabled"
                        :hint="holdingDropdownHint"
                        sort-order="ASC"
                    />
                </VCol>
                <VCol xlg="4" lg="5" md="6" sm="12" offset-lg="1">
                    <SrHeadline level="3" weight="bold" class="mt-3 mb-6">
                        Publishers
                        <SrInfoBox>NEW LINE SEPARATED LIST OF PUBLISHERS</SrInfoBox>
                    </SrHeadline>
                    <VTextarea
                        v-model="stringifiedPublishers"
                        outlined
                        name="input-7-4"
                        label="Publishers"
                        :rules="rules.publishers"
                        :readonly="isEditDisabled"
                        required
                    >
                        <template #label>
                            <SrInputLabel label="Publishers" required />
                        </template>
                    </VTextarea>
                </VCol>
            </VRow>
        </VForm>
        <div slot="footer" class="d-flex justify-end">
            <VRow no-gutters class="mt-3">
                <VCol cols="9">
                    <ErrorBox :show="showErrorMessage" :message="shownError" />
                </VCol>
                <VCol cols="1" />
                <VCol cols="2">
                    <SrButton
                        slot="header-cta"
                        :disabled="!isValid"
                        :loading="isSaving"
                        class="blue white--text mr-2 save-publisher-list"
                        depressed
                        large
                        @click="submit"
                    >
                        Submit
                    </SrButton>
                </VCol>
            </VRow>
        </div>
    </SrModalPage>
</template>

<script>
import { SrButton, SrHeadline, SrInfoBox, SrInput, SrInputLabel, SrModalPage, SrSelect } from '@ads/design-system';
import { mapGetters } from 'vuex';
import rulesMixins from '@/modules/config/rulesMixins';
import BasicForm from '@/components/Form/BasicForm';
import ErrorBox from '@/components/ErrorBox';
import UserMixin from '@/mixins/UserMixin';
import { isRequired } from '@/shared/validation';
import isNewlineSeparatedArray from '@/shared/validation/isNewlineSeparatedArray';
import { publisherListErrorRuleFactory } from './services/PublisherListErrorRuleFactory';
import { publisherListService } from './services/PublisherListService';

export default {
    name: 'PublisherListForm',
    components: {
        SrModalPage,
        ErrorBox,
        SrHeadline,
        SrInput,
        SrSelect,
        SrInputLabel,
        SrButton,
        SrInfoBox,
    },
    extends: BasicForm,
    mixins: [rulesMixins, UserMixin],
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        loadError: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            show: true,
            publisherList: this.value,
            customRules: {},
            isEditable: false,
            isValid: false,
            isSaving: false,
            canEditSharedLists: false,
            publisherListService,
            disableErrorBox: false,
            errorMessage: null,
        };
    },
    computed: {
        ...mapGetters({
            holdings: 'iam/holdings',
        }),
        shownError() {
            return this.errorMessage || this.loadError;
        },
        showErrorMessage() {
            return Boolean(this.shownError) && !this.disableErrorBox;
        },
        selectableHoldings() {
            return this.holdings.map((holding) => ({
                value: holding.id,
                label: holding.name,
            }));
        },
        userHasOnlyOneHolding() {
            return this.holdings.length === 1;
        },
        canEditHolding() {
            // cannot edit holding on a shared list
            if (this.isEditForm && !this.publisherList.holdingId) {
                return false;
            }
            if (!this.isEditForm && this.canEditSharedLists) {
                return true;
            }
            return !this.userHasOnlyOneHolding;
        },
        holdingDropdownHint() {
            if (this.canEditSharedLists) {
                return 'Select only in case you want to restrict this publisher list to a specific Holding.';
            }
            return null;
        },
        rules() {
            return {
                name: this.nameRules('Publisher List name', this.customRules.name),
                publishers: [isRequired, isNewlineSeparatedArray('Publishers', 1, 10_000)],
            };
        },
        isEditDisabled() {
            return this.isEditForm && !this.isEditable;
        },
        stringifiedPublishers: {
            get() {
                return this.publisherList.publishers.join('\n');
            },
            set(publishers) {
                this.publisherList.publishers = publishers.split('\n');
            },
        },
    },
    watch: {
        value(value) {
            this.publisherList = value;
            this.setIsEditable();
        },
    },
    async created() {
        await this.setIsEditable();
        await this.setCanEditSharedLists();
    },
    methods: {
        async setIsEditable() {
            if (!this.isEditForm) {
                this.isEditable = true;
                return;
            }
            this.isEditable = this.canEditList(this.publisherList.holdingId);
        },
        async setCanEditSharedLists() {
            this.canEditSharedLists = await this.canEditSharedList();
        },
        async save() {
            try {
                this.disableErrorBox = true;
                this.isSaving = true;

                await this.publisherListService[this.submitAction](this.publisherList);
            } catch (e) {
                this.customRules = publisherListErrorRuleFactory.create(e);
                if (!Object.keys(this.customRules).length) {
                    this.disableErrorBox = false;
                    this.errorMessage = e?.response?.message || String(e);
                }
                await this.$nextTick();
                this.$refs.form.validate();
                throw e;
            } finally {
                this.isSaving = false;
            }
        },
        async close() {
            await this.$router.push({
                name: this.afterCloseRoute,
                params: this.afterCloseRouteParams,
            });
        },
    },
};
</script>

<style lang="scss">
.footer {
    border-top: 1px solid #e3e3e3;
}
</style>

<template>
    <SelectableTree
        v-model="selectedTree"
        :source-nodes="bundleListNodes"
        :mode.sync="mode"
        :form-mode="true"
        :is-loading="isLoading"
        @create="navigateToCreateForm"
        @edit="navigateToEditForm"
    />
</template>

<script>
import './index.scss';
import SelectableTree from '@/components/SelectableTree';
import { SelectedTree } from '@/components/SelectableTree/SelectedTree';
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import UserMixin from '@/mixins/UserMixin';
import ListTargetingMixin from '@/mixins/ListTargetingMixin';
import { bundleListService } from './services/BundleListService';

export default {
    name: 'BundleList',
    components: { SelectableTree },
    mixins: [LineItemModel, TargetingCountUpdate, UserMixin, ListTargetingMixin],
    data() {
        return {
            mode: this.getInitialMode(),
            cachedBundleLists: this.createCachedBundleList(),
            selectedTree: new SelectedTree(),
            bundleListService,
            isLoading: false,
            bundleListNodes: [],
        };
    },
    watch: {
        async $route(currentRoute, previousRoute) {
            if (previousRoute.name.includes('bundle-list')) {
                this.bundleListNodes = await this.getLists(this.bundleListService);
            }
        },
        selectedTree: {
            deep: true,
            handler() {
                this.updateLineItem();
            },
        },
    },
    async created() {
        this.selectedTree = await this.initializeTree('bundleListTargetings', 'bundleList');
        this.bundleListNodes = await this.getLists(this.bundleListService);
    },
    methods: {
        createCachedBundleList() {
            if (!this.value?.targetings?.bundleListTargetings?.length) {
                return [];
            }
            return [...this.value.targetings.bundleListTargetings];
        },
        updateLineItem() {
            const listIds = this.selectedTree.getSelectedNodeTrees().map((node) => node.id);
            this.$set(
                this.lineItem.targetings,
                'bundleListTargetings',
                listIds.map((listId) => {
                    const cachedBundleListTargeting = this.cachedBundleLists.find((list) => list.bundleList.id === listId);
                    return this.createBundleListTargeting(cachedBundleListTargeting, listId);
                }),
            );
        },
        createBundleListTargeting(currentNode, listId) {
            if (currentNode) {
                return {
                    id: currentNode.id,
                    bundleList: currentNode.bundleList,
                    isExcluded: this.mode === SELECTION_MODE.EXCLUDE,
                };
            }
            return {
                id: null,
                bundleList: {
                    id: listId,
                },
                isExcluded: this.mode === SELECTION_MODE.EXCLUDE,
            };
        },
        getInitialMode() {
            if (this.value.targetings?.bundleListTargetings?.length) {
                if (this.value.targetings?.bundleListTargetings[0].isExcluded) {
                    return SELECTION_MODE.EXCLUDE;
                }
            }
            return SELECTION_MODE.INCLUDE;
        },
        navigateToCreateForm() {
            if (this.lineItem.id) {
                return this.$router.push({
                    name: 'existing-bundle-list-create',
                    params: {
                        id: this.lineItem.id,
                    },
                });
            }

            return this.$router.push({
                name: 'bundle-list-create',
            });
        },
        navigateToEditForm(node) {
            if (this.lineItem.id) {
                return this.$router.push({
                    name: 'existing-bundle-list-update',
                    params: {
                        id: this.lineItem.id,
                        listId: node.id,
                    },
                });
            }

            return this.$router.push({
                name: 'bundle-list-update',
                params: {
                    listId: node.id,
                },
            });
        },
    },
};
</script>

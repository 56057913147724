import { Pixel, PIXEL_CONVERSION_TYPE } from '@/modules/pixels/types';
import { Predicate } from '@ads/predicate-model';
import IPixelSegmentTemplate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Segment/PixelSegments/Templates/IPixelSegmentTemplate';
import MissingPixelsError from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Segment/PixelSegments/Templates/errors/MissingPixelsError';

export default abstract class AbstractRetargetingTemplate implements IPixelSegmentTemplate {
    private readonly pixels: Pixel[];

    protected abstract readonly requiredPixelTypes: PIXEL_CONVERSION_TYPE[];

    constructor(availablePixels: Pixel[]) {
        this.pixels = availablePixels;
    }

    toPredicate(): Predicate {
        const pixels = this.findRequiredPixels();
        return new Predicate({
            type: 'COMPLEX',
            operator: 'AND',
            isNegated: false,
            children: [
                new Predicate({
                    type: 'ATOMIC',
                    isNegated: false,
                    filterCriteria: 'dspId',
                    predicateDefinition: 'IS',
                    parameterValue: pixels.shift().dspId,
                }),
                new Predicate({
                    type: 'COMPLEX',
                    operator: 'OR',
                    isNegated: true,
                    children: [
                        new Predicate({
                            type: 'ATOMIC',
                            isNegated: false,
                            filterCriteria: 'dspId',
                            predicateDefinition: 'IS',
                            parameterValue: pixels.shift().dspId,
                        }),
                        new Predicate({
                            type: 'ATOMIC',
                            isNegated: false,
                            filterCriteria: 'dspId',
                            predicateDefinition: 'IS',
                            parameterValue: pixels.shift().dspId,
                        }),
                    ],
                }),
            ],
        });
    }

    private findRequiredPixels(): Pixel[] {
        const missingPixelTypes = [];
        const pixels = this.requiredPixelTypes.map((pixelConversionType) => {
            const pixel = this.pixels.find((currentPixel) => currentPixel.pixelConversionType === pixelConversionType);
            if (!pixel) {
                missingPixelTypes.push(pixelConversionType);
            }
            return pixel;
        });

        if (missingPixelTypes.length > 0) {
            throw new MissingPixelsError(missingPixelTypes);
        }

        return pixels;
    }
}

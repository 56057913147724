import { API_URL } from '@/config';
import BusinessEntityService from '@/modules/iam/services/BusinessEntityService';
import HoldingService from '@/modules/iam/services/HoldingService';
import { RequestService } from '@ads/iam-library';

const requestService = new RequestService({ baseUrl: API_URL });
const holdingService = new HoldingService(requestService);
const businessEntityService = new BusinessEntityService(requestService);

export default {
    async initialize({ dispatch }) {
        await Promise.all([dispatch('fetchHoldings'), dispatch('fetchBusinessEntities')]);
    },

    async fetchHoldings({ commit }) {
        const holdings = await holdingService.listHoldings();

        commit('setHoldings', holdings);
    },

    async fetchBusinessEntities({ commit }) {
        const businessEntities = await businessEntityService.listBusinessEntities();

        commit('setBusinessEntities', businessEntities);
    },
};

import { getTimezoneOffset } from 'date-fns-tz';
import { getDateInGivenTimezone } from '@/shared/date-time';

export default function getStartOfDateInGivenTimezone(timeZone: string, date: Date): Date {
    const timeZoneOffset = getTimezoneOffset(timeZone);

    const currentDateInGivenTimezone = getDateInGivenTimezone(timeZone, date);
    const utcStartOfCurrentDateInGivenTimeZone = currentDateInGivenTimezone.setUTCHours(0, 0, 0, 0);

    return new Date(utcStartOfCurrentDateInGivenTimeZone - timeZoneOffset);
}

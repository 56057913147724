<template>
    <div>
        <SrTable :headers="headers" :items="items" :show-footer="true">
            <template #start="{ item }">
                <TextDateInput
                    v-if="isEditOpenFor(item)"
                    v-model="currentlyEditedItem.start"
                    :min="currentlyEditedItem.previousEnd"
                    :max="currentlyEditedItem.end"
                    label="Start Date"
                    @update:error="onStartErrorChange"
                />
                <div v-else>{{ item.start }}</div>
            </template>
            <template #end="{ item }">
                <TextDateInput
                    v-if="isEditOpenFor(item)"
                    v-model="currentlyEditedItem.end"
                    :min="currentlyEditedItem.start"
                    :max="currentlyEditedItem.nextStart"
                    label="End Date"
                    @update:error="onEndErrorChange"
                />
                <div v-else>{{ item.end }}</div>
            </template>
            <template #budget="{ item }">
                <CurrencyInput
                    v-if="isEditOpenFor(item)"
                    v-model="currentlyEditedItem.budget"
                    :currency="currencyCode"
                    :precision="0"
                    label="Budget"
                    compact
                    required
                    @update:error="onBudgetErrorChange"
                    @keyup.native.enter="onConfirmEdit"
                />
                <div v-else>
                    {{ formatNumberToCurrency(item.budget, currencyCode) }}
                </div>
            </template>
            <template #actions="{ item }">
                <div v-if="isEditOpenFor(item)">
                    <SrButton icon :disabled="currentlyEditedItemHasError" @click="onConfirmEdit()">
                        <SrIcon icon="TickBrandBlue" size="xs" :disabled="currentlyEditedItemHasError" />
                    </SrButton>
                    <SrButton icon @click="onCancelEdit">
                        <SrIcon icon="CloseBrandRed" size="xs" />
                    </SrButton>
                </div>
                <div v-else>
                    <SrButton icon @click="onEdit(item)">
                        <SrIcon icon="edit" size="xs" />
                    </SrButton>
                    <SrButton icon @click="onRemove(item)">
                        <SrIcon icon="trash" size="xs" />
                    </SrButton>
                </div>
            </template>
        </SrTable>
        <div class="d-flex justify-end mt-5">
            <SrButton type="secondary" @click="onAdd">Add new Pacing Target</SrButton>
        </div>
    </div>
</template>

<script>
import { SrTable, SrButton, SrIcon } from '@ads/design-system';
import { computed, ref } from 'vue';
import { formatNumberToCurrency } from '@/shared/utils';
import CurrencyInput from '@/components/CurrencyInput';
import TextDateInput from '@/components/TextDateInput';
import { addDays, endOfMonth, parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import headers from '@/modules/campaign/components/CampaignPacing/headers';

export default {
    name: 'CampaignPacing',
    components: { TextDateInput, CurrencyInput, SrTable, SrButton, SrIcon },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        runtime: {
            type: Object,
            required: true,
        },
        currencyCode: {
            type: String,
            default: 'USD',
        },
    },
    setup(props, { emit }) {
        const items = computed(() =>
            props.value == null
                ? []
                : props.value.map((item, index) => ({
                      index,
                      ...item,
                  })),
        );

        const currentlyEditedItem = ref(null);
        const isEditOpenFor = (item) => currentlyEditedItem.value != null && currentlyEditedItem.value.index === item.index;
        const onBudgetErrorChange = (hasError) => {
            currentlyEditedItem.value.hasBudgetError = hasError;
        };
        const onStartErrorChange = (hasError) => {
            currentlyEditedItem.value.hasStartError = hasError;
        };
        const onEndErrorChange = (hasError) => {
            currentlyEditedItem.value.hasEndError = hasError;
        };
        const currentlyEditedItemHasError = computed(
            () =>
                currentlyEditedItem.value != null &&
                (currentlyEditedItem.value.hasStartError ||
                    currentlyEditedItem.value.hasEndError ||
                    currentlyEditedItem.value.hasBudgetError),
        );
        const toStandardItem = (item) => ({ start: item.start, end: item.end, budget: item.budget });
        const onConfirmEdit = () => {
            if (currentlyEditedItemHasError.value) {
                return;
            }
            const changedItems = items.value.map((item, index) => {
                const currentEditValue = currentlyEditedItem.value;
                if (index === currentEditValue.index) {
                    return toStandardItem(currentEditValue);
                }
                return toStandardItem(item);
            });
            currentlyEditedItem.value = null;
            emit('input', changedItems);
        };
        const onCancelEdit = () => {
            currentlyEditedItem.value = null;
        };
        const onEdit = (item) => {
            const index = item.index;
            const previousEnd = index > 0 ? items.value[index - 1].end : null;
            const nextStart = index < items.value.length - 1 ? items.value[index + 1].start : null;
            currentlyEditedItem.value = {
                ...item,
                previousEnd,
                nextStart,
                hasStartError: false,
                hasEndError: false,
                hasBudgetError: false,
            };
        };
        const onRemove = (itemToRemove) => {
            const changedItems = items.value.filter((_, index) => index !== itemToRemove.index).map(toStandardItem);
            emit('input', changedItems.length === 0 ? null : changedItems);
        };
        const lastItem = computed(() => (items.value.length > 0 ? items.value[items.value.length - 1] : null));
        const formatAsIsoDate = (date) => format(date, 'yyyy-MM-dd');
        const runtimeStart = computed(() => utcToZonedTime(parseISO(props.runtime.startDate), props.runtime.timeZone));
        const getNextItem = () => {
            const start = lastItem.value != null ? addDays(parseISO(lastItem.value.end), 1) : runtimeStart.value;
            const end = endOfMonth(start);
            const budget = lastItem.value != null ? lastItem.value.budget : 0;
            return { start: formatAsIsoDate(start), end: formatAsIsoDate(end), budget };
        };
        const onAdd = () => {
            const nextItem = getNextItem();
            const previousItems = items.value.map(toStandardItem);
            previousItems.push(nextItem);
            emit('input', previousItems);
        };

        return {
            items,
            headers,
            currentlyEditedItem,
            isEditOpenFor,
            currentlyEditedItemHasError,
            onConfirmEdit,
            onCancelEdit,
            onBudgetErrorChange,
            onStartErrorChange,
            onEndErrorChange,
            onEdit,
            onRemove,
            onAdd,
        };
    },
    methods: { formatNumberToCurrency },
};
</script>

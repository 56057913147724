export default class CaretPositionCalculator {
    public static fromFormattedToNumber(
        positionInFormattedValue: number,
        formattedValue: string,
        numberValue: number | string,
    ): number {
        const numberValueAsString = String(numberValue);

        let shiftBy = 0;
        for (
            let currentPositionInFormattedValue = 0;
            currentPositionInFormattedValue < positionInFormattedValue;
            currentPositionInFormattedValue++
        ) {
            const currentPositionInNumberValue = currentPositionInFormattedValue - shiftBy;
            const areCharactersInBothValuesSame =
                formattedValue.at(currentPositionInFormattedValue) === numberValueAsString.at(currentPositionInNumberValue);
            const isDecimalSeparator = numberValueAsString.at(currentPositionInNumberValue) === '.';
            if (!areCharactersInBothValuesSame && !isDecimalSeparator) {
                shiftBy++;
            }
        }

        return positionInFormattedValue - shiftBy;
    }
}

export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            campaign: this.value,
        };
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                this.campaign = value;
            },
        },
        campaign: {
            deep: true,
            handler(value) {
                this.$emit('input', value);
            },
        },
    },
};

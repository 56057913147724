<template>
    <div>
        Select Retargeting Strategy
        <VRadioGroup v-model="selectedTemplate" @change="onTemplateChange">
            <div v-for="template in templates" :key="template.id" class="d-flex flex-column">
                <div class="d-flex pixel-segment-template">
                    <SrRadioButton :value="template.id" :label="template.label" class="mt-1 mb-1 d-flex" />
                    <SrInfoBox v-if="template.description" v-bind="{ [tooltipDirection]: true }" class="d-flex ml-auto">
                        <span class="description" v-text="template.description" />
                    </SrInfoBox>
                </div>
                <p
                    v-if="errorMessageByTemplateId[template.id]"
                    class="error--text"
                    v-text="errorMessageByTemplateId[template.id]"
                />
            </div>
        </VRadioGroup>
    </div>
</template>

<script>
import { Predicate } from '@ads/predicate-model';
import { SrInfoBox, SrRadioButton } from '@ads/design-system';
import { PIXEL_SEGMENT_TEMPLATE } from '../types';
import PixelSegmentTemplateService from './PixelSegmentTemplateService';
import MissingPixelsError from './errors/MissingPixelsError';

export default {
    name: 'PixelSegmentTemplates',
    components: {
        SrInfoBox,
        SrRadioButton,
    },
    props: {
        value: {
            type: Predicate,
            default: null,
        },
        pixels: {
            type: Array,
            required: true,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        selectedTemplateId: {
            type: Number,
            default: PIXEL_SEGMENT_TEMPLATE.NONE,
        },
    },
    data() {
        return {
            errorMessageByTemplateId: null,
            selectedTemplate: this.selectedTemplateId,
            pixelSegmentTemplateService: new PixelSegmentTemplateService(this.pixels),
            templates: [
                {
                    id: PIXEL_SEGMENT_TEMPLATE.NONE,
                    label: 'None',
                },
                {
                    id: PIXEL_SEGMENT_TEMPLATE.CUSTOM,
                    label: 'Custom',
                },
                {
                    id: PIXEL_SEGMENT_TEMPLATE.ROS__TO__REG_START,
                    label: 'ROS - Reg_Start',
                    description:
                        'Strategy for people who have visited the page at least once but never registered or never deposited.',
                },
                {
                    id: PIXEL_SEGMENT_TEMPLATE.REG_START__TO__REGISTRATION,
                    label: 'Reg_Start - Registration',
                    description:
                        "Strategy for people who have started the registration process but didn't complete the registration.",
                },
                {
                    id: PIXEL_SEGMENT_TEMPLATE.REGISTRATION__TO__FTD,
                    label: 'Registration - FTD',
                    description: 'Strategy for people who have registered but haven’t deposited for the first time yet.',
                },
            ],
        };
    },
    computed: {
        tooltipDirection() {
            if (this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
                return 'right';
            }
            return 'left';
        },
        isCustomTemplate() {
            return this.selectedTemplate === PIXEL_SEGMENT_TEMPLATE.CUSTOM;
        },
    },
    watch: {
        pixels(pixels) {
            this.pixelSegmentTemplateService = new PixelSegmentTemplateService(pixels);
            this.selectTemplateFromPredicate(this.value);
        },
        value: {
            handler: 'selectTemplateFromPredicate',
            immediate: true,
            deep: true,
        },
    },
    created() {
        this.resetErrors();
    },
    methods: {
        onTemplateChange(templateId) {
            this.resetErrors();
            this.updateSelectedTemplateIdProp();

            if (this.isCustomTemplate) {
                this.updateValidState(true);
                return;
            }

            try {
                const pixelSegment = this.pixelSegmentTemplateService.createSegment(templateId);
                this.updateValue(pixelSegment);
                this.updateValidState(true);
            } catch (error) {
                if (error instanceof MissingPixelsError) {
                    this.errorMessageByTemplateId[templateId] = error.message;
                } else {
                    this.updateValue(this.pixelSegmentTemplateService.createSegment(PIXEL_SEGMENT_TEMPLATE.NONE));
                }
                this.updateValidState(false);
            }
        },
        selectTemplateFromPredicate(predicate) {
            if (!predicate) {
                return;
            }

            let predictedTemplateId = PIXEL_SEGMENT_TEMPLATE.CUSTOM;
            const predicateAsString = predicate.toString();
            this.templates
                .filter((template) => template.id !== PIXEL_SEGMENT_TEMPLATE.CUSTOM)
                .forEach((template) => {
                    try {
                        const pixelSegment = this.pixelSegmentTemplateService.createSegment(template.id);
                        if (predicateAsString === pixelSegment.toString()) {
                            predictedTemplateId = template.id;
                        }
                    } catch (error) {
                        // do nothing
                    }
                });
            this.selectedTemplate = predictedTemplateId;
        },
        updateValue(pixelSegment) {
            this.$emit('input', pixelSegment);
        },
        updateValidState(isValid) {
            this.$emit('update:is-valid', isValid);
        },
        updateSelectedTemplateIdProp() {
            this.$emit('update:selected-template-id', this.selectedTemplate);
        },
        resetErrors() {
            this.errorMessageByTemplateId = {
                [PIXEL_SEGMENT_TEMPLATE.NONE]: null,
                [PIXEL_SEGMENT_TEMPLATE.CUSTOM]: null,
                [PIXEL_SEGMENT_TEMPLATE.ROS__TO__REG_START]: null,
                [PIXEL_SEGMENT_TEMPLATE.REG_START__TO__REGISTRATION]: null,
                [PIXEL_SEGMENT_TEMPLATE.REGISTRATION__TO__FTD]: null,
            };
        },
    },
};
</script>

<style lang="scss">
.pixel-segment-template {
    width: 14em;
}
.description {
    display: block;
    max-width: 16em;
    word-wrap: normal;
}
</style>

<template>
    <SrModalPage type="basic" :show="show" :loading="isLoading" @close="close">
        <VForm v-if="location" slot="content" ref="form" v-model="isValid">
            <SrHeadline level="1" weight="bold" class="mb-6">
                {{ isEditForm ? 'Edit Location' : 'Create Location' }}
            </SrHeadline>
            <VRow no-gutters>
                <VCol cols="5">
                    <VRow dense>
                        <VCol cols="12">
                            <SrHeadline level="3" weight="bold"> Basic Details </SrHeadline>
                        </VCol>
                        <VCol cols="11">
                            <SrInput v-model="location.name" required :rules="allNameRules" label="Name" />
                        </VCol>
                        <VCol cols="1">
                            <!-- Placeholder for not having SrInfoBox-->
                        </VCol>
                        <VCol cols="11">
                            <SrGeoCoordinateInput
                                v-model.number="location.latitude"
                                label="Latitude"
                                :precision="5"
                                :min="-90"
                                :max="90"
                            />
                        </VCol>
                        <VCol cols="1" class="mt-3">
                            <SrInfoBox> GEOGRAPHIC COORDINATE IN ° </SrInfoBox>
                        </VCol>
                        <VCol cols="11">
                            <SrGeoCoordinateInput
                                v-model.number="location.longitude"
                                label="Longitude"
                                :precision="5"
                                :min="-180"
                                :max="180"
                            />
                        </VCol>
                        <VCol cols="1" class="mt-3">
                            <SrInfoBox> GEOGRAPHIC COORDINATE IN ° </SrInfoBox>
                        </VCol>
                        <VCol cols="11">
                            <SrInput
                                v-model.number="location.radius"
                                type="number"
                                required
                                :rules="rules.radius"
                                label="Radius"
                                prefix="m"
                            />
                        </VCol>
                        <VCol cols="1" class="mt-3">
                            <SrInfoBox> RADIUS IN M AROUND THE GIVEN LOCATION </SrInfoBox>
                        </VCol>
                    </VRow>
                </VCol>
            </VRow>
        </VForm>
        <div slot="footer" class="d-flex justify-end">
            <VRow no-gutters class="mt-3">
                <VCol cols="9">
                    <ErrorBox :show="showErrorMessage" :message="shownError" />
                </VCol>
                <VCol cols="1" />
                <VCol cols="2">
                    <SrButton
                        slot="header-cta"
                        :disabled="!isValid"
                        :loading="isSaving"
                        class="blue white--text mr-2 save-application-name-list"
                        depressed
                        large
                        @click="submit"
                    >
                        Submit
                    </SrButton>
                </VCol>
            </VRow>
        </div>
    </SrModalPage>
</template>

<script>
import { SrButton, SrHeadline, SrInfoBox, SrInput, SrModalPage } from '@ads/design-system';
import BasicForm from '@/components/Form/BasicForm';
import ErrorBox from '@/components/ErrorBox';
import SrGeoCoordinateInput from '@/components/SrGeoCoordinateInput';
import { getLengthValidationRules } from '@/shared/validation';
import { locationService } from './services/LocationService';
import { radiusRules } from './validator';
import { locationErrorRuleFactory } from './services/LocationErrorRuleFactory';

export default {
    name: 'LocationForm',
    components: {
        SrGeoCoordinateInput,
        SrModalPage,
        SrHeadline,
        SrInput,
        ErrorBox,
        SrButton,
        SrInfoBox,
    },
    extends: BasicForm,
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        loadError: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            show: true,
            location: this.value,
            isValid: false,
            isSaving: false,
            locationService,
            errorMessage: null,
            rules: {
                name: getLengthValidationRules('Location name', 3, 64),
                radius: Object.values(radiusRules),
            },
            dynamicRules: {
                name: [],
            },
        };
    },
    computed: {
        shownError() {
            return this.errorMessage || this.loadError;
        },
        showErrorMessage() {
            return Boolean(this.shownError);
        },
        allNameRules() {
            if (this.dynamicRules.name) {
                return [...this.rules.name, ...this.dynamicRules.name];
            }
            return this.rules.name;
        },
    },

    watch: {
        value(value) {
            this.location = value;
        },
    },
    methods: {
        async save() {
            try {
                this.errorMessage = null;
                this.isSaving = true;
                await this.locationService[this.submitAction](this.location);
            } catch (e) {
                this.dynamicRules = locationErrorRuleFactory.create(e);
                await this.$nextTick();
                this.$refs.form.validate();
                throw e;
            } finally {
                this.isSaving = false;
            }
        },
        async close() {
            await this.$router.push({ name: this.afterCloseRoute, params: this.afterCloseRouteParams });
        },
    },
};
</script>

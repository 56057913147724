<template>
    <VCol sm="5">
        <div class="d-flex">
            <SrHeadline level="3" class="mb-0">
                <strong>Ads.TXT</strong>
            </SrHeadline>
            <SrInfoBox class="ml-2"> IAB-approved text file that aims to prevent unauthorized inventory sales </SrInfoBox>
        </div>
        <SrCheckbox v-model="lineItem.isAdsTxtEnabled" label="Prohibit not authorized sellers" />
    </VCol>
</template>

<script>
import { SrCheckbox, SrHeadline, SrInfoBox } from '@ads/design-system';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';

export default {
    name: 'UnauthorizedSellingOption',
    components: {
        SrHeadline,
        SrCheckbox,
        SrInfoBox,
    },
    mixins: [LineItemModel],
};
</script>

<template>
    <SrNotification
        v-if="shouldShowMessage"
        type="error"
        title="Invalid SSP Targeting configuration"
        :description="inactiveNetworkDescription"
    />
</template>

<script>
import { SrNotification } from '@ads/design-system';

export default {
    name: 'LineItemInvalidSSPTargetingError',
    components: {
        SrNotification,
    },
    props: {
        lineItem: {
            type: Object,
            default: null,
        },
    },
    computed: {
        networks() {
            return this.lineItem?.targetings?.sspTargetings || [];
        },
        inactiveNetworks() {
            return this.networks.filter((targetedNetwork) => {
                const isFound = this.$store.getters['ssp/networks'].find((network) => targetedNetwork.networkId === network.id);
                return !isFound;
            });
        },
        inactiveNetworkDescription() {
            const inactiveNetworkDescription = this.inactiveNetworks
                .map((inactiveNetwork) => `${inactiveNetwork.name}(${inactiveNetwork.networkId})`)
                .join(', ');
            return `This line item is targeting at least one inactive network. Please remove it under Targetings →
                Content → SSP. \n Inactive networks: ${inactiveNetworkDescription}`;
        },
        isTargetingInactive() {
            return this.inactiveNetworks.length > 0;
        },
        shouldShowMessage() {
            return !!this.lineItem?.isTargetingInactiveNetwork && this.isTargetingInactive;
        },
    },
};
</script>

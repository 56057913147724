<template>
    <SrInput
        v-if="isInEditMode"
        v-model="viewModel.inputValue"
        dense
        hide-details="auto"
        :label="dateInputHint"
        :rules="rules"
        :disabled="disabled"
        data-testid="EditableEndDateRuntime__input"
        @input="onDateChange"
        @update:error="onError"
    />
    <HumanReadableRuntime v-else :runtime="runtime" />
</template>

<script>
import { SrInput } from '@ads/design-system';
import HumanReadableRuntime from '@/components/HumanReadableRuntime';
import { getCampaignEndDateValidationRules } from '@/modules/campaign/components/validator';
import { isDateEqualOrLaterThanStartOfToday, isValidDateFormat } from '@/shared/validation/date-time';
import EditableEndDateRuntimeViewModel from './EditableEndDateRuntimeViewModel';

export default {
    name: 'EditableEndDateRuntime',
    components: {
        SrInput,
        HumanReadableRuntime,
    },
    props: {
        runtime: {
            type: Object,
            required: true,
        },
        isInEditMode: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            viewModel: new EditableEndDateRuntimeViewModel(this.runtime),
        };
    },
    computed: {
        dateInputHint() {
            return 'dd/mm/yyyy';
        },
        rules() {
            let campaignFormEndDateRules = [];
            const endDate = this.viewModel.getInputValueAsUtcIsoString();

            if (this.viewModel.inputValue) {
                campaignFormEndDateRules = campaignFormEndDateRules.concat([
                    () => isValidDateFormat(this.viewModel.parseInputValue()),
                    () => isDateEqualOrLaterThanStartOfToday(this.viewModel.parseInputValue().toString(), this.runtime.timeZone),
                ]);
            }
            return campaignFormEndDateRules.concat(
                getCampaignEndDateValidationRules({
                    ...this.runtime,
                    endDate,
                }),
            );
        },
    },
    watch: {
        isInEditMode() {
            this.viewModel.resetFormattedDate();
        },
        runtime: {
            handler(newRuntime) {
                this.viewModel.initializeWith(newRuntime);
            },
            deep: true,
        },
    },
    methods: {
        resetViewModel() {
            this.viewModel = new EditableEndDateRuntimeViewModel(this.runtime);
        },
        onDateChange() {
            this.$emit('endDateChange', this.viewModel.getInputValueAsUtcIsoString());
        },
        onError(hasError) {
            this.$emit('update:error', hasError);
        },
    },
};
</script>

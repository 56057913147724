import IDeviceTypeResponse from 'api-contracts/line-items/targetings/devices/IDeviceTypeResponse';

export default {
    setDeviceTypes(state, deviceTypes: IDeviceTypeResponse[]): void {
        state.deviceTypes = deviceTypes;
    },
    setLoading(state, { key, value }): void {
        state.loadingStates = {
            ...state.loadingStates,
            [key]: value,
        };
    },
};

<template>
    <VCol class="pl-0 mr-2">
        <VRow>
            <VCol class="d-flex justify-space-between">
                <SrHeadline level="2" weight="bold">
                    {{ title }}
                </SrHeadline>
            </VCol>
        </VRow>
        <VRow align="center">
            <VCol>
                <SrSearchInput slot="quicksearch" v-model="searchInput" hide-details :debounce-ms="300" />
            </VCol>
            <VCol class="text-right">
                <SrButton v-if="isActionAdd" color="blue lighten-5 blue--text text--lighten-1" @click="addAll">
                    Add All Listed
                </SrButton>
                <SrButton v-else type="delete" @click="removeAll"> Remove All Listed </SrButton>
            </VCol>
        </VRow>
        <Table
            :items="items"
            :headers="headers"
            :is-loading="loading"
            class="pa-0 creatives-table"
            :options.sync="options"
            :[pagination]="serverItemsLength"
            :footer-props="footerProperties"
            @pagination="updatePage"
        >
            <template #actions="item">
                <CreativeTableActions
                    :is-disabled="item.isSelected"
                    :action="action"
                    :has-preview="hasPreview(item)"
                    @add="add(item)"
                    @remove="remove(item)"
                    @preview="preview(item)"
                />
            </template>
        </Table>
    </VCol>
</template>

<script>
import Table from '@/components/Table/Table';
import { SrButton, SrHeadline, SrSearchInput } from '@ads/design-system';
import CreativeTableActions from './CreativeTableActions';

export default {
    name: 'CreativeSection',
    components: {
        CreativeTableActions,
        SrHeadline,
        SrButton,
        SrSearchInput,
        Table,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        headers: {
            type: Array,
            required: true,
        },
        serverItemsLength: {
            type: Number,
            default: 10,
        },
        quickSearchValue: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            required: true,
        },
        action: {
            type: String,
            required: true,
        },
        footerProperties: {
            type: Object,
            default: () => ({
                'items-per-page-options': [5, 10, 15],
            }),
        },
    },
    data() {
        return {
            options: {
                page: 1,
                itemsPerPage: 10,
            },
            searchInput: this.quickSearchValue,
        };
    },
    computed: {
        pagination() {
            if (this.isActionAdd) {
                return 'serverItemsLength';
            }
            return null;
        },
        isActionAdd() {
            return this.action === 'add';
        },
    },
    watch: {
        'options.page': function () {
            this.emitFetchCreatives();
        },
        'options.itemsPerPage': function () {
            this.emitFetchCreatives();
        },
        searchInput() {
            this.resetPage();
            this.emitFetchCreatives();
        },
    },
    mounted() {
        this.emitFetchCreatives();
    },
    methods: {
        add(item) {
            this.$emit('add', item);
        },
        addAll() {
            this.$emit('addAll');
        },
        remove(item) {
            this.$emit('remove', item);
        },
        removeAll() {
            this.$emit('removeAll');
        },
        preview(item) {
            this.$emit('preview', item);
        },
        hasPreview(item) {
            return item.type !== 'NativeCreative';
        },
        emitFetchCreatives() {
            const { page, itemsPerPage } = this.options;
            this.$emit('fetch', { page, itemsPerPage, query: this.searchInput });
        },
        updatePage(options) {
            this.options.page = options.page;
        },
        resetPage() {
            this.options.page = 1;
        },
    },
};
</script>

<style scoped lang="scss">
.creatives-table {
    word-break: break-word;
}
</style>

import { ValidationResult, ValidationValue } from '@/shared/validation/types';

export default function isNewlineSeparatedArray(
    propertyName,
    minEntries?: number,
    maxEntries?: number,
): (value: ValidationValue) => ValidationResult {
    return (value: ValidationValue) => {
        const normalizedValue = value == null ? '' : value.toString();
        const numberOfEntries = normalizedValue.split('\n').length;
        if (minEntries != null && (numberOfEntries < minEntries || normalizedValue.length === 0)) {
            return `${propertyName} needs at least ${minEntries} entries`;
        }
        if (maxEntries != null && numberOfEntries > maxEntries) {
            return `${propertyName} allows at maximum ${maxEntries} entries`;
        }

        return true;
    };
}

import { Node } from '@/components/SelectableTree/Node';

export type SportCalendarEvent = {
    dspId: number;
    name: string;
    eventId: string;
    startTime: string;
    endTime: string;
    competitors: string;
    sport: { dspId: number; name: string };
    country: { dspId: number; name: string };
    tournament: { dspId: number; name: string };
};

export type SportCalendarTournament = {
    dspId: number;
    name: string;
    sport: { dspId: number; name: string };
    country: { dspId: number; name: string };
};

export type SportCalendarCountry = {
    dspId: number;
    name: string;
};

export type SportCalendarSport = {
    dspId: number;
    name: string;
};

export enum SPORT_CALENDAR_TARGETING_TYPE {
    EVENT = 'event',
    TOURNAMENT = 'tournament',
}

export type SportCalendarTargeting = {
    id?: number;
    dspId: number;
    name: string;
    type: SPORT_CALENDAR_TARGETING_TYPE;
    tournamentDspId: number;
    tournamentName: string;
};

export type SportCalendarLoadingState = {
    isLoadingTargetings: boolean;
    isLoadingSports: boolean;
    isLoadingCountries: boolean;
    isLoadingTournaments: boolean;
    isLoadingEvents: boolean;
};

export type UpdateLoadingStateFn = (state: Partial<SportCalendarLoadingState>) => void;

export type SportCalendarComponentData = {
    nodes: Node[];
    filterItems: SportCalendarFilterItems;
};

export type SportCalendarFilterItems = {
    sports?: SelectableSportCalendarData[];
    countries?: SelectableSportCalendarData[];
    tournaments?: SelectableSportCalendarData[];
};

export type SportCalendarFilterParams = {
    sportId: number | null;
    countryId: number | null;
    tournamentId: number | null;
};

export type SelectableSportCalendarData = {
    value: number;
    label: string;
};

export type CacheableSportCalendarObjects = SportCalendarCountry[] | SportCalendarTournament[] | SportCalendarEvent[];

<template>
    <SelectableTree v-model="selectedTree" :source-nodes="countries" :is-loading="isLoadingCountries" mode="Combined" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SelectableTree from '@/components/SelectableTree/SelectableTree';
import { SelectedTree } from '@/components/SelectableTree/SelectedTree';
import { GEO_LOADING } from '@/modules/geo/store/state';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import { GeoTargetingController } from './GeoTargetingController';

export default {
    name: 'TargetingsGeoTargeting',
    components: { SelectableTree },
    mixins: [LineItemModel, TargetingCountUpdate],
    data() {
        const geoTargetings = this.value.targetings.geoTargetings || [];
        return {
            selectedTree: new SelectedTree(
                geoTargetings.map((geoTargeting) => GeoTargetingController.toSelectedNode(geoTargeting)),
            ),
        };
    },
    computed: {
        ...mapGetters({
            countries: 'geo/countries',
            isLoading: 'geo/isLoading',
        }),
        isLoadingCountries() {
            return this.isLoading(GEO_LOADING.COUNTRIES);
        },
    },
    watch: {
        selectedTree: {
            deep: true,
            handler() {
                this.$set(
                    this.lineItem.targetings,
                    'geoTargetings',
                    this.selectedTree
                        .getSelectedNodeTrees()
                        .map((selectedNodeTree) => GeoTargetingController.toGeoTargeting(selectedNodeTree)),
                );
            },
        },
    },
    created() {
        this.getCountries();
    },
    methods: {
        ...mapActions({
            getCountries: 'geo/getCountries',
        }),
        targetingChanged(geoTargetings) {
            const oldLocations = this.cachedTargeting?.map((targeting) => targeting.geoLocation.name) || [];
            const newLocations = geoTargetings.map((targeting) => targeting.geoLocation.name);

            return JSON.stringify(oldLocations.sort()) !== JSON.stringify(newLocations.sort());
        },
    },
};
</script>

import { FullDirectiveAction } from '@ads/design-system';
import throwIfRequiredPropertyIsMissing from '@/mixins/utils/throwIfRequiredPropertyIsMissing';

export default {
    props: {
        requiredPermission: {
            type: Object,
            required: false,
            validator: (requiredPermission: FullDirectiveAction): boolean => {
                throwIfRequiredPropertyIsMissing(requiredPermission, 'domainName');
                throwIfRequiredPropertyIsMissing(requiredPermission, 'resourceType');
                throwIfRequiredPropertyIsMissing(requiredPermission, 'actionName');

                return true;
            },
        },
    },
    computed: {
        requiresPermissionChecks(): boolean {
            return this.requiredPermission !== undefined;
        },
    },
};

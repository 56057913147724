export default {
    methods: {
        emitValidState(isValid) {
            this.$emit('update:isValid', isValid);
        },
    },
    watch: {
        appOrWebTargetingIsValid() {
            this.emitValidState(this.appOrWebTargetingIsValid);
        },
        pixelSegmentsTargetingIsValid() {
            this.emitValidState(this.pixelSegmentsTargetingIsValid);
        },
    },
};

import { getValidationResult, isRequired } from '@/shared/validation';
import { isDateBefore } from '@/shared/validation/date-time';
import { ValidationResult, ValidationRule, ValidationValue } from '@/shared/validation/types';
import { Runtime } from '@/modules/campaign/types';

const getCampaignStartDateValidationRules = (runtime: Runtime): ValidationResult[] => {
    const { startDate, endDate } = runtime;
    return [isRequired(startDate, 'Start date'), isDateBefore(startDate, endDate, 'Start date needs to be before end date')];
};

const getCampaignEndDateValidationRules = (runtime: Runtime): ValidationResult[] => {
    const { startDate, endDate } = runtime;
    return [isRequired(endDate, 'End date'), isDateBefore(startDate, endDate, 'End date needs to be after start date')];
};

function getCampaignBudgetValidationRules(_spentBudget?: number): ValidationRule[] {
    return [(value: ValidationValue): ValidationResult => isBudgetLargerThanZero(value)];
}

function isBudgetLargerThanZero(value: ValidationValue): ValidationResult {
    return getValidationResult(typeof value === 'number' && value > 0, 'Budget should be greater than 0');
}

export { getCampaignStartDateValidationRules, getCampaignEndDateValidationRules, getCampaignBudgetValidationRules };

// TODO: This class can be removed once we have the unified error reporting in place.
//       https://jira.sportradar.ag/browse/DSP-273
export default class DealErrorMessageFactory {
    private readonly errorKeyToFormattingFunctionMap = new Map<string, string>([
        ['external_id, network_id', 'formatExternalIdUniqueConstraintMessage'],
    ]);

    createFromResponseMessage(responseMessage: string): string {
        let errorCollection: { [key: string]: string } = {};

        try {
            errorCollection = JSON.parse(responseMessage);
            const errorKey = Object.keys(errorCollection)[0];

            if (!this.errorKeyToFormattingFunctionMap.has(errorKey)) {
                return 'Unknown Error!';
            }

            const formattingFunctionName = this.errorKeyToFormattingFunctionMap.get(errorKey);
            return this[formattingFunctionName](errorCollection[errorKey]);
        } catch (e) {
            return responseMessage;
        }
    }

    private formatExternalIdUniqueConstraintMessage(message: string): string {
        const id = message.split(',')[0];

        return `Deal with id "${id}" already exists in this Network.`;
    }
}

import { NODE_SELECTION_STATUS, SelectedNodeTree } from '@/components/SelectableTree/SelectedTree';
import { Node } from '@/components/SelectableTree/Node';
import {
    VideoPlayerSizeResponse,
    VideoPlayerSizeTargetingRequest,
    VideoPlayerSizeTargetingResponse,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/VideoPlayerSize/types';

export class VideoPlayerSizeTransformer {
    videoPlayerSizesToSelectableNodes(videoPlayerSizes: VideoPlayerSizeResponse[]): Node[] {
        return videoPlayerSizes.map(({ id, name }) => new Node({ id, name }));
    }

    videoPlayerSizeTargetingsToSelectedNodes(videoPlayerSizeTargetings: VideoPlayerSizeTargetingResponse[]): SelectedNodeTree[] {
        return videoPlayerSizeTargetings.map((videoPlayerSizeTargetingResponse) =>
            this.videoPlayerSizeTargetingToSelectedNode(videoPlayerSizeTargetingResponse),
        );
    }

    private videoPlayerSizeTargetingToSelectedNode(
        videoPlayerSizeTargetingResponse: VideoPlayerSizeTargetingResponse,
    ): SelectedNodeTree {
        const { videoPlayerSize } = videoPlayerSizeTargetingResponse;
        const { id, name } = videoPlayerSize;
        return new SelectedNodeTree(id, name, NODE_SELECTION_STATUS.INCLUDED);
    }

    selectedNodesToVideoPlayerSizeTargetings(selectedNodes: SelectedNodeTree[]): VideoPlayerSizeTargetingRequest[] {
        return selectedNodes.map((node) => {
            const id = node.getId() as number;
            return {
                videoPlayerSize: {
                    id,
                },
            };
        });
    }
}

export const videoPlayerSizeTransformer = new VideoPlayerSizeTransformer();

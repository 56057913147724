import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';

export default class HoldingService {
    private readonly requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async listHoldings() {
        const options: IRequestOptions = {
            domain: 'iam',
            resource: 'holding',
            action: ACTION_NAME.READ,
        };

        return this.requestService.get('/holdings', options);
    }
}

<template>
    <div>
        <ApplicationNameListForm
            v-model="applicationNameList"
            :submit-action="actionType"
            :after-close-route="closingRoute"
            :after-close-route-params="closeRouteParams"
            :after-submit-route="closingRoute"
            :after-submit-route-params="afterSubmitParams"
            :load-error="errorMessage"
            :is-loading="isLoading"
            non-linear
        />
        <RouterView />
    </div>
</template>

<script>
import ApplicationNameListForm from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/ApplicationNameList/ApplicationNameListForm';
import { applicationNameListService } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/ApplicationNameList/services/ApplicationNameListService';
import { ACTION_TYPES } from '@/modules/line-items/views/targetings/types';
import TargetingFormRoutes from '@/modules/line-items/views/targetings/TargetingFormRoutes';

export default {
    name: 'EditApplicationNameList',
    components: {
        ApplicationNameListForm,
    },
    mixins: [TargetingFormRoutes],
    props: {
        lineItem: {
            type: Object,
            required: true,
        },
        listId: {
            type: [Number, String],
            required: true,
            default: null,
        },
    },
    data() {
        return {
            applicationNameListService,
            applicationNameList: {
                name: '',
                holdingId: null,
                applications: [],
            },
            actionType: ACTION_TYPES.EDIT,
            errorMessage: null,
            isLoading: false,
        };
    },
    async created() {
        await this.loadApplicationNameList();
    },
    methods: {
        async loadApplicationNameList() {
            this.isLoading = true;
            try {
                this.applicationNameList = await this.applicationNameListService.getById(this.listId);
            } catch (error) {
                this.errorMessage = error?.response?.message || String(error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

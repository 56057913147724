import { LineItem } from '@/modules/line-items/types';
import { NODE_SELECTION_STATUS, SelectedNodeTree, SelectedTree } from '@/components/SelectableTree/SelectedTree';
import DomainListService from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/DomainList/services/DomainListService';
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import {
    DomainList,
    DomainListTargeting,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/DomainList/types';
import { Node } from '@/components/SelectableTree/Node';
import { ACTION_NAME, ActionValidationMiddleware, IMarketingCloudUser, SportradarResourceName } from '@ads/iam-library';
import { createActionValidator } from '@ads/design-system';

type PartialDomainListTargeting = {
    id?: DomainListTargeting['id'];
    domainList: Pick<DomainListTargeting['domainList'], 'id' | 'holdingId'> & {
        name?: DomainListTargeting['domainList']['name'];
    };
    isExcluded: DomainListTargeting['isExcluded'];
};

class DomainListViewModel {
    private readonly domainListService: DomainListService;

    private readonly user: IMarketingCloudUser;

    constructor(domainListService: DomainListService, user: IMarketingCloudUser) {
        this.domainListService = domainListService;
        this.user = user;
    }

    async loadInitialDataFor(
        lineItem: LineItem | null,
    ): Promise<{ domainListTargetings?: DomainListTargeting[]; domainLists: Node[] }> {
        const domainLists = await this.getDomainListsBy(lineItem.campaign.id);

        return {
            domainLists,
            domainListTargetings: lineItem.targetings?.domainLists,
        };
    }

    async getDomainListsBy(campaignId: number): Promise<Node[]> {
        const domainLists = await this.domainListService.getByCampaignId(campaignId);
        return Promise.all(
            domainLists.map(async (domainList) => {
                const isEditable = await this.isDomainListEditable(domainList);
                return new Node({
                    ...domainList,
                    details: { isEditable },
                });
            }),
        );
    }

    createDomainListTargetingsFrom(selectedTree: SelectedTree): PartialDomainListTargeting[] {
        const selectedNodes = selectedTree.getSelectedNodeTrees();
        return selectedNodes.map((node) => ({
            domainList: {
                id: node.getId() as number,
            },
            isExcluded: node.isExcluded(),
        }));
    }

    async createSelectedTreeFrom(domainListTargetings: PartialDomainListTargeting[] | null): Promise<SelectedTree> {
        if (!domainListTargetings) {
            return new SelectedTree();
        }
        const selectedNodes = await Promise.all(
            domainListTargetings.map(async (domainListTargeting) => {
                const isEditable = await this.isDomainListEditable(domainListTargeting.domainList as DomainList);
                return new SelectedNodeTree(
                    domainListTargeting.domainList.id,
                    domainListTargeting.domainList.name,
                    domainListTargeting.isExcluded ? NODE_SELECTION_STATUS.EXCLUDED : NODE_SELECTION_STATUS.INCLUDED,
                    null,
                    [],
                    {
                        targetId: domainListTargeting.id,
                        isEditable,
                    },
                );
            }),
        );
        return new SelectedTree(selectedNodes);
    }

    getInitialTreeModeFrom(domainListTargetings: PartialDomainListTargeting[] | null): SELECTION_MODE {
        if (domainListTargetings?.length) {
            if (domainListTargetings[0].isExcluded) {
                return SELECTION_MODE.EXCLUDE;
            }
        }
        return SELECTION_MODE.INCLUDE;
    }

    isExisting(lineItem: LineItem | null): boolean {
        return Boolean(lineItem?.id);
    }

    private async isDomainListEditable(domainList: DomainList): Promise<boolean> {
        const actionValidator: ActionValidationMiddleware = await createActionValidator(this.user);
        return actionValidator.validateAction({
            domainName: 'programmatic-campaigns',
            resourceType: 'line-item',
            actionName: ACTION_NAME.EDIT,
            userId: this.user.getCognitoId(),
            holdingId: domainList.holdingId ? domainList.holdingId : SportradarResourceName.sharedIdentifier,
            businessEntityId: domainList.holdingId
                ? SportradarResourceName.globalIdentifier
                : SportradarResourceName.sharedIdentifier,
        });
    }
}

export default DomainListViewModel;

<template>
    <CampaignBudget
        v-if="isInEditMode"
        :value="budget.price.value"
        :currency="budget.price.currencyCode"
        :spent-budget="spentBudget"
        :disabled="disabled"
        :compact="true"
        data-testid="CampaignListBudget"
        @input="onBudgetChange"
        @update:error="onErrorUpdate"
    />
    <div v-else>
        {{ formattedBudget }}
    </div>
</template>

<script>
import { formatNumberToCurrency } from '@/shared/utils';
import CampaignBudget from '@/modules/campaign/components/CampaignBudget';

export default {
    name: 'CampaignListBudget',
    components: { CampaignBudget },
    props: {
        budget: {
            type: Object,
            required: true,
        },
        isInEditMode: {
            type: Boolean,
            required: true,
        },
        lineItemsBudgetOverview: {
            type: Object,
            required: false,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        formattedBudget() {
            const { value, currencyCode } = this.budget.price;
            return formatNumberToCurrency(value, currencyCode);
        },
        spentBudget() {
            return this.lineItemsBudgetOverview?.spentByLineItems;
        },
    },
    methods: {
        onBudgetChange(newBudget) {
            this.$emit('budgetChange', newBudget);
        },
        onErrorUpdate(hasError) {
            this.$emit('update:error', hasError);
        },
    },
};
</script>

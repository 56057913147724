<template>
    <VForm ref="form" v-model="formIsValid">
        <VRow>
            <VCol sm="6" lg="4">
                <TimezoneSelector v-model="campaign.runtime.timeZone" data-testid="CampaignFormStepRuntime__time_zone" />
            </VCol>
        </VRow>
        <VRow>
            <VCol sm="8" lg="6">
                <FutureDateTimeSelector
                    v-model="campaign.runtime.startDate"
                    :rules="startDateRules"
                    :target-time-zone="campaign.runtime.timeZone"
                    class="start-date"
                    default-time="00:00:00"
                    label="Start"
                    data-testid="CampaignFormStepRuntime__start_date"
                    required
                />
            </VCol>
        </VRow>
        <VRow>
            <VCol sm="8" lg="6">
                <FutureDateTimeSelector
                    v-model="campaign.runtime.endDate"
                    :rules="endDateRules"
                    :target-time-zone="campaign.runtime.timeZone"
                    class="end-date"
                    default-time="23:59:59"
                    label="End"
                    data-testid="CampaignFormStepRuntime__end_date"
                    required
                />
            </VCol>
        </VRow>

        <VRow>
            <VCol sm="6" lg="4">
                <SrCheckbox v-model="hasFrequencyCapping" label="Frequency Capping" />
            </VCol>
        </VRow>
        <VRow v-if="hasFrequencyCapping">
            <VCol sm="8" lg="6">
                <FrequencyCappingForm :frequency-capping="campaign.frequencyCapping" @update="update" />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrCheckbox } from '@ads/design-system';
import FrequencyCappingForm from '@/components/FrequencyCappingForm';
import { frequencyCappingDefaultPeriod } from '@/components/FrequencyCappingForm/types';
import TimezoneSelector from '@/components/TimezoneSelector';
import CampaignModel from '@/modules/campaign/components/CampaignForm/CampaignModel';
import FutureDateTimeSelector from '@/components/FutureDateTimeSelector/FutureDateTimeSelector';
import { getCampaignEndDateValidationRules, getCampaignStartDateValidationRules } from '@/modules/campaign/components/validator';

export default {
    name: 'CampaignFormStepRuntime',
    components: {
        FutureDateTimeSelector,
        FrequencyCappingForm,
        SrCheckbox,
        TimezoneSelector,
    },
    mixins: [CampaignModel],
    props: {
        isValid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formIsValid: this.isValid,
            frequencyCappingCache: {
                cappingLimit: null,
                timeValue: null,
                timeUnit: frequencyCappingDefaultPeriod,
            },
        };
    },
    computed: {
        startDateRules() {
            return getCampaignStartDateValidationRules(this.campaign.runtime);
        },
        endDateRules() {
            return getCampaignEndDateValidationRules(this.campaign.runtime);
        },
        hasFrequencyCapping: {
            get() {
                return Boolean(this.campaign.frequencyCapping);
            },
            set(value) {
                if (!value) {
                    if (this.campaign.frequencyCapping) {
                        this.frequencyCappingCache = { ...this.campaign.frequencyCapping };
                    }
                    this.$set(this.campaign, 'frequencyCapping', null);
                    return;
                }

                if (!this.campaign.frequencyCapping) {
                    this.$set(this.campaign, 'frequencyCapping', this.frequencyCappingCache);
                }
            },
        },
    },
    watch: {
        formIsValid(isValid) {
            this.$emit('update:isValid', isValid);
        },
        isValid(isValid) {
            this.formIsValid = isValid;
        },
    },
    methods: {
        update(frequencyCapping) {
            this.campaign.frequencyCapping = frequencyCapping;
        },
    },
};
</script>

<template>
    <div>
        <PublisherListForm
            v-model="publisherList"
            :submit-action="actionType"
            :after-close-route="closingRoute"
            :after-close-route-params="closeRouteParams"
            :after-submit-route="closingRoute"
            :after-submit-route-params="afterSubmitParams"
            :load-error="errorMessage"
            :is-loading="isLoading"
            non-linear
        />
        <RouterView />
    </div>
</template>

<script>
import PublisherListForm from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/PublisherList/PublisherListForm';
import { ACTION_TYPES } from '@/modules/line-items/views/targetings/types';
import { publisherListService } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/PublisherList/services/PublisherListService';
import TargetingFormRoutes from '@/modules/line-items/views/targetings/TargetingFormRoutes';

export default {
    name: 'EditPublisherList',
    components: {
        PublisherListForm,
    },
    mixins: [TargetingFormRoutes],
    props: {
        lineItem: {
            type: Object,
            required: true,
        },
        listId: {
            type: [Number, String],
            required: true,
            default: null,
        },
    },
    data() {
        return {
            publisherList: {
                name: '',
                holdingId: null,
                publishers: [],
            },
            actionType: ACTION_TYPES.EDIT,
            publisherListService,
            errorMessage: null,
            isLoading: false,
        };
    },
    async created() {
        await this.loadPublisherList();
    },
    methods: {
        async loadPublisherList() {
            this.isLoading = true;
            try {
                this.publisherList = await this.publisherListService.getById(this.listId);
            } catch (error) {
                this.errorMessage = error?.response?.message || String(error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

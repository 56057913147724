<template>
    <SrInput
        :value="value"
        :label="labelWithFormat"
        :rules="rules"
        compact
        hide-details="auto"
        required
        @input="onDateChange"
        @update:error="onError"
    />
</template>

<script>
import { SrInput } from '@ads/design-system';
import { isValid, parseISO } from 'date-fns';
import { computed } from 'vue';

export default {
    name: 'TextDateInput',
    components: {
        SrInput,
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        min: {
            type: String,
            default: null,
        },
        max: {
            type: String,
            default: null,
        },
    },
    setup(props, { emit }) {
        const onDateChange = (value) => emit('input', value);
        const onError = (value) => emit('update:error', value);
        const labelWithFormat = computed(() => `${props.label} (yyyy-MM-dd)`);
        const minDate = computed(() => (props.min != null ? parseISO(props.min) : null));
        const maxDate = computed(() => (props.max != null ? parseISO(props.max) : null));
        const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
        const rules = computed(() => {
            const baseRules = [
                (value) => isoDateRegex.test(value) || 'Invalid date format',
                (value) => isValid(parseISO(value)) || 'Invalid date',
            ];
            if (minDate.value != null) {
                baseRules.push((value) => parseISO(value) > minDate.value || `Date must be after ${props.min}`);
            }
            if (maxDate.value != null) {
                baseRules.push((value) => parseISO(value) < maxDate.value || `Date must be before ${props.max}`);
            }
            return baseRules;
        });

        return {
            labelWithFormat,
            rules,
            onDateChange,
            onError,
        };
    },
};
</script>

import { InputValidationRule } from 'vuetify';
import { getLengthValidationRules, isRequired } from '@/shared/validation';

export default {
    methods: {
        nameRules(name: string, conditions: InputValidationRule[] = []) {
            return [(v) => isRequired(v, name), ...getLengthValidationRules(name, 3, 128), ...conditions];
        },
        descriptionRules(description: string, conditions: InputValidationRule[] = []) {
            return [...getLengthValidationRules(description, 0, 1024), ...conditions];
        },
    },
};

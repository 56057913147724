import { TargetingCount } from '@/modules/line-items/services/TargetingCount';
import { ReactiveTargetingCount } from '@/modules/line-items/services/ReactiveTargetingCount';
import ReactiveTargetingValueTransformer from '@/modules/line-items/services/ReactiveTargetingValueTransformer';

export default class ReactiveTargetingCountTransformer {
    private readonly reactiveValueTransformer: ReactiveTargetingValueTransformer;

    constructor(reactiveTargetingValueTransformer: ReactiveTargetingValueTransformer) {
        this.reactiveValueTransformer = reactiveTargetingValueTransformer;
    }

    transform(targetingCount: TargetingCount): ReactiveTargetingCount {
        return {
            audience: {
                count: targetingCount.audience.count,
                types: {
                    geoTargetings: this.reactiveValueTransformer.transform(targetingCount.audience.types.geoTargetings),
                    timeTargeting: this.reactiveValueTransformer.transform(targetingCount.audience.types.timeTargeting),
                    locationTargetings: this.reactiveValueTransformer.transform(targetingCount.audience.types.locationTargetings),
                    browserLanguageTargetings: this.reactiveValueTransformer.transform(
                        targetingCount.audience.types.browserLanguageTargetings,
                    ),
                    sportCalendarTargetings: this.reactiveValueTransformer.transform(
                        targetingCount.audience.types.sportCalendarTargetings,
                    ),
                },
            },
            content: {
                count: targetingCount.content.count,
                types: {
                    applicationNameLists: this.reactiveValueTransformer.transform(
                        targetingCount.content.types.applicationNameLists,
                    ),
                    sspTargetings: this.reactiveValueTransformer.transform(targetingCount.content.types.sspTargetings),
                    dealTargetings: this.reactiveValueTransformer.transform(targetingCount.content.types.dealTargetings),
                    visibilityTargetings: this.reactiveValueTransformer.transform(
                        targetingCount.content.types.visibilityTargetings,
                    ),
                    videoPlayerSizeTargetings: this.reactiveValueTransformer.transform(
                        targetingCount.content.types.videoPlayerSizeTargetings,
                    ),
                    domainLists: this.reactiveValueTransformer.transform(targetingCount.content.types.domainLists),
                    bundleListTargetings: this.reactiveValueTransformer.transform(
                        targetingCount.content.types.bundleListTargetings,
                    ),
                    categoryTargetings: this.reactiveValueTransformer.transform(targetingCount.content.types.categoryTargetings),
                    publisherListTargetings: this.reactiveValueTransformer.transform(
                        targetingCount.content.types.publisherListTargetings,
                    ),
                    appOrWebTargeting: this.reactiveValueTransformer.transform(targetingCount.content.types.appOrWebTargeting),
                    contextualCategoryTargeting: this.reactiveValueTransformer.transform(
                        targetingCount.content.types.contextualCategoryTargeting,
                    ),
                },
            },
            technology: {
                count: targetingCount.technology.count,
                types: {
                    devices: this.reactiveValueTransformer.transform(targetingCount.technology.types.devices),
                    deviceTypeTargetings: this.reactiveValueTransformer.transform(
                        targetingCount.technology.types.deviceTypeTargetings,
                    ),
                    browserTargetings: this.reactiveValueTransformer.transform(targetingCount.technology.types.browserTargetings),
                    operatingSystems: this.reactiveValueTransformer.transform(targetingCount.technology.types.operatingSystems),
                },
            },
            segments: {
                count: targetingCount.segments.count,
                types: {
                    pixelSegmentTargeting: this.reactiveValueTransformer.transform(
                        targetingCount.segments.types.pixelSegmentTargeting,
                    ),
                    fanIdTargeting: this.reactiveValueTransformer.transform(targetingCount.segments.types.fanIdTargeting),
                },
            },
        };
    }
}

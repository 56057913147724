<template>
    <div>
        <LocationForm
            v-model="location"
            :submit-action="actionType"
            :after-close-route="closingRoute"
            :after-close-route-params="closeRouteParams"
            :after-submit-route="closingRoute"
            :after-submit-route-params="afterSubmitParams"
            non-linear
        />
        <RouterView />
    </div>
</template>

<script>
import LocationForm from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/Location/LocationForm';
import TargetingFormRoutes from '@/modules/line-items/views/targetings/TargetingFormRoutes';
import LineItemHoldingIdProvider from '@/modules/line-items/views/targetings/LineItemHoldingIdProvider';
import { ACTION_TYPES } from '@/modules/line-items/views/targetings/types';

export default {
    name: 'CreateLocation',
    components: {
        LocationForm,
    },
    mixins: [TargetingFormRoutes, LineItemHoldingIdProvider],
    data() {
        return {
            show: true,
            location: {
                name: '',
                latitude: null,
                longitude: null,
                radius: null,
                holdingId: null,
            },
            actionType: ACTION_TYPES.CREATE,
        };
    },
    watch: {
        lineItemHoldingId(value) {
            this.setDefaultHoldingId(value);
        },
    },
    created() {
        this.setDefaultHoldingId(this.lineItemHoldingId);
    },
    methods: {
        setDefaultHoldingId(value) {
            this.publisherList.holdingId = value;
        },
    },
};
</script>

import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import { OperatingSystem } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Technology/Browser/types';

export default class OperatingSystemService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'line-item',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async getAll(): Promise<OperatingSystem[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get('/operating-systems', options);
    }
}

export const operatingSystemService = new OperatingSystemService(new RequestService({ baseUrl: API_URL }));

// eslint-disable-next-line max-classes-per-file
import { geoService } from '@/modules/geo/services/GeoService';
import { Node } from '@/components/SelectableTree/Node';

export abstract class GeoLocation extends Node {
    parent: GeoLocation | null = null;

    children: GeoLocation[] | null = [];
}

export class City extends GeoLocation {
    type = 'city';

    constructor(city: Partial<City>, parent: Country | Subdivision1 | Subdivision2) {
        super(city);
        this.children = undefined;
        this.parent = parent;
    }
}

export class Country extends GeoLocation {
    type = 'country';

    async fetchChildren(): Promise<(Subdivision1 | City)[]> {
        return geoService.getCountryChildren(this);
    }
}

export class Subdivision1 extends GeoLocation {
    type = 'subdivision1';

    constructor(subdivision1: Partial<Subdivision1>, country: Country) {
        super(subdivision1);
        this.parent = country;
    }

    async fetchChildren(): Promise<Subdivision2[] | City[]> {
        return geoService.getSubdivision1Children(this);
    }

    getCountryId(): string | number {
        return this.parent.id;
    }
}

export class Subdivision2 extends GeoLocation {
    type = 'subdivision2';

    constructor(subdivision2: Partial<Subdivision2>, subdivision1: Subdivision1) {
        super(subdivision2);
        this.parent = subdivision1;
        this.children = subdivision2.children.map((child) => new City(child, this));
    }
}

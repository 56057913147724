<template>
    <SrSelect
        :value="value"
        :items="selectableCountries"
        item-value="id"
        label="Select Countries"
        return-object
        multiple
        searchable
        sort-order="ASC"
        @change="onChange"
    />
</template>
<script>
import { SrSelect } from '@ads/design-system';
import { mapGetters } from 'vuex';

export default {
    name: 'CountrySelector',
    components: {
        SrSelect,
    },
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            countries: 'geo/countries',
        }),
        selectableCountries() {
            return this.countries.map((country) => ({
                id: country.id,
                label: country.name,
            }));
        },
    },
    methods: {
        onChange(newValue) {
            this.$emit('input', newValue);
        },
    },
};
</script>

<template>
    <div>
        <PublisherListForm
            v-model="publisherList"
            :submit-action="actionType"
            :after-close-route="closingRoute"
            :after-close-route-params="closeRouteParams"
            :after-submit-route="closingRoute"
            :after-submit-route-params="afterSubmitParams"
            non-linear
        />
        <RouterView />
    </div>
</template>

<script>
import PublisherListForm from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/PublisherList/PublisherListForm';
import TargetingFormRoutes from '@/modules/line-items/views/targetings/TargetingFormRoutes';
import LineItemHoldingIdProvider from '@/modules/line-items/views/targetings/LineItemHoldingIdProvider';
import { ACTION_TYPES } from '@/modules/line-items/views/targetings/types';

export default {
    name: 'CreatePublisherList',
    components: {
        PublisherListForm,
    },
    mixins: [TargetingFormRoutes, LineItemHoldingIdProvider],
    data() {
        return {
            publisherList: {
                name: '',
                holdingId: null,
                publishers: [],
            },
            actionType: ACTION_TYPES.CREATE,
        };
    },
    watch: {
        lineItemHoldingId(value) {
            this.setDefaultHoldingId(value);
        },
    },
    created() {
        this.setDefaultHoldingId(this.lineItemHoldingId);
    },
    methods: {
        setDefaultHoldingId(value) {
            this.publisherList.holdingId = value;
        },
    },
};
</script>

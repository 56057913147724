import store from '@/store';
import { OperatorTypes, SearchValueTypes } from '@/components/types';
import { SelectSortOrder } from '@ads/design-system/src/components/SrAdvancedFilter/types';
import { Predicate } from '@ads/predicate-model';

type SelectValues = { value: string | number; label: string }[] | string[];

interface ISelectableFilter {
    name: string;
    filterCriteria: string;
    operatorType: OperatorTypes;
    searchValueType: SearchValueTypes;
    selectValues?: SelectValues;
    selectSortOrder?: SelectSortOrder;
    selectSearchable?: boolean;
    toStringConfig?: {
        getParameterValueLabel: (predicate: Predicate) => string;
    };
}

function getParameterValueLabel(predicate: Predicate): string {
    if (!predicate) {
        return '';
    }
    const selectedBusinessEntity = findBusinessEntityByValue(predicate);
    if (selectedBusinessEntity) {
        return `"${selectedBusinessEntity.label}"`;
    }
    return predicate.parameterValue as string;
}

function findBusinessEntityByValue(predicate: Predicate) {
    return store.getters['iam/selectableBusinessEntities'].find(
        (businessEntity) => businessEntity.value === predicate.parameterValue,
    );
}

function getFilterConfigPredicates(): ISelectableFilter[] {
    return [
        {
            name: 'DSP ID',
            filterCriteria: 'dspId',
            operatorType: OperatorTypes.NUMERIC,
            searchValueType: SearchValueTypes.TEXT,
        },
        {
            name: 'Campaign Name',
            filterCriteria: 'name',
            operatorType: OperatorTypes.ALPHABETIC,
            searchValueType: SearchValueTypes.TEXT,
        },
        {
            name: 'Advertiser',
            filterCriteria: 'businessEntity.id',
            operatorType: OperatorTypes.BOOLEAN,
            searchValueType: SearchValueTypes.SELECT,
            selectValues: store.getters['iam/selectableBusinessEntities'],
            selectSortOrder: SelectSortOrder.ASC,
            selectSearchable: true,
            toStringConfig: {
                getParameterValueLabel: (predicate) => getParameterValueLabel(predicate),
            },
        },
        {
            name: 'Campaign Status',
            filterCriteria: 'status',
            operatorType: OperatorTypes.BOOLEAN,
            searchValueType: SearchValueTypes.SELECT,
            selectValues: ['Active', 'Inactive'],
        },
    ];
}

export { getFilterConfigPredicates };

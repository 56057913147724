import PIXEL_SOURCE from 'api-contracts/pixels/PixelSource';
import { DataTableHeader } from 'vuetify';

export enum PixelManagerGroup {
    CURRENT_ADVERTISER = 'Current advertiser',
    DISCOVERY = 'Discovery',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { PIXEL_MANAGER, ...NonPixelManagerGroup } = PIXEL_SOURCE;

export const PixelGroup = {
    ...PixelManagerGroup,
    ...NonPixelManagerGroup,
};

export const pixelsTableHeaders: DataTableHeader[] = [
    {
        text: 'DSP ID',
        value: 'dspId',
        align: 'start',
        sortable: true,
    },
    {
        text: 'Name',
        value: 'name',
        align: 'start',
        sortable: true,
    },
];

export const notificationContent = {
    title: 'Pixel(s) from another advertiser(s) selected',
    description:
        'Selecting pixels from another advertiser is allowed. ' +
        'This is just a notification to make sure you will not use them by mistake.',
};

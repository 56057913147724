import { API_URL } from '@/config';
import { Campaign } from '@/modules/campaign/types';
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { Predicate } from '@ads/predicate-model';
import ICampaignOverviewResponse from 'api-contracts/campaigns/ICampaignOverviewResponse';
import IUpdateCampaignBudgetAndRuntimeRequest from 'api-contracts/campaigns/IUpdateCampaignBudgetAndRuntimeRequest';
import ICampaignResponse from 'api-contracts/campaigns/ICampaignResponse';
import ICampaignDuplicationInfo from 'api-contracts/campaigns/ICampaignDuplicationInfo';

export default class CampaignService {
    private readonly basePath = '/campaigns';

    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'campaign',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async create(campaign: Campaign): Promise<ICampaignResponse> {
        const options = this.getRequestOptions({ action: ACTION_NAME.CREATE });

        return this.requestService.post(this.basePath, campaign, options);
    }

    async edit(campaign: Campaign): Promise<ICampaignResponse> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });

        return this.requestService.put(`${this.basePath}/${campaign.id}`, campaign, options);
    }

    async listCampaigns(): Promise<ICampaignOverviewResponse[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(this.basePath, options);
    }

    async getCampaign(id: number): Promise<ICampaignResponse> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`${this.basePath}/${id}`, options);
    }

    async filterCampaigns(filter: Predicate): Promise<Campaign[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        const query = btoa(filter.toMinifiedJson());
        return this.requestService.get(`${this.basePath}?query=${query}`, options);
    }

    async getChangelog(campaignId: number) {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`${this.basePath}/${campaignId}/changelog`, options);
    }

    async partialEdit(campaignId: number, data: IUpdateCampaignBudgetAndRuntimeRequest): Promise<ICampaignResponse> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.patch(`${this.basePath}/${campaignId}`, data, options);
    }

    async getDuplicationInfo(campaignId: number): Promise<ICampaignDuplicationInfo> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get<ICampaignDuplicationInfo>(`${this.basePath}/${campaignId}/duplicate-info`, options);
    }
}

export const campaignService = new CampaignService(new RequestService({ baseUrl: API_URL }));

import { Predicate } from '@ads/predicate-model';
import { ValidationResult } from '@/shared/validation/types';
import { Pixel } from '@/modules/pixels/types';

const segmentContainsOnlyGivenPixels = (pixelSegment: Predicate, pixels: Pixel[]): ValidationResult => {
    if (!pixelSegment) {
        return true;
    }

    let nonExistentPixelId: number;
    const pixelIdsInSegment = pixelSegment.getParameterValuesFlat();

    const segmentContainsNonGivenPixels = pixelIdsInSegment.some((pixelDspIdInSegment: number): boolean => {
        const foundPixel = pixels.find((pixel) => pixel.dspId === pixelDspIdInSegment);
        if (!foundPixel) {
            nonExistentPixelId = pixelDspIdInSegment;
            return true;
        }
        return false;
    });

    if (segmentContainsNonGivenPixels) {
        return `Pixel with DSP ID ${nonExistentPixelId} does not exist`;
    }

    return true;
};

export { segmentContainsOnlyGivenPixels };

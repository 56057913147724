<template>
    <NumberInput
        :value="value"
        :unit="currencySymbol"
        :locale="locale"
        :precision="precision"
        :rules="rulesWithCurrencyValidation"
        :label="label"
        :message="message"
        :compact="compact"
        :required="required"
        :disabled="disabled"
        @input="onInput"
        @update:error="onError"
    />
</template>

<script>
import { computed } from 'vue';
import NumberInput from '@/components/NumberInput';
import getCurrencySymbol from './getCurrencySymbol';

export default {
    name: 'CurrencyInput',
    components: { NumberInput },
    props: {
        value: {
            type: [Number, String],
            default: null,
        },
        currency: {
            type: String,
            required: true,
        },
        locale: {
            type: String,
            default: () => navigator.languages[0],
        },
        precision: {
            type: Number,
            default: 0,
        },
        rules: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            required: false,
        },
        message: {
            type: String,
            required: false,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const currencySymbol = computed(() => {
            if (props.currency) {
                return getCurrencySymbol(props.locale, props.currency);
            }
            return null;
        });

        const hasCurrency = computed(() => Boolean(props.currency));
        const currencyValidationResult = computed(() => hasCurrency.value || 'Currency is missing. Please reach out to support.');
        const rulesWithCurrencyValidation = computed(() => [currencyValidationResult.value, ...props.rules]);

        const onInput = (value) => emit('input', value);

        const onError = (hasError) => emit('update:error', hasError);

        return {
            currencySymbol,
            rulesWithCurrencyValidation,
            onInput,
            onError,
        };
    },
};
</script>

export default function getHtmlInputElementFrom(element: HTMLElement): HTMLInputElement | null {
    if (isHtmlInputElement(element)) {
        return element;
    }

    return element?.querySelector('input');
}

function isHtmlInputElement(element: HTMLElement): element is HTMLInputElement {
    return element?.matches('input');
}

<template>
    <ActionModal
        v-model="isOpen"
        :title="title"
        :description="description"
        :error="errorMessage"
        :is-loading="isDeletingLineItem"
        @cancel="cancelDeletion"
    >
        <template #actions>
            <SrButton type="delete" :loading="isDeletingLineItem" :disabled="isDeletingLineItem" @click="deleteLineItem">
                Delete
            </SrButton>
        </template>
    </ActionModal>
</template>

<script>
import ActionModal from '@/components/ActionModal';
import { SrButton } from '@ads/design-system';
import { lineItemsService } from '@/modules/line-items/services/LineItemsService';
import LINE_ITEM_STATUS from 'api-contracts/line-items/statuses/Status';

export default {
    name: 'SingleDeleteModal',
    components: {
        ActionModal,
        SrButton,
    },
    props: {
        /**
         * @model is modal open
         */
        value: {
            type: Boolean,
            default: false,
        },
        lineItem: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isOpen: this.value,
            isDeletingLineItem: false,
            errorMessage: null,
        };
    },
    computed: {
        title() {
            return `Delete${this.isActive ? ' Active ' : ' '}Line Item?`;
        },
        description() {
            if (this.isActive) {
                return (
                    'The line item you have selected to delete is active. ' +
                    `Are you sure you want to delete active ${this.lineItemName}?`
                );
            }

            return `Are you sure you want to delete ${this.lineItemName}?`;
        },
        isActive() {
            return this.lineItem.status === LINE_ITEM_STATUS.ACTIVE;
        },
        lineItemName() {
            return `line item ${this.lineItem.name}`;
        },
    },
    watch: {
        value(newValue) {
            this.isOpen = newValue;
        },
        isOpen() {
            this.emitIsOpenChange();
        },
    },
    methods: {
        emitIsOpenChange() {
            this.$emit('input', this.isOpen);
        },
        cancelDeletion() {
            this.clear();
            this.$emit('cancel');
        },
        handleDeletionSuccess() {
            this.clear();
            this.$emit('completed');
        },
        handleDeletionError() {
            this.errorMessage = `Could not delete ${this.lineItemName}.`;
        },
        async deleteLineItem() {
            this.isDeletingLineItem = true;
            this.errorMessage = null;
            try {
                await lineItemsService.delete(this.lineItem);
                this.handleDeletionSuccess();
            } catch (error) {
                this.handleDeletionError();
            } finally {
                this.isDeletingLineItem = false;
            }
        },
        clear() {
            this.isDeletingLineItem = false;
            this.errorMessage = null;
        },
    },
};
</script>

<template>
    <SrExpansionPanels :panels="panels" :targeting-type-count="targetingTypeCount">
        <template v-for="panel in panels" #[panel.name]>
            <div v-if="panel.component" :key="panel.name">
                <component
                    :is="panel.component"
                    v-model="lineItem"
                    :is-valid.sync="validStates[panel.name]"
                    :targeting-key="panel.key"
                    :initial-count="initialCount(panel.key)"
                    @currentTargetingCount="(count, key) => currentTargetingCount(count, key || panel.key)"
                    @changed="(status, key) => setChangedStatus(status, key || panel.key)"
                />
            </div>
            <div v-else :key="panel.name">{{ panel.title }}'s content</div>
        </template>
    </SrExpansionPanels>
</template>

<script>
import SrExpansionPanels from '@/components/SrExpansionPanels';
import emitValidStateMixin from '@/modules/line-items/mixins/emitValidStateMixin';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountDelegation from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountDelegation';
import FanIdTargeting from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Segment/FanIdTargeting';
import PixelSegments from './PixelSegments/PixelSegments';

export default {
    name: 'TargetingsSegment',
    components: {
        SrExpansionPanels,
        PixelSegments,
        FanIdTargeting,
    },
    mixins: [emitValidStateMixin, LineItemModel, TargetingCountDelegation],
    props: {
        isValid: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            validStates: {
                pixelSegmentTargeting: this.isValid,
                fanIdTargeting: this.isValid,
            },
            targetingCategory: 'segments',
        };
    },
    computed: {
        panels() {
            if (this.$root.user.isAdmin()) {
                return this.adminPanels;
            }
            return this.defaultPanels;
        },
        defaultPanels() {
            return [
                {
                    name: 'pixelSegmentTargeting',
                    title: 'Pixel Segments',
                    component: 'PixelSegments',
                    key: 'pixelSegmentTargeting',
                    hasError: !this.validStates.pixelSegmentTargeting,
                },
            ];
        },
        adminPanels() {
            return [
                ...this.defaultPanels,
                {
                    name: 'fanIdTargeting',
                    title: 'FanID',
                    component: 'FanIdTargeting',
                    key: 'fanIdTargeting',
                    hasError: !this.validStates.fanIdTargeting,
                },
            ];
        },
        isEveryTargetingValid() {
            return Object.values(this.validStates).every((validState) => Boolean(validState) === true);
        },
    },
    watch: {
        isEveryTargetingValid: 'emitValidState',
    },
    methods: {
        emitValidState() {
            this.$emit('update:isValid', this.isEveryTargetingValid);
        },
    },
};
</script>

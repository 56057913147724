<template>
    <VContainer v-if="isLoading" data-testid="FanIdTargeting__skeleton-container">
        <VRow>
            <VCol cols="12" class="pa-0">
                <VSkeletonLoader type="image" height="56" />
            </VCol>
        </VRow>
    </VContainer>
    <VContainer v-else data-testid="FanIdTargeting__container">
        <VForm v-model="isValid" @input="emitIsValid">
            <VRow>
                <VCol cols="12" class="pl-0 pr-0">
                    <ErrorBox :show="Boolean(errorMessage)" :message="errorMessage" />
                </VCol>
            </VRow>
            <VRow>
                <VCol cols="12" class="pa-0">
                    <SrSelect v-model="selectValue" :items="items" label="FanID Segment ID" sort-order="ASC" searchable />
                </VCol>
            </VRow>
            <VRow v-if="isFanIdSegmentSelected">
                <VCol cols="12" class="pa-0">
                    <SrNotification
                        data-testid="FanIdTargeting__SrNotification_fanIdSegment"
                        type="info"
                        :title="fanIdSegmentTitle"
                        :description="fanIdSegmentPriceDescription"
                        :dismissible="true"
                        @close="onClose"
                    />
                </VCol>
            </VRow>
        </VForm>
    </VContainer>
</template>

<script>
import { SrNotification, SrSelect } from '@ads/design-system';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import { mapGetters } from 'vuex';
import ErrorBox from '@/components/ErrorBox/ErrorBox';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';

export default {
    name: 'FanIdTargeting',
    components: { ErrorBox, SrSelect, SrNotification },
    mixins: [LineItemModel, TargetingCountUpdate],
    data() {
        const selectValue = this.getInitialFanIdSegmentId();
        return {
            selectValue,
            isValid: null,
        };
    },
    computed: {
        ...mapGetters({
            fanIdSegments: 'fanId/fanIdSegments',
            isLoading: 'fanId/isLoadingFanIdSegments',
            errorMessage: 'fanId/fanIdSegmentsFetchErrorMessage',
        }),
        items() {
            return this.fanIdSegments.map((segment) => ({
                value: segment.id,
                label: `${segment.name} (${segment.cpm} USD)`,
            }));
        },
        selectedFanIdSegmentId() {
            if (this.selectValue) {
                return this.selectValue;
            }
            return null;
        },
        selectedFanIdSegment() {
            if (this.selectedFanIdSegmentId == null) {
                return null;
            }
            return this.$store.getters['fanId/fanIdSegmentById'](this.selectedFanIdSegmentId);
        },
        isFanIdSegmentSelected() {
            return Boolean(this.selectedFanIdSegment);
        },
        fanIdSegmentTitle() {
            return `${this.selectedFanIdSegment?.name} (ID: ${this.selectedFanIdSegment?.id})`;
        },
        fanIdSegmentPriceDescription() {
            return `CPM for the FanID Segment is ${this.selectedFanIdSegment?.cpm} USD.`;
        },
        currentCount() {
            if (this.selectedFanIdSegment) {
                return 1;
            }
            return 0;
        },
    },
    watch: {
        selectedFanIdSegment(value) {
            this.updateTargetingOnLineItem(value);
        },
    },
    created() {
        this.fetchFanIdSegments();
    },
    methods: {
        onClose() {
            this.selectValue = null;
        },
        getInitialFanIdSegmentId() {
            const fanIdSegmentTargeting = this.value.targetings?.fanIdSegmentTargeting;
            if (fanIdSegmentTargeting) {
                return fanIdSegmentTargeting.fanIdSegment.id;
            }
            return null;
        },
        updateTargetingOnLineItem(fanIdSegment) {
            if (fanIdSegment) {
                this.setTargetingOnLineItem({ fanIdSegment });
            } else {
                this.setTargetingOnLineItem(null);
            }
        },
        setTargetingOnLineItem(fanIdSegmentTargeting) {
            this.lineItem.targetings.fanIdSegmentTargeting = fanIdSegmentTargeting;
        },
        fetchFanIdSegments() {
            this.$store.dispatch('fanId/fetchFanIdSegments');
        },
        emitIsValid(isValid) {
            this.$emit('update:isValid', isValid);
        },
    },
};
</script>

export const decimalRepresentationOf = (value: number): string => {
    const number = Number(value);
    const isValidNumber = value != null && !Number.isNaN(number);
    if (isValidNumber) {
        const decimals = 10 ** 5;
        const roundedTo5Decimals = Math.round(number * decimals) / decimals;
        return roundedTo5Decimals.toFixed(5);
    }
    return String(value);
};

import { Pixel } from '@/modules/pixels/types';

export type RetargetingStrategy = {
    id: number;
    expression: string;
    pixel: Pixel[];
};

export enum PIXEL_SEGMENT_TEMPLATE {
    NONE,
    CUSTOM,
    ROS__TO__REG_START,
    REG_START__TO__REGISTRATION,
    REGISTRATION__TO__FTD,
}

export type PixelSegmentTreeNode = {
    value: number;
    inclusionLabel: string;
    label: string;
    children?: PixelSegmentTreeNode[];
};

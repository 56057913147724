import {
    CacheableSportCalendarObjects,
    SportCalendarFilterParams,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/SportCalendar/types';

export default class SportCalendarCache {
    cachedData: Map<string, CacheableSportCalendarObjects>;

    constructor() {
        this.cachedData = new Map();
    }

    public get(filter: SportCalendarFilterParams): CacheableSportCalendarObjects {
        return this.cachedData.get(this.createCacheKeyFrom(filter));
    }

    public set(filter: SportCalendarFilterParams, data: CacheableSportCalendarObjects): void {
        this.cachedData.set(this.createCacheKeyFrom(filter), data);
    }

    public has(filter: SportCalendarFilterParams): boolean {
        return this.cachedData.has(this.createCacheKeyFrom(filter));
    }

    private createCacheKeyFrom(filter: SportCalendarFilterParams): string {
        const { sportId, countryId, tournamentId } = filter;
        return `${sportId}, ${countryId}, ${tournamentId}`;
    }
}

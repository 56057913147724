import { campaignService } from '@/modules/campaign/services/CampaignService';
import { Campaign } from '@/modules/campaign/types';
import { Predicate } from '@ads/predicate-model';

export default {
    async initialize({ dispatch }) {
        dispatch('fetchCampaigns');
    },

    async create(_, campaignRequest: Campaign) {
        return campaignService.create(campaignRequest);
    },

    async edit(_, campaignRequest: Campaign) {
        return campaignService.edit(campaignRequest);
    },

    async fetchCampaigns({ commit }, filter?: Predicate) {
        let campaigns = [];

        if (filter && filter.hasChildren()) {
            campaigns = await campaignService.filterCampaigns(filter);
        } else {
            campaigns = await campaignService.listCampaigns();
        }

        commit('setCampaigns', campaigns);
    },

    async fetchChangelog({ commit }, id) {
        const changelog = await campaignService.getChangelog(id);

        commit('setChangelog', changelog);
    },
};

import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import IFanIdSegmentResponse from 'api-contracts/fan-id/IFanIdSegmentResponse';

export default class FanIdSegmentService {
    private readonly requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async getFanIdSegments(): Promise<IFanIdSegmentResponse[]> {
        const options: IRequestOptions = {
            domain: 'programmatic-campaigns',
            resource: 'line-item',
            action: ACTION_NAME.READ,
        };

        return this.requestService.get('/fan-id-segments', options);
    }
}

export const fanIdSegmentService = new FanIdSegmentService(new RequestService({ baseUrl: API_URL }));

<template>
    <SrInput
        v-bind="$attrs"
        :label="label"
        class="sr-search-input"
        prepend-icon="search"
        data-testid="SrSearchInput__input"
        v-on="$listeners"
    />
</template>

<script>
import { SrInput } from '@ads/design-system';

export default {
    name: 'SrSearchInput',
    components: {
        SrInput,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: 'Quick search',
        },
    },
};
</script>

<style lang="scss" scoped>
.sr-search-input {
    ::v-deep .v-input__prepend-inner {
        margin-top: 0;
    }
}
</style>

<template>
    <VAlert prominent text :icon="false" class="ma-0 pt-0 pb-0 pl-15 pr-15" type="info">
        <VRow align="center">
            <VCol class="shrink">
                <VChip color="info" class="white--text" small>
                    {{ absoluteTargetings }}
                </VChip>
            </VCol>
            <VCol class="grow">
                <VCard outlined color="transparent" class="black--text">
                    <VCardTitle> Targetings Summary </VCardTitle>
                    <VCardSubtitle color="transparent" class="grey--text">
                        {{ headers }}
                    </VCardSubtitle>
                </VCard>
            </VCol>
        </VRow>
    </VAlert>
</template>

<script>
export default {
    name: 'TargetingsOverviewHeader',
    props: {
        targetingCount: {
            type: Object,
            required: true,
        },
    },
    computed: {
        absoluteTargetings() {
            if (!Object.keys(this.targetingCount).length) {
                return 0;
            }
            return (
                this.targetingCount.audience.count +
                this.targetingCount.content.count +
                this.targetingCount.technology.count +
                this.targetingCount.segments.count
            );
        },
        headers() {
            const headers = [];
            headers.push(this.addHeader('Audience', Boolean(this.targetingCount?.audience?.count)));
            headers.push(this.addHeader('Content', Boolean(this.targetingCount?.content?.count)));
            headers.push(this.addHeader('Technology', Boolean(this.targetingCount?.technology?.count)));
            headers.push(this.addHeader('Segments', Boolean(this.targetingCount?.segments?.count)));

            return headers.filter((header) => header).join(', ');
        },
    },
    methods: {
        addHeader(header, condition) {
            if (!condition) {
                return '';
            }
            return header;
        },
    },
};
</script>

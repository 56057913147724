/* eslint-disable max-len */
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import {
    DomainListCreate,
    DomainListEdit,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/DomainList/services/DomainList';
import { DomainList } from '../types';

export default class DomainListService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'line-item',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async create(domainList: DomainListCreate): Promise<DomainListEdit> {
        const options = this.getRequestOptions({ action: ACTION_NAME.CREATE });

        return this.requestService.post('/domain-lists', domainList, options);
    }

    async getByCampaignId(campaignId: number): Promise<DomainList[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get<DomainList[]>(`/domain-lists/?campaignId=${campaignId}`, options);
    }

    async getById(id: number): Promise<DomainListEdit> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get<DomainListEdit>(`/domain-lists/${id}`, options);
    }

    async edit(domainList: DomainListEdit): Promise<DomainListEdit> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });

        return this.requestService.put<DomainListEdit>(`/domain-lists/${domainList.id}`, domainList, options);
    }
}

export const domainListService = new DomainListService(new RequestService({ baseUrl: API_URL }));

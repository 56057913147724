<template>
    <VForm ref="form" v-model="formIsValid" :disabled="isLoading">
        <VRow v-if="isEditForm">
            <VCol sm="6" lg="4">
                <SrInput v-model.number="lineItem.dspId" label="DSP ID" disabled />
            </VCol>
            <VCol sm="6" lg="4">
                <SrInput v-model.number="lineItem.persistentId" label="Persistent ID" disabled />
            </VCol>
        </VRow>
        <VRow>
            <VCol sm="12" lg="4">
                <SrHeadline level="3" class="mb-6" weight="bold"> Basic Details </SrHeadline>
                <VRow>
                    <VCol>
                        <SrInput
                            v-model="lineItem.name"
                            :rules="rules.name"
                            label="Line Item Name"
                            class="line-item-name"
                            required
                            data-testid="LineItemFormStepBasics__name"
                        />
                    </VCol>
                </VRow>
                <VRow>
                    <VCol>
                        <SrSelect
                            :value="campaign"
                            searchable
                            class="selected-campaign"
                            label="Campaign"
                            :items="campaigns"
                            item-value="id"
                            item-text="name"
                            :disabled="isEditForm"
                            return-object
                            required
                            data-testid="LineItemFormStepBasics__campaign"
                            @change="updateSelectedCampaign"
                        />
                    </VCol>
                </VRow>
                <VRow>
                    <VCol>
                        <SrSelect
                            v-model="lineItem.status"
                            label="Status"
                            :items="lineItemStatus"
                            item-value="key"
                            item-text="name"
                            required
                        />
                    </VCol>
                </VRow>
            </VCol>
            <VCol sm="12" lg="4">
                <SrHeadline level="3" class="mb-6" weight="bold"> Duration </SrHeadline>
                <VRow>
                    <VCol>
                        <TimezoneSelector
                            v-model="lineItem.runtime.timeZone"
                            disabled
                            data-testid="LineItemFormStepBasics__time_zone"
                        />
                    </VCol>
                </VRow>
                <VRow>
                    <VCol>
                        <FutureDateTimeSelector
                            ref="startDate"
                            v-model="lineItem.runtime.startDate"
                            class="start-date"
                            :target-time-zone="lineItem.runtime.timeZone"
                            :rules="startDateRules"
                            :min-date="campaignStartDate"
                            :max-date="campaignEndDate"
                            default-time="00:00:00"
                            label="Start"
                            required
                            :disabled="!hasCampaignId"
                            data-testid="LineItemFormStepBasics__start_date"
                        />
                    </VCol>
                </VRow>
                <VRow>
                    <VCol>
                        <FutureDateTimeSelector
                            ref="endDate"
                            v-model="lineItem.runtime.endDate"
                            class="end-date"
                            :target-time-zone="lineItem.runtime.timeZone"
                            :rules="endDateRules"
                            :min-date="campaignStartDate"
                            :max-date="campaignEndDate"
                            label="End"
                            default-time="23:59:59"
                            required
                            :disabled="!hasCampaignId"
                            data-testid="LineItemFormStepBasics__end_date"
                        />
                    </VCol>
                </VRow>
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrHeadline, SrInput, SrSelect } from '@ads/design-system';
import { mapGetters } from 'vuex';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TimezoneSelector from '@/components/TimezoneSelector';
import FutureDateTimeSelector from '@/components/FutureDateTimeSelector/FutureDateTimeSelector';
import { getLengthValidationRules } from '@/shared/validation';
import LINE_ITEM_STATUS from 'api-contracts/line-items/statuses/Status';
import { getLineItemStartDateValidationRules, getLineItemEndDateValidationRules } from './validator';

export default {
    name: 'LineItemFormStepBasics',
    components: {
        SrInput,
        SrSelect,
        SrHeadline,
        FutureDateTimeSelector,
        TimezoneSelector,
    },
    mixins: [LineItemModel],
    props: {
        isValid: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isEditForm: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formIsValid: this.isValid,
            rules: {
                name: getLengthValidationRules('Line item name', 3, 128),
            },
            lineItemStatus: Object.values(LINE_ITEM_STATUS),
        };
    },
    computed: {
        ...mapGetters({
            campaigns: 'campaign/campaigns',
        }),
        startDateRules() {
            return getLineItemStartDateValidationRules(
                this.lineItem.runtime,
                this.campaignStartDate,
                this.campaignEndDate,
                this.shouldAllowDatesBeforeStartOfToday,
            );
        },
        endDateRules() {
            return getLineItemEndDateValidationRules(
                this.lineItem.runtime,
                this.campaignStartDate,
                this.campaignEndDate,
                this.shouldAllowDatesBeforeStartOfToday,
            );
        },
        shouldAllowDatesBeforeStartOfToday() {
            return this.isEditForm;
        },
        campaignStartDate() {
            if (!this.lineItem?.campaign?.runtime?.startDate) {
                return null;
            }
            return this.lineItem.campaign.runtime.startDate;
        },
        campaignEndDate() {
            if (!this.lineItem?.campaign?.runtime?.endDate) {
                return null;
            }
            return this.lineItem.campaign.runtime.endDate;
        },
        campaign() {
            return this.lineItem?.campaign;
        },
        campaignId() {
            return this.campaign?.id;
        },
        hasCampaignId() {
            return Boolean(this.campaignId);
        },
    },
    watch: {
        formIsValid(isValid) {
            this.$emit('update:isValid', isValid);
        },
        isValid(isValid) {
            this.formIsValid = isValid;
        },
        'lineItem.id': function () {
            this.$refs.form.resetValidation();
        },
    },
    methods: {
        updateSelectedCampaign(campaign) {
            if (!campaign) {
                return;
            }

            const { name, id, runtime } = campaign;
            this.lineItem.campaign = {
                name,
                id,
                runtime,
                currencyCode: campaign.budget.price.currencyCode,
            };
            this.lineItem.runtime.timeZone = runtime.timeZone;
            this.lineItem.runtime.startDate = runtime.startDate;
            this.lineItem.runtime.endDate = runtime.endDate;
        },
    },
};
</script>

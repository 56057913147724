<template>
    <div class="switch">
        <button
            v-for="button in buttons"
            :key="button.value"
            class="button"
            :class="{ active: button.value === value, 'has-error': button.hasError, 'status-changed': button.statusChanged }"
            :data-testid="`Switcher__${button.value}_button`"
            @click="onClick(button.value)"
        >
            <CountableBar :title="button.label" :initial-count="button.count" :updated-count="button.currentCount" />
        </button>
    </div>
</template>

<script>
import CountableBar from '@/components/CountableBar';

export default {
    name: 'Switcher',
    components: {
        CountableBar,
    },
    props: {
        buttons: {
            type: Array,
            default: () => [],
        },
        value: {
            type: [Number, String],
            default: null,
        },
    },
    methods: {
        onClick(value) {
            this.$emit('click', value);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@ads/design-system/src/scss/variables';

$error-color: var(--v-error-base, #ff5252);

.button {
    border: 1px solid $gray-light;
    border-radius: 3px;
    min-height: 50px !important;
    padding: 14px 16px 14px 20px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    width: 100%;
    text-align: left;
    transition: color 0.3s, border 0.3s;

    &.active {
        border: 1px solid #3367d6;
    }

    &.has-error {
        border: 1px solid $error-color;
    }

    &.status-changed {
        background-color: #e5f3fe;
    }
}

.button + .button {
    margin-top: 20px;
}
</style>

<template>
    <div>
        <span v-if="message" class="human-readable-runtime-message">
            {{ message }}
            <span v-if="showDate" class="human-readable-runtime-date"> (end {{ viewModel.formattedEndDate }}) </span>
        </span>
    </div>
</template>

<script>
import HumanReadableRuntimeViewModel from '@/components/HumanReadableRuntime/HumanReadableRuntimeViewModel';

export default {
    name: 'HumanReadableRuntime',
    props: {
        runtime: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            viewModel: new HumanReadableRuntimeViewModel(this.runtime),
        };
    },
    computed: {
        message() {
            if (this.isScheduled) {
                return '';
            }

            if (this.isActive && this.viewModel.isEndingToday()) {
                return 'Ends today';
            }

            if (this.isActive && this.viewModel.isEndingTomorrow()) {
                return 'Ends tomorrow';
            }

            if (this.isEnded) {
                return 'Ended';
            }

            return `Ends in ${this.viewModel.daysRemainingInHumanReadableFormat()}`;
        },
        isScheduled() {
            return this.status === 'Scheduled';
        },
        isActive() {
            return this.status === 'Active';
        },
        isEnded() {
            return this.status === 'Ended';
        },
        showDate() {
            return !(this.viewModel.isEndingToday(this.isActive) || this.viewModel.isEndingTomorrow(this.isActive));
        },
        status() {
            return this.runtime.status;
        },
    },
    watch: {
        runtime: {
            handler(newRuntime) {
                this.viewModel.initializeWith(newRuntime);
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '~@ads/design-system/src/scss/variables';

.human-readable-runtime {
    &-date {
        color: $gray-border;
    }
}
</style>

import state from '@/modules/feature-flags/store/state';
import actions from '@/modules/feature-flags/store/actions';
import mutations from '@/modules/feature-flags/store/mutations';
import getters from '@/modules/feature-flags/store/getters';

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

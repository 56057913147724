import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import IDeviceTypeResponse from 'api-contracts/line-items/targetings/devices/IDeviceTypeResponse';

export default class DeviceTypeService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'line-item',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async getAll(): Promise<IDeviceTypeResponse[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get('/device-types', options);
    }
}

export const deviceTypeService = new DeviceTypeService(new RequestService({ baseUrl: API_URL }));

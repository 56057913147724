<template>
    <div>
        <SrMenu v-model="optionsAreOpened" offset-y left min-width="100" :disabled="disabled">
            <template #activator="{ on, attrs }">
                <SrButton icon v-bind="attrs" :disabled="disabled" v-on="on">
                    <SrIcon :active="optionsAreOpened" icon="more" size="xs" />
                </SrButton>
            </template>
            <SrList dense>
                <!-- Other actions -->
                <SrListItem
                    v-for="action in actionRoutesAsArray"
                    :key="action.routeName"
                    :to="getRoute(action)"
                    :disabled="action.disabled"
                    @click="emitAction(action)"
                >
                    <SrListItemIcon>
                        <SrIcon :icon="action.icon" size="xs" />
                    </SrListItemIcon>
                    <SrListItemTitle>
                        {{ action.name }}
                    </SrListItemTitle>
                </SrListItem>

                <!-- Archive -->
                <SrListItem v-if="canArchiveLineItem" @click="showArchiveModal">
                    <SrListItemIcon>
                        <SrIcon icon="Inventory" size="xs" />
                    </SrListItemIcon>
                    <SrListItemTitle> Archive </SrListItemTitle>
                </SrListItem>

                <!-- Delete -->
                <SrListItem @click="showDeleteModal">
                    <SrListItemIcon>
                        <SrIcon icon="Trash" size="xs" />
                    </SrListItemIcon>
                    <SrListItemTitle> Delete </SrListItemTitle>
                </SrListItem>
            </SrList>
        </SrMenu>
        <SingleDeleteModal
            v-model="isShowingDeleteModal"
            :line-item="lineItem"
            @cancel="closeDeleteModal"
            @completed="onDeletionCompleted"
        />
        <SingleArchiveModal
            v-model="isShowingArchiveModal"
            :line-item="lineItem"
            @cancel="closeArchiveModal"
            @completed="onArchivingCompleted"
        />
    </div>
</template>

<script>
import { SrButton, SrIcon, SrMenu, SrList, SrListItem, SrListItemTitle, SrListItemIcon } from '@ads/design-system';
import SingleArchiveModal from '@/modules/line-items/components/SingleArchiveModal';
import SingleDeleteModal from '@/modules/line-items/components/SingleDeleteModal';
import LINE_ITEM_COMPUTED_STATUS from 'api-contracts/line-items/statuses/ComputedStatus';

export default {
    name: 'LineItemListTableItemActions',
    components: {
        SrButton,
        SrIcon,
        SingleArchiveModal,
        SingleDeleteModal,
        SrMenu,
        SrList,
        SrListItem,
        SrListItemTitle,
        SrListItemIcon,
    },
    props: {
        lineItem: {
            type: Object,
            required: true,
        },
        actionRoutes: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            optionsAreOpened: false,
            isShowingArchiveModal: false,
            isShowingDeleteModal: false,
        };
    },
    computed: {
        actionRoutesAsArray() {
            return Object.values(this.actionRoutes).filter((route) => {
                if (route.isAdminOnly) {
                    return this.$root.user.isAdmin();
                }

                return true;
            });
        },
        canArchiveLineItem() {
            return this.lineItem.computedStatus !== LINE_ITEM_COMPUTED_STATUS.ARCHIVE;
        },
    },
    methods: {
        getRoute(action) {
            if (!action.routeName) {
                return null;
            }

            const { id, name, dspId } = this.lineItem;
            if (action.routeName === 'line-items-history') {
                return {
                    name: action.routeName,
                    params: { id, dspId },
                    query: { name, currency: this.lineItem.campaign.currencyCode, timezone: this.lineItem.runtime.timeZone },
                };
            }

            return {
                name: action.routeName,
                params: { id },
                query: action.query,
            };
        },
        emitAction(action) {
            this.$emit('action', action, this.lineItem);
        },
        showArchiveModal() {
            this.isShowingArchiveModal = true;
        },
        onArchivingCompleted(archivedLineItem) {
            this.closeArchiveModal();
            this.emitDataUpdate(archivedLineItem);
        },
        closeArchiveModal() {
            this.isShowingArchiveModal = false;
        },
        emitDataUpdate(updatedLineItem) {
            this.$emit('update', updatedLineItem);
        },
        showDeleteModal() {
            this.isShowingDeleteModal = true;
        },
        onDeletionCompleted() {
            this.closeDeleteModal();
            this.emitDataDeletion();
        },
        closeDeleteModal() {
            this.isShowingDeleteModal = false;
        },
        emitDataDeletion() {
            this.$emit('delete');
        },
    },
};
</script>

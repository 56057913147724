import { parse, parseISO } from 'date-fns';
import { Runtime } from '@/modules/campaign/types';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export default class EditableEndDateRuntimeViewModel {
    public inputValue: string;

    private runtime: Runtime;

    private inputValueRegex = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;

    constructor(runtime: Runtime) {
        this.initializeWith(runtime);
    }

    public initializeWith(runtime: Runtime): void {
        this.runtime = runtime;
        this.resetFormattedDate();
    }

    public resetFormattedDate(): void {
        this.inputValue = this.formatEndDateFrom(this.runtime);
    }

    public getInputValueAsUtcIsoString(): string | null {
        const parsedInputValue = this.getParsedInputValue();
        if (!parsedInputValue) {
            return null;
        }

        const [day, month, year] = parsedInputValue;
        const endDateCopy = this.getEndDateCopy();
        this.enrichDate(endDateCopy, day, month, year);

        return zonedTimeToUtc(endDateCopy, this.runtime.timeZone).toISOString();
    }

    private enrichDate(date: Date, day: number, month: number, year: number): void {
        const dayThatAllMonthsHave = 1;
        date.setDate(dayThatAllMonthsHave); // This prevents skipping of months
        date.setFullYear(year);
        date.setMonth(month - 1);
        date.setDate(day);
    }

    private getEndDateCopy(): Date {
        const endDate = this.getZonedEndDate(this.runtime);
        return new Date(endDate);
    }

    private getParsedInputValue(): [number, number, number] | null {
        const match = this.inputValue.match(this.inputValueRegex);
        if (!match) {
            return null;
        }
        if (isNaN(this.parseInputValue().valueOf())) {
            return null;
        }
        return match.slice(1, 4).map(Number) as [number, number, number];
    }

    public parseInputValue(): Date {
        return parse(this.inputValue, 'd/M/y', new Date());
    }

    private formatEndDateFrom(runtime: Runtime): string {
        const endDate = this.getZonedEndDate(runtime);
        return `${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`;
    }

    private getZonedEndDate(runtime: Runtime): Date {
        return utcToZonedTime(parseISO(runtime.endDate), runtime.timeZone);
    }
}

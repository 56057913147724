<template>
    <div>
        <VRow>
            <VCol md="12" lg="3">
                <SrSelect v-model="selectedCreativeType" :items="creativeTypes" label="Type" clearable />
            </VCol>
        </VRow>
        <VDivider />
        <VRow class="mt-5">
            <VCol md="12" lg="6">
                <CreativeSection
                    ref="available-creatives-section"
                    :headers="availableCreativesHeaders"
                    :items="availableCreatives"
                    :server-items-length="creativesCount"
                    :loading="loadingAllCreatives"
                    :quick-search-value="searchValueAvailable"
                    action="add"
                    title="Available Creatives"
                    @add="add"
                    @addAll="addAll"
                    @fetch="fetchAllCreatives"
                    @preview="previewCreative"
                />
            </VCol>
            <VCol md="12" lg="6">
                <CreativeSection
                    ref="selected-creatives-section"
                    :headers="selectedCreativesHeaders"
                    :items="filteredCreatives"
                    :loading="loadingSelectedCreatives"
                    :quick-search-value="searchValueSelected"
                    action="remove"
                    title="Selected Creatives"
                    @remove="remove"
                    @removeAll="removeAll"
                    @fetch="filterLineItemCreatives"
                    @preview="previewCreative"
                />
            </VCol>
        </VRow>
    </div>
</template>

<script>
import { SrSelect } from '@ads/design-system';
import { filterByIdOrName } from '@/shared/utils';
import { creativesService } from '@/modules/line-items/components/LineItemForm/LineItemFormStepCreatives/services/CreativesService';
import { creativePreviewService } from '@/modules/line-items/components/LineItemForm/LineItemFormStepCreatives/services/CreativePreviewService';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import CREATIVE_TYPES from 'api-contracts/creatives/CreativeTypes';
import { availableCreativesHeaders, selectedCreativesHeaders } from './CreativesSelectionTables/config';
import CreativeSection from './CreativesSelectionTables/CreativeSection';

export default {
    name: 'LineItemFormStepCreatives',
    components: {
        CreativeSection,
        SrSelect,
    },
    mixins: [LineItemModel],
    props: {
        isValid: {
            type: Boolean,
            required: true,
        },
        selectedCreatives: {
            type: Array,
            default: null,
            validator: (prop) => prop === null || Boolean(prop),
        },
        filterConfig: {
            type: Object,
        },
    },
    data() {
        return {
            availableCreatives: [],
            filteredCreatives: [],
            creativesCount: 0,
            creativesService,
            creativePreviewService,
            loadingAllCreatives: false,
            loadingSelectedCreatives: false,
            selectedCreativesHeaders,
            availableCreativesHeaders,
            selectedCreativeType: this.filterConfig.type,
            searchValueAvailable: this.filterConfig.searchValueAvailable,
            searchValueSelected: this.filterConfig.searchValueSelected,
            creativeTypes: Object.values(CREATIVE_TYPES),
        };
    },
    computed: {
        hasCampaignId() {
            return Boolean(this.lineItem.campaign.id);
        },
    },
    watch: {
        selectedCreatives: {
            handler() {
                this.lineItem.creatives = this.selectedCreatives ? this.selectedCreatives.map(({ id }) => id) : [];
                this.markAvailableCreativesAsSelected();
                this.filterLineItemCreatives();
            },
        },
        selectedCreativeType() {
            this.resetPagination();
            this.fetchAllCreatives();
            this.filterLineItemCreatives();
        },
        'lineItem.campaign.id': function () {
            this.resetPagination();
            this.fetchAllCreatives();
            this.filterLineItemCreatives();
        },
    },
    mounted() {
        if (this.selectedCreatives === null) {
            this.fetchLineItemCreatives();
        }
    },
    methods: {
        addAll() {
            const creativesToAdd = this.availableCreatives.filter(({ isSelected }) => !isSelected);
            this.availableCreatives.forEach((creative) => {
                creative.isSelected = true;
            });
            this.updateSelectedCreatives([...this.selectedCreatives, ...creativesToAdd]);
        },
        removeAll() {
            const options = this.$refs['selected-creatives-section'].options;
            const selectedCreatives = this.filteredCreatives.slice(
                (options.page - 1) * options.itemsPerPage,
                options.page * options.itemsPerPage,
            );
            this.availableCreatives.forEach((availableCreative) => {
                const creativeToRemove = selectedCreatives.find(
                    (selectedCreative) => selectedCreative.id === availableCreative.id,
                );
                if (creativeToRemove) {
                    availableCreative.isSelected = false;
                }
            });
            const updatedCreatives = this.selectedCreatives.filter((value) => !selectedCreatives.includes(value));
            this.updateSelectedCreatives(updatedCreatives);
        },
        add({ id }) {
            const creativeToAdd = this.availableCreatives.find((creative) => creative.id === id);
            if (creativeToAdd) {
                creativeToAdd.isSelected = true;
                this.updateSelectedCreatives([...this.selectedCreatives, creativeToAdd]);
            }
        },
        remove({ id }) {
            this.updateSelectedCreatives(this.selectedCreatives.filter((creative) => creative.id !== id));
            this.markAvailableCreativeAsNotSelected(id);
        },
        async fetchAllCreatives(value) {
            try {
                const options = this.$refs['available-creatives-section'].options;
                this.searchValueAvailable = value ? value.query : this.searchValueAvailable;
                this.updateFilterConfig();

                if (this.hasCampaignId) {
                    this.loadingAllCreatives = true;
                    const creativeResponse = await creativesService.getAllByCampaignItem(this.lineItem.campaign.id, {
                        page: options?.page,
                        itemsPerPage: options?.itemsPerPage,
                        query: this.searchValueAvailable,
                        type: this.selectedCreativeType,
                    });

                    this.creativesCount = creativeResponse.totalCount;
                    this.populateAvailableCreatives(creativeResponse);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error.response.message);
            } finally {
                this.loadingAllCreatives = false;
            }
        },
        async fetchLineItemCreatives() {
            try {
                this.loadingSelectedCreatives = true;
                const lineItemCreatives = await creativesService.getLineItemCreatives(this.lineItem.id);

                this.updateSelectedCreatives(
                    lineItemCreatives.results.map((creative) => ({
                        ...creative,
                        isSelected: true,
                        size: this.creativeSize(creative),
                    })),
                );

                this.markAvailableCreativesAsSelected();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error.response.message);
            } finally {
                this.loadingSelectedCreatives = false;
            }
        },
        filterLineItemCreatives(value) {
            this.searchValueSelected = value ? value.query : this.searchValueSelected;
            this.updateFilterConfig();

            const selectedCreatives = this.selectedCreatives ?? [];
            this.filteredCreatives = selectedCreatives.filter((creative) => {
                if (this.selectedCreativeType) {
                    return creative.type === this.selectedCreativeType;
                }
                return true;
            });
            if (this.searchValueSelected !== '') {
                this.filteredCreatives = filterByIdOrName(this.filteredCreatives, this.searchValueSelected);
            }
        },
        markAvailableCreativeAsNotSelected(id) {
            this.availableCreatives.forEach((availableCreative) => {
                if (availableCreative.id === id) {
                    availableCreative.isSelected = false;
                }
            });
        },
        markAvailableCreativesAsSelected() {
            this.availableCreatives.forEach((creative) => {
                if (this.isCreativeSelected(creative.id)) {
                    creative.isSelected = true;
                }
            });
        },
        isCreativeSelected(id) {
            const selectedCreative = this.selectedCreatives?.find((creative) => creative.id === id);
            return Boolean(selectedCreative);
        },
        populateAvailableCreatives(creativeResponse) {
            this.availableCreatives = creativeResponse.results.map((creative) => ({
                ...creative,
                isSelected: this.isCreativeSelected(creative.id),
                size: this.creativeSize(creative),
            }));
        },
        updateFilterConfig() {
            this.$emit('update:filterConfig', {
                searchValueAvailable: this.searchValueAvailable,
                searchValueSelected: this.searchValueSelected,
                type: this.selectedCreativeType,
            });
        },
        creativeSize(creative) {
            if (!creative.size) {
                creative.size = { name: '' };
            }
            return creative.size;
        },
        async previewCreative({ id: dspId, type }) {
            const preview = window.open('about:blank', '_blank');
            const document = preview.document;
            let htmlPreview = '<p id="loading">Loading...</p>';
            document.write(htmlPreview);
            document.close();

            try {
                htmlPreview = await creativePreviewService.getCreativePreview(dspId, type);
            } catch (e) {
                htmlPreview = 'This Creative can not be displayed!';
            } finally {
                document.open();
                document.write(htmlPreview);
                document.close();
            }
        },
        resetPagination() {
            this.$refs['available-creatives-section'].resetPage();
            this.$refs['selected-creatives-section'].resetPage();
        },
        updateSelectedCreatives(selectedCreatives) {
            this.$emit('update:selectedCreatives', selectedCreatives);
        },
    },
};
</script>

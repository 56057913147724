<template>
    <div class="side-navigation">
        <SrNavigationBar :items="navItems" />
    </div>
</template>

<script>
import { SrNavigationBar } from '@ads/design-system';
import { canReadDeals, canReadCampaigns, canReadLineItems } from '@/permissions';

export default {
    name: 'SideNavigation',
    components: { SrNavigationBar },
    data() {
        return {
            navItems: [
                {
                    title: 'Campaigns',
                    icon: 'campaign',
                    routeName: 'campaign-list',
                    ...canReadCampaigns,
                },
                {
                    title: 'Line Items',
                    icon: 'programmatic',
                    routeName: 'line-items-list',
                    ...canReadLineItems,
                },
                {
                    title: 'Deals',
                    icon: 'file',
                    routeName: 'deals-list',
                    ...canReadDeals,
                },
            ],
        };
    },
};
</script>

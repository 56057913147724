<template>
    <SrModalPage type="basic" :loading="isLoading" :show="show" @close="close">
        <template #content>
            <VForm v-if="videoPlayerSize" ref="form" v-model="isValid">
                <SrHeadline level="1" weight="bold" class="mb-6">
                    {{ isEditForm ? 'Edit Video Player Size' : 'Create New Video Player Size' }}
                </SrHeadline>
                <VRow>
                    <VCol xlg="3" lg="3" md="3">
                        <SrHeadline level="3" weight="bold" class="mt-3 mb-6"> Basic Details </SrHeadline>
                        <SrInput v-model="videoPlayerSize.dspId" label="DSP ID" disabled class="mb-3" />
                        <SrInput
                            id="video-player-size-name"
                            v-model="videoPlayerSize.name"
                            label="Name"
                            required
                            :rules="rules.name"
                            class="mb-3"
                            :disabled="!videoPlayerSize.isEditable"
                        />
                    </VCol>
                    <VSpacer cols="1" />
                    <VCol xlg="8" lg="8" md="8">
                        <SrHeadline level="3" weight="bold" class="mt-3 mb-6"> Include ranges </SrHeadline>
                        <VContainer id="size-ranges" class="pa-0">
                            <VRow v-for="(range, index) in videoPlayerSize.sizeRanges" :key="range.key">
                                <VCol cols="5">
                                    <VRow>
                                        <VCol class="pr-1 pl-1 pb-0">
                                            <SrInput
                                                :id="`video-player-size-min-width-${index}`"
                                                :ref="`minWidth${index}`"
                                                v-model.number="range.minWidth"
                                                class="mb-0"
                                                label="Min Width"
                                                required
                                                :rules="rules.minWidth(index)"
                                                type="number"
                                                :disabled="!videoPlayerSize.isEditable"
                                                @input="validateMaxWidth(index)"
                                            />
                                        </VCol>
                                        <VCol class="pr-1 pl-1 pb-0">
                                            <SrInput
                                                :id="`video-player-size-max-width-${index}`"
                                                :ref="`maxWidth${index}`"
                                                v-model.number="range.maxWidth"
                                                class="mb-0"
                                                label="Max Width"
                                                required
                                                :rules="rules.maxWidth(index)"
                                                type="number"
                                                :disabled="!videoPlayerSize.isEditable"
                                                @input="validateMinWidth(index)"
                                            />
                                        </VCol>
                                    </VRow>
                                </VCol>
                                <VSpacer />
                                <VCol cols="5">
                                    <VRow>
                                        <VCol class="pr-1 pl-1 pb-0">
                                            <SrInput
                                                :id="`video-player-size-min-height-${index}`"
                                                :ref="`minHeight${index}`"
                                                v-model.number="range.minHeight"
                                                class="mb-0"
                                                label="Min Height"
                                                required
                                                :rules="rules.minHeight(index)"
                                                type="number"
                                                :disabled="!videoPlayerSize.isEditable"
                                                @input="validateMaxHeight(index)"
                                            />
                                        </VCol>
                                        <VCol class="pr-1 pl-1 pb-0">
                                            <SrInput
                                                :id="`video-player-size-max-height-${index}`"
                                                :ref="`maxHeight${index}`"
                                                v-model.number="range.maxHeight"
                                                class="mb-0"
                                                label="Max Height"
                                                required
                                                :rules="rules.maxHeight(index)"
                                                type="number"
                                                :disabled="!videoPlayerSize.isEditable"
                                                @input="validateMinHeight(index)"
                                            />
                                        </VCol>
                                    </VRow>
                                </VCol>
                                <VCol v-if="index !== 0" class="icon-wrapper" cols="1">
                                    <SrButton icon :disabled="!videoPlayerSize.isEditable" @click="deleteRange(index)">
                                        <SrIcon icon="Trash" size="xs" />
                                    </SrButton>
                                </VCol>
                                <VSpacer v-else cols="1" />
                            </VRow>
                            <VRow justify="end" class="mt-0">
                                <VCol class="add-wrapper pt-0 pl-1 pr-1" cols="5">
                                    Add new Range
                                    <SrButton
                                        color="blue lighten-5 blue--text text--lighten-1"
                                        elevation="0"
                                        class="add-range"
                                        :disabled="!videoPlayerSize.isEditable"
                                        @click="addRange"
                                    >
                                        Add
                                    </SrButton>
                                </VCol>
                                <VCol cols="1" />
                            </VRow>
                        </VContainer>
                        <SrHeadline level="3" weight="bold" class="mt-12 mb-6"> Exclude sizes </SrHeadline>
                        <VContainer id="excluded-sizes" class="m-0 pa-0">
                            <VRow v-for="(excludedSize, index) in videoPlayerSize.excludedSizes" :key="excludedSize.key">
                                <VCol class="pl-1 pr-1 pb-0" cols="5">
                                    <SrInput
                                        v-model.number="excludedSize.width"
                                        class="mb-0"
                                        label="Width"
                                        :rules="rules.excludedSize"
                                        type="number"
                                        :disabled="!videoPlayerSize.isEditable"
                                    />
                                </VCol>
                                <VSpacer cols="1" />
                                <VCol class="pl-1 pr-1 pb-0" cols="5">
                                    <SrInput
                                        v-model.number="excludedSize.height"
                                        class="mb-0"
                                        label="Height"
                                        :rules="rules.excludedSize"
                                        type="number"
                                        :disabled="!videoPlayerSize.isEditable"
                                    />
                                </VCol>
                                <VCol v-if="index !== 0" class="icon-wrapper" cols="1">
                                    <SrButton icon :disabled="!videoPlayerSize.isEditable" @click="deleteExcludedSize(index)">
                                        <SrIcon icon="Trash" size="xs" />
                                    </SrButton>
                                </VCol>
                                <VSpacer v-else cols="1" />
                            </VRow>
                            <VRow justify="end" class="mt-0">
                                <VCol class="add-wrapper pt-0 pl-1 pr-1" cols="5">
                                    Add new Size
                                    <SrButton
                                        color="blue lighten-5 blue--text text--lighten-1"
                                        elevation="0"
                                        class="add-excluded-size"
                                        :disabled="!videoPlayerSize.isEditable"
                                        @click="addExcludedSize"
                                    >
                                        Add
                                    </SrButton>
                                </VCol>
                                <VCol cols="1" />
                            </VRow>
                        </VContainer>
                    </VCol>
                </VRow>
            </VForm>
        </template>
        <template #footer>
            <div class="submit-wrapper">
                <VRow no-gutters class="mt-3">
                    <VCol cols="9">
                        <ErrorBox :show="showErrorMessage" :message="shownError" />
                    </VCol>
                    <VCol cols="1" />
                    <VCol cols="2">
                        <SrButton
                            slot="header-cta"
                            :disabled="!isValid"
                            :loading="isSaving"
                            class="blue white--text mr-2"
                            depressed
                            large
                            @click="submit"
                        >
                            Submit
                        </SrButton>
                    </VCol>
                </VRow>
            </div>
        </template>
    </SrModalPage>
</template>

<script>
import BasicForm from '@/components/Form/BasicForm';
import { SrButton, SrHeadline, SrIcon, SrInput, SrModalPage } from '@ads/design-system';
import rulesMixins from '@/modules/config/rulesMixins';
import { videoPlayerSizeService } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/VideoPlayerSize/service/VideoPlayerSizeService';
import ErrorBox from '@/components/ErrorBox';

const sizeRangeCommonRules = [
    (v) => !isNaN(Number(v)) || 'Only numeric values are allowed',
    (v) => Number(v) > 0 || 'Only positive values are allowed',
    (v) => Number(v) < 10000 || 'Only values smaller than 10 000 are allowed',
];

export default {
    name: 'VideoPlayerSizeForm',
    components: { SrModalPage, SrHeadline, SrInput, SrIcon, SrButton, ErrorBox },
    extends: BasicForm,
    mixins: [rulesMixins],
    props: {
        loadError: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            videoPlayerSizeService,
            videoPlayerSize: this.annotatedWithKeys(this.value),
            show: true,
            isSaving: false,
            isValid: false,
            errorMessage: null,
            disableErrorBox: false,
        };
    },
    computed: {
        rules() {
            return this.videoRules(this.videoPlayerSize);
        },
        shownError() {
            return this.errorMessage || this.loadError;
        },
        showErrorMessage() {
            return Boolean(this.shownError) && !this.disableErrorBox;
        },
    },
    watch: {
        value(value) {
            this.videoPlayerSize = this.annotatedWithKeys(value);
        },
    },
    methods: {
        videoRules(videoPlayerSize) {
            return {
                name: this.nameRules('Video Player Size name'),
                minWidth(index) {
                    return sizeRangeCommonRules.concat([
                        (v) => {
                            const maxWidth = Number(videoPlayerSize.sizeRanges[index].maxWidth);
                            return !maxWidth || Number(v) < maxWidth || 'Min Width must be less than Max Width';
                        },
                    ]);
                },
                maxWidth(index) {
                    return sizeRangeCommonRules.concat([
                        (v) => {
                            const minWidth = Number(videoPlayerSize.sizeRanges[index].minWidth);
                            return !minWidth || Number(v) > minWidth || 'Max Width must be greater than Min Width';
                        },
                    ]);
                },
                minHeight(index) {
                    return sizeRangeCommonRules.concat([
                        (v) => {
                            const maxHeight = Number(videoPlayerSize.sizeRanges[index].maxHeight);
                            return !maxHeight || Number(v) < maxHeight || 'Min Height must be less than Max Height';
                        },
                    ]);
                },
                maxHeight(index) {
                    return sizeRangeCommonRules.concat([
                        (v) => {
                            const minHeight = Number(videoPlayerSize.sizeRanges[index].minHeight);
                            return !minHeight || Number(v) > minHeight || 'Max Height must be greater than Min Height';
                        },
                    ]);
                },
                excludedSize: [
                    (v) => !v || !isNaN(Number(v)) || 'Only numeric values are allowed',
                    (v) => !v || Number(v) > 0 || 'Only positive values are allowed',
                    (v) => !v || Number(v) < 10000 || 'Only values smaller than 10 000 are allowed',
                ],
            };
        },
        validateMinWidth(index) {
            if (this.$refs[`minWidth${index}`]) {
                this.$refs[`minWidth${index}`][0].$children[0].validate();
            }
        },
        validateMaxWidth(index) {
            if (this.$refs[`maxWidth${index}`]) {
                this.$refs[`maxWidth${index}`][0].$children[0].validate();
            }
        },
        validateMinHeight(index) {
            if (this.$refs[`minHeight${index}`]) {
                this.$refs[`minHeight${index}`][0].$children[0].validate();
            }
        },
        validateMaxHeight(index) {
            if (this.$refs[`maxHeight${index}`]) {
                this.$refs[`maxHeight${index}`][0].$children[0].validate();
            }
        },
        annotatedWithKeys(videoPlayerSize) {
            return {
                ...videoPlayerSize,
                sizeRanges: this.value.sizeRanges.map((sizeRange, index) => ({
                    ...sizeRange,
                    key: index,
                })),
                excludedSizes: this.value.excludedSizes.map((excludedSize, index) => ({
                    ...excludedSize,
                    key: index,
                })),
            };
        },
        getNextSizeRangeKey() {
            return Math.max(...this.videoPlayerSize.sizeRanges.map(({ key }) => key)) + 1;
        },
        getNextExcludedSizeKey() {
            return Math.max(...this.videoPlayerSize.excludedSizes.map(({ key }) => key)) + 1;
        },
        addRange() {
            this.videoPlayerSize.sizeRanges = this.videoPlayerSize.sizeRanges.concat({
                key: this.getNextSizeRangeKey(),
                ...this.videoPlayerSizeService.createEmptySizeRange(),
            });
        },
        deleteRange(deletedIndex) {
            this.videoPlayerSize.sizeRanges = this.videoPlayerSize.sizeRanges.filter((_, index) => deletedIndex !== index);
        },
        addExcludedSize() {
            this.videoPlayerSize.excludedSizes = this.videoPlayerSize.excludedSizes.concat({
                key: this.getNextExcludedSizeKey(),
                ...this.videoPlayerSizeService.createEmptySize(),
            });
        },
        deleteExcludedSize(deletedIndex) {
            this.videoPlayerSize.excludedSizes = this.videoPlayerSize.excludedSizes.filter((_, index) => deletedIndex !== index);
        },
        async save() {
            try {
                this.isSaving = true;
                this.disableErrorBox = true;
                // eslint-disable-next-line
                const videoPlayerSize = Object.assign({}, this.videoPlayerSize);
                delete videoPlayerSize.isEditable;
                const videoPlayerSizeRequest = {
                    ...videoPlayerSize,
                    sizeRanges: this.videoPlayerSize.sizeRanges.map((sizeRange) => {
                        const range = { ...sizeRange };
                        delete range.key;
                        return range;
                    }),
                    excludedSizes: this.videoPlayerSize.excludedSizes
                        .map((excludedSize) => {
                            const size = { ...excludedSize };
                            delete size.key;
                            return size;
                        })
                        .filter((size) => Boolean(size.width) && Boolean(size.height)),
                };
                await this.videoPlayerSizeService[this.submitAction](videoPlayerSizeRequest);
            } catch (e) {
                this.disableErrorBox = false;
                this.error = e?.response?.message ?? String(e);
                throw e;
            } finally {
                this.isSaving = false;
            }
        },
        async close() {
            await this.$router.push({ name: this.afterCloseRoute, params: this.afterCloseRouteParams });
        },
    },
};
</script>

<style lang="scss">
@import '~@ads/design-system/src/scss/variables';
.icon-wrapper {
    padding-top: 20px;
    padding-left: 15px;
}

.add-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    color: $gray-border;
}

.add-excluded-size,
.add-range {
    margin-left: 10px;
}

.submit-wrapper {
    display: flex;
    justify-content: flex-end;
}
</style>

<template>
    <VForm v-model="formIsValid">
        <VRow>
            <VCol sm="6" lg="4">
                <CampaignBudget
                    v-model="budget.price.value"
                    :currency="budget.price.currencyCode"
                    :spent-budget="spentBudget"
                    data-testid="CampaignFormStepBudget__price"
                    @input="emitBudgetPrice"
                />
            </VCol>
            <VCol sm="4" lg="2">
                <SrSelect
                    v-model="budget.type"
                    label="Budget Type"
                    :items="budgetTypes"
                    item-value="key"
                    item-text="name"
                    required
                    disabled
                />
            </VCol>
        </VRow>
        <VRow v-if="isPacingEnabled">
            <VCol sm="12" lg="6">
                <SrHeadline level="3" weight="bold">Pacing Targets</SrHeadline>
                <CampaignPacing
                    v-model="pacing"
                    :runtime="value.runtime"
                    :currency-code="budget.price.currencyCode"
                    @input="emitPacingChange"
                />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrHeadline, SrSelect } from '@ads/design-system';
import BUDGET_TYPE from 'api-contracts/campaigns/BudgetType';
import CampaignBudget from '@/modules/campaign/components/CampaignBudget';
import { mapGetters } from 'vuex';
import CampaignPacing from '@/modules/campaign/components/CampaignPacing';
import UserMixin from '@/mixins/UserMixin';

export default {
    name: 'CampaignFormStepBudget',
    components: {
        CampaignPacing,
        SrHeadline,
        CampaignBudget,
        SrSelect,
    },
    mixins: [UserMixin],
    props: {
        /**
         * @model
         */
        value: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        spentBudget: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            budget: this.value.budget,
            pacing: this.value.pacing,
            formIsValid: this.isValid,
        };
    },
    computed: {
        ...mapGetters({
            getFeatureFlags: 'featureFlags/getFeatureFlag',
        }),
        budgetTypes() {
            return Object.values(BUDGET_TYPE);
        },
        isPacingEnabled() {
            return this.getFeatureFlags('dsp-2040-campaign-pacing-enabled') && this.isLoggedInAsAdmin;
        },
    },
    watch: {
        formIsValid(isValid) {
            this.$emit('update:isValid', isValid);
        },
        isValid(isValid) {
            this.formIsValid = isValid;
        },
        value: {
            deep: true,
            handler(value) {
                this.budget = value.budget;
                this.pacing = value.pacing;
            },
        },
    },
    methods: {
        emitBudgetPrice(newBudget) {
            this.$emit('budgetPriceChange', newBudget);
        },
        emitPacingChange(newPacing) {
            this.$emit('pacingChange', newPacing);
        },
    },
};
</script>

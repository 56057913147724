import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import ISspResponse from 'api-contracts/ssps/ISspResponse';
import INetworkResponse from 'api-contracts/ssps/INetworkResponse';

export default class SspService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'ssps',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async getSsps(): Promise<ISspResponse[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get('/ssps', options);
    }

    async getNetworksOfSsp(sspId): Promise<INetworkResponse[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/ssps/${sspId}/networks`, options);
    }
}

export const sspService = new SspService(new RequestService({ baseUrl: API_URL }));

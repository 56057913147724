/* eslint-disable max-len */
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import {
    ApplicationNameListCreate,
    ApplicationNameListEdit,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/ApplicationNameList/services/ApplicationNameList';
import { API_URL } from '@/config';
import IListOverviewResponse from 'api-contracts/overview/IListOverviewResponse';

export default class ApplicationNameListService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'line-item',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async create(applicationNameList: ApplicationNameListCreate): Promise<ApplicationNameListEdit> {
        const options = this.getRequestOptions({ action: ACTION_NAME.CREATE });

        return this.requestService.post('/application-name-lists', applicationNameList, options);
    }

    async getById(id: number): Promise<ApplicationNameListEdit> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/application-name-lists/${id}`, options);
    }

    async edit(applicationNameList: ApplicationNameListEdit): Promise<ApplicationNameListEdit> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });

        return this.requestService.put(`/application-name-lists/${applicationNameList.id}`, applicationNameList, options);
    }

    async getByCampaignId(campaignId): Promise<IListOverviewResponse[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/application-name-lists/?campaignId=${campaignId}`, options);
    }
}

export const applicationNameListService = new ApplicationNameListService(new RequestService({ baseUrl: API_URL }));

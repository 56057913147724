<template>
    <div>
        <SrHeadline level="3" weight="bold"> Available Operating Systems </SrHeadline>
        <SelectableTree v-model="selectedTree" :source-nodes="operatingSystemNodes" :mode.sync="mode" :is-loading="isLoading" />
    </div>
</template>

<script>
import { SrHeadline } from '@ads/design-system';
import SelectableTree from '@/components/SelectableTree/SelectableTree';
import { SelectedTree } from '@/components/SelectableTree/SelectedTree';
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import { operatingSystemService } from './service/OperatingSystemService';
import { operatingSystemTransformer } from './OperatingSystemTransformer';

export default {
    name: 'OperatingSystem',
    components: { SelectableTree, SrHeadline },
    mixins: [LineItemModel],
    data() {
        return {
            operatingSystemNodes: [],
            isLoading: false,
            mode: this.getInitialMode(),
            selectedTree: null,
            operatingSystemService,
            operatingSystemTransformer,
        };
    },
    watch: {
        selectedTree: {
            deep: true,
            handler() {
                this.$set(
                    this.lineItem.targetings,
                    'operatingSystems',
                    this.operatingSystemTransformer.selectedNodesToOperatingSystemTargetings(
                        this.selectedTree.getSelectedNodeTrees(),
                    ),
                );
            },
        },
    },
    created() {
        this.getOperatingSystems();
        this.selectedTree = new SelectedTree(this.getSelectedOperatingSystems());
    },
    methods: {
        async getOperatingSystems() {
            this.isLoading = true;
            try {
                this.operatingSystemNodes = this.operatingSystemTransformer.operatingSystemsToSelectableNodes(
                    await this.operatingSystemService.getAll(),
                );
            } finally {
                this.isLoading = false;
            }
        },
        getSelectedOperatingSystems() {
            if (this.lineItem.targetings?.operatingSystems?.length > 0) {
                return this.operatingSystemTransformer.operatingSystemTargetingsToSelectedNodes(
                    this.lineItem.targetings.operatingSystems,
                );
            }
            return [];
        },
        getInitialMode() {
            if (this.value.targetings?.operatingSystems?.length > 0) {
                return this.value.targetings.operatingSystems[0].isExcluded ? SELECTION_MODE.EXCLUDE : SELECTION_MODE.INCLUDE;
            }
            return SELECTION_MODE.INCLUDE;
        },
    },
};
</script>

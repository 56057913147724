<template>
    <div>
        <VRow no-gutters>
            <ListHeader
                headline-name="Deals"
                button-name="Create Deal"
                route="deals-create"
                :required-permission="canCreateDeals"
            >
                <SrAdvancedFilter
                    slot="filter"
                    v-model="filter"
                    class="mt-6"
                    :config="filterConfig"
                    data-testid="DealList__filter"
                />
                <SrSearchInput slot="quicksearch" v-model="quickSearchValue" />
            </ListHeader>

            <Divider />

            <ErrorBox :show="showErrorMessage" :message="error" class="flex" />

            <Table :headers="listHeaders" :is-loading="isLoading" :items="deals" entity-name="Deal">
                <template #actions="item">
                    <TableItemActions :action-routes="listActionRoutes" :item="item" data-testid="DealList__actions" />
                </template>

                <template #name="item">
                    <router-link :to="getUpdateRoute(item)" class="font-weight-bold">
                        {{ item.name }}
                    </router-link>
                </template>
            </Table>
        </VRow>
        <router-view />
    </div>
</template>

<script>
import ListHeader from '@/components/ListHeader/ListHeader';
import Divider from '@/components/Divider/Divider';
import Table from '@/components/Table/Table';
import { mapGetters } from 'vuex';
import TableItemActions from '@/components/Table/TableItemActions';
import { SrAdvancedFilter } from '@ads/design-system';
import {
    OPERATORS,
    OperatorTypes,
    SearchValueTypes,
    SelectSortOrder,
} from '@ads/design-system/src/components/SrAdvancedFilter/types';
import ErrorBox from '@/components/ErrorBox';
import SrSearchInput from '@/components/SrSearchInput';
import { canCreateDeals } from '@/permissions';
import DealEnvironment from 'api-contracts/deals/DealEnvironment';
import DealFormat from 'api-contracts/deals/DealFormat';
import { listActionRoutes, listHeaders } from './config';

export default {
    name: 'List',
    components: {
        SrSearchInput,
        ListHeader,
        Divider,
        Table,
        TableItemActions,
        SrAdvancedFilter,
        ErrorBox,
    },
    data() {
        return {
            canCreateDeals,
            listHeaders,
            listActionRoutes,
            filter: null,
            error: null,
            isLoading: false,
            quickSearchValue: null,
        };
    },
    computed: {
        ...mapGetters({
            allSsps: 'ssp/selectableSsps',
            allSelectableNetworks: 'ssp/selectableNetworks',
            allCountries: 'geo/countries',
        }),
        selectableCountries() {
            return this.allCountries.map((country) => ({
                value: country.id,
                name: country.name,
                label: country.name,
            }));
        },
        countryNames() {
            return this.allCountries.map((country) => country.name);
        },
        deals() {
            if (this.quickSearchValue) {
                return this.$store.getters['deal/dealsByFilter'](this.quickSearchValue, this.allCountries);
            }
            return this.$store.getters['deal/deals'];
        },
        showErrorMessage() {
            return Boolean(this.error);
        },
        filterConfig() {
            return {
                properties: [
                    {
                        name: 'ID',
                        filterCriteria: 'id',
                        operatorType: OperatorTypes.NUMERIC,
                        searchValueType: SearchValueTypes.TEXT,
                    },
                    {
                        name: 'Name',
                        filterCriteria: 'name',
                        operatorType: OperatorTypes.ALPHABETIC,
                        searchValueType: SearchValueTypes.TEXT,
                    },
                    {
                        name: 'Deal ID',
                        filterCriteria: 'externalId',
                        operatorType: OperatorTypes.ALPHABETIC,
                        searchValueType: SearchValueTypes.TEXT,
                    },
                    {
                        name: 'SSP',
                        filterCriteria: 'ssp.id',
                        operatorType: OperatorTypes.BOOLEAN,
                        searchValueType: SearchValueTypes.SELECT,
                        selectValues: this.allSsps,
                        toStringConfig: {
                            getParameterValueLabel: (predicate) => {
                                if (!predicate) {
                                    return '';
                                }
                                const selectedSsp = this.allSsps.find((ssp) => ssp.value === predicate.parameterValue);
                                if (selectedSsp) {
                                    return selectedSsp.label;
                                }
                                return predicate.parameterValue;
                            },
                        },
                    },
                    {
                        name: 'Network',
                        filterCriteria: 'network.id',
                        operatorType: OperatorTypes.BOOLEAN,
                        searchValueType: SearchValueTypes.SELECT,
                        selectValues: this.allSelectableNetworks,
                        selectSortOrder: SelectSortOrder.ASC,
                        selectSearchable: true,
                        toStringConfig: {
                            getParameterValueLabel: (predicate) => {
                                if (!predicate) {
                                    return '';
                                }
                                const selectedNetwork = this.allSelectableNetworks.find(
                                    (network) => network.value === predicate.parameterValue,
                                );
                                if (selectedNetwork) {
                                    return selectedNetwork.label;
                                }
                                return predicate.parameterValue;
                            },
                        },
                    },
                    {
                        name: 'Environment',
                        filterCriteria: 'environment',
                        operatorType: OperatorTypes.BOOLEAN,
                        searchValueType: SearchValueTypes.SELECT,
                        selectSearchable: true,
                        selectSortOrder: SelectSortOrder.ASC,
                        selectValues: Object.values(DealEnvironment),
                    },
                    {
                        name: 'Country',
                        filterCriteria: 'dealHasCountries.country_id',
                        operatorType: OperatorTypes.BOOLEAN,
                        searchValueType: SearchValueTypes.SELECT,
                        selectSearchable: true,
                        selectSortOrder: SelectSortOrder.ASC,
                        selectValues: this.selectableCountries,
                        toStringConfig: {
                            getParameterValueLabel: (predicate) => {
                                if (!predicate) {
                                    return '';
                                }
                                const selectedCountry = this.allCountries.find(
                                    (country) => country.id === predicate.parameterValue,
                                );
                                if (selectedCountry) {
                                    return selectedCountry.name;
                                }
                                return predicate.parameterValue;
                            },
                        },
                    },
                    {
                        name: 'Format',
                        filterCriteria: 'format',
                        operatorType: OperatorTypes.BOOLEAN,
                        searchValueType: SearchValueTypes.SELECT,
                        selectSearchable: true,
                        selectSortOrder: SelectSortOrder.ASC,
                        selectValues: Object.values(DealFormat),
                    },
                ],
                operators: OPERATORS,
            };
        },
    },
    watch: {
        filter: {
            deep: true,
            handler: 'fetchDeals',
        },
        async $route(currentRoute, previousRoute) {
            const isRouteChange = currentRoute.name !== previousRoute.name;
            const isCurrentRouteDealList = currentRoute.name === 'deals-list';
            if (isRouteChange && isCurrentRouteDealList) {
                await this.fetchDeals();
            }
        },
    },
    created() {
        this.fetchDeals();
    },
    methods: {
        async fetchDeals(filter = null) {
            try {
                this.isLoading = true;
                await this.$store.dispatch('deal/fetchDeals', filter);
            } catch (error) {
                this.error = error?.response?.message ?? error.toString();
            } finally {
                this.isLoading = false;
            }
        },
        getUpdateRoute({ id }) {
            return {
                name: 'deals-update',
                params: { id },
            };
        },
    },
};
</script>

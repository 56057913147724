export const changelogTableHeader = [
    {
        text: 'Action Type',
        value: 'actionType',
        align: 'left',
        width: '150px',
    },
    {
        text: 'Changes',
        value: 'changes',
        align: 'left',
    },
    {
        text: 'Changed at',
        value: 'date',
        align: 'left',
        width: '200px',
    },
];

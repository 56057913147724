<template>
    <VForm ref="form" v-model="isPixelSegmentValid">
        <div>
            <VRow>
                <VCol cols="12">
                    <PixelSegmentTemplates
                        v-model="pixelSegment"
                        :is-valid.sync="isPixelSegmentTemplateValid"
                        :selected-template-id.sync="pixelSegmentTemplateId"
                        :pixels="pixelSegmentTemplatesPixels"
                    />
                </VCol>
            </VRow>
            <VRow>
                <VCol cols="12">
                    <SrNotification
                        v-if="isPixelFromAnotherAdvertiserSelected"
                        :title="notificationContent.title"
                        :description="notificationContent.description"
                        type="info"
                        data-testid="PixelSegments__notification"
                    />
                </VCol>
            </VRow>
            <VRow>
                <VCol cols="12">
                    <ErrorBox :message="errorMessage" :show="Boolean(errorMessage)" />
                </VCol>
            </VRow>
            <VRow>
                <VCol cols="6">
                    <SrSearchInput v-model.trim="searchValue" :disabled="arePixelsLoading" />
                    <VDataTable
                        item-key="dspId"
                        :items="availablePixelsWithGroupProp"
                        :search="searchValue"
                        :headers="pixelsTableHeaders"
                        :custom-group="customGroup"
                        group-by="group"
                        class="pixel-table"
                        dense
                        disable-pagination
                        fixed-header
                        height="300"
                        hide-default-footer
                        :loading="arePixelsLoading"
                    >
                        <template #[`group.header`]="{ group, isOpen, toggle }">
                            <th :ref="group" colspan="2" class="mc-user-input" @click="toggle">
                                <VIcon color="brand-blue">
                                    {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                                </VIcon>
                                {{ group }}
                            </th>
                        </template>
                        <template #item="{ item }">
                            <tr>
                                <td>
                                    <strong>{{ item.dspId }}</strong>
                                </td>
                                <td>{{ item.name }}</td>
                            </tr>
                        </template>
                    </VDataTable>
                </VCol>
                <VCol cols="6">
                    <PredicateInput
                        v-model="pixelSegment"
                        label="Pixel Segment Expression"
                        :rules="pixelSegmentRules"
                        :disabled="shouldDisablePredicateInput"
                        clearable
                    />
                    <div class="list pixel-segment-list">
                        <PixelSegmentTree :items="pixelSegmentTree" />
                    </div>
                </VCol>
            </VRow>
        </div>
    </VForm>
</template>

<script>
import { pixelService } from '@/modules/pixels/services/PixelService';
import { Predicate } from '@ads/predicate-model';
import SrSearchInput from '@/components/SrSearchInput';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import { SrNotification } from '@ads/design-system';
import ErrorBox from '@/components/ErrorBox/ErrorBox';
import { segmentContainsOnlyGivenPixels } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Segment/PixelSegments/validator';
import PIXEL_SOURCE from 'api-contracts/pixels/PixelSource';
import { PIXEL_SEGMENT_TEMPLATE } from './types';
import { notificationContent, PixelGroup, pixelsTableHeaders } from './config';
import PixelSegmentTree from './PixelSegmentTree';
import PredicateInput from './PredicateInput';
import PixelSegmentTemplates from './Templates/PixelSegmentTemplates';
import PixelSegmentsTree from './PixelSegmentsTree';

export default {
    name: 'PixelSegments',
    components: {
        ErrorBox,
        PredicateInput,
        PixelSegmentTemplates,
        PixelSegmentTree,
        SrSearchInput,
        SrNotification,
    },
    mixins: [LineItemModel, TargetingCountUpdate],
    props: {
        isValid: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            pixelsTableHeaders,
            notificationContent,
            pixelSegment: null,
            availablePixels: [],
            searchValue: '',
            arePixelsLoading: false,
            pixelSegmentTree: [],
            isPixelSegmentValid: false,
            isPixelSegmentTemplateValid: true,
            pixelSegmentTemplateId: PIXEL_SEGMENT_TEMPLATE.NONE,
            tablePixelGroupNames: Object.values(PixelGroup), // Defines order of groups in table
            errorMessage: null,
        };
    },
    computed: {
        availablePixelsWithGroupProp() {
            return this.availablePixels.map((pixel) => ({
                ...pixel,
                group: this.getPixelGroup(pixel),
            }));
        },
        pixelSegmentTemplatesPixels() {
            if (this.businessEntityId === null) {
                return [];
            }
            return this.availablePixels.filter((pixel) => pixel?.businessEntity?.id === this.businessEntityId);
        },
        businessEntityId() {
            return this.campaign?.businessEntity.id;
        },
        holdingId() {
            return this.campaign?.businessEntity.holding.id;
        },
        campaign() {
            return this.$store.getters['campaign/campaignById'](this.lineItem.campaign.id);
        },
        currentCount() {
            if (!this.pixelSegmentHasChildren) {
                return 0;
            }
            return Number(this.isPixelSegmentValid);
        },
        pixelSegmentHasChildren() {
            return Boolean(this.lineItem?.targetings?.pixelSegmentTargeting?.children?.length);
        },
        pixelSegmentRules() {
            if (this.arePixelsLoading) {
                return [];
            }
            return [(v) => segmentContainsOnlyGivenPixels(v, this.availablePixels)];
        },
        isPixelFromAnotherAdvertiserSelected() {
            if (!this.pixelSegment || this.businessEntityId == null) {
                return false;
            }
            const pixelIdsInSegment = this.pixelSegment.getParameterValuesFlat();
            const pixelsInSegment = this.availablePixels.filter((pixel) => pixelIdsInSegment.includes(pixel.dspId));
            return pixelsInSegment.some((pixel) => pixel.businessEntity?.id && pixel.businessEntity.id !== this.businessEntityId);
        },
        shouldDisablePredicateInput() {
            return !this.isPixelSegmentTemplateValid || this.arePixelsLoading;
        },
    },
    watch: {
        pixelSegment() {
            this.isPixelSegmentValid = this.$refs.form.validate();
            this.updatePixelSegmentTree();
            this.updateValue();
        },
        availablePixels() {
            this.updatePixelSegmentTree();
        },
        isPixelSegmentValid() {
            this.emitValidState();
        },
        isPixelSegmentTemplateValid() {
            this.emitValidState();
        },
    },
    async created() {
        this.initPixelSegment();
        await this.fetchAllPixels();
        this.collapseAllGroupsExceptCurrentAdvertiser();
    },
    methods: {
        clearErrorMessage() {
            this.errorMessage = null;
        },
        initPixelSegment() {
            const existingPixelSegment = this.lineItem.targetings.pixelSegmentTargeting;
            if (existingPixelSegment) {
                this.pixelSegment = new Predicate(existingPixelSegment);
                this.setCachedTargeting(this.pixelSegment.toString());
            } else {
                this.setCachedTargeting('');
            }
        },
        async fetchAllPixels() {
            this.arePixelsLoading = true;
            this.clearErrorMessage();
            try {
                this.availablePixels = await pixelService.getForHolding(this.holdingId);
            } catch (error) {
                this.errorMessage = 'Could not get the list of pixels.';
                // eslint-disable-next-line no-console
                console.log(error);
            } finally {
                this.arePixelsLoading = false;
            }
        },
        updatePixelSegmentTree() {
            if (!this.pixelSegment) {
                return;
            }

            const pixelSegmentsTree = new PixelSegmentsTree(this.pixelSegment, this.availablePixels);
            this.pixelSegmentTree = pixelSegmentsTree.transformToTree();
        },
        updateValue() {
            this.$set(this.lineItem.targetings, 'pixelSegmentTargeting', this.pixelSegment.withoutCircularReferences());

            this.emitValidState();
        },
        emitValidState() {
            this.$emit('update:isValid', this.isPixelSegmentValid && this.isPixelSegmentTemplateValid);
        },
        targetingChanged(value) {
            return this.cachedTargeting !== value.toString() || this.currentCount !== this.initialCount;
        },
        getPixelGroup(pixel) {
            if (pixel.source === PIXEL_SOURCE.PIXEL_MANAGER) {
                if (this.businessEntityId != null && pixel.businessEntity?.id === this.businessEntityId) {
                    return PixelGroup.CURRENT_ADVERTISER;
                }
                return PixelGroup.DISCOVERY;
            }
            return pixel.source;
        },
        groupPixels(pixels) {
            return pixels.reduce((groupedPixels, pixel) => {
                groupedPixels[pixel.group] = groupedPixels[pixel.group] || [];
                groupedPixels[pixel.group].push(pixel);
                return groupedPixels;
            }, {});
        },
        customGroup(pixels) {
            const groups = this.groupPixels(pixels);
            return this.tablePixelGroupNames.map((name) => ({ name, items: groups[name] || [] }));
        },
        collapseAllGroupsExceptCurrentAdvertiser() {
            const groupsToCollapse = this.tablePixelGroupNames.filter((name) => name !== PixelGroup.CURRENT_ADVERTISER);
            groupsToCollapse.forEach((group) => {
                this.$refs[group]?.click();
            });
        },
    },
};
</script>

<style lang="scss">
@import '~@ads/design-system/src/scss/variables';
@import '~@ads/design-system/src/scss/mixins';

$group-header-row-height: 42px;
$group-header-font-size: 14px;

.list {
    border: 1px solid $gray;
    border-radius: 3px;
}

.pixel-segment-list {
    min-height: 300px;
}

.pixel-table {
    border: 1px solid $gray;
    border-radius: 3px;

    .v-data-table__wrapper {
        @include custom-scrollbar;
    }

    .v-row-group__header {
        background-color: $gray-lightest !important;
        height: $group-header-row-height;

        > th {
            font-size: $group-header-font-size !important;
            color: $brand-blue;
            font-weight: normal;
            cursor: pointer;
        }
    }

    tr {
        &:hover {
            background-color: $gray-lightest !important;
        }
        cursor: default;
    }

    th {
        &.active i::before {
            color: $brand-red;
        }

        > span {
            color: $brand-blue;
            font-weight: normal;
        }
    }
}
</style>

<template>
    <SelectableTree v-model="selectedTree" :source-nodes="allSspNetworks" :mode.sync="mode" :is-loading="isLoading" />
</template>

<script>
import SelectableTree from '@/components/SelectableTree/SelectableTree';
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import SspTargetingViewModel from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/SspTargeting/SspTargetingViewModel';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';

export default {
    name: 'SspTargeting',
    components: { SelectableTree },
    mixins: [LineItemModel],
    props: {
        wasDataLoaded: {
            type: Boolean,
        },
    },
    data() {
        return {
            sspTargetingViewModel: new SspTargetingViewModel(),
            selectedTree: undefined,
            mode: SELECTION_MODE.INCLUDE,
            allSspNetworks: [],
            isLoading: false,
        };
    },
    watch: {
        selectedTree: {
            deep: true,
            handler() {
                this.syncLineItemWithSelectedTree();
            },
        },
    },
    async created() {
        const sspTargetings = this.lineItem.targetings.sspTargetings;
        this.selectedTree = this.sspTargetingViewModel.createSelectedTreeFrom(sspTargetings);
        this.mode = this.sspTargetingViewModel.getInitialTreeModeFrom(sspTargetings);

        this.allSspNetworks = this.sspTargetingViewModel.getAllSspNetworks();
        // needed to check for changes
        this.$emit('update:wasDataLoaded', true);
    },
    methods: {
        syncLineItemWithSelectedTree() {
            this.$set(
                this.lineItem.targetings,
                'sspTargetings',
                this.sspTargetingViewModel.createSspTargetingsFrom(this.selectedTree),
            );
        },
    },
};
</script>

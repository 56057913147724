<template>
    <SrMenu v-model="optionsAreOpened" offset-y left min-width="100" :disabled="disabled">
        <template #activator="{ on, attrs }">
            <SrButton icon v-bind="attrs" :disabled="disabled" data-testid="TableItemActions__open_button" v-on="on">
                <SrIcon :active="optionsAreOpened" icon="more" size="xs" :disabled="disabled" />
            </SrButton>
        </template>
        <SrList dense>
            <SrListItem
                v-for="action in actionRoutesAsArray"
                :key="action.routeName"
                :to="getRoute(action)"
                :disabled="action.disabled"
                @click="emitAction(action)"
            >
                <SrListItemIcon>
                    <SrIcon :icon="action.icon" size="xs" />
                </SrListItemIcon>
                <SrListItemTitle>
                    {{ action.name }}
                </SrListItemTitle>
            </SrListItem>
        </SrList>
    </SrMenu>
</template>

<script>
import { SrButton, SrIcon, SrListItemTitle, SrListItemIcon, SrListItem, SrList, SrMenu } from '@ads/design-system';

export default {
    components: { SrButton, SrIcon, SrListItemTitle, SrListItemIcon, SrListItem, SrList, SrMenu },
    props: {
        item: {
            type: Object,
            required: true,
        },
        actionRoutes: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            optionsAreOpened: false,
        };
    },
    computed: {
        actionRoutesAsArray() {
            return Object.values(this.actionRoutes).filter((route) => {
                if (route.visibilityCondition) {
                    return route.visibilityCondition(this.item);
                }

                return true;
            });
        },
    },
    methods: {
        getRoute(action) {
            if (!action.routeName) {
                return null;
            }

            const { id } = this.item;
            return {
                name: action.routeName,
                params: { id },
                query: action.query,
            };
        },
        emitAction(action) {
            this.$emit('action', action, this.item);
        },
    },
};
</script>

import { RouteConfig } from 'vue-router';
import LineItemsCreate from './views/create';
import LineItemsEdit from './views/edit';
import LineItemsHistory from './views/history';
import LineItemsList from './views/list';
import ApplicationNameListCreate from './views/targetings/applicationNameList/create';
import ApplicationNameListEdit from './views/targetings/applicationNameList/edit';
import DomainListCreate from './views/targetings/domainList/create';
import DomainListEdit from './views/targetings/domainList/edit';
import BundleListCreate from './views/targetings/bundleList/create';
import BundleListEdit from './views/targetings/bundleList/edit';
import PublisherListCreate from './views/targetings/publisherList/create';
import PublisherListEdit from './views/targetings/publisherList/edit';
import VideoPlayerSizeCreate from './views/targetings/videoPlayerSize/create';
import VideoPlayerSizeEdit from './views/targetings/videoPlayerSize/edit';
import LocationCreate from './views/targetings/location/create';
import LocationEdit from './views/targetings/location/edit';

const routes: RouteConfig[] = [
    {
        name: 'line-items-list',
        path: '/line-items',
        component: LineItemsList,
        props: (route) => {
            const campaignIdParameter = route.query.campaignId as string;
            const campaignId = Number.parseInt(campaignIdParameter, 10);
            if (Number.isNaN(campaignId)) {
                return null;
            }
            return { campaignId };
        },
        children: [
            {
                name: 'line-items-create',
                path: 'create',
                component: LineItemsCreate,
                props: true,
                children: [
                    {
                        name: 'video-player-size-create',
                        path: 'video-player-size/create',
                        component: VideoPlayerSizeCreate,
                    },
                    {
                        name: 'video-player-size-update',
                        path: 'video-player-size/:videoPlayerSizeId',
                        component: VideoPlayerSizeEdit,
                        props: (route) => ({
                            videoPlayerSizeId: route.params.videoPlayerSizeId,
                        }),
                    },
                    {
                        name: 'application-name-list-create',
                        path: 'application-name-list/create',
                        component: ApplicationNameListCreate,
                    },
                    {
                        name: 'application-name-list-update',
                        path: 'application-name-list/:listId',
                        component: ApplicationNameListEdit,
                        props: (route) => ({
                            listId: route.params.listId,
                        }),
                    },
                    {
                        name: 'domain-list-create',
                        path: 'domain-list/create',
                        component: DomainListCreate,
                    },
                    {
                        name: 'domain-list-update',
                        path: 'domain-list/:listId',
                        component: DomainListEdit,
                        props: (route) => ({
                            listId: route.params.listId,
                        }),
                    },
                    {
                        name: 'bundle-list-create',
                        path: 'bundle-list/create',
                        component: BundleListCreate,
                    },
                    {
                        name: 'bundle-list-update',
                        path: 'bundle-list/:listId',
                        component: BundleListEdit,
                        props: (route) => ({
                            listId: route.params.listId,
                        }),
                    },
                    {
                        name: 'publisher-list-create',
                        path: 'publisher-list/create',
                        component: PublisherListCreate,
                    },
                    {
                        name: 'publisher-list-update',
                        path: 'publisher-list/:listId',
                        component: PublisherListEdit,
                        props: (route) => ({
                            listId: route.params.listId,
                        }),
                    },
                    {
                        name: 'location-create',
                        path: 'location/create',
                        component: LocationCreate,
                    },
                    {
                        name: 'location-update',
                        path: 'location/:locationId',
                        component: LocationEdit,
                        props: (route) => ({
                            locationId: route.params.locationId,
                        }),
                    },
                ],
            },
            {
                name: 'line-items-update',
                path: ':id',
                component: LineItemsEdit,
                props: true,
                children: [
                    {
                        name: 'existing-application-name-list-create',
                        path: 'application-name-list/create',
                        component: ApplicationNameListCreate,
                        props: (route) => ({
                            id: route.params.id,
                        }),
                    },
                    {
                        name: 'existing-application-name-list-update',
                        path: 'application-name-list/:listId',
                        component: ApplicationNameListEdit,
                        props: (route) => ({
                            id: route.params.id,
                            listId: route.params.listId,
                        }),
                    },
                    {
                        name: 'existing-domain-list-create',
                        path: 'domain-list/create',
                        component: DomainListCreate,
                        props: (route) => ({
                            id: route.params.id,
                        }),
                    },
                    {
                        name: 'existing-domain-list-update',
                        path: 'domain-list/:listId',
                        component: DomainListEdit,
                        props: (route) => ({
                            id: route.params.id,
                            listId: route.params.listId,
                        }),
                    },
                    {
                        name: 'existing-bundle-list-create',
                        path: 'bundle-list/create',
                        component: BundleListCreate,
                        props: (route) => ({
                            id: route.params.id,
                        }),
                    },
                    {
                        name: 'existing-bundle-list-update',
                        path: 'bundle-list/:listId',
                        component: BundleListEdit,
                        props: (route) => ({
                            id: route.params.id,
                            listId: route.params.listId,
                        }),
                    },
                    {
                        name: 'existing-publisher-list-create',
                        path: 'publisher-list/create',
                        component: PublisherListCreate,
                        props: (route) => ({
                            id: route.params.id,
                        }),
                    },
                    {
                        name: 'existing-publisher-list-update',
                        path: 'publisher-list/:listId',
                        component: PublisherListEdit,
                        props: (route) => ({
                            id: route.params.id,
                            listId: route.params.listId,
                        }),
                    },
                    {
                        name: 'existing-video-player-size-create',
                        path: 'video-player-size/create',
                        component: VideoPlayerSizeCreate,
                        props: (route) => ({
                            id: route.params.id,
                        }),
                    },
                    {
                        name: 'existing-video-player-size-update',
                        path: 'video-player-size/:videoPlayerSizeId',
                        component: VideoPlayerSizeEdit,
                        props: (route) => ({
                            id: route.params.id,
                            videoPlayerSizeId: route.params.videoPlayerSizeId,
                        }),
                    },
                    {
                        name: 'existing-location-create',
                        path: 'location/create',
                        component: LocationCreate,
                        props: (route) => ({
                            id: route.params.id,
                        }),
                    },
                    {
                        name: 'existing-location-update',
                        path: 'location/:locationId',
                        component: LocationEdit,
                        props: (route) => ({
                            id: route.params.id,
                            locationId: route.params.locationId,
                        }),
                    },
                ],
            },
            {
                name: 'line-items-history',
                path: 'history/:dspId',
                component: LineItemsHistory,
                props: (route) => ({
                    dspId: route.params.dspId,
                }),
            },
        ],
    },
];

export default routes;

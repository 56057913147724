import { RouteConfig } from 'vue-router';
import DealsCreate from './views/create';
import DealsList from './views/list';
import DealsEdit from './views/edit';
import DealDelete from './views/delete';

const routes: RouteConfig[] = [
    {
        name: 'deals-list',
        path: '/deals',
        component: DealsList,
        children: [
            {
                name: 'deals-create',
                path: 'create',
                component: DealsCreate,
            },
            {
                name: 'deals-delete',
                path: ':id/delete',
                component: DealDelete,
                props: true,
            },
            {
                name: 'deals-update',
                path: ':id',
                component: DealsEdit,
                props: true,
            },
        ],
    },
];

export default routes;

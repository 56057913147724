<template>
    <VForm v-model="isFormValid" :disabled="disableEditing" class="mt-3 mb-3">
        <VRow>
            <VCol class="pt-2 pb-1">
                <SrHeadline level="2"> Basic Settings </SrHeadline>
            </VCol>
        </VRow>
        <VRow>
            <VCol class="pt-1 pb-1">
                <SrInput
                    v-model="duplicationConfig.name"
                    label="Campaign Name"
                    :rules="rules.name"
                    required
                    data-testid="CampaignDuplicateModal__name"
                />
            </VCol>
        </VRow>
        <VRow>
            <VCol class="pt-1 pb-1">
                <SrCheckbox v-model="duplicationConfig.willKeepLearnings" label="Keep campaign learnings" class="ma-0 pa-0" />
            </VCol>
        </VRow>
        <VRow>
            <VCol class="pt-2 pb-1">
                <SrHeadline level="2"> Budget </SrHeadline>
            </VCol>
        </VRow>
        <VRow>
            <VCol class="pt-1 pb-1">
                <CampaignBudget
                    :value="duplicationConfig.budget.price.value"
                    :currency="duplicationConfig.budget.price.currencyCode"
                    data-testid="CampaignDuplicateModal__budget"
                    @input="onBudgetChange"
                />
            </VCol>
        </VRow>
        <VRow>
            <VCol class="pt-2 pb-1">
                <SrHeadline level="2"> Runtime </SrHeadline>
            </VCol>
        </VRow>
        <VRow>
            <VCol class="pt-1 pb-1">
                <TimezoneSelector v-model="duplicationConfig.runtime.timeZone" data-testid="CampaignDuplicateModal__time_zone" />
            </VCol>
        </VRow>
        <VRow>
            <VCol class="pt-1 pb-1">
                <FutureDateTimeSelector
                    v-model="duplicationConfig.runtime.startDate"
                    label="Start"
                    default-time="00:00:00"
                    :target-time-zone="duplicationConfig.runtime.timeZone"
                    :rules="startDateRules"
                    :disabled="disableEditing"
                    required
                    data-testid="CampaignDuplicateModal__start_date"
                />
            </VCol>
        </VRow>
        <VRow>
            <VCol class="pt-1 pb-1">
                <FutureDateTimeSelector
                    v-model="duplicationConfig.runtime.endDate"
                    label="End"
                    default-time="23:59:59"
                    :target-time-zone="duplicationConfig.runtime.timeZone"
                    :rules="endDateRules"
                    :disabled="disableEditing"
                    required
                    data-testid="CampaignDuplicateModal__end_date"
                />
            </VCol>
        </VRow>
        <VRow v-if="isUserAdmin">
            <VCol class="pt-1 pb-1">
                <SrHeadline level="2">Internal Information</SrHeadline>
                <KpiConfigurator v-model="duplicationConfig.kpi" :currency="duplicationConfig.budget.price.currencyCode" />
            </VCol>
        </VRow>
        <VRow v-if="duplicationConfig.willKeepLearnings">
            <VCol class="pt-1 pb-1">
                <SrNotification
                    type="info"
                    title="Keeping campaign learnings"
                    :description="keepingLearningsInfoText"
                    data-testid="CampaignDuplicateModal__learnings_notification"
                />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import FutureDateTimeSelector from '@/components/FutureDateTimeSelector/FutureDateTimeSelector';
import TimezoneSelector from '@/components/TimezoneSelector';
import { SrCheckbox, SrHeadline, SrInput, SrNotification } from '@ads/design-system';
import { getLengthValidationRules } from '@/shared/validation';
import { getCampaignEndDateValidationRules, getCampaignStartDateValidationRules } from '@/modules/campaign/components/validator';
import { toLocaleStringInTimezone } from '@/shared/date-time';
import { areDateRangesNotOverlapping } from '@/shared/validation/date-time/dateTime';
import CampaignBudget from '@/modules/campaign/components/CampaignBudget';
import KpiConfigurator from '@/modules/campaign/components/CampaignForm/CampaignFormStepBasic/KpiConfigurator';

export default {
    name: 'CampaignDuplicateForm',
    components: {
        KpiConfigurator,
        CampaignBudget,
        FutureDateTimeSelector,
        SrCheckbox,
        SrHeadline,
        SrInput,
        SrNotification,
        TimezoneSelector,
    },
    props: {
        /**
         * @model duplication config
         */
        value: {
            type: Object,
            required: true,
        },
        originalRuntime: {
            type: Object,
            default: null,
        },
        disableEditing: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            duplicationConfig: this.value,
            isFormValid: false,
            rules: {
                name: getLengthValidationRules('Campaign name', 3, 128),
            },
        };
    },
    computed: {
        startDateRules() {
            return [...getCampaignStartDateValidationRules(this.duplicationConfig?.runtime), this.areRuntimesNotOverlapping];
        },
        endDateRules() {
            return [...getCampaignEndDateValidationRules(this.duplicationConfig?.runtime), this.areRuntimesNotOverlapping];
        },
        areRuntimesNotOverlapping() {
            const canRuntimesOverlap = !this.duplicationConfig?.willKeepLearnings;
            if (canRuntimesOverlap) {
                return true;
            }
            return areDateRangesNotOverlapping(
                { start: this.duplicationConfig?.runtime?.startDate, end: this.duplicationConfig?.runtime?.endDate },
                { start: this.originalRuntime?.startDate, end: this.originalRuntime?.endDate },
                "Runtime is overlapping with original campaign's runtime",
            );
        },
        originalStartDateLocale() {
            if (!this.originalRuntime?.startDate) {
                return '';
            }
            return toLocaleStringInTimezone(this.originalRuntime.startDate, this.duplicationConfig.runtime.timeZone);
        },
        originalEndDateLocale() {
            if (!this.originalRuntime?.endDate) {
                return '';
            }
            return toLocaleStringInTimezone(this.originalRuntime.endDate, this.duplicationConfig.runtime.timeZone);
        },
        keepingLearningsInfoText() {
            return (
                "When keeping learnings, you need to select dates outside of original campaign's runtime " +
                `(${this.originalStartDateLocale} - ${this.originalEndDateLocale}).`
            );
        },
        isUserAdmin() {
            return Boolean(this.$root?.user?.isAdmin());
        },
    },
    watch: {
        value(newValue) {
            this.duplicationConfig = newValue;
        },
        duplicationConfig() {
            this.emitConfigChange();
        },
        isFormValid() {
            this.emitIsFormValidChange();
        },
    },
    methods: {
        emitConfigChange() {
            this.$emit('input', this.duplicationConfig);
        },
        emitIsFormValidChange() {
            this.$emit('isFormValid', this.isFormValid);
        },
        onBudgetChange(newBudget) {
            this.duplicationConfig.budget.price.value = newBudget;
            this.emitConfigChange();
        },
    },
};
</script>

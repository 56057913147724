import LINE_ITEM_STATUS from 'api-contracts/line-items/statuses/Status';
import LINE_ITEM_COMPUTED_STATUS from 'api-contracts/line-items/statuses/ComputedStatus';
import { Pixel } from '@/modules/pixels/types';
import { GeoTargeting } from 'src/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/TargetingsGeoTargeting/GeoTargetingController';
import {
    BrowserTargeting,
    OperatingSystemTargetingResponse,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Technology/Browser/types';
import { CategoryTargetingResponse } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/Category/types';
import { VideoPlayerSizeTargeting } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/VideoPlayerSize/types';
import { DomainListTargeting } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/DomainList/types';
import { SportCalendarTargeting } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/SportCalendar/types';
import { StrategyConfig } from 'api-contracts';
import IAppOrWebTargeting from 'api-contracts/line-items/targetings/IAppOrWebTargeting';
import IHoursByDay from 'api-contracts/line-items/targetings/time/IHoursByDay';
import { Runtime } from '@/modules/campaign/types';
import IContextualCategoryTargetingResponse from 'api-contracts/line-items/targetings/IContextualCategoryTargetingResponse';

export enum LINE_ITEM_ALERT_STATUS {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
}

export type LineItemRuntime = {
    startDate: string;
    endDate: string;
    timeZone: string;
};

export type LineItemCampaign = {
    id?: number;
    name: string;
    runtime?: Runtime;
};

type LineItemAlert = {
    status: LINE_ITEM_ALERT_STATUS;
    description: string;
};

export type LineItem = {
    id?: number;
    name: string;
    campaign: LineItemCampaign;
    status: LINE_ITEM_STATUS;
    computedStatus: LINE_ITEM_COMPUTED_STATUS;
    runtime: LineItemRuntime;
    alerts: LineItemAlert[];
    pixels: Pixel[];
    targetings?: {
        id?: number;
        timeTargeting?: IHoursByDay;
        appOrWebTargeting: IAppOrWebTargeting;
        geoTargetings: GeoTargeting[];
        operatingSystems: OperatingSystemTargetingResponse[];
        browserTargetings: BrowserTargeting[];
        categoryTargetings: CategoryTargetingResponse[];
        videoPlayerSizeTargetings: VideoPlayerSizeTargeting[];
        domainLists: DomainListTargeting[];
        sportCalendarTargetings: SportCalendarTargeting[];
        contextualCategoryTargeting: IContextualCategoryTargetingResponse | null;
    };
    strategyId?: number;
    strategyConfig?: StrategyConfig;
    isFrequencyCappingEnabled?: boolean;
    frequencyCappings: FrequencyCapping[];
};

export type FrequencyCapping = {
    cappingLimit: number;
    timeValue: number;
    timeUnit: string;
};

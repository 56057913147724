<template>
    <div class="programmatic-campaigns-mf" data-app>
        <VApp :id="MICROFRONTEND_APPLICATION_CONTAINER_ID" class="programmatic-campaigns">
            <SrAppContainer :id="PROGRAMMATIC_CAMPAIGNS_CONTAINER_ID">
                <template #sidebar>
                    <SideNavigation />
                </template>
                <template #content>
                    <router-view />
                </template>
            </SrAppContainer>
        </VApp>
    </div>
</template>

<script>
import { SrAppContainer } from '@ads/design-system';
import { MICROFRONTEND_APPLICATION_CONTAINER_ID, PROGRAMMATIC_CAMPAIGNS_CONTAINER_ID } from '@/config';
import SideNavigation from './components/SideNavigation';

export default {
    name: 'App',
    components: { SrAppContainer, SideNavigation },
    data() {
        return {
            MICROFRONTEND_APPLICATION_CONTAINER_ID,
            PROGRAMMATIC_CAMPAIGNS_CONTAINER_ID,
        };
    },
    created() {
        this.$store.dispatch('initialize');
    },
};
</script>

import { filterByIdOrName } from '@/shared/utils';

export default {
    campaigns: (state) => state.campaigns,
    campaignsByIdOrName: (state) => (term) => filterByIdOrName(state.campaigns, term),
    campaignById: (state) => (id) => state.campaigns.find((item) => item.id === id),
    changelog: (state) => state.changelog,
    selectableCampaigns: (state) =>
        state.campaigns.map(({ id, name }) => ({
            value: id,
            label: name,
        })),
};

import getTodaysOffsetForTimezone from '@/shared/date-time/getTodaysOffsetForTimezone';

export default class TimezoneSelectorViewModel {
    appendTimezoneOffset(timezone: string): string {
        try {
            return `${timezone} (${getTodaysOffsetForTimezone(timezone)})`;
        } catch (e) {
            if (e instanceof RangeError) {
                return timezone;
            }
            throw e;
        }
    }
}

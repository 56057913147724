import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import { QueryParameter } from '@/modules/line-items/components/LineItemForm/LineItemFormStepCreatives/types';
import IPaginatedResponse from 'api-contracts/IPaginatedResponse';
import ICreativeResponse from 'api-contracts/creatives/ICreativeResponse';

export default class CreativesService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'line-item',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async getAllByCampaignItem(
        campaignId: number,
        queryParameter: QueryParameter,
    ): Promise<IPaginatedResponse<ICreativeResponse>> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        const urlSearchParams = this.getUrlSearchParams(queryParameter);

        return this.requestService.get(`/campaigns/${campaignId}/creatives?${urlSearchParams}`, options);
    }

    async getLineItemCreatives(lineItemId: number): Promise<IPaginatedResponse<ICreativeResponse>> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/line-items/${lineItemId}/creatives/`, options);
    }

    private getUrlSearchParams(queryParameters: QueryParameter): URLSearchParams {
        const query = new URLSearchParams();
        for (const key of Object.keys(queryParameters)) {
            const queryParameterValue = queryParameters[key];

            if (queryParameterValue) {
                query.append(key, queryParameterValue);
            }
        }

        return query;
    }
}

export const creativesService = new CreativesService(new RequestService({ baseUrl: API_URL }));

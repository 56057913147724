import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import {
    LocationCreate,
    LocationEdit,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/Location/services/types';

export default class LocationService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'line-item',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async create(location: LocationCreate): Promise<LocationEdit> {
        const options = this.getRequestOptions({ action: ACTION_NAME.CREATE });

        return this.requestService.post('/locations', location, options);
    }

    async getById(id: number): Promise<LocationEdit> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/locations/${id}`, options);
    }

    async edit(location: LocationEdit): Promise<LocationEdit> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });

        return this.requestService.put(`/locations/${location.id}`, location, options);
    }

    async getByCampaignId(campaignId): Promise<LocationEdit[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/locations/?campaignId=${campaignId}`, options);
    }
}

export const locationService = new LocationService(new RequestService({ baseUrl: API_URL }));

<template>
    <div>
        <SrHeadline level="3" weight="bold"> Available Devices </SrHeadline>
        <SelectableTree v-model="selectableTree" :source-nodes="devices" :mode.sync="mode" :is-loading="isLoading" />
    </div>
</template>

<script>
import { SrHeadline } from '@ads/design-system';
import SelectableTree from '@/components/SelectableTree/SelectableTree';
import { Node } from '@/components/SelectableTree/Node';
import { SelectedTree } from '@/components/SelectableTree/SelectedTree';
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import { deviceService } from './service/DeviceService';
import DeviceTargetingsTransformer from './service/DeviceTargetingsTransformer';
import SelectedTreeNodesTransformer from './service/SelectedTreeNodesTransformer';

export default {
    name: 'Device',
    components: { SelectableTree, SrHeadline },
    mixins: [LineItemModel],
    data() {
        return {
            deviceService,
            devices: [],
            isLoading: false,
            mode: this.getInitialMode(),
            deviceTargetingTransformer: new DeviceTargetingsTransformer(this.createSelectedDeviceCache()),
            selectableTree: this.initTree(),
        };
    },
    watch: {
        selectableTree: {
            handler() {
                this.$set(
                    this.lineItem.targetings,
                    'devices',
                    this.deviceTargetingTransformer.transform(this.selectableTree.getSelectedNodeTrees()),
                );
            },
            deep: true,
        },
    },
    async created() {
        this.initTree();
        await this.devicesByManufacturer();
    },
    methods: {
        createSelectedDeviceCache() {
            if (!this.value?.targetings?.devices?.length) {
                return [];
            }
            return [...this.value.targetings.devices];
        },
        async devicesByManufacturer() {
            this.isLoading = true;

            const devicesByManufacturers = await this.deviceService.getAllByManufacturer();

            this.devices = devicesByManufacturers.map((devicesByManufacturer) => {
                const children = devicesByManufacturer.devices.length
                    ? devicesByManufacturer.devices.map(
                          (device) =>
                              new Node({
                                  id: device.id,
                                  name: device.name,
                              }),
                      )
                    : undefined;
                return new Node({
                    id: devicesByManufacturer.deviceManufacturer.id,
                    name: devicesByManufacturer.deviceManufacturer.id,
                    children,
                });
            });

            this.isLoading = false;
        },
        getInitialMode() {
            if (this.value.targetings?.devices?.length) {
                if (this.value.targetings?.devices[0].isExcluded) {
                    return SELECTION_MODE.EXCLUDE;
                }
            }
            return SELECTION_MODE.INCLUDE;
        },
        initTree() {
            const selectedTreeNodesTransformer = new SelectedTreeNodesTransformer();
            return new SelectedTree(selectedTreeNodesTransformer.transform(this.value.targetings?.devices));
        },
    },
};
</script>

import { TargetingValue } from '@/modules/line-items/services/TargetingCount';
import { ReactiveTargetingValue } from '@/modules/line-items/services/ReactiveTargetingCount';

export default class ReactiveTargetingValueTransformer {
    transform(targetingValue: TargetingValue): ReactiveTargetingValue {
        return {
            ...targetingValue,
            updateCount: targetingValue.count,
            wasUpdated: false,
        };
    }
}

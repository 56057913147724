<template>
    <div class="strategy-field d-flex justify-center">
        <CurrencyInput
            v-if="isFieldOfTypeCurrency"
            ref="field"
            :value="internalValue"
            :label="field.name"
            :rules="rules"
            :required="field.required"
            :message="infoMessage"
            :currency="currency"
            :precision="numberPrecision"
            :data-testid="field.name"
            @update:error="setHasError"
            @input="emitInput"
        />
        <NumberInput
            v-if="isFieldOfTypePercentage"
            ref="field"
            :value="internalValue"
            :label="field.name"
            :rules="rules"
            :required="field.required"
            unit="%"
            :precision="numberPrecision"
            :data-testid="field.name"
            @update:error="setHasError"
            @input="emitInput"
        />
        <SrInfoBox class="mt-4 ml-1">
            {{ field.name }}
        </SrInfoBox>
    </div>
</template>

<script>
import { SrInfoBox } from '@ads/design-system';
import { FORM_FIELD_TYPES } from 'api-contracts';
import StrategyFieldRuleBuilder from '@/modules/line-items/components/LineItemForm/LineItemFormStepStrategy/LineItemStrategyDetailsForm/StrategyForm/StrategyFieldRuleBuilder';
import CurrencyInput from '@/components/CurrencyInput';
import NumberInput from '@/components/NumberInput/NumberInput';

export default {
    name: 'StrategyFormField',
    components: { NumberInput, CurrencyInput, SrInfoBox },
    props: {
        strategyConfig: {
            type: Object,
            default: null,
        },
        field: {
            type: Object,
            required: true,
        },
        currency: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            internalValue: this.getValueFromStrategyConfig(this.strategyConfig),
            hasError: false,
        };
    },
    computed: {
        advertiserBudget() {
            return this.strategyConfig?.advertiserBudget;
        },
        numberPrecision() {
            return this.field.commaOffset ?? 2;
        },
        isFieldOfTypeCurrency() {
            return this.field.type === FORM_FIELD_TYPES.CURRENCY;
        },
        isFieldOfTypePercentage() {
            return this.field.type === FORM_FIELD_TYPES.PERCENTAGE;
        },
        isValueHigherThanAdvertiserBudget() {
            const hasAdvertiserBudget = this.advertiserBudget != null && this.advertiserBudget !== '';
            return hasAdvertiserBudget && this.internalValue > this.advertiserBudget;
        },
        infoMessage() {
            if (this.isValueHigherThanAdvertiserBudget && !this.hasError) {
                return `Be aware that your ${this.field.name} is higher than the Advertiser Budget!`;
            }
            return null;
        },
        rules() {
            if (!this.strategyConfig) {
                return undefined;
            }
            const ruleBuilder = new StrategyFieldRuleBuilder(this.field, this.strategyConfig);
            return ruleBuilder.build();
        },
    },
    watch: {
        strategyConfig: {
            handler(newStrategyConfig) {
                this.internalValue = this.getValueFromStrategyConfig(newStrategyConfig);
            },
            deep: true,
        },
    },
    methods: {
        emitInput(newValue) {
            this.$emit('input', newValue);
        },
        setHasError(hasError) {
            this.hasError = hasError;
        },
        getValueFromStrategyConfig(config) {
            if (!config) {
                return null;
            }
            return config[this.field.key];
        },
    },
};
</script>

<style>
.strategy-field input[type='number']::-webkit-outer-spin-button,
.strategy-field input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.strategy-field input[type='number'] {
    -moz-appearance: textfield;
}
</style>

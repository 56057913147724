import { Deal } from '@/modules/deals/store/state';
import { Country } from '@/modules/geo/types';

type IdObject = { id: number };
type CountyId = number;

export default function filterDeals(input: Deal[], term: string, allCountries: Country[] = []): Deal[] {
    const countryLookup = createCountryLookup(allCountries);
    return input.filter((deal) => isDealMatchingTerm(deal, term, countryLookup));
}

function isDealMatchingTerm(deal: Deal, term: string, countryLookup: Map<number, Country>): boolean {
    const isMatchingId = includesTerm(deal.id, term);
    if (isMatchingId) {
        return true;
    }

    const isMatchingDspId = includesTerm(deal.dspId, term);
    if (isMatchingDspId) {
        return true;
    }

    const isMatchingName = includesTerm(deal.name, term);
    if (isMatchingName) {
        return true;
    }

    const isMatchingExternalId = includesTerm(deal.externalId, term);
    if (isMatchingExternalId) {
        return true;
    }

    return isMatchingCountries(deal.countries, countryLookup, term);
}

function isMatchingCountries(dealCountries: IdObject[], countryLookup: Map<CountyId, Country>, term: string): boolean {
    const dealCountryIds = dealCountries?.map((country) => country.id) || [];
    const existingDealCountries = dealCountryIds
        .map((id) => countryLookup.get(id))
        .filter((countryOrUndefined) => !!countryOrUndefined);
    const isCountryNamesMatchingTerm = existingDealCountries.some((country) => includesTerm(country.name, term));
    return isCountryNamesMatchingTerm;
}

function includesTerm(value: string | number, term: string): boolean {
    return String(value).toLowerCase().includes(term.toLowerCase().trim());
}

function createCountryLookup(allCountries: Country[]): Map<CountyId, Country> {
    const countryLookup = new Map<CountyId, Country>();
    allCountries.forEach((country) => countryLookup.set(Number(country.id), country));
    return countryLookup;
}

import { isDateBefore, isDateEqualOrLaterThanStartOfToday, isDateInRange } from '@/shared/validation/date-time';
import { ValidationResult } from '@/shared/validation/types';
import { isRequired } from '@/shared/validation';
import { LineItemRuntime } from '@/modules/line-items/types';

const isInCampaignDateRange = (
    date: Date | string,
    startDate: Date | string,
    endDate: Date | string,
    message = 'Date and time should be in range of campaign runtime',
): ValidationResult => isDateInRange(date, startDate, endDate, message);

const getLineItemStartDateValidationRules = (
    lineItemRuntime: LineItemRuntime,
    campaignStartDate: string,
    campaignEndDate: string,
    allowDateBeforeStartOfToday: boolean,
): ValidationResult[] => {
    const { startDate, endDate, timeZone } = lineItemRuntime;
    return [
        isRequired(startDate, 'Start date'),
        isDateBefore(startDate, endDate, 'Start date needs to be before end date'),
        isDateEqualOrLaterThanStartOfToday(startDate, timeZone, allowDateBeforeStartOfToday),
        isInCampaignDateRange(startDate, campaignStartDate, campaignEndDate),
    ];
};

const getLineItemEndDateValidationRules = (
    lineItemRuntime: LineItemRuntime,
    campaignStartDate: string,
    campaignEndDate: string,
    allowDateBeforeStartOfToday: boolean,
): ValidationResult[] => {
    const { startDate, endDate, timeZone } = lineItemRuntime;
    return [
        isRequired(endDate, 'End date'),
        isDateBefore(startDate, endDate, 'End date needs to be after start date'),
        isDateEqualOrLaterThanStartOfToday(endDate, timeZone, allowDateBeforeStartOfToday),
        isInCampaignDateRange(endDate, campaignStartDate, campaignEndDate),
    ];
};

export { getLineItemStartDateValidationRules, getLineItemEndDateValidationRules };

<template>
    <VForm v-model="formIsValid">
        <VRow>
            <VCol sm="6" lg="4">
                <SrInput v-model.number="campaign.dspId" label="DSP ID" disabled />
            </VCol>
            <VCol sm="6" lg="4">
                <SrInput
                    v-model.number="campaign.persistentId"
                    label="Persistent ID"
                    :disabled="isCreateForm"
                    data-testid="CampaignFormStepBasic__persistent_id"
                />
            </VCol>
        </VRow>
        <VRow>
            <VCol sm="6" lg="4">
                <SrInput
                    v-model="campaign.name"
                    :rules="rules.name"
                    label="Campaign Name"
                    class="campaign-name"
                    required
                    data-testid="CampaignFormStepBasic__name"
                />
            </VCol>
            <VCol sm="6" lg="4">
                <SrSelect
                    v-model="campaign.status"
                    label="Status"
                    :items="campaignStatus"
                    item-value="key"
                    item-text="name"
                    required
                />
            </VCol>
        </VRow>
        <VRow>
            <VCol sm="6" lg="4">
                <SrSelect
                    v-model="campaign.businessEntity"
                    label="Advertiser"
                    :items="businessEntities"
                    item-value="id"
                    item-text="name"
                    return-object
                    required
                    searchable
                    sort-order="ASC"
                    :disabled="isUpdateForm"
                    data-testid="CampaignFormStepBasic__advertiser"
                />
            </VCol>
        </VRow>
        <VRow v-if="isUserAdmin">
            <VCol sm="6" lg="4">
                <SrHeadline level="2">Internal Information</SrHeadline>
                <KpiConfigurator v-model="campaign.kpi" :currency="currencyCode" />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrInput, SrSelect, SrHeadline } from '@ads/design-system';
import CampaignModel from '@/modules/campaign/components/CampaignForm/CampaignModel';
import { getLengthValidationRules } from '@/shared/validation';
import CAMPAIGN_STATUS from 'api-contracts/campaigns/Status';
import KpiConfigurator from './KpiConfigurator';

export default {
    name: 'CampaignFormStepBasic',
    components: {
        SrInput,
        SrSelect,
        SrHeadline,
        KpiConfigurator,
    },
    mixins: [CampaignModel],
    props: {
        isValid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formIsValid: this.isValid,
            rules: {
                name: getLengthValidationRules('Campaign name', 3, 128),
            },
        };
    },
    computed: {
        isCreateForm() {
            return isNaN(this.campaign.id);
        },
        campaignStatus() {
            return Object.values(CAMPAIGN_STATUS);
        },
        businessEntities() {
            return this.$store.getters['iam/businessEntities'];
        },
        isUpdateForm() {
            return !isNaN(this.campaign.id);
        },
        isUserAdmin() {
            return Boolean(this.$root?.user?.isAdmin());
        },
        currencyCode() {
            return this.campaign?.businessEntity?.currencyCode;
        },
    },
    watch: {
        'campaign.businessEntity': function ({ currencyCode }) {
            this.campaign.budget.price.currencyCode = currencyCode;
        },
        formIsValid(isValid) {
            this.$emit('update:isValid', isValid);
        },
        isValid(isValid) {
            this.formIsValid = isValid;
        },
    },
};
</script>

<template>
    <div>
        <SrHeadline level="3" class="mb-2" weight="bold"> Select Traffic <span class="required-text">*</span> </SrHeadline>
        <ErrorBox :message="'At least websites or applications need to be enabled.'" :show="!isTargetingValid" />
        <div class="d-flex justify-space-between container-width">
            <div v-for="data in appWebTargetings" :key="data.title">
                <SrCheckbox
                    v-model="lineItem.targetings.appOrWebTargeting[data.key]"
                    :label="data.label"
                    :disabled="data.infoMessage && hasLocationTargeting"
                />
                <p v-if="data.infoMessage && hasLocationTargeting" class="info-message">
                    {{ data.infoMessage }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { SrCheckbox, SrHeadline } from '@ads/design-system';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import ErrorBox from '@/components/ErrorBox/ErrorBox';
import { appWebTargetings } from './config';

export default {
    name: 'AppWebTargeting',
    components: { ErrorBox, SrCheckbox, SrHeadline },
    mixins: [TargetingCountUpdate],
    props: {
        value: {
            type: Object,
            required: true,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            lineItem: this.value,
            appWebTargetings,
            targeting: {
                isApp: true,
                isWeb: true,
            },
        };
    },
    computed: {
        isTargetingValid() {
            return this.lineItem.targetings?.appOrWebTargeting?.isApp || this.lineItem.targetings?.appOrWebTargeting?.isWeb;
        },
        hasLocationTargeting() {
            return this.lineItem.targetings.locationTargetings?.length > 0;
        },
        currentCount() {
            return (
                Number(this.lineItem.targetings?.appOrWebTargeting?.isApp) +
                Number(this.lineItem.targetings?.appOrWebTargeting?.isWeb)
            );
        },
    },
    watch: {
        'lineItem.targetings.appOrWebTargeting': {
            deep: true,
            handler() {
                this.emitValidState();
            },
        },
    },
    created() {
        if (!this.lineItem.targetings?.appOrWebTargeting) {
            this.initAppOrWebTargeting();
        }
        this.emitValidState();
    },
    methods: {
        emitValidState() {
            this.$emit('update:isValid', this.isTargetingValid);
        },
        initAppOrWebTargeting() {
            this.lineItem.targetings.appOrWebTargeting = {
                isApp: true,
                isWeb: true,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@ads/design-system/src/scss/variables';

.required-text {
    color: $brand-red;
}

.container-width {
    width: 70%;
}

.info-message {
    color: $blue;
}
</style>

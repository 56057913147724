<template>
    <div>
        <VProgressLinear v-if="isLocationLoading" indeterminate />
        <TargetableSelectableTree
            v-if="!hasWebsitesSelected"
            :line-item="lineItem"
            :items="locations"
            :routes="routeConfig"
            edit-route-param="locationId"
            targeting-property="location"
            targeting="locationTargetings"
        />
        <p v-if="hasWebsitesSelected" class="blue--text">
            You can not select location targeting if you have enabled Websites under "App or Web Targeting"
        </p>
    </div>
</template>

<script>
import { Node } from '@/components/SelectableTree/Node';
import TargetableSelectableTree from '@/components/TargetableTree/TargetableSelectableTree';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import { routeConfig } from './config';
import { locationService } from './services/LocationService';

export default {
    name: 'Location',
    components: { TargetableSelectableTree },
    mixins: [LineItemModel, TargetingCountUpdate],
    data() {
        return {
            locationService,
            locations: [],
            routeConfig,
            isLoading: false,
        };
    },
    computed: {
        hasWebsitesSelected() {
            return this.lineItem.targetings?.appOrWebTargeting.isWeb;
        },
        isLocationLoading() {
            return this.isLoading && !this.hasWebsitesSelected;
        },
    },
    watch: {
        async $route(currentRoute, previousRoute) {
            if (previousRoute.name.includes('location')) {
                await this.getLocations();
            }
        },
    },
    async created() {
        await this.getLocations();
    },
    methods: {
        async getLocations() {
            try {
                this.isLoading = true;
                const locations = await this.locationService.getByCampaignId(this.lineItem.campaign.id);
                this.locations = locations.map((location) => new Node(location));
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

import { SSPS } from '@/modules/ssp/types';
import ISspResponse from 'api-contracts/ssps/ISspResponse';
import INetworkResponse from 'api-contracts/ssps/INetworkResponse';

type SspStoreState = {
    ssps: ISspResponse[];
    networksBySspId: Record<SSPS, INetworkResponse[]>;
};

export default <SspStoreState>{
    ssps: [],
    networksBySspId: {
        [SSPS.BIDSWITCH]: [],
        [SSPS.FEEDAD]: [],
        [SSPS.TRIPLELIFT]: [],
        [SSPS.ADSWIZZ]: [],
        [SSPS.ADMIX]: [],
    },
};

<template>
    <div>
        <SrHeadline level="3" weight="bold"> Available Device Types </SrHeadline>
        <SelectableTree :source-nodes="deviceTypes" :mode.sync="mode" :value="selectedDeviceTypes" :is-loading="isLoading" />
    </div>
</template>

<script>
import { SrHeadline } from '@ads/design-system';
import SelectableTree from '@/components/SelectableTree/SelectableTree';
import { Node } from '@/components/SelectableTree/Node';
import { SelectedTree, SelectedNodeTree, NODE_SELECTION_STATUS } from '@/components/SelectableTree/SelectedTree';
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import { DEVICE_LOADING_STATES } from './store/state';

export default {
    name: 'DeviceType',
    components: { SelectableTree, SrHeadline },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            lineItem: this.value,
            selectedDeviceTypes: this.lineItemDeviceTypesTargetings(),
            mode: this.getMode(),
        };
    },
    computed: {
        deviceTypes() {
            return this.$store.getters['lineItems/targetings/device/deviceTypes'].map((deviceType) => new Node(deviceType));
        },
        isLoading() {
            return this.$store.getters['lineItems/targetings/device/isLoading'](DEVICE_LOADING_STATES.DEVICE_TYPE);
        },
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                this.lineItem = value;
            },
        },
        lineItem: {
            deep: true,
            handler(value) {
                this.$emit('input', value);
            },
        },
        selectedDeviceTypes: {
            handler() {
                this.$set(
                    this.lineItem.targetings,
                    'deviceTypeTargetings',
                    this.transformDeviceTypes(this.selectedDeviceTypes.getSelectedNodeTrees()),
                );
            },
            deep: true,
        },
    },
    async created() {
        await this.loadDeviceTypes();
    },
    methods: {
        async loadDeviceTypes() {
            await this.$store.dispatch('lineItems/targetings/device/fetchDeviceTypes');
        },
        transformDeviceTypes(deviceTypes) {
            return deviceTypes.map(({ id, name }) => ({
                externalId: id,
                name,
                isExcluded: this.mode === SELECTION_MODE.EXCLUDE,
            }));
        },
        isEditForm() {
            return !!this.$route.params.id;
        },
        getMode() {
            if (!this.isEditForm()) {
                return SELECTION_MODE.INCLUDE;
            }
            return this.value.targetings.deviceTypeTargetings[0]?.isExcluded ? SELECTION_MODE.EXCLUDE : SELECTION_MODE.INCLUDE;
        },
        lineItemDeviceTypesTargetings() {
            return new SelectedTree(
                this.value.targetings.deviceTypeTargetings?.map(
                    ({ externalId, name, isExcluded }) =>
                        new SelectedNodeTree(
                            externalId,
                            name,
                            isExcluded ? NODE_SELECTION_STATUS.EXCLUDED : NODE_SELECTION_STATUS.INCLUDED,
                        ),
                ),
            );
        },
    },
};
</script>

import { ContextualState } from '@/modules/contextual/store/ContextualState';
import IContextualCategoryResponse from 'api-contracts/contextual/IContextualCategoryResponse';
import { ContextualCategoryFactory } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/ContextualTargeting/ContextualCategoryFactory';

export type ContextualCategory = Omit<IContextualCategoryResponse, 'expiresAt'> & { expiresAt: Date | null };

const getters = {
    isLoadingContextualCategories: (state: ContextualState): boolean => state.isLoadingContextualCategories,
    contextualCategoriesFetchErrorMessage: (state: ContextualState): string | null => state.contextualCategoriesFetchErrorMessage,
    contextualCategoryById:
        (state: ContextualState) =>
        (id: number): ContextualCategory | null => {
            const selectedCategory = state.contextualCategories.find((category) => category.id === id);
            if (selectedCategory) {
                return ContextualCategoryFactory.createFromResponse(selectedCategory);
            }
            return null;
        },
};

export default getters;

import { API_URL } from '@/config';
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { plainToClass } from 'class-transformer';
import { Pixel } from '@/modules/pixels/types';
import PIXEL_SOURCE from 'api-contracts/pixels/PixelSource';

export default class PixelService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'pixel-manager',
        resource: 'pixel',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async getForHolding(holdingId: number, sources?: PIXEL_SOURCE[]) {
        if (sources && sources.length === 0) {
            return [];
        }

        const options = this.getRequestOptions({ action: ACTION_NAME.READ });
        const url = this.composeGetForHoldingUrl(holdingId, sources);

        const response = await this.requestService.get(url, options);

        return plainToClass(Pixel, response);
    }

    async getForCampaign(campaignId: number, sources: PIXEL_SOURCE[]) {
        if (sources.length === 0) {
            return [];
        }

        const options = this.getRequestOptions({ action: ACTION_NAME.READ });
        const url = `/campaigns/${campaignId}/pixels?sources=${JSON.stringify(sources)}`;

        const response = await this.requestService.get(url, options);

        return plainToClass(Pixel, response);
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    private composeGetForHoldingUrl(holdingId: number, sources?: PIXEL_SOURCE[]) {
        let url = `/pixels?holdingId=${holdingId}`;
        if (sources) {
            url += `&sources=${JSON.stringify(sources)}`;
        }
        return url;
    }
}

export const pixelService = new PixelService(new RequestService({ baseUrl: API_URL }));

import { USER_TIMEZONE } from '@/config';
import IRuntimeRequest from 'api-contracts/value-objects/IRuntimeRequest';

export default function getEmptyCampaignRuntime(): IRuntimeRequest {
    return {
        startDate: null,
        endDate: null,
        timeZone: USER_TIMEZONE,
    };
}

<template>
    <div>
        <SrNotification
            v-if="notification !== null"
            :title="notification.title"
            :type="notification.type"
            :description="notification.description"
            :dismissible="true"
            data-testid="CampaignList__notification"
            @close="notification = null"
        />
        <VRow no-gutters>
            <ListHeader headline-name="Campaigns" button-name="Create Campaign" route="campaign-create">
                <template #filter>
                    <SrAdvancedFilter
                        v-if="hasSelectableBusinessEntities"
                        v-model="advancedFilterPredicate"
                        :config="advancedFilterConfig"
                        class="mt-6"
                    />
                </template>
                <template #quicksearch>
                    <SrSearchInput v-model="quickSearchValue" class="mt-3" />
                </template>
            </ListHeader>

            <Divider />

            <Table :headers="headers" :items="campaigns" :is-loading="isLoading">
                <template #budget="{ item }">
                    <CampaignListBudget
                        :budget="getBudgetFor(item)"
                        :is-in-edit-mode="isQuickEditOpenFor(item)"
                        :line-items-budget-overview="item.lineItemsBudgetOverview"
                        :disabled="quickEditData.isSubmitting"
                        @budgetChange="onBudgetChange"
                        @update:error="onBudgetChangeError"
                        @keyup.native.enter="submitQuickEditCampaign(item)"
                    />
                </template>

                <template #allocatedBudget="item">
                    <div>{{ getAllocatedBudget(item) }}</div>
                </template>

                <template #name="item">
                    <router-link :to="getUpdateRoute(item)" class="font-weight-bold" data-testid="CampaignList__edit_link">
                        {{ item.name }}
                    </router-link>
                </template>

                <template #[`runtime.daysRemaining`]="item">
                    <EditableEndDateRuntime
                        :runtime="item.runtime"
                        :is-in-edit-mode="isQuickEditOpenFor(item)"
                        :disabled="quickEditData.isSubmitting"
                        @endDateChange="onEndDateChange"
                        @update:error="onEndDateChangeError"
                        @keyup.native.enter="submitQuickEditCampaign(item)"
                    />
                </template>

                <template #actions="item">
                    <div v-if="isQuickEditOpenFor(item)" class="d-flex align-center justify-end">
                        <SrButton
                            class="confirm-button"
                            icon
                            :loading="quickEditData.isSubmitting"
                            :disabled="quickEditHasError"
                            data-testid="CampaignList__submit_quick_edit_button"
                            @click="submitQuickEditCampaign(item)"
                        >
                            <SrIcon icon="TickBrandBlue" :disabled="quickEditHasError" size="xs" />
                        </SrButton>
                        <SrButton class="close-button" icon :disabled="quickEditData.isSubmitting" @click="exitQuickEdit()">
                            <SrIcon icon="CloseBrandRed" :disabled="quickEditData.isSubmitting" size="xs" />
                        </SrButton>
                    </div>
                    <div v-else class="d-flex align-center justify-end">
                        <SrButton
                            icon
                            :disabled="isQuickEditOpen"
                            data-testid="CampaignList__open_quick_edit_button"
                            @click="openQuickEditFor(item)"
                        >
                            <SrIcon icon="edit" color="red" size="xs" :disabled="isQuickEditOpen" />
                        </SrButton>
                        <TableItemActions
                            :action-routes="getActionRoutes(item)"
                            :disabled="isQuickEditOpen"
                            :item="item"
                            data-testid="CampaignList__actions"
                        />
                    </div>
                </template>
            </Table>
        </VRow>
        <router-view />
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import TableItemActions from '@/components/Table/TableItemActions';
import Table from '@/components/Table/Table';
import ListHeader from '@/components/ListHeader/ListHeader';
import { TableActionTypes } from '@/components/types';
import { OPERATORS } from '@ads/design-system/src/components/SrAdvancedFilter/types';
import { SrAdvancedFilter, SrIcon, SrButton, SrNotification, SrSearchInput } from '@ads/design-system';
import Divider from '@/components/Divider/Divider';
import { formatNumberToCurrency } from '@/shared/utils';
import EditableEndDateRuntime from '@/components/EditableEndDateRuntime';
import { getFilterConfigPredicates } from '@/modules/campaign/views/list/campaignFilterUtils';
import CampaignListBudget from '@/modules/campaign/components/CampaignListBudget';
import { campaignService } from '../../services/CampaignService';
import {
    listActionRoutes,
    listHeaders,
    partialEditErrorMessage,
    partialEditSuccessMessage,
    quickEditDefaultData,
} from './config';

export default {
    components: {
        CampaignListBudget,
        SrSearchInput,
        Divider,
        ListHeader,
        TableItemActions,
        Table,
        SrAdvancedFilter,
        EditableEndDateRuntime,
        SrNotification,
        SrIcon,
        SrButton,
    },
    model: {
        prop: 'filterParams',
        event: 'input',
    },
    data() {
        return {
            isLoading: false,
            headers: listHeaders,
            routes: listActionRoutes,
            campaignService,
            quickSearchValue: null,
            advancedFilterPredicate: null,
            notification: null,
            quickEditData: {
                ...quickEditDefaultData,
            },
        };
    },
    computed: {
        selectableBusinessEntities() {
            return this.$store.getters['iam/selectableBusinessEntities'];
        },
        hasSelectableBusinessEntities() {
            return this.selectableBusinessEntities.length > 0;
        },
        campaigns() {
            if (this.quickSearchValue !== null) {
                return this.$store.getters['campaign/campaignsByIdOrName'](this.quickSearchValue);
            }
            return this.$store.getters['campaign/campaigns'];
        },
        advancedFilterConfig() {
            return {
                persistent: true,
                properties: getFilterConfigPredicates(),
                operators: OPERATORS,
            };
        },
        isQuickEditOpen() {
            return this.quickEditData.campaign !== null;
        },
        quickEditHasError() {
            return this.quickEditData.hasBudgetError || this.quickEditData.hasEndDateError;
        },
    },
    watch: {
        advancedFilterPredicate: {
            deep: true,
            async handler() {
                await this.fetchCampaigns();
            },
        },
        async $route(currentRoute, previousRoute) {
            const isRouteChange = currentRoute.name !== previousRoute.name;
            const isCurrentRouteCampaignList = currentRoute.name === 'campaign-list';
            if (isRouteChange && isCurrentRouteCampaignList) {
                this.resetQuickEditData();
                await this.fetchCampaigns();
            }
        },
    },
    created() {
        this.fetchCampaigns();
    },
    methods: {
        ...mapMutations({
            updateCampaignsBudgetAndRuntime: 'campaign/updateCampaignsBudgetAndRuntime',
        }),
        async submitQuickEditCampaign(campaign) {
            if (this.quickEditHasError) {
                return;
            }
            this.$set(this.quickEditData, 'isSubmitting', true);
            try {
                const updatedCampaign = await this.campaignService.partialEdit(campaign.id, {
                    budgetValue: this.quickEditData.newBudgetValue,
                    runtime: {
                        ...campaign.runtime,
                        endDate: this.quickEditData.newEndDateValue,
                    },
                });
                this.updateDisplayedCampaign(updatedCampaign);
                this.updateNotification(partialEditSuccessMessage());
            } catch (error) {
                this.updateNotification(partialEditErrorMessage(error.response.message || error.response));
            } finally {
                this.$set(this.quickEditData, 'isSubmitting', false);
                this.exitQuickEdit();
            }
        },
        async fetchCampaigns() {
            this.isLoading = true;
            try {
                await this.$store.dispatch('campaign/fetchCampaigns', this.advancedFilterPredicate);
            } catch (error) {
                this.error = error.response.message;
            } finally {
                this.isLoading = false;
            }
        },
        getUpdateRoute({ id }) {
            return {
                name: this.routes[TableActionTypes.EDIT].routeName,
                params: { id },
            };
        },
        getAllocatedBudget(campaign) {
            return campaign.lineItemsBudgetOverview
                ? formatNumberToCurrency(
                      campaign.lineItemsBudgetOverview.allocatedOnLineItems,
                      campaign.budget.price.currencyCode,
                  )
                : null;
        },
        getActionRoutes(campaign) {
            return {
                ...this.routes,
                [TableActionTypes.SHOW_LINE_ITEMS]: {
                    ...this.routes[TableActionTypes.SHOW_LINE_ITEMS],
                    query: { campaignId: campaign.id },
                },
            };
        },
        updateNotification(notification) {
            this.notification = notification;
        },
        update(campaign, index) {
            this.campaigns[index] = campaign;
        },
        openQuickEditFor(campaign) {
            this.quickEditData = {
                ...this.quickEditData,
                campaign,
                newBudgetValue: campaign.budget.price.value,
                newEndDateValue: campaign.runtime.endDate,
            };
        },
        resetQuickEditData() {
            this.quickEditData = {
                ...quickEditDefaultData,
            };
        },
        exitQuickEdit() {
            this.resetQuickEditData();
        },
        isQuickEditOpenFor(campaign) {
            return this.quickEditData.campaign?.id === campaign.id;
        },
        getBudgetFor(campaign) {
            if (this.isQuickEditOpenFor(campaign)) {
                return {
                    ...campaign.budget,
                    price: {
                        ...campaign.budget.price,
                        value: this.quickEditData.newBudgetValue,
                    },
                };
            }
            return campaign.budget;
        },
        onBudgetChange(newBudget) {
            this.quickEditData.newBudgetValue = newBudget;
        },
        onBudgetChangeError(hasError) {
            this.$set(this.quickEditData, 'hasBudgetError', hasError);
        },
        onEndDateChange(newEndDate) {
            this.quickEditData.newEndDateValue = newEndDate;
        },
        onEndDateChangeError(hasError) {
            this.$set(this.quickEditData, 'hasEndDateError', hasError);
        },
        updateDisplayedCampaign(campaign) {
            this.updateCampaignsBudgetAndRuntime({
                campaignId: campaign.id,
                newBudget: campaign.budget.price.value,
                newEndDate: campaign.runtime.endDate,
                newStatus: campaign.runtime.status,
            });
        },
    },
};
</script>

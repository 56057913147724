<template>
    <SrInput
        ref="srInput"
        :value="inputValue"
        type="text"
        :label="label"
        :suffix="unit"
        :rules="validationResults"
        :required="required"
        :disabled="disabled"
        :compact="compact"
        :hint="message"
        persistent-hint
        hide-details="auto"
        class="mc-user-input"
        outlined
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @update:error="onError"
    />
</template>

<script>
import { computed, ref, watch } from 'vue';
import { SrInput } from '@ads/design-system';
import { sleep } from '@/shared/utils';
import NumberTransformer from '@/components/NumberInput/transformation/NumberTransformer';
import StringifiedNumberValidator from '@/components/NumberInput/validation/StringifiedNumberValidator';
import getValidationRuleResult from '@/components/NumberInput/validation/getValidationRuleResult';
import CaretPositionCalculator from '@/components/NumberInput/CaretPositionCalculator';
import getHtmlInputElementFrom from '@/components/NumberInput/getHtmlInputElementFrom';

export default {
    name: 'NumberInput',
    components: { SrInput },
    props: {
        value: {
            type: [Number, String],
            default: null,
        },
        unit: {
            type: String,
            required: false,
        },
        locale: {
            type: String,
            default: () => navigator.languages[0],
        },
        precision: {
            type: Number,
            default: 0,
        },
        rules: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            required: false,
        },
        message: {
            type: String,
            required: false,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const numberValidator = new StringifiedNumberValidator(props.precision);
        const numberTransformer = new NumberTransformer(numberValidator, {
            precision: props.precision,
            locale: props.locale,
        });

        const srInput = ref();
        const inputValue = ref();
        const numberOrInputValue = ref();
        const hasAlreadyEmittedInput = ref(false);

        const initializeValuesWith = (newValue) => {
            const valueAsString = String(newValue ?? '');
            inputValue.value = numberTransformer.stringToFormattedOrSame(valueAsString);
            numberOrInputValue.value = numberTransformer.stringToNumberOrSame(valueAsString);
        };

        initializeValuesWith(props.value);

        watch(
            () => props.value,
            (newValue, oldValue) => {
                const wasInputEmptyBefore = oldValue === null && !hasAlreadyEmittedInput.value;
                if (newValue === null || wasInputEmptyBefore) {
                    initializeValuesWith(newValue);
                }
            },
        );

        const getNumberValidationResults = () => {
            const numberOrInputValueAsString = String(numberOrInputValue.value);
            if (numberOrInputValueAsString === '') {
                return [];
            }
            return numberValidator.validate(numberOrInputValueAsString);
        };

        const validationResults = computed(() => {
            const numberValidationResults = getNumberValidationResults();
            const resultsOfRulesFromProps = props.rules.map((rule) => getValidationRuleResult(rule, numberOrInputValue.value));
            return [...numberValidationResults, ...resultsOfRulesFromProps];
        });

        const emitInput = (value) => {
            emit('input', value);
            hasAlreadyEmittedInput.value = true;
        };

        const onInput = (newValue) => {
            inputValue.value = newValue;
            const numberValueOrSame = numberTransformer.stringToNumberOrSame(newValue);
            numberOrInputValue.value = numberValueOrSame;
            emitInput(numberValueOrSame);
        };

        const getCaretPositionForNumberOrInputValue = async (htmlInputElement) => {
            await sleep(10); // Makes sure to get correct caret position
            const currentPosition = htmlInputElement?.selectionStart ?? inputValue.value.length;
            return CaretPositionCalculator.fromFormattedToNumber(currentPosition, inputValue.value, numberOrInputValue.value);
        };

        const setCaretPosition = async (htmlInputElement, newPosition) => {
            await sleep(10); // Prevents caret from jumping to the end
            htmlInputElement?.setSelectionRange(newPosition, newPosition);
        };

        const onFocus = async () => {
            const numberOrInputValueAsString = String(numberOrInputValue.value);
            const htmlInputElement = getHtmlInputElementFrom(srInput.value.$el);
            const newPosition = await getCaretPositionForNumberOrInputValue(htmlInputElement);
            inputValue.value = numberOrInputValueAsString;
            await setCaretPosition(htmlInputElement, newPosition);
        };

        const onBlur = () => {
            inputValue.value = numberTransformer.stringToFormattedOrSame(inputValue.value);
        };

        const onError = (hasError) => emit('update:error', hasError);

        return {
            srInput,
            inputValue,
            validationResults,
            onInput,
            onFocus,
            onBlur,
            onError,
        };
    },
};
</script>

<template>
    <div>
        <VRadioGroup v-model="buttonValue" row>
            <div v-for="item in items" :key="item" class="d-flex button-text">
                <SrRadioButton :value="item" :label="item" />
            </div>
        </VRadioGroup>
    </div>
</template>

<script>
import { SrRadioButton } from '@ads/design-system';

export default {
    name: 'RadioButtonGroup',
    components: { SrRadioButton },
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            buttonValue: this.value,
        };
    },
    watch: {
        value(value) {
            this.buttonValue = value;
        },
        buttonValue(value) {
            this.$emit('change', value);
        },
    },
};
</script>

<style lang="scss" scoped>
.button-text {
    margin-right: 25px;
}

.v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 0;
}
</style>

export enum TableActionTypes {
    EDIT = 'Edit',
    HISTORY = 'History',
    DELETE = 'Delete',
    ARCHIVE = 'Archive',
    SHOW_LINE_ITEMS = 'Show line items',
    DUPLICATE = 'Duplicate',
}

export enum OperatorTypes {
    ALPHABETIC = 'Alphabetic',
    NUMERIC = 'Numeric',
    BOOLEAN = 'Boolean',
}

export enum SearchValueTypes {
    TEXT = 'Text',
    SELECT = 'Select',
}

<template>
    <div>
        <SportCalendarFilter :filter-items="filterItems" :loading-state="loadingState" @filter-update="onFilterUpdate" />
        <SelectableTree
            v-model="selectedTree"
            :source-nodes="nodes"
            :disable-mode-selection="true"
            :is-loading="loadingState.isLoadingTargetings"
            mode="Include"
        />
    </div>
</template>

<script>
import SelectableTree from '@/components/SelectableTree';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import SportCalendarViewModel from './SportCalendarViewModel';
import SportCalendarFilter from './SportCalendarFilter';
import { sportCalendarService } from './services/SportCalendarService';

export default {
    name: 'SportCalendar',
    components: {
        SportCalendarFilter,
        SelectableTree,
    },
    mixins: [LineItemModel, TargetingCountUpdate],
    data() {
        return {
            sportCalendarViewModel: new SportCalendarViewModel(sportCalendarService, this.updateLoadingState),
            nodes: [],
            loadingState: {
                isLoadingTargetings: false,
                isLoadingEvents: false,
                isLoadingCountries: false,
                isLoadingTournaments: false,
            },
            selectedTree: undefined,
            filterItems: {
                sports: [],
                countries: [],
                tournaments: [],
            },
        };
    },
    computed: {
        isSelectedTreeLoading() {
            return Object.values(this.loadingState).some(Boolean);
        },
    },
    watch: {
        selectedTree: {
            deep: true,
            handler() {
                this.syncLineItemWithSelectedTree();
            },
        },
    },
    async created() {
        const { sportCalendarTargetings, filterItems } = await this.sportCalendarViewModel.loadInitialDataFor(this.lineItem);

        if (sportCalendarTargetings) {
            this.$set(this.lineItem.targetings, 'sportCalendarTargetings', sportCalendarTargetings);
        }
        this.selectedTree = this.sportCalendarViewModel.createSelectedTreeFrom(this.lineItem.targetings.sportCalendarTargetings);
        this.filterItems = filterItems;
        // needed to check for changes
        this.wasDataLoaded = true;
    },
    methods: {
        syncLineItemWithSelectedTree() {
            this.$set(
                this.lineItem.targetings,
                'sportCalendarTargetings',
                this.sportCalendarViewModel.createSportCalendarTargetingsFrom(this.selectedTree),
            );
        },
        async onFilterUpdate(filter) {
            const { nodes, filterItems } = await this.sportCalendarViewModel.handleFilterUpdate(filter);

            this.nodes = nodes;
            this.updateFilterItems(filterItems);
        },
        updateFilterItems(newFilterItems) {
            this.filterItems = {
                ...this.filterItems,
                ...newFilterItems,
            };
        },
        updateLoadingState(newValues) {
            this.loadingState = {
                ...this.loadingState,
                ...newValues,
            };
        },
    },
};
</script>

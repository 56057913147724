<template>
    <div class="pixel">
        <div class="selection-control">
            <VRadio :value="pixel.dspId" color="brand-red" />
        </div>
        <div class="conversion-type" :title="conversionTypeLabel">
            {{ conversionTypeLabel }}
        </div>
        <div class="name" :title="pixelLabel">
            {{ pixelLabel }}
        </div>
        <SrButton
            v-if="isRemovingEnabled"
            class="close-icon ml-2"
            x-small
            icon
            data-testid="ConversionPixelsRow__removeButton"
            @click="emitRemove"
        >
            <SrIcon icon="close" size="xxs" />
        </SrButton>
    </div>
</template>
<script>
import { SrButton, SrIcon } from '@ads/design-system';

export default {
    name: 'ConversionPixelsRow',
    components: {
        SrButton,
        SrIcon,
    },
    props: {
        pixel: {
            type: Object,
            required: true,
        },
        onRemove: {
            type: Function,
            required: false,
            default: null,
        },
        isRemovingEnabled: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        conversionTypeLabel() {
            return `Optimize ${this.pixel.pixelConversionType}`;
        },
        pixelLabel() {
            const hasBusinessEntity = Boolean(this.pixel.businessEntity);
            const conversionType = this.pixel.pixelConversionType;

            if (hasBusinessEntity) {
                const businessEntity = this.pixel.businessEntity.name;
                return `${this.pixel.dspId} ${this.pixel.name} ${businessEntity}_${conversionType}`;
            }

            return `${this.pixel.dspId} ${this.pixel.name}_${conversionType}`;
        },
    },
    methods: {
        emitRemove() {
            this.$emit('remove');
        },
    },
};
</script>

<template>
    <CurrencyInput
        :value="value"
        :currency="currency"
        :precision="0"
        :rules="rules"
        :disabled="disabled"
        :compact="compact"
        :label="label"
        required
        @input="onValueChange"
        @update:error="onErrorUpdate"
    />
</template>

<script>
import CurrencyInput from '@/components/CurrencyInput';
import { computed } from 'vue';
import { getCampaignBudgetValidationRules } from '@/modules/campaign/components/validator';

export default {
    name: 'CampaignBudget',
    components: { CurrencyInput },
    props: {
        value: {
            type: [Number, String],
            default: null,
        },
        currency: {
            type: String,
            required: true,
        },
        spentBudget: {
            type: Number,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const onValueChange = (value) => emit('input', value);
        const onErrorUpdate = (value) => emit('update:error', value);

        const rules = computed(() => getCampaignBudgetValidationRules(props.spentBudget));
        const label = computed(() => (props.compact ? '' : 'Define Budget'));

        return {
            rules,
            label,
            onValueChange,
            onErrorUpdate,
        };
    },
};
</script>

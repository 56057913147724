import Hour from 'api-contracts/line-items/targetings/time/Hour';
import WeekDay from 'api-contracts/line-items/targetings/time/WeekDay';
import IHoursByDay from 'api-contracts/line-items/targetings/time/IHoursByDay';

export type TimeTargetingRange = {
    from: Hour;
    to: Hour;
    days: WeekDay[];
};

class TimeTargetingPickerController {
    static getEmptyTargeting(): IHoursByDay {
        return {
            [WeekDay.monday]: [],
            [WeekDay.tuesday]: [],
            [WeekDay.wednesday]: [],
            [WeekDay.thursday]: [],
            [WeekDay.friday]: [],
            [WeekDay.saturday]: [],
            [WeekDay.sunday]: [],
        };
    }

    static selectWeekend(targeting: IHoursByDay): IHoursByDay {
        return TimeTargetingPickerController.selectRange(
            {
                days: [WeekDay.saturday, WeekDay.sunday],
                from: 0,
                to: 23,
            },
            targeting,
        );
    }

    static selectWorkingHours(targeting: IHoursByDay): IHoursByDay {
        return TimeTargetingPickerController.selectRange(
            {
                days: [WeekDay.monday, WeekDay.tuesday, WeekDay.wednesday, WeekDay.thursday, WeekDay.friday],
                from: 9,
                to: 17,
            },
            targeting,
        );
    }

    static selectRange({ days, from, to }: TimeTargetingRange, targeting: IHoursByDay): IHoursByDay {
        if (!TimeTargetingPickerController.isValidRange({ days, from, to })) {
            return {
                ...targeting,
            };
        }
        const updatedTargeting = {
            ...targeting,
        };
        const selectedHours = Array.from(Array(to - from + 1).keys()).map((hour) => hour + from) as Hour[];

        days.forEach((day) => {
            updatedTargeting[day] = Array.from(new Set([...targeting[day], ...selectedHours]));
        });

        return updatedTargeting;
    }

    static fromBooleanMatrix(matrix: boolean[][]): IHoursByDay {
        const targeting = {} as IHoursByDay;
        matrix.forEach((day, dayIndex) => {
            targeting[dayIndex] = [];
            day.forEach((isHourSelected, hourIndex) => {
                if (isHourSelected) {
                    targeting[dayIndex].push(hourIndex);
                }
            });
        });
        return targeting;
    }

    static toBooleanMatrix(targeting: IHoursByDay): boolean[][] {
        return Object.values(targeting).map((day: Hour[]) =>
            Array.from(Array(24).keys()).map((hourIndex: Hour) => day.includes(hourIndex)),
        );
    }

    static isValidRange({ days, from, to }: TimeTargetingRange): boolean {
        return days && days.length > 0 && from < to;
    }
}

export default TimeTargetingPickerController;

<template>
    <SrInput
        :value="expression.toUpperCase()"
        :label="label"
        :rules="combinedRules"
        v-bind="$attrs"
        @input="expression = $event ? $event.toUpperCase() : ''"
    />
</template>

<script>
import { Predicate, PredicateStringParsingError } from '@ads/predicate-model';
import { SrInput } from '@ads/design-system';

export default {
    name: 'PredicateInput',
    components: { SrInput },
    props: {
        value: {
            type: Predicate,
            default: null,
        },
        rules: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            expression: '',
            errorMessage: null,
            predicate: null,
            lastEmitted: null,
            stringifyConfig: {
                onlyValues: true,
                defaultFilterCriteria: 'dspId',
                defaultPredicateDefinition: 'IS',
            },
        };
    },
    computed: {
        combinedRules() {
            return [() => this.errorMessage || true];
        },
    },
    watch: {
        expression(expression) {
            this.errorMessage = null;

            try {
                const predicate = Predicate.fromString(expression, this.stringifyConfig);
                this.updateValue(predicate);
            } catch (error) {
                if (error instanceof PredicateStringParsingError) {
                    this.errorMessage = error.message;
                } else {
                    this.errorMessage = 'Invalid expression';
                }
            }
        },
        value: {
            handler(predicate) {
                if (!predicate) {
                    this.expression = '';
                    return;
                }

                const expression = predicate.toString(this.stringifyConfig);
                if (expression === this.lastEmitted) {
                    return;
                }
                this.expression = expression;
            },
            immediate: true,
        },
    },
    methods: {
        updateValue(predicate) {
            this.validate(predicate);
            this.$emit('input', predicate);
            this.lastEmitted = predicate.toString(this.stringifyConfig);
        },
        validate(predicate) {
            this.errorMessage = null;
            for (const rule of this.rules) {
                const result = rule(predicate);
                if (typeof result === 'string') {
                    this.errorMessage = result;
                    break;
                }
            }
        },
    },
};
</script>

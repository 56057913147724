type Filterable = {
    id: number;
    dspId?: number;
    name: string;
};

export default function filterByIdOrName<T extends Filterable>(input: T[], term: string): T[] {
    return input.filter(({ id, dspId, name }) => {
        const normalizedTerm = term.toLowerCase().trim();
        const isMatchingId = id.toString().includes(normalizedTerm);
        const isMatchingDspId = dspId?.toString().includes(normalizedTerm);
        const isMatchingName = name.toLowerCase().includes(normalizedTerm);

        return isMatchingId || isMatchingDspId || isMatchingName;
    });
}

<template>
    <DealForm
        v-model="deal"
        :is-loading="isLoading"
        :required-permission="canUpdateDeals"
        submit-action="deal/edit"
        after-submit-route="deals-list"
        after-close-route="deals-list"
    />
</template>

<script>
import DealForm from '@/modules/deals/components/DealForm';
import { dealService } from '@/modules/deals/services/DealService';
import { canUpdateDeals } from '@/permissions';
import createEmptyDeal from '@/modules/deals/views/createEmptyDeal';

export default {
    name: 'DealEdit',
    components: {
        DealForm,
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            canUpdateDeals,
            deal: createEmptyDeal(),
            errorMessage: null,
            isLoading: false,
        };
    },
    created() {
        this.loadDeal();
    },
    methods: {
        async loadDeal() {
            this.isLoading = true;
            try {
                this.deal = await dealService.getById(this.id);
            } catch (error) {
                this.errorMessage = error.response.message;
            }
            this.isLoading = false;
        },
    },
};
</script>

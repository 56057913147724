import { sspService } from '@/modules/ssp/services/SspService';

export default {
    async initialize({ dispatch, state }): Promise<void> {
        if (!state.ssps.length) {
            await dispatch('fetchSsps');
        }

        if (!state.networksBySspId.length) {
            await dispatch('fetchNetworksBySspId');
        }
    },

    async fetchSsps({ commit }): Promise<void> {
        const ssps = await sspService.getSsps();

        commit('setSsps', ssps);
    },

    async fetchNetworksBySspId({ commit, state }): Promise<void> {
        const ssps = state.ssps;
        const promises = ssps.map(({ id }) => sspService.getNetworksOfSsp(id));

        const networksBySsp = await Promise.all(promises);
        networksBySsp.forEach((networks, index) => {
            commit('setNetworksBySspId', { networks, sspId: ssps[index].id });
        });
    },
};

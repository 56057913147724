<template>
    <VRow align="center" :class="classList">
        <VCol class="bar-title">
            {{ title }}
        </VCol>
        <VCol v-if="hasChanges" class="shrink px-1">
            <VChip v-if="initialCount > updatedCount" color="error" class="white--text removed-count" small>
                {{ updatedCount - initialCount }}
            </VChip>
            <VChip v-if="initialCount < updatedCount" color="success" class="white--text added-count" small>
                +{{ updatedCount - initialCount }}
            </VChip>
        </VCol>
        <VCol v-if="initialCount" class="shrink px-1">
            <VChip color="info" class="white--text count" small>
                {{ initialCount }}
            </VChip>
        </VCol>
    </VRow>
</template>

<script>
import { USER_INPUT_CLASS } from '@ads/design-system';

export default {
    name: 'CountableBar',
    props: {
        title: {
            type: String,
            required: true,
        },
        initialCount: {
            type: Number,
            required: true,
        },
        updatedCount: {
            type: Number,
            required: false,
            default: null,
        },
    },
    computed: {
        hasChanges() {
            if (this.updatedCount == null) {
                return false;
            }
            return this.initialCount !== this.updatedCount;
        },
        classList() {
            return [USER_INPUT_CLASS];
        },
    },
};
</script>

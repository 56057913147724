import { LineItem } from '@/modules/line-items/types';

import { NODE_SELECTION_STATUS, SelectedNodeTree, SelectedTree } from '@/components/SelectableTree/SelectedTree';
import { Node } from '@/components/SelectableTree/Node';

import SportCalendarEventTransformer from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/SportCalendar/SportCalendarEventTransformer';
import SportCalendarService from './services/SportCalendarService';
import { TournamentNode } from './TournamentNode';
import SportCalendarCache from './SportCalendarCache';
import {
    CacheableSportCalendarObjects,
    SelectableSportCalendarData,
    SPORT_CALENDAR_TARGETING_TYPE,
    SportCalendarComponentData,
    SportCalendarCountry,
    SportCalendarEvent,
    SportCalendarFilterItems,
    SportCalendarFilterParams,
    SportCalendarLoadingState,
    SportCalendarSport,
    SportCalendarTargeting,
    SportCalendarTournament,
    UpdateLoadingStateFn,
} from './types';

type SportCalendarObjects = {
    sports?: SportCalendarSport[];
    countries?: SportCalendarCountry[];
    tournaments?: SportCalendarTournament[];
    events?: SportCalendarEvent[];
};

class SportCalendarViewModel {
    private readonly sportCalendarService: SportCalendarService;

    private readonly updateLoadingState: UpdateLoadingStateFn;

    private readonly sportCalendarCache: SportCalendarCache;

    constructor(sportCalendarService: SportCalendarService, updateLoadingState: UpdateLoadingStateFn) {
        this.sportCalendarService = sportCalendarService;
        this.updateLoadingState = updateLoadingState;

        this.sportCalendarCache = new SportCalendarCache();
    }

    async loadInitialDataFor(
        lineItem: LineItem,
    ): Promise<{ filterItems: SportCalendarFilterItems; sportCalendarTargetings?: SportCalendarTargeting[] }> {
        const initialPromise = async (): Promise<SportCalendarFilterItems> => {
            const sports = await this.loadSports();
            return this.transformSportCalendarObjectToFilterItems({
                sports,
                countries: [],
                tournaments: [],
            });
        };

        const filterItems = await initialPromise();

        return {
            filterItems,
            sportCalendarTargetings: lineItem.targetings?.sportCalendarTargetings,
        };
    }

    async handleFilterUpdate(filter: SportCalendarFilterParams): Promise<SportCalendarComponentData> {
        const sportCalendarData = await this.loadSportCalendarDataFor(filter);

        const nodes = this.transformSportCalendarObjectsToNodes(sportCalendarData);
        const filterItems = this.transformSportCalendarObjectToFilterItems(sportCalendarData);

        return { nodes, filterItems };
    }

    createSportCalendarTargetingsFrom(selectedTree: SelectedTree): SportCalendarTargeting[] {
        return selectedTree.getSelectedNodeTrees().map(
            (node) =>
                ({
                    dspId: node.getId() as number,
                    name: node.getName(),
                    type: node.getType() as SPORT_CALENDAR_TARGETING_TYPE,
                    tournamentDspId: node.getDetails()?.tournamentDspId,
                    tournamentName: node.getDetails()?.tournamentName,
                } as SportCalendarTargeting),
        );
    }

    createSelectedTreeFrom(sportCalendarTargetings: SportCalendarTargeting[] | null): SelectedTree {
        if (!sportCalendarTargetings) {
            return new SelectedTree();
        }

        const selectedNodes = sportCalendarTargetings.map((sportCalendarTargeting) => {
            let node = new SelectedNodeTree(
                sportCalendarTargeting.dspId,
                sportCalendarTargeting.name,
                NODE_SELECTION_STATUS.INCLUDED,
                sportCalendarTargeting.type,
                [],
                {
                    tournamentDspId: sportCalendarTargeting.tournamentDspId,
                    tournamentName: sportCalendarTargeting.tournamentName,
                },
            );

            if (sportCalendarTargeting.type === SPORT_CALENDAR_TARGETING_TYPE.EVENT) {
                node = new SelectedNodeTree(
                    sportCalendarTargeting.tournamentDspId,
                    sportCalendarTargeting.tournamentName,
                    NODE_SELECTION_STATUS.NONE,
                    null,
                    [node],
                );
            }

            return node;
        });

        return new SelectedTree(selectedNodes);
    }

    private async loadSportCalendarDataFor(filter: SportCalendarFilterParams): Promise<SportCalendarObjects> {
        const { sportId, countryId, tournamentId } = filter;
        if (sportId && countryId && tournamentId) {
            const events = await this.getEvents(sportId, countryId, tournamentId);
            return { events };
        }
        if (sportId && countryId) {
            const tournaments = await this.getTournaments(sportId, countryId);
            return { tournaments };
        }
        if (sportId) {
            const countries = await this.getCountries(sportId);
            const tournaments = [];
            return { countries, tournaments };
        }
        return {};
    }

    private transformSportCalendarObjectsToNodes(objects: SportCalendarObjects): Node[] {
        const { events, tournaments } = objects;
        if (events) {
            return this.createEventNodesFrom(events);
        }
        if (tournaments) {
            return this.createTournamentNodesFrom(tournaments);
        }
        return [];
    }

    private transformSportCalendarObjectToFilterItems(objects: SportCalendarObjects): SportCalendarFilterItems {
        const { sports, countries, tournaments } = objects;
        const filterItems: SportCalendarFilterItems = {};

        if (tournaments) {
            filterItems.tournaments = this.transformToSelectableData(objects.tournaments);
        }
        if (countries) {
            filterItems.countries = this.transformToSelectableData(objects.countries);
        }
        if (sports) {
            filterItems.sports = this.transformToSelectableData(objects.sports);
        }

        return filterItems;
    }

    private createTournamentNodesFrom(tournaments: SportCalendarTournament[]): Node[] {
        return tournaments.map((tournament) => {
            const node: Partial<TournamentNode> = {
                id: tournament.dspId,
                name: tournament.name,
                type: SPORT_CALENDAR_TARGETING_TYPE.TOURNAMENT,
                children: [],
            };
            return new TournamentNode(node, tournament.sport.dspId, tournament.country.dspId);
        });
    }

    private createEventNodesFrom(events: SportCalendarEvent[]): Node[] {
        return SportCalendarEventTransformer.transformToNodes(events);
    }

    private async getCountries(sportId: number): Promise<SportCalendarCountry[]> {
        const countries = await this.getFromCacheOrExecutePromise({ sportId, countryId: null, tournamentId: null }, async () =>
            this.loadCountries(sportId),
        );
        return countries as SportCalendarCountry[];
    }

    private async getEvents(sportId: number, countryId: number, tournamentId: number): Promise<SportCalendarEvent[]> {
        const events = await this.getFromCacheOrExecutePromise({ sportId, countryId, tournamentId }, async () =>
            this.loadEvents(sportId, countryId, tournamentId),
        );
        return events as SportCalendarEvent[];
    }

    private async getTournaments(sportId: number, countryId: number): Promise<SportCalendarTournament[]> {
        const tournaments = await this.getFromCacheOrExecutePromise({ sportId, countryId, tournamentId: null }, async () =>
            this.loadTournaments(sportId, countryId),
        );
        return tournaments as SportCalendarTournament[];
    }

    private async loadSports(): Promise<SportCalendarSport[]> {
        return this.executePromiseWithLoading(this.sportCalendarService.getSports(), 'isLoadingSports');
    }

    private async loadCountries(sportId: number): Promise<SportCalendarCountry[]> {
        return this.executePromiseWithLoading(this.sportCalendarService.getCountries(sportId), 'isLoadingCountries');
    }

    private async loadTournaments(sportId: number, countryId: number): Promise<SportCalendarTournament[]> {
        const tournaments = await this.executePromiseWithLoading(
            this.sportCalendarService.getTournaments(sportId, countryId),
            'isLoadingTournaments',
        );

        return this.sortTournaments(tournaments);
    }

    private sortTournaments(tournaments: SportCalendarTournament[]): SportCalendarTournament[] {
        return tournaments.sort((tournamentA, tournamentB) => {
            const valueA = tournamentA.name.toLowerCase();
            const valueB = tournamentB.name.toLowerCase();
            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }

            return 0;
        });
    }

    private async loadEvents(sportId: number, countryId: number, tournamentId: number): Promise<SportCalendarEvent[]> {
        const events = await this.executePromiseWithLoading(
            this.sportCalendarService.getEvents(sportId, countryId, tournamentId),
            'isLoadingEvents',
        );

        return this.sortEvents(events);
    }

    private sortEvents(events: SportCalendarEvent[]): SportCalendarEvent[] {
        return events.sort((eventA, eventB) => {
            const valueA = SportCalendarEventTransformer.getEventName(eventA).toLowerCase();
            const valueB = SportCalendarEventTransformer.getEventName(eventB).toLowerCase();
            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }

            return 0;
        });
    }

    private async executePromiseWithLoading<T>(promise: Promise<T>, loadingKey: keyof SportCalendarLoadingState): Promise<T> {
        this.updateLoadingState({ [loadingKey]: true });
        try {
            return await promise;
        } finally {
            this.updateLoadingState({ [loadingKey]: false });
        }
    }

    private async getFromCacheOrExecutePromise(
        filter: SportCalendarFilterParams,
        promise: () => Promise<CacheableSportCalendarObjects>,
    ): Promise<CacheableSportCalendarObjects> {
        if (this.sportCalendarCache.has(filter)) {
            return this.sportCalendarCache.get(filter) as SportCalendarEvent[];
        }
        const cacheableData = await promise();
        this.sportCalendarCache.set(filter, cacheableData);
        return cacheableData;
    }

    private transformToSelectableData(objectsToTransform: { dspId: number; name: string }[]): SelectableSportCalendarData[] {
        return objectsToTransform.map((obj) => ({ value: obj.dspId, label: obj.name }));
    }
}

export default SportCalendarViewModel;

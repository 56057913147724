import { API_URL } from '@/config';
import { RequestService } from '@ads/iam-library';
import ConfigService from '@/modules/config/services/ConfigService';

const configService = new ConfigService(new RequestService({ baseUrl: API_URL }));
const MAX_LOAD_RETRIES = 3;

export default {
    async initialize({ dispatch }) {
        await dispatch('fetchConfig');
    },

    async fetchConfig({ commit }) {
        commit('startLoading');
        for (let retry = 1; retry <= MAX_LOAD_RETRIES; retry++) {
            try {
                // eslint-disable-next-line no-await-in-loop
                const config = await configService.loadConfig();
                commit('setConfig', config);
                return;
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        }
        commit('setLoadingFailed');
    },
};

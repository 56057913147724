import { InputValidationRule } from 'vuetify';

type FormField = {
    required: boolean;
    name: string;
    key: string;
    rules?: {
        min?: number;
        max?: number;
        greaterThanField?: Partial<FormField>;
        smallerThanField?: Partial<FormField>;
    };
};
export default class StrategyFieldRuleBuilder {
    private readonly formField: FormField;

    private readonly strategy: { [key: string]: number };

    constructor(formField: FormField, strategy: { [key: string]: number }) {
        this.formField = formField;
        this.strategy = strategy;
    }

    build(): InputValidationRule[] {
        const rules: InputValidationRule[] = [];
        this.addRequiredRule(rules);
        this.addMinimumValueRule(rules);
        this.addGreaterThanFieldRule(rules);
        this.addSmallerThanFieldRule(rules);
        this.addMaximumValueRule(rules);

        return rules;
    }

    private addRequiredRule(rules: InputValidationRule[] = []): void {
        if (this.formField.required) {
            rules.push(this.makeRule((v) => v !== null && v !== undefined, `${this.formField.name} is required`));
        }
    }

    private addMinimumValueRule(rules: InputValidationRule[] = []): void {
        if (this.formField.rules?.min) {
            rules.push(
                this.makeRule(() => {
                    const value = this.strategy[this.formField.key];
                    return value >= this.formField.rules.min;
                }, `${this.formField.name} must be at least ${this.formField.rules.min}`),
            );
        }
    }

    private addMaximumValueRule(rules: InputValidationRule[] = []): void {
        if (this.formField.rules?.max) {
            rules.push(
                this.makeRule(() => {
                    const value = this.strategy[this.formField.key];
                    return value <= this.formField.rules.max;
                }, `${this.formField.name} must be smaller than ${this.formField.rules.max}`),
            );
        }
    }

    private makeRule(condition: (v) => boolean, message: string): InputValidationRule {
        return (value) => condition(value) || message;
    }

    private addGreaterThanFieldRule(rules: InputValidationRule[] = []): void {
        const greaterThanField = this.formField.rules?.greaterThanField;
        if (greaterThanField) {
            rules.push(
                this.makeRule(() => {
                    const value = this.strategy[this.formField.key];
                    const targetFieldValue = this.strategy[greaterThanField.key];
                    return this.isEmpty(targetFieldValue) || value > targetFieldValue;
                }, `${this.formField.name} must be greater than ${greaterThanField.name}`),
            );
        }
    }

    private addSmallerThanFieldRule(rules: InputValidationRule[] = []): void {
        const smallerThanField = this.formField.rules?.smallerThanField;
        if (smallerThanField) {
            rules.push(
                this.makeRule(() => {
                    const value = this.strategy[this.formField.key];
                    const targetFieldValue = this.strategy[smallerThanField.key];
                    return this.isEmpty(targetFieldValue) || value < targetFieldValue;
                }, `${this.formField.name} must be smaller than ${smallerThanField.name}`),
            );
        }
    }

    private isEmpty(value: unknown): boolean {
        return value == null || value.toString().trim() === '';
    }
}

import WeekDay from 'api-contracts/line-items/targetings/time/WeekDay';

export const weekdaysLabels = {
    [WeekDay.monday]: {
        short: 'Mon',
        long: 'Monday',
    },
    [WeekDay.tuesday]: {
        short: 'Tue',
        long: 'Tuesday',
    },
    [WeekDay.wednesday]: {
        short: 'Wed',
        long: 'Wednesday',
    },
    [WeekDay.thursday]: {
        short: 'Thu',
        long: 'Thursday',
    },
    [WeekDay.friday]: {
        short: 'Fri',
        long: 'Friday',
    },
    [WeekDay.saturday]: {
        short: 'Sat',
        long: 'Saturday',
    },
    [WeekDay.sunday]: {
        short: 'Sun',
        long: 'Sunday',
    },
};

export const matrixHeaders = Object.entries(weekdaysLabels).map(([index, labels]) => ({
    text: labels.short,
    textFull: labels.long,
    value: Number(index),
}));

export const matrixHeadlines = Array.from({ length: 24 }, (_, index) => ({
    text: index < 10 ? `0${index}:00` : `${index}:00`,
    value: index,
}));

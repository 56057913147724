export default abstract class ErrorRuleFactory {
    protected abstract mappings;

    create(error: { response: { message: string } }): Record<string, unknown> {
        try {
            const errorObject = JSON.parse(error.response.message);
            const errorRules = {};
            Object.keys(errorObject).forEach((errorKey) => {
                const mappedKey = this.mappings[errorKey] || errorKey;
                if (this[mappedKey]) {
                    errorRules[mappedKey] = this[mappedKey](errorObject[errorKey]);
                }
            });
            return errorRules;
        } catch (err) {
            return {};
        }
    }
}

<template>
    <SrCheckbox
        :key="`${row.value}_${col.value}`"
        :title="`${col.text} ${row.text}`"
        :input-value="value"
        hide-details
        dense
        :ripple="false"
        class="cell cell-checkbox mt-0 pt-0 shrink"
        @change="onChange"
    />
</template>

<script>
import { SrCheckbox } from '@ads/design-system';

export default {
    name: 'BooleanMatrixCheckbox',
    components: { SrCheckbox },
    props: {
        col: {
            type: Object,
            default: () => ({}),
        },
        row: {
            type: Object,
            default: () => ({}),
        },
        value: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onChange(event) {
            this.$emit('click', {
                row: this.row.value,
                col: this.col.value,
                value: event,
            });
        },
    },
};
</script>

<style scoped>
.cell {
    min-width: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cell-checkbox {
    padding-left: 13px;
}
</style>

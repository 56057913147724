<template>
    <SrExpansionPanels :panels="panels" :targeting-type-count="targetingTypeCount">
        <template v-for="panel in panels" #[panel.name]>
            <div v-if="panel.component" :key="panel.name">
                <component
                    :is="panel.component"
                    v-model="lineItem"
                    :is-valid.sync="validStates[panel.name]"
                    :targeting-key="panel.key"
                    :initial-count="initialCount(panel.key)"
                    @currentTargetingCount="(count, key) => currentTargetingCount(count, key || panel.key)"
                    @changed="(status, key) => setChangedStatus(status, key || panel.key)"
                />
            </div>
            <div v-else :key="panel.name">{{ panel.title }}'s content</div>
        </template>
    </SrExpansionPanels>
</template>

<script>
import SrExpansionPanels from '@/components/SrExpansionPanels';
import emitValidStateMixin from '@/modules/line-items/mixins/emitValidStateMixin';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountDelegation from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountDelegation';
import AppWebTargeting from './AppWebTargeting';
import ApplicationNameList from './ApplicationNameList';
import DomainList from './DomainList';
import BundleList from './BundleList/BundleList';
import PublisherList from './PublisherList';
import Category from './Category';
import VideoPlayerSize from './VideoPlayerSize';
import Visibility from './Visibility/Visibility';
import SspTargetingOverview from './SspTargeting/SspTargetingOverview';
import ContextualTargeting from './ContextualTargeting';

export default {
    name: 'TargetingsContent',
    components: {
        SrExpansionPanels,
        AppWebTargeting,
        ApplicationNameList,
        DomainList,
        BundleList,
        PublisherList,
        Category,
        VideoPlayerSize,
        Visibility,
        SspTargetingOverview,
        ContextualTargeting,
    },
    mixins: [emitValidStateMixin, LineItemModel, TargetingCountDelegation],
    props: {
        isValid: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            validStates: {
                appOrWebTargeting: this.isValid,
                contextualTargeting: this.isValid,
            },
            targetingCategory: 'content',
        };
    },
    computed: {
        panels() {
            return [
                {
                    name: 'contextualTargeting',
                    title: 'Contextual Targeting - Powered by Peer39',
                    component: 'ContextualTargeting',
                    key: 'contextualCategoryTargeting',
                },
                {
                    name: 'applicationNameList',
                    title: 'Application Name List',
                    component: 'ApplicationNameList',
                    key: 'applicationNameLists',
                },
                {
                    name: 'ssp',
                    title: 'SSP',
                    component: 'SspTargetingOverview',
                    key: ['sspTargetings', 'dealTargetings'],
                },
                {
                    name: 'visibility',
                    title: 'Visibility',
                    component: 'Visibility',
                    key: 'visibilityTargetings',
                },
                {
                    name: 'videoPlayerSize',
                    title: 'Video Player Size',
                    component: 'VideoPlayerSize',
                    key: 'videoPlayerSizeTargetings',
                },
                {
                    name: 'domainList',
                    title: 'Domain List',
                    component: 'DomainList',
                    key: 'domainLists',
                },
                {
                    name: 'bundleList',
                    title: 'Bundle List',
                    component: 'BundleList',
                    key: 'bundleListTargetings',
                },
                {
                    name: 'category',
                    title: 'Category',
                    component: 'Category',
                    key: 'categoryTargetings',
                },
                {
                    name: 'publisherList',
                    title: 'Publisher List',
                    component: 'PublisherList',
                    key: 'publisherListTargetings',
                },
                {
                    name: 'appOrWebTargeting',
                    title: 'App Or Web Targeting',
                    component: 'AppWebTargeting',
                    hasError: !this.validStates.appOrWebTargeting,
                    key: 'appOrWebTargeting',
                },
            ];
        },
        isEveryTargetingValid() {
            return Object.values(this.validStates).every((validState) => Boolean(validState) === true);
        },
    },
    watch: {
        isEveryTargetingValid: 'emitValidState',
    },
    methods: {
        emitValidState() {
            this.$emit('update:isValid', this.isEveryTargetingValid);
        },
    },
};
</script>

import ISspResponse from 'api-contracts/ssps/ISspResponse';
import INetworkResponse from 'api-contracts/ssps/INetworkResponse';

export default {
    setSsps(state, ssps: ISspResponse[]): void {
        state.ssps = ssps;
    },
    setNetworksBySspId(state, { networks, sspId }: { networks: INetworkResponse[]; sspId: number }): void {
        state.networksBySspId[sspId] = networks;
    },
};

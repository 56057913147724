import BackgroundProcessService, {
    backgroundProcessService as backgroundProcessServiceInstance,
} from '@/modules/background-process/services/BackgroundProcessService';
import { ICampaignDuplicationConfig } from 'api-contracts/campaigns/ICampaignDuplicationConfig';
import BACKGROUND_PROCESS_STATUS from 'api-contracts/background-process/Status';
import { sleep } from '@/shared/utils';
import { BackgroundProcessEnqueueResponse } from '@/modules/background-process/types';
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';

export default class CampaignDuplicationService {
    private readonly requestService: RequestService;

    private readonly requestOptions: IRequestOptions = {
        domain: 'programmatic-campaigns',
        resource: 'campaign',
        action: ACTION_NAME.CREATE,
    };

    private readonly backgroundProcessService: BackgroundProcessService;

    private backgroundProcess;

    constructor(requestService: RequestService, backgroundProcessService: BackgroundProcessService) {
        this.requestService = requestService;
        this.backgroundProcessService = backgroundProcessService;
    }

    async duplicate(originalCampaignId: number, duplicationConfig: ICampaignDuplicationConfig): Promise<void> {
        this.initializeBackgroundProcess();
        try {
            const duplicationResponse = await this.sendRequest(originalCampaignId, duplicationConfig);
            this.backgroundProcess.id = duplicationResponse.id;
        } catch (error) {
            const errorMessage = error?.response?.message || 'Could not duplicate the campaign.';
            throw new Error(errorMessage);
        }
        await this.startBackgroundProcessStatusPolling();
    }

    private initializeBackgroundProcess(): void {
        this.backgroundProcess = { id: null, status: null };
    }

    private async sendRequest(
        originalCampaignId: number,
        duplicationConfig: ICampaignDuplicationConfig,
    ): Promise<BackgroundProcessEnqueueResponse> {
        return this.requestService.post(`/campaigns/${originalCampaignId}/duplicate`, duplicationConfig, this.requestOptions);
    }

    private async startBackgroundProcessStatusPolling(): Promise<void> {
        if (!this.backgroundProcess.id) {
            throw new Error(`Background process ID is not valid: ${this.backgroundProcess.id}`);
        }
        while (!this.isBackgroundProcessCompleted()) {
            // eslint-disable-next-line no-await-in-loop
            await sleep(1000);
            // eslint-disable-next-line no-await-in-loop
            this.backgroundProcess = await this.backgroundProcessService.getBackgroundProcess(this.backgroundProcess.id);
            if (this.backgroundProcess.status === BACKGROUND_PROCESS_STATUS.FAILED) {
                throw new Error(this.backgroundProcess.processData.errorMessage);
            }
        }
    }

    private isBackgroundProcessCompleted(): boolean {
        return (
            this.backgroundProcess.status === BACKGROUND_PROCESS_STATUS.FAILED ||
            this.backgroundProcess.status === BACKGROUND_PROCESS_STATUS.SUCCESS
        );
    }
}

export const campaignDuplicationService = new CampaignDuplicationService(
    new RequestService({ baseUrl: API_URL }),
    backgroundProcessServiceInstance,
);

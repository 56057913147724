<template>
    <div class="browser-overview">
        <Browser v-model="lineItem" />
        <OperatingSystem v-model="lineItem" />
    </div>
</template>

<script>
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import Browser from './Browser';
import OperatingSystem from './OperatingSystem';

export default {
    name: 'BrowserOverview',
    components: { Browser, OperatingSystem },
    mixins: [LineItemModel, TargetingCountUpdate],
};
</script>

<style lang="scss" scoped>
.browser-overview {
    > :first-child {
        margin-bottom: 20px;
    }
}
</style>

/* eslint-disable max-len */
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import {
    PublisherListCreate,
    PublisherListEdit,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/PublisherList/services/PublisherList';
import IListOverviewResponse from 'api-contracts/overview/IListOverviewResponse';

export default class PublisherListService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'line-item',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async create(publisherList: PublisherListCreate): Promise<PublisherListEdit> {
        const options = this.getRequestOptions({ action: ACTION_NAME.CREATE });

        return this.requestService.post('/publisher-lists', publisherList, options);
    }

    async getById(id: number): Promise<PublisherListEdit> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/publisher-lists/${id}`, options);
    }

    async edit(publisherList: PublisherListEdit): Promise<PublisherListEdit> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });

        return this.requestService.put(`/publisher-lists/${publisherList.id}`, publisherList, options);
    }

    async getByCampaignId(campaignId): Promise<IListOverviewResponse[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/publisher-lists/?campaignId=${campaignId}`, options);
    }
}

export const publisherListService = new PublisherListService(new RequestService({ baseUrl: API_URL }));

import { geoService } from '@/modules/geo/services/GeoService';
import { GEO_LOADING } from '@/modules/geo/store/state';

export default {
    async initialize({ dispatch }) {
        dispatch('getCountries');
    },

    async getCountries({ commit }) {
        commit('setLoading', {
            key: GEO_LOADING.COUNTRIES,
            value: true,
        });
        const countries = await geoService.getCountries();
        commit('setCountries', countries);
        commit('setLoading', {
            key: GEO_LOADING.COUNTRIES,
            value: false,
        });
    },
};

import { ICampaignDuplicationConfig } from 'api-contracts/campaigns/ICampaignDuplicationConfig';
import ICampaignDuplicationInfo from 'api-contracts/campaigns/ICampaignDuplicationInfo';

export default function getInitialDuplicationConfigFor(duplicationInfo: ICampaignDuplicationInfo): ICampaignDuplicationConfig {
    return {
        name: '',
        willKeepLearnings: false,
        budget: duplicationInfo.budget,
        runtime: {
            startDate: null,
            endDate: null,
            timeZone: duplicationInfo.runtime.timeZone,
        },
        kpi: duplicationInfo.kpi,
    };
}

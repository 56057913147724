<template>
    <VContainer>
        <SrHeadline level="3" weight="bold"> Tracking - Conversion Pixels </SrHeadline>
        <SrSelect
            v-model="selectedPixelSources"
            :items="availablePixelSources"
            :loading="isLoading"
            label="Attribution Provider"
            sort-order="ASC"
            hide-details
            multiple
            dense
        />
        <VRadioGroup v-if="shouldShowPixelsTable" v-model="selectedOptimizedPixelDspId" :disabled="isLoading" mandatory>
            <ErrorBox :show="Boolean(errorMessage)" :message="errorMessage" />
            <div class="conversion-pixel-table">
                <div v-for="pixel in lineItem.pixels" :key="pixel.dspId" class="conversion-pixel-table-row">
                    <ConversionPixelsRow
                        :pixel="pixel"
                        :is-removing-enabled="isRemovingEnabled"
                        @remove="() => onRemove(pixel)"
                    />
                </div>
            </div>
        </VRadioGroup>
        <SrBodyText v-else color="gray--text text--darken-2" class="mt-3 text-center"> No Pixels Available </SrBodyText>
    </VContainer>
</template>

<script>
import { SrBodyText, SrHeadline, SrSelect } from '@ads/design-system';
import ErrorBox from '@/components/ErrorBox';
import ConversionPixelsRow from '@/modules/line-items/components/LineItemForm/LineItemFormStepStrategy/ConversionPixels/ConversionPixelsRow';
import { pixelService } from '@/modules/pixels/services/PixelService';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import PIXEL_SOURCE from 'api-contracts/pixels/PixelSource';
import ALL_PIXEL_SOURCES from 'api-contracts/pixels/AllPixelSources';

const DEFAULT_SELECTED_PIXEL_SOURCES = [PIXEL_SOURCE.PIXEL_MANAGER];

export default {
    name: 'ConversionPixels',
    components: {
        ConversionPixelsRow,
        SrHeadline,
        SrSelect,
        SrBodyText,
        ErrorBox,
    },
    mixins: [LineItemModel],
    props: {
        isEditForm: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            errorMessage: null,
            pixelService,
        };
    },
    computed: {
        availablePixelSources() {
            return ALL_PIXEL_SOURCES;
        },
        selectedOptimizedPixelDspId: {
            get() {
                return this.getOptimizedPixelDspId();
            },
            set(newOptimizedPixelId) {
                this.setOptimizedPixelOnLineItem(newOptimizedPixelId);
            },
        },
        selectedPixelSources: {
            get() {
                return this.getUniquePixelSourcesOfLineItemPixels();
            },
            async set(selectedSources) {
                await this.loadPixelsFromSources(selectedSources);
            },
        },
        shouldShowPixelsTable() {
            return this.lineItem.pixels && this.lineItem.pixels.length > 0;
        },
        isRemovingEnabled() {
            return this.lineItem.pixels && this.lineItem.pixels.length > 1;
        },
    },
    async created() {
        if (this.lineItem.pixels) {
            this.lineItem.pixels = this.getSortedPixelsWithOptimizedPixelSet(
                this.lineItem.pixels,
                this.selectedOptimizedPixelDspId,
            );
        } else if (!this.isEditForm) {
            await this.loadPixelsFromSources(DEFAULT_SELECTED_PIXEL_SOURCES);
        }
    },
    methods: {
        getOptimizedPixelDspId() {
            const optimizedPixel = this.lineItem.pixels?.find((pixel) => pixel.isOptimized);
            return optimizedPixel?.dspId;
        },
        setOptimizedPixelOnLineItem(optimizedPixelDspId) {
            this.lineItem.pixels = this.getSortedPixelsWithOptimizedPixelSet(this.lineItem.pixels, optimizedPixelDspId);
        },
        getUniquePixelSourcesOfLineItemPixels() {
            return [...new Set(this.lineItem.pixels?.map((pixel) => pixel.source))];
        },
        async loadPixelsFromSources(selectedSources, optimizedPixelDspId) {
            this.clearErrorMessage();
            this.isLoading = true;
            try {
                const pixels = await this.fetchPixels(selectedSources);
                this.lineItem.pixels = this.getSortedPixelsWithOptimizedPixelSet(pixels, optimizedPixelDspId);
            } catch (error) {
                this.errorMessage = 'Something went wrong when loading pixels.';
            } finally {
                this.isLoading = false;
            }
        },
        async fetchPixels(selectedSources) {
            return this.pixelService.getForCampaign(this.lineItem?.campaign?.id, selectedSources);
        },
        getSortedPixelsWithOptimizedPixelSet(pixels, optimizedPixelDspId) {
            const sortedPixels = this.sortBySourceAndDspId(pixels);
            if (!optimizedPixelDspId) {
                optimizedPixelDspId = sortedPixels[0]?.dspId;
            }
            return sortedPixels?.map((pixel) => ({
                ...pixel,
                isOptimized: pixel.dspId === optimizedPixelDspId,
            }));
        },
        clearErrorMessage() {
            this.errorMessage = null;
        },
        sortBySourceAndDspId(array) {
            if (!array) {
                return [];
            }

            const arrayCopy = [...array];
            arrayCopy.sort((a, b) => {
                if (a.source === b.source) {
                    return a.dspId - b.dspId;
                }
                return a.source.localeCompare(b.source);
            });
            return arrayCopy;
        },
        onRemove(pixelToRemove) {
            const remainingPixels = this.lineItem.pixels.filter((pixel) => pixel.dspId !== pixelToRemove.dspId);
            this.lineItem.pixels = [...remainingPixels];
        },
    },
};
</script>

<style lang="scss">
@import '~@ads/design-system/src/scss/variables';
@import '~@ads/design-system/src/scss/mixins';

$row-height: 40px;
$table-max-height: 5 * $row-height;

.conversion-pixel-table {
    border: 1px solid $gray;
    border-radius: 3px;
    max-height: $table-max-height;
    overflow-y: auto;
    @include custom-scrollbar;

    &-row:nth-child(odd) {
        background-color: $gray-lightest;
    }

    .pixel {
        height: $row-height;
        display: flex;
        padding: 0 8px;
        align-items: center;

        &:hover {
            background-color: $gray;
        }

        > div {
            margin: 0 8px;
            min-width: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .conversion-type {
            width: 30%;
            overflow: hidden;
        }

        .name {
            width: 70%;
            overflow: hidden;
        }
    }
}
</style>

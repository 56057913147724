<template>
    <div class="matrix">
        <div class="header">
            <div class="header-placeholder cell">&nbsp;</div>
            <div
                v-for="header in matrixHeaders"
                :key="header.value"
                class="header-item pointer"
                @click="onColumnHeaderClick(header.value)"
            >
                <SrHeadline level="4" class="mb-0 cell">
                    <strong>{{ header.text }}</strong>
                </SrHeadline>
            </div>
        </div>
        <div v-for="row in headlines" :key="row.value" class="matrix-row">
            <div class="row-header pointer" @click="onRowHeaderClick(row.value)">
                <SrHeadline level="4" class="mb-0 cell">
                    {{ row.text }}
                </SrHeadline>
            </div>
            <BooleanMatrixCheckbox
                v-for="header in matrixHeaders"
                :key="`${row.value}_${header.value}`"
                :col="header"
                :row="row"
                :value="matrix[header.value][row.value]"
                @click="onCheckboxClick"
            />
        </div>
    </div>
</template>

<script>
import { SrHeadline } from '@ads/design-system';
import BooleanMatrixCheckbox from './BooleanMatrixCheckbox';

export default {
    name: 'BooleanMatrix',
    components: { BooleanMatrixCheckbox, SrHeadline },
    props: {
        headers: {
            type: Array,
            default: () => [],
        },
        headlines: {
            type: Array,
            default: () => [],
        },
        matrix: {
            type: Array,
            default: () => Array.from({ length: 7 }, () => Array.from({ length: 24 }, () => false)),
        },
    },
    computed: {
        matrixHeaders() {
            return [...this.headers];
        },
    },
    methods: {
        updateMatrix(matrix) {
            this.$emit('update:matrix', matrix);
        },
        onCheckboxClick(cell) {
            const newMatrix = this.matrix.map((column, colIndex) => {
                if (cell.col === colIndex) {
                    return column.map((value, rowIndex) => {
                        if (cell.row === rowIndex) {
                            return cell.value;
                        }
                        return value;
                    });
                }
                return column;
            });
            this.updateMatrix(newMatrix);
        },
        onColumnHeaderClick(columnIndex) {
            const newMatrix = this.matrix.map((column, index) => {
                if (index === columnIndex) {
                    return column.map(() => !this.isSelected(column));
                }
                return column;
            });
            this.updateMatrix(newMatrix);
        },
        onRowHeaderClick(index) {
            const row = this.matrix.map((column) => column[index]);
            const newValue = !this.isSelected(row);
            const newMatrix = this.matrix.map((column) =>
                column.map((value, valueIndex) => {
                    if (valueIndex === index) {
                        return newValue;
                    }
                    return value;
                }),
            );
            this.updateMatrix(newMatrix);
        },
        isSelected(values) {
            return values.filter(Boolean).length === values.length;
        },
    },
};
</script>
<style scoped lang="scss">
@import '~@ads/design-system/src/scss/variables';

.matrix {
    border: 1px solid $gray-light;
    border-radius: 3px;
    width: fit-content;
}

.matrix .matrix-row:nth-child(odd) {
    background-color: #fafafa;
}

.header,
.matrix-row {
    display: flex;
    align-items: center;
    padding: 4px 6px 4px 6px;
}

.header {
    border-bottom: 1px solid $gray-light;
    padding: 8px 6px 8px 6px !important;
}

.row-header .cell {
    padding-right: 8px;
    text-align: start;
    justify-content: start;
    padding-left: 4px;
}

.pointer {
    cursor: pointer;
    user-select: none;
}

.cell {
    min-width: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

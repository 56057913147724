<template>
    <ActionModal
        v-model="isOpen"
        :title="title"
        :description="description"
        :error="errorMessage"
        :is-loading="isLoading"
        @cancel="cancelDuplication"
    >
        <template #content>
            <SrNotification
                v-for="(notification, index) in lineItemNotifications"
                :key="index"
                :title="notification.title"
                :type="notification.type"
                :description="notification.description"
            />
        </template>
        <template #actions>
            <SrButton
                type="secondary"
                :loading="isDuplicatingLineItem"
                :disabled="isDuplicationDisabled"
                @click="duplicateLineItem"
            >
                Duplicate
            </SrButton>
        </template>
    </ActionModal>
</template>

<script>
import ActionModal from '@/components/ActionModal';
import { SrButton, SrNotification } from '@ads/design-system';
import { lineItemsService } from '@/modules/line-items/services/LineItemsService';
import LINE_ITEM_STATUS from 'api-contracts/line-items/statuses/Status';

export default {
    name: 'DuplicateModal',
    components: {
        ActionModal,
        SrButton,
        SrNotification,
    },
    props: {
        /**
         * @model is modal open
         */
        value: {
            type: Boolean,
            default: false,
        },
        lineItemId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isOpen: this.value,
            isLoadingDuplicationInfo: false,
            lineItemName: '',
            lineItemStatus: LINE_ITEM_STATUS.ARCHIVE,
            lineItemNotifications: [],
            isDuplicatingLineItem: false,
            loadDuplicationInfoErrorMessage: null,
            duplicationErrorMessage: null,
        };
    },
    computed: {
        title() {
            return `Duplicate${this.isLineItemActive ? ' Active ' : ' '}Line Item?`;
        },
        description() {
            return `Are you sure you want to duplicate line item ${this.lineItemName}?`;
        },
        isLoading() {
            return this.isLoadingDuplicationInfo || this.isDuplicatingLineItem;
        },
        isDuplicationDisabled() {
            if (this.isLoading || this.loadDuplicationInfoErrorMessage != null) {
                return true;
            }
            return this.lineItemNotifications.some((notification) => notification.type === 'error');
        },
        isLineItemActive() {
            return this.lineItemStatus === LINE_ITEM_STATUS.ACTIVE;
        },
        errorMessage() {
            return this.loadDuplicationInfoErrorMessage != null
                ? this.loadDuplicationInfoErrorMessage
                : this.duplicationErrorMessage;
        },
    },
    watch: {
        value(newValue) {
            this.isOpen = newValue;
        },
        isOpen() {
            this.emitIsOpenChange();
        },
    },
    mounted() {
        this.loadDuplicationInfo();
    },
    methods: {
        async loadDuplicationInfo() {
            this.loadDuplicationInfoErrorMessage = null;
            this.isLoadingDuplicationInfo = true;
            try {
                const duplicationInfo = await lineItemsService.getDuplicationInfo(this.lineItemId);
                this.lineItemName = duplicationInfo.name;
                this.lineItemStatus = duplicationInfo.status;
                this.lineItemNotifications = duplicationInfo.notifications;
            } catch (e) {
                this.loadDuplicationInfoErrorMessage = 'Could not get information required for duplicating.';
            } finally {
                this.isLoadingDuplicationInfo = false;
            }
        },
        emitIsOpenChange() {
            this.$emit('input', this.isOpen);
        },
        cancelDuplication() {
            this.clear();
            this.$emit('cancel');
        },
        handleDuplicationSuccess() {
            this.clear();
            this.$emit('completed');
        },
        async duplicateLineItem() {
            this.duplicationErrorMessage = null;
            this.isDuplicatingLineItem = true;
            try {
                await lineItemsService.duplicate(this.lineItemId);
                this.handleDuplicationSuccess();
            } catch (e) {
                this.duplicationErrorMessage = `Could not duplicate line item ${this.lineItemName}.`;
            } finally {
                this.isDuplicatingLineItem = false;
            }
        },
        clear() {
            this.isDuplicatingLineItem = false;
            this.loadDuplicationInfoErrorMessage = null;
            this.duplicationErrorMessage = null;
        },
    },
};
</script>

<template>
    <VCol>
        <VRow>
            <VCol xs="12" sm="12">
                <VRow>
                    <VCol lg="3" class="pl-lg-0">
                        <SrSelect
                            v-model="range.days"
                            label="Days"
                            :items="matrixHeaders"
                            item-text="textFull"
                            item-value="value"
                            multiple
                            full-width
                            hide-details
                        />
                    </VCol>
                    <VCol lg="6" class="hours-select">
                        <SrSelect
                            v-model="range.from"
                            class="select-hour"
                            label="From"
                            :items="matrixHeadlines"
                            item-text="text"
                            item-value="value"
                            hide-details
                        />

                        <span class="pr-3 pl-3"> to </span>

                        <SrSelect
                            v-model="range.to"
                            class="select-hour"
                            label="To"
                            :items="matrixHeadlines"
                            item-text="text"
                            item-value="value"
                            hide-details
                        />
                    </VCol>
                    <VCol lg="3" class="d-flex align-center pr-lg-0">
                        <SrButton type="secondary" class="btn ml-sm-0" :disabled="!isRangeValid" block @click="selectRange">
                            Apply Range
                        </SrButton>
                    </VCol>
                </VRow>
                <VRow>
                    <VCol xs="12" sm="12" md="3" xl="2" class="pa-0 mt-sm-4">
                        <VRow class="pt-2 mr-xs-6 mr-sm-6" no-gutters>
                            <VCol xs="3" sm="12" class="px-md-0 px-sm-3">
                                <SrButton class="mb-4" color="transparent" block @click="selectWorkingHours">
                                    Work Hours
                                </SrButton>
                            </VCol>
                            <VCol xs="3" sm="12" class="px-md-0 px-sm-3">
                                <SrButton class="mb-4" color="transparent" block @click="selectWeekend"> Weekend </SrButton>
                            </VCol>
                            <VCol xs="3" sm="12" class="px-md-0 px-sm-3">
                                <SrButton class="mb-4" type="delete" block @click="resetAll"> Reset All </SrButton>
                            </VCol>
                        </VRow>
                    </VCol>
                    <VCol xs="12" sm="12" md="9" xl="10" class="pa-0 ma-0">
                        <div>
                            <span class="ml-6 ml-sm-0 timezone">
                                {{ lineItem.runtime.timeZone }}
                            </span>
                            <BooleanMatrix
                                :headers="matrixHeaders"
                                :headlines="matrixHeadlines"
                                :matrix="booleanMatrix"
                                class="ml-6 ml-sm-0 matrix"
                                @update:matrix="onMatrixUpdate"
                            />
                        </div>
                    </VCol>
                </VRow>
            </VCol>
        </VRow>
    </VCol>
</template>

<script>
import { SrSelect, SrButton } from '@ads/design-system';
import BooleanMatrix from '@/components/BooleanMatrix';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import TimeTargetingPickerController from './TimeTargetingPickerController';
import { matrixHeaders, matrixHeadlines } from './config';

export default {
    name: 'TimeTargetingPicker',
    components: { SrSelect, SrButton, BooleanMatrix },
    mixins: [LineItemModel, TargetingCountUpdate],
    data() {
        return {
            range: {
                days: null,
                from: null,
                to: null,
            },
            matrixHeaders,
            matrixHeadlines,
            targeting: this.value.targetings?.timeTargeting || TimeTargetingPickerController.getEmptyTargeting(),
        };
    },
    computed: {
        booleanMatrix() {
            return TimeTargetingPickerController.toBooleanMatrix(this.targeting);
        },
        isRangeValid() {
            return TimeTargetingPickerController.isValidRange(this.range);
        },
        currentCount() {
            if (!this.targeting) {
                return 0;
            }
            return Object.values(this.targeting)
                .map((hoursOfDay) => hoursOfDay.length)
                .reduce((a, b) => a + b, 0);
        },
    },
    watch: {
        targeting: {
            handler() {
                this.$set(this.lineItem.targetings, 'timeTargeting', this.targeting);
                this.$emit('input', this.lineItem);
            },
            deep: true,
        },
    },
    methods: {
        resetAll() {
            this.targeting = TimeTargetingPickerController.getEmptyTargeting();
        },
        selectWeekend() {
            this.targeting = TimeTargetingPickerController.selectWeekend(this.targeting);
        },
        selectWorkingHours() {
            this.targeting = TimeTargetingPickerController.selectWorkingHours(this.targeting);
        },
        selectRange() {
            this.targeting = TimeTargetingPickerController.selectRange(this.range, this.targeting);
        },
        onMatrixUpdate(matrix) {
            this.targeting = TimeTargetingPickerController.fromBooleanMatrix(matrix);
        },
    },
};
</script>

<style scoped lang="scss">
.btn {
    height: 55px !important;
    margin-bottom: 0 !important;
}

.timezone {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 4px;
}

.matrix {
    height: 485px;
    overflow-y: scroll;
    flex-grow: 0;
}

.select-hour {
    min-width: 95px;
}

.hours-select {
    display: flex;
    align-items: center;
}
</style>

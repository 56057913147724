<template>
    <div>
        <LineItemForm
            v-model="lineItem"
            :is-loading="isLoading"
            :load-line-item-form-error="errorMessage"
            submit-action="create"
            after-submit-route="line-items-list"
            after-close-route="line-items-list"
        />
        <RouterView :line-item="lineItem" />
    </div>
</template>

<script>
import LineItemForm from '@/modules/line-items/components/LineItemForm';
import { mapGetters } from 'vuex';
import getEmptyLineItem from '@/modules/line-items/getEmptyLineItem';

export default {
    name: 'CreateLineItem',
    components: {
        LineItemForm,
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            lineItem: {
                ...getEmptyLineItem(),
                targetings: this.deepCopy(this.$store.getters['config/lineItemDefaultTargetings']),
            },
        };
    },
    computed: {
        ...mapGetters({
            defaultTargetings: 'config/lineItemDefaultTargetings',
            isLoading: 'config/isLoading',
            errorMessage: 'config/errorMessage',
        }),
        campaignId() {
            return this.lineItem?.campaign?.id;
        },
    },
    watch: {
        defaultTargetings: {
            deep: true,
            handler() {
                this.lineItem.targetings = this.deepCopy(this.defaultTargetings);
            },
        },
    },
    methods: {
        deepCopy(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
    },
};
</script>

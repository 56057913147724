<template>
    <DealForm
        v-model="deal"
        :required-permission="canCreateDeals"
        submit-action="deal/create"
        after-submit-route="deals-list"
        after-close-route="deals-list"
    />
</template>

<script>
import { canCreateDeals } from '@/permissions';
import createEmptyDeal from '@/modules/deals/views/createEmptyDeal';
import DealForm from '../../components/DealForm';

export default {
    name: 'Create',
    components: { DealForm },
    data() {
        return {
            canCreateDeals,
            deal: createEmptyDeal(),
        };
    },
    computed: {
        holdings() {
            return this.$store.getters['iam/holdings'];
        },
    },
    watch: {
        holdings: {
            handler(holdings) {
                // if there is only one holding, select the first one.
                if (holdings.length === 1) {
                    this.deal.holding.id = holdings[0].id;
                }
            },
            immediate: true,
        },
    },
};
</script>

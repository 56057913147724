import { deviceTypeService } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Technology/Device/service/DeviceTypeService';
import { DEVICE_LOADING_STATES } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Technology/Device/store/state';

export default {
    async fetchDeviceTypes({ commit }): Promise<void> {
        commit('setLoading', {
            key: DEVICE_LOADING_STATES.DEVICE_TYPE,
            value: true,
        });
        const deviceTypes = await deviceTypeService.getAll();
        commit('setLoading', {
            key: DEVICE_LOADING_STATES.DEVICE_TYPE,
            value: false,
        });

        commit('setDeviceTypes', deviceTypes);
    },
};

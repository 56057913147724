<template>
    <VExpansionPanelHeader
        class="expander-header"
        :class="{ 'has-error': hasError, 'status-changed': statusChanged }"
        data-testid="SrExpansionHeader__header"
    >
        <template v-if="keyExists">
            <CountableBar :title="title" :initial-count="initialCount" :updated-count="updatedCount" />
            <div class="mr-5 shrink" />
        </template>
        <v-row v-else align="center">
            <VCol>
                {{ title }}
            </VCol>
        </v-row>
    </VExpansionPanelHeader>
</template>

<script>
import CountableBar from '@/components/CountableBar';

export default {
    name: 'SrExpansionHeader',
    components: {
        CountableBar,
    },
    props: {
        targetingKey: {
            type: [String, Array],
            required: true,
        },
        targetingTypeCount: {
            type: Object,
            required: true,
        },
        hasError: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    computed: {
        initialCount() {
            if (Array.isArray(this.targetingKey)) {
                return this.targetingKey.reduce((count, key) => count + this.targetingTypeCount[key].count, 0);
            }
            return this.targetingTypeCount[this.targetingKey].count;
        },
        updatedCount() {
            if (Array.isArray(this.targetingKey)) {
                return this.targetingKey.reduce((count, key) => count + this.targetingTypeCount[key].updateCount, 0);
            }
            return this.targetingTypeCount[this.targetingKey].updateCount;
        },
        statusChanged() {
            if (Array.isArray(this.targetingKey)) {
                return this.targetingKey.some((currentKey) => Boolean(this.targetingTypeCount[currentKey]?.wasUpdated));
            }
            return Boolean(this.targetingTypeCount[this.targetingKey]?.wasUpdated);
        },
        keyExists() {
            if (Array.isArray(this.targetingKey)) {
                return !this.targetingKey.some((currentKey) => !this.targetingTypeCount[currentKey]);
            }
            return Boolean(this.targetingTypeCount[this.targetingKey]);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@ads/design-system/src/scss/variables';

$error-color: var(--v-error-base, #ff5252);

.expander-panel + .expander-panel {
    margin-top: 9px;
}

.expander-panel.v-expansion-panel::before {
    box-shadow: unset !important;
}

.expander-header {
    border: 1px solid $gray-light;
    border-radius: 3px;
    min-height: 50px !important;
    padding: 14px 16px 14px 20px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;

    &.has-error {
        border: 1px solid $error-color !important;
    }
}

.expander-panel.v-expansion-panel--active .expander-header {
    border: 1px solid #3367d6;
}
</style>

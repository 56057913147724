import { format } from 'date-fns-tz';

export default function getTodaysOffsetForTimezone(timezoneName: string): string {
    const today = new Date().valueOf();
    const offset = format(today, 'XXX', { timeZone: timezoneName });

    if (offset === 'Z') {
        return '+00:00';
    }

    return offset;
}

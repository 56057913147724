import { SelectedNodeTree } from '@/components/SelectableTree/SelectedTree';
import { BrowserLanguageTargeting } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/BrowserLanguage/services/BrowserLanguage';

export default class BrowserLanguageTargetingTransformer {
    private readonly cachedBrowserLanguageTargetings;

    constructor(cachedBrowserLanguageTargetings) {
        this.cachedBrowserLanguageTargetings = cachedBrowserLanguageTargetings;
    }

    transform(selectedTreeNodes: SelectedNodeTree[]): BrowserLanguageTargeting[] {
        return selectedTreeNodes.map((treeNode) => {
            const cachedBrowserLanguageTargeting = this.cachedBrowserLanguageTargetings.find(
                (list) => list.browserLanguage.id === treeNode.getId(),
            );
            return this.createBrowserLanguageTargeting(treeNode, cachedBrowserLanguageTargeting);
        });
    }

    // noinspection JSMethodCanBeStatic
    private createBrowserLanguageTargeting(
        treeNode: SelectedNodeTree,
        cachedBrowserLanguageTargeting: BrowserLanguageTargeting,
    ): BrowserLanguageTargeting {
        if (cachedBrowserLanguageTargeting) {
            return {
                id: cachedBrowserLanguageTargeting.id,
                browserLanguage: cachedBrowserLanguageTargeting.browserLanguage,
                isExcluded: treeNode.isExcluded(),
            };
        }
        return {
            id: null,
            browserLanguage: { id: treeNode.getId().toString() },
            isExcluded: treeNode.isExcluded(),
        };
    }
}

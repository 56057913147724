<template>
    <div class="d-flex align-center justify-end">
        <SrButton v-if="hasPreview" small icon @click="preview">
            <SrIcon icon="observe" size="xs" />
        </SrButton>
        <SrButton
            v-if="isActionAdd"
            :disabled="isDisabled"
            small
            class="ml-2"
            color="blue lighten-5 blue--text text--lighten-1"
            elevation="0"
            @click="add"
        >
            Add
        </SrButton>
        <SrButton v-if="!isActionAdd" x-small icon class="close-icon ml-2" @click="remove">
            <SrIcon icon="close" size="xxs" />
        </SrButton>
    </div>
</template>

<script>
import { SrButton, SrIcon } from '@ads/design-system';

export default {
    name: 'CreativeTableActions',
    components: {
        SrButton,
        SrIcon,
    },
    props: {
        action: {
            type: String,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        hasPreview: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        isActionAdd() {
            return this.action === 'add';
        },
    },
    methods: {
        add() {
            this.$emit('add');
        },
        remove() {
            this.$emit('remove');
        },
        preview() {
            this.$emit('preview');
        },
    },
};
</script>

<style>
.close-icon {
    background-color: #acacac;
}
</style>

import isSubset from 'is-subset';
import { instanceToInstance } from 'class-transformer';

export default {
    props: {
        initialCount: {
            required: true,
        },
        targetingKey: {
            required: true,
        },
    },
    data() {
        return {
            cachedTargeting: {},
            wasDataLoaded: false,
        };
    },
    created() {
        this.createTargetingWatchers();
        this.initCache();
    },
    computed: {
        currentCount() {
            if (Array.isArray(this.targetingKey)) {
                return this.targetingKey.reduce((count, key) => {
                    let targetingCount;
                    if (!this.lineItem.targetings?.[key]) {
                        targetingCount = 0;
                    } else {
                        targetingCount = this.lineItem.targetings?.[key].length;
                    }
                    return {
                        ...count,
                        [key]: targetingCount,
                    };
                }, {});
            }

            if (!this.lineItem.targetings?.[this.targetingKey]) {
                return 0;
            }
            return this.lineItem.targetings?.[this.targetingKey].length;
        },
    },
    watch: {
        currentCount: {
            deep: true,
            handler(value) {
                if (Number.isInteger(value)) {
                    this.$emit('currentTargetingCount', value, this.targetingKey);
                } else {
                    Object.keys(value).forEach((key) => {
                        this.$emit('currentTargetingCount', value[key], key);
                    });
                }
            },
        },
        wasDataLoaded(wasDataLoaded) {
            if (wasDataLoaded === true) {
                this.initCache();
            }
        },
    },
    methods: {
        initCache() {
            const isMultiTargetingKey = Array.isArray(this.targetingKey);
            if (isMultiTargetingKey) {
                this.setCachedTargeting(
                    this.targetingKey.reduce(
                        (multiCache, key) => ({
                            ...multiCache,
                            [key]: this.lineItem.targetings[key],
                        }),
                        {},
                    ),
                );
                return;
            }
            this.setCachedTargeting(this.lineItem.targetings[this.targetingKey]);
        },
        createTargetingWatchers() {
            if (Array.isArray(this.targetingKey)) {
                this.targetingKey.forEach((targetingKey) => {
                    this.$watch(
                        `lineItem.targetings.${targetingKey}`,
                        (value) => {
                            this.$emit('changed', this.targetingChangedByKey(value, targetingKey), targetingKey);
                        },
                        { deep: true },
                    );
                });
            } else {
                this.$watch(
                    `lineItem.targetings.${this.targetingKey}`,
                    (value): void => {
                        this.$emit('changed', this.targetingChanged(value));
                    },
                    { deep: true },
                );
            }
        },
        setCachedTargeting(value): void {
            this.$set(this, 'cachedTargeting', instanceToInstance(value));
        },
        targetingChangedByKey(value, targetingKey): boolean {
            const isNotEmpty = this.currentCount[targetingKey] + this.initialCount[targetingKey] !== 0;
            return (
                (!isSubset(this.cachedTargeting[targetingKey], value) && isNotEmpty) ||
                this.currentCount[targetingKey] !== this.initialCount[targetingKey]
            );
        },
        targetingChanged(value): boolean {
            const isNotEmpty = this.currentCount + this.initialCount !== 0;
            return (!isSubset(this.cachedTargeting, value) && isNotEmpty) || this.currentCount !== this.initialCount;
        },
    },
};

import { SelectedNodeTree } from '@/components/SelectableTree/SelectedTree';
import IDeviceManufacturerResponse from 'api-contracts/line-items/targetings/devices/IDeviceManufacturerResponse';
import IDeviceResponse from 'api-contracts/line-items/targetings/devices/IDeviceResponse';
import IDeviceTargetingResponse from 'api-contracts/line-items/targetings/devices/IDeviceTargetingResponse';

export default class DeviceTargetingsTransformer {
    private readonly cachedDeviceTargetings;

    constructor(cachedDeviceTargetings) {
        this.cachedDeviceTargetings = cachedDeviceTargetings;
    }

    transform(selectedTreeNodes: SelectedNodeTree[]): IDeviceTargetingResponse[] {
        return selectedTreeNodes.map((treeNode) => {
            const cachedDeviceTargeting = this.cachedDeviceTargetings.find(
                (list) => list.device?.id === treeNode.getId() || list.deviceManufacturer?.id === treeNode.getId(),
            );
            return this.createDeviceTargeting(treeNode, cachedDeviceTargeting);
        });
    }

    // noinspection JSMethodCanBeStatic
    private createDeviceTargeting(
        treeNode: SelectedNodeTree,
        cachedDeviceTargeting: IDeviceTargetingResponse,
    ): IDeviceTargetingResponse {
        if (cachedDeviceTargeting) {
            return {
                device: cachedDeviceTargeting.device,
                deviceManufacturer: cachedDeviceTargeting.deviceManufacturer,
                isExcluded: treeNode.isExcluded(),
            };
        }
        return {
            device: this.getDevice(treeNode) as IDeviceResponse,
            deviceManufacturer: this.getDeviceManufacturer(treeNode),
            isExcluded: treeNode.isExcluded(),
        };
    }

    // noinspection JSMethodCanBeStatic
    private getDevice(treeNode: SelectedNodeTree): Partial<IDeviceResponse> {
        const deviceId = treeNode.getId();
        if (!Number.isInteger(deviceId)) {
            return null;
        }
        return { id: Number(deviceId) };
    }

    // noinspection JSMethodCanBeStatic
    private getDeviceManufacturer(treeNode: SelectedNodeTree): IDeviceManufacturerResponse {
        const deviceId = treeNode.getId();
        if (Number.isInteger(deviceId)) {
            return null;
        }
        return { id: `${deviceId}` };
    }
}

import { BusinessEntity, Holding } from '@/modules/iam/types';

export default {
    setHoldings(state, holdings: Holding[]) {
        state.holdings = holdings;
    },
    setBusinessEntities(state, businessEntities: BusinessEntity[]) {
        state.businessEntities = businessEntities;
    },
};

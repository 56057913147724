export const formSteps = [
    {
        name: 'Basic Settings',
        isValid: true,
    },
    {
        name: 'Runtime',
        isValid: true,
    },
    {
        name: 'Budget',
        isValid: true,
    },
];

import ICampaignDuplicationInfo from 'api-contracts/campaigns/ICampaignDuplicationInfo';
import getEmptyCampaignBudget from '@/modules/campaign/views/getEmptyCampaignBudget';
import getEmptyCampaignRuntime from '@/modules/campaign/views/getEmptyCampaignRuntime';

export default function getEmptyCampaignDuplicationInfo(): ICampaignDuplicationInfo {
    return {
        name: '',
        budget: getEmptyCampaignBudget(),
        runtime: getEmptyCampaignRuntime(),
        notifications: [],
    };
}

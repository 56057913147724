<template>
    <div class="node source-node-item">
        <div class="node-title" data-testid="TreeNode__container">
            <div
                class="node-label"
                :class="{
                    disabled: node.isDisabled,
                }"
            >
                <SrButton
                    v-if="hasChildren"
                    icon
                    class="node-toggle"
                    color="brand-blue"
                    :disabled="node.isDisabled"
                    data-testId="TreeNode__expand_children_button"
                    @click="toggleChildren()"
                >
                    <VIcon v-text="isChildrenOpened ? 'mdi-chevron-down' : 'mdi-chevron-right'" />
                </SrButton>
                <span
                    v-if="!hasChildren"
                    :class="{
                        'toggle-placeholder': !hasChildren,
                    }"
                    :title="node.name"
                />
                <span>{{ node.name }}</span>
            </div>
            <div>
                <v-btn v-if="formMode" class="edit-application-name-list" icon :disabled="!isEditable" @click="edit">
                    <SrIcon icon="edit" size="xs" :disabled="!isEditable" />
                </v-btn>
            </div>
            <div class="controls">
                <div v-if="isCombinedMode" class="combined-mode-controls">
                    <SrButton
                        :class="{ 'include-button-checked': isIncluded }"
                        depressed
                        small
                        outlined
                        :disabled="isIncludeDisabled"
                        data-testid="TreeNode__include_button"
                        @click="includeNode()"
                    >
                        <VIcon small class="check-icon"> mdi-check-bold </VIcon>
                    </SrButton>
                    <SrButton
                        :class="{ 'exclude-button-checked': isExcluded }"
                        depressed
                        small
                        outlined
                        color="$gray-light"
                        :disabled="isExcludeDisabled"
                        data-testid="TreeNode__exclude_button"
                        @click="excludeNode()"
                    >
                        <SrIcon icon="closeBrandRed" size="xxs" />
                    </SrButton>
                </div>
                <div v-else class="single-mode-info-controls">
                    <SrInfoBox v-if="hasInfoBox" left class="mr-2">
                        {{ node.details.info }}
                    </SrInfoBox>
                    <SrCheckbox
                        :input-value="isSelected"
                        :disabled="!canBeSelected"
                        data-testid="TreeNode__toggle_button"
                        @change="toggleNodeSelection"
                    />
                </div>
            </div>
        </div>
        <div v-if="isChildrenOpened">
            <VProgressLinear v-if="node.isLoadingChildren" indeterminate />
            <div v-else class="node-children">
                <div v-for="child in node.children" :key="child.id" class="node-child">
                    <TreeNode :node="child" :selected-tree="selectedTree" :mode="mode" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SrButton, SrCheckbox, SrIcon, SrInfoBox } from '@ads/design-system';
import { Node } from './Node';
import { SelectedTree } from './SelectedTree';
import { SELECTION_MODE } from './types';

export default {
    name: 'TreeNode',
    components: {
        SrButton,
        SrIcon,
        SrCheckbox,
        SrInfoBox,
    },
    props: {
        node: {
            type: Node,
            default: () => ({}),
        },
        selectedTree: {
            type: SelectedTree,
            required: true,
        },
        mode: {
            type: String,
            required: true,
        },
        formMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isChildrenOpened: false,
        };
    },
    computed: {
        isIncludeDisabled() {
            return this.node.isDisabled || this.isIncluded || !this.canBeIncluded;
        },
        isExcludeDisabled() {
            return this.node.isDisabled || this.isExcluded || !this.canBeExcluded;
        },
        isCombinedMode() {
            return this.mode === SELECTION_MODE.COMBINED;
        },
        isIncludeMode() {
            return this.mode === SELECTION_MODE.INCLUDE;
        },
        isExcludeMode() {
            return this.mode === SELECTION_MODE.EXCLUDE;
        },
        isIncluded() {
            return this.selectedTree.doesInclude(this.node);
        },
        isExcluded() {
            return this.selectedTree.doesExclude(this.node);
        },
        isSelected() {
            if (this.isIncludeMode) {
                return this.isIncluded;
            }
            if (this.isExcludeMode) {
                return this.isExcluded;
            }
            return false;
        },
        hasChildren() {
            return Boolean(this.node.children);
        },
        canBeIncluded() {
            return this.selectedTree.canInclude(this.node);
        },
        canBeExcluded() {
            return this.selectedTree.canExclude(this.node);
        },
        canBeSelected() {
            if (this.node.isDisabled) {
                return false;
            }
            if (this.isIncludeMode) {
                return this.canBeIncluded;
            }
            if (this.isExcludeMode) {
                return this.canBeExcluded;
            }
            return false;
        },
        hasInfoBox() {
            return Boolean(this.node.details?.info);
        },
        isEditable() {
            if (typeof this.node.details?.isEditable === 'boolean') {
                return this.node.details.isEditable;
            }
            return true;
        },
    },
    methods: {
        async toggleChildren() {
            this.isChildrenOpened = !this.isChildrenOpened;
            if (this.isChildrenOpened) {
                await this.node.loadChildren();
            }
        },
        includeNode() {
            this.selectedTree.include(this.node);
        },
        excludeNode() {
            this.selectedTree.exclude(this.node);
        },
        toggleNodeSelection(selected) {
            if (selected) {
                if (this.mode === SELECTION_MODE.INCLUDE) {
                    this.includeNode();
                    return;
                }
                if (this.mode === SELECTION_MODE.EXCLUDE) {
                    this.excludeNode();
                    return;
                }
            }
            this.selectedTree.deselectNodeById(this.node.id);
        },
        edit() {
            this.$emit('edit', this.node);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@ads/design-system/src/scss/variables';

$row-height: 38px;

.node-label {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: $row-height;

    &.disabled {
        color: #a1a1a1;
    }
}

.node-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 0;
    height: $row-height;
    color: $brand-blue;
}

.node-toggle {
    width: 26px;
    height: 26px;
}

.toggle-placeholder {
    margin-left: 36px;
}

.node-children {
    padding-left: 16px;
}

.controls {
    margin: 2px 8px 2px;
}

@mixin checked-button($include: true) {
    opacity: 1 !important;
    @if ($include) {
        background-color: $blue !important;
    } @else {
        background-color: $red-light !important;
    }
    color: $white !important;

    .v-btn__content {
        .v-icon {
            opacity: 1 !important;
            color: $white !important;
        }
    }
}

.combined-mode-controls {
    $disabled-opacity: 0.4;
    $button-width: 27px;
    $button-height: 18px;

    display: flex;
    justify-content: space-between;

    > :not(:first-child) {
        margin-left: 8px;
    }

    .sr-button {
        min-width: $button-width !important;
        width: $button-width !important;
        height: $button-height !important;
        border-color: $gray-light;

        .v-btn__content {
            .v-icon {
                color: $blue !important;
                font-size: 14px !important;
            }
        }

        &.v-btn--disabled.include-button-checked {
            @include checked-button();
        }

        &.v-btn--disabled.exclude-button-checked {
            @include checked-button(false);
        }

        &.v-btn--disabled {
            color: $gray-light !important;
            opacity: $disabled-opacity;

            .v-btn__content {
                .v-icon {
                    opacity: $disabled-opacity;
                }
            }
        }
    }
}

.node-status {
    color: $brand-red;
}

.edit-application-name-list {
    display: none;
}

.source-node-item:hover {
    .edit-application-name-list {
        display: block;
    }
}

.single-mode-info-controls {
    display: flex;
    align-items: center;
}
</style>

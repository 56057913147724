import ErrorRuleFactory from '@/modules/config/ErrorRuleFactory';

export default class LocationErrorRuleFactory extends ErrorRuleFactory {
    protected name = (nameHolding) => {
        const [name] = nameHolding.split(',');
        return [(v) => v !== name || `'${name}' already exists for this holding`];
    };

    protected mappings = {
        'name, holding_id': 'name',
    };
}

export const locationErrorRuleFactory = new LocationErrorRuleFactory();

<template>
    <div>
        <SrHeadline level="3" weight="bold"> Available Deals </SrHeadline>
        <SelectableTree v-model="selectedTargeting" :source-nodes="deals" :mode.sync="mode" />
    </div>
</template>

<script>
import SelectableTree from '@/components/SelectableTree/SelectableTree';
import { Node } from '@/components/SelectableTree/Node';
import { SelectedTree, SelectedNodeTree, NODE_SELECTION_STATUS } from '@/components/SelectableTree/SelectedTree';
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import { SrHeadline } from '@ads/design-system';

export default {
    name: 'DealTargeting',
    components: { SelectableTree, SrHeadline },
    props: {
        lineItem: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selectedTargeting: this.selectedLineItemTargeting(),
            mode: this.getMode(),
        };
    },
    computed: {
        deals() {
            return this.$store.getters['deal/deals'].map((deal) => new Node(deal));
        },
    },
    watch: {
        selectedTargeting: {
            handler() {
                this.$set(
                    this.lineItem.targetings,
                    'dealTargetings',
                    this.transformTargeting(this.selectedTargeting.getSelectedNodeTrees()),
                );
            },
            deep: true,
        },
        'lineItem.targetings.sspTargetings': function () {
            if (this.lineItem.targetings.sspTargetings.length === 0) {
                this.$set(this.lineItem.targetings, 'dealTargetings', []);
                this.$set(this.selectedTargeting, 'children', []);
            }
        },
    },
    created() {
        if (this.isEditForm()) {
            this.selectedDeals = this.lineItem.targetings.dealTargetings?.map((dealTargeting) => dealTargeting.deal.id);
        }
    },
    methods: {
        getMode() {
            if (this.lineItem.targetings?.dealTargetings?.length) {
                if (this.lineItem.targetings.dealTargetings[0].isExcluded) {
                    return SELECTION_MODE.EXCLUDE;
                }
            }
            return SELECTION_MODE.INCLUDE;
        },
        selectedLineItemTargeting() {
            return new SelectedTree(
                this.lineItem.targetings.dealTargetings?.map(
                    ({ deal, isExcluded }) =>
                        new SelectedNodeTree(
                            deal.id,
                            deal.name,
                            isExcluded ? NODE_SELECTION_STATUS.EXCLUDED : NODE_SELECTION_STATUS.INCLUDED,
                        ),
                ),
            );
        },
        transformTargeting(deals) {
            return deals.map(({ id }) => ({
                deal: { id },
                isExcluded: this.mode === SELECTION_MODE.EXCLUDE,
            }));
        },
        isEditForm() {
            return Boolean(this.lineItem.id);
        },
    },
};
</script>

import { Node } from '@/components/SelectableTree/Node';
import {
    Browser,
    BrowserTargeting,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Technology/Browser/types';
import { NODE_SELECTION_STATUS, SelectedNodeTree } from '@/components/SelectableTree/SelectedTree';

export class BrowserTransformer {
    browsersToSelectableNodes(browsers: Browser[]): Node[] {
        return browsers.map((browser) => new Node({ ...browser }));
    }

    browserTargetingsToSelectedNodes(browserTargetings: BrowserTargeting[]): SelectedNodeTree[] {
        return browserTargetings.map(
            (browserTargeting) =>
                new SelectedNodeTree(
                    browserTargeting.browser.id,
                    browserTargeting.browser.name,
                    browserTargeting.isExcluded ? NODE_SELECTION_STATUS.EXCLUDED : NODE_SELECTION_STATUS.INCLUDED,
                ),
        );
    }

    selectedNodesToBrowserTargetings(selectedNodes: SelectedNodeTree[]): BrowserTargeting[] {
        return selectedNodes.map((selectedNode) => ({
            browser: {
                id: selectedNode.getId() as number,
                name: selectedNode.getName(),
            },
            isExcluded: selectedNode.isExcluded(),
        }));
    }
}

export const browserTransformer = new BrowserTransformer();

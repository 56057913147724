<template>
    <VExpansionPanels>
        <VExpansionPanel
            v-for="panel in panels"
            :key="panel.name"
            class="expander-panel"
            :data-testid="`SrExpansionPanels__${panel.name}`"
        >
            <SrExpansionHeader
                :targeting-key="panel.key"
                :targeting-type-count="targetingTypeCount"
                :has-error="Boolean(panel.hasError)"
                :title="panel.title"
            />
            <VExpansionPanelContent class="mt-4" data-testid="SrExpansionPanels__content">
                <slot :name="panel.name" />
            </VExpansionPanelContent>
        </VExpansionPanel>
    </VExpansionPanels>
</template>

<script>
import SrExpansionHeader from '@/components/SrExpansionPanels/SrExpansionHeader';

export default {
    name: 'SrExpansionPanels',
    components: { SrExpansionHeader },
    props: {
        panels: {
            type: Array,
            default: () => [],
        },
        targetingTypeCount: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@ads/design-system/src/scss/variables';

$error-color: var(--v-error-base, #ff5252);

.expander-panel + .expander-panel {
    margin-top: 9px;
}

.expander-panel.v-expansion-panel::before {
    box-shadow: unset !important;
}

.expander-header {
    border: 1px solid $gray-light;
    border-radius: 3px;
    min-height: 50px !important;
    padding: 14px 16px 14px 20px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;

    &.has-error {
        border: 1px solid $error-color !important;
    }

    &.status-changed {
        background-color: #e5f3fe;
    }
}

.expander-panel.v-expansion-panel--active .expander-header {
    border: 1px solid #3367d6;
}
</style>

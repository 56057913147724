import { IEditDealRequest } from '@/modules/deals/types';
import { dealService } from '@/modules/deals/services/DealService';
import { Predicate } from '@ads/predicate-model';
import IDealRequest from 'api-contracts/deals/IDealRequest';

export default {
    async initialize({ dispatch }) {
        await Promise.all([dispatch('fetchDeals')]);
    },

    async create(_, dealRequest: IDealRequest) {
        return dealService.create(dealRequest);
    },
    async edit(_, dealRequest: IEditDealRequest) {
        return dealService.edit(dealRequest);
    },
    async fetchDeals({ commit }, filter?: Predicate) {
        commit('setDeals', await dealService.getAllDeals(filter));
    },
};

import IDealResponse from 'api-contracts/deals/IDealResponse';
import ILineItemResponse from 'api-contracts/line-items/ILineItemResponse';
import { stringifyError } from '@/shared/utils/stringifyError';
import { DealDeleteBackgroundService } from '@/modules/deals/services/DealDeleteBackgroundService';
import LINE_ITEM_COMPUTED_STATUS from 'api-contracts/line-items/statuses/ComputedStatus';

export class DealDeleteModalViewModel {
    private readonly deal: IDealResponse;

    private readonly lineItems: ILineItemResponse[];

    private readonly dealDeleteBackgroundService: DealDeleteBackgroundService;

    private isDeleting: boolean = false;

    private error: string | null = null;

    constructor(deal: IDealResponse, lineItems: ILineItemResponse[], dealDeleteBackgroundService: DealDeleteBackgroundService) {
        this.deal = deal;
        this.lineItems = lineItems;
        this.dealDeleteBackgroundService = dealDeleteBackgroundService;
    }

    public async deleteDeal(onSuccess: () => unknown): Promise<void> {
        if (this.isDeleting) {
            return;
        }
        this.isDeleting = true;
        this.error = null;
        try {
            await this.dealDeleteBackgroundService.delete(this.deal.id);
            onSuccess();
        } catch (error) {
            this.handleError(error);
        } finally {
            this.isDeleting = false;
        }
    }

    public hasLineItems(): boolean {
        return Boolean(this.lineItems?.length);
    }

    public hasActiveLineItems(): boolean {
        return this.lineItems?.some((lineItem) => lineItem.computedStatus === LINE_ITEM_COMPUTED_STATUS.ACTIVE);
    }

    public isDeletingDeal(): boolean {
        return this.isDeleting;
    }

    public deletionError(): string | null {
        return this.error;
    }

    public clearError(): void {
        this.error = null;
    }

    private handleError(error: unknown): void {
        this.error = `Failed to delete deal: ${stringifyError(error)}`;
    }
}

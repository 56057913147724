<template>
    <ProgressBar :value="budgetStatistics.spent" :threshold="budgetStatistics.forecast" :total="totalBudget">
        <template #value> Spent {{ spentAsLocaleCurrency }} </template>
        <template #threshold> Forecast {{ forecastAsLocaleCurrency }} </template>
        <template #total>
            {{ totalAsLocaleCurrency }}
        </template>
    </ProgressBar>
</template>

<script>
import ProgressBar from '@/components/ProgressBar';
import { formatNumberToCurrency } from '@/shared/utils';

export default {
    name: 'BudgetStatistics',
    components: {
        ProgressBar,
    },
    props: {
        totalBudget: {
            type: Number,
            required: true,
        },
        budgetStatistics: {
            type: Object,
            required: true,
        },
        currencyCode: {
            type: String,
            required: true,
        },
    },
    computed: {
        spentAsLocaleCurrency() {
            return formatNumberToCurrency(this.budgetStatistics.spent, this.currencyCode);
        },
        forecastAsLocaleCurrency() {
            return formatNumberToCurrency(this.budgetStatistics.forecast, this.currencyCode);
        },
        totalAsLocaleCurrency() {
            return formatNumberToCurrency(this.totalBudget, this.currencyCode);
        },
    },
};
</script>

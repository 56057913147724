export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            lineItem: this.value,
        };
    },
    watch: {
        value: {
            deep: true,
            handler(value): void {
                this.lineItem = value;
            },
        },
        lineItem: {
            deep: true,
            handler(value): void {
                this.$emit('input', value);
            },
        },
    },
};

<template>
    <div>
        <LocationForm
            v-model="location"
            :submit-action="actionType"
            :after-close-route="closingRoute"
            :after-close-route-params="closeRouteParams"
            :after-submit-route="closingRoute"
            :after-submit-route-params="afterSubmitParams"
            :is-loading="isLoading"
            :load-error="errorMessage"
            non-linear
        />
        <RouterView />
    </div>
</template>

<script>
import LocationForm from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/Location/LocationForm';
import TargetingFormRoutes from '@/modules/line-items/views/targetings/TargetingFormRoutes';
import { ACTION_TYPES } from '../../types';
import { locationService } from '../../../../components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/Location/services/LocationService';

export default {
    name: 'EditLocation',
    components: {
        LocationForm,
    },
    mixins: [TargetingFormRoutes],
    props: {
        lineItem: {
            type: Object,
            required: true,
        },
        locationId: {
            type: [Number, String],
            required: true,
            default: null,
        },
    },
    data() {
        return {
            show: true,
            location: {
                name: '',
                latitude: null,
                longitude: null,
                radius: null,
            },
            actionType: ACTION_TYPES.EDIT,
            errorMessage: null,
            isLoading: false,
            locationService,
        };
    },
    async created() {
        await this.loadLocations();
    },
    methods: {
        async loadLocations() {
            this.isLoading = true;
            try {
                this.isLoading = true;
                this.location = await this.locationService.getById(this.locationId);
            } catch (error) {
                this.errorMessage = error?.response?.message || String(error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

import device from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Technology/Device/store/';

const modules = {
    device,
};

export default {
    namespaced: true,
    modules,
};

<template>
    <div>
        <LineItemForm
            v-model="lineItem"
            submit-action="edit"
            after-submit-route="line-items-list"
            after-close-route="line-items-list"
            :is-loading.sync="isLoading"
            :load-line-item-form-error="loadLineItemError"
            non-linear
        />
        <RouterView :line-item="lineItem" />
    </div>
</template>

<script>
import LineItemForm from '@/modules/line-items/components/LineItemForm';
import { lineItemsService } from '@/modules/line-items/services/LineItemsService';
import getEmptyLineItem from '@/modules/line-items/getEmptyLineItem';

export default {
    name: 'LineItemEdit',
    components: {
        LineItemForm,
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            lineItem: { ...getEmptyLineItem(), id: this.id },
            loadLineItemError: null,
        };
    },
    created() {
        this.loadLineItem();
    },
    methods: {
        async loadLineItem() {
            this.isLoading = true;
            try {
                this.lineItem = await lineItemsService.getById(this.id);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
                this.loadLineItemError = error.message || error.response?.message || error;
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

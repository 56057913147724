import LINE_ITEM_STATUS from 'api-contracts/line-items/statuses/Status';
import LINE_ITEM_COMPUTED_STATUS from 'api-contracts/line-items/statuses/ComputedStatus';
import { USER_TIMEZONE } from '@/config';
import { DEFAULT_LINE_ITEM_VIEWABILITY_TARGETING_THRESHOLD_VALUE } from 'api-contracts/line-items/ViewabilityTargetingThreshold';

export default function getEmptyLineItem() {
    return {
        name: '',
        campaign: {
            id: null,
            name: null,
        },
        status: LINE_ITEM_STATUS.INACTIVE,
        computedStatus: LINE_ITEM_COMPUTED_STATUS.INACTIVE,
        runtime: {
            startDate: null,
            endDate: null,
            timeZone: USER_TIMEZONE,
        },
        strategyId: null,
        strategyConfig: {},
        frequencyCappings: [],
        isFrequencyCappingEnabled: false,
        isAdsTxtEnabled: false,
        isViewabilityTargetingEnabled: false,
        viewabilityTargetingThreshold: DEFAULT_LINE_ITEM_VIEWABILITY_TARGETING_THRESHOLD_VALUE,
        pixels: null,
        alerts: [],
        creatives: [],
    };
}

import { Node } from '@/components/SelectableTree/Node';
import { sportCalendarService } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/SportCalendar/services/SportCalendarService';
import SportCalendarEventTransformer from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/SportCalendar/SportCalendarEventTransformer';

export class TournamentNode extends Node {
    declare id: number;

    sportId: number;

    countryId: number;

    constructor(node: Partial<TournamentNode>, sportId: number, countryId: number) {
        super(node);
        this.id = node.id;
        this.sportId = sportId;
        this.countryId = countryId;
    }

    async fetchChildren(): Promise<Node[]> {
        const tournamentEvents = await sportCalendarService.getEvents(this.sportId, this.countryId, this.id);
        return SportCalendarEventTransformer.transformToNodes(tournamentEvents);
    }
}

<template>
    <SrSelect :value="value" label="Timezone" :items="timeZones" :disabled="disabled" searchable required @input="onUpdate" />
</template>

<script>
import { SrSelect } from '@ads/design-system';
import TIME_ZONES from 'api-contracts/value-objects/TimeZones';
import TimezoneSelectorViewModel from './TimezoneSelectorViewModel';

export default {
    name: 'TimezoneSelector',
    components: { SrSelect },
    props: {
        value: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const viewModel = new TimezoneSelectorViewModel();
        return {
            viewModel,
            timeZones: TIME_ZONES.map((timezone) => ({
                label: viewModel.appendTimezoneOffset(timezone),
                value: timezone,
            })),
        };
    },
    methods: {
        onUpdate(newValue) {
            this.$emit('input', newValue);
        },
    },
};
</script>

const headers = [
    {
        text: 'Start Date',
        value: 'start',
        align: 'left',
        width: '100px',
        sortable: false,
    },
    {
        text: 'End Date',
        value: 'end',
        align: 'left',
        width: '100px',
        sortable: false,
    },
    {
        text: 'Budget',
        value: 'budget',
        align: 'right',
        width: '100px',
        sortable: false,
    },
    {
        text: 'Actions',
        value: 'actions',
        align: 'left',
        width: '80px',
        sortable: false,
    },
];
export default headers;

<template>
    <ActionModal
        v-model="isOpen"
        title="Delete Line Items"
        :description="description"
        :error="errorMessage"
        :is-loading="isDeletingLineItems"
        @cancel="cancelBulkDeletion"
    >
        <template #actions>
            <SrButton type="delete" :loading="isDeletingLineItems" :disabled="isDeletingLineItems" @click="deleteLineItems">
                Delete
            </SrButton>
        </template>
    </ActionModal>
</template>

<script>
import ActionModal from '@/components/ActionModal';
import { SrButton } from '@ads/design-system';
import LINE_ITEM_COMPUTED_STATUS from 'api-contracts/line-items/statuses/ComputedStatus';
import { lineItemsService } from '@/modules/line-items/services/LineItemsService';

export default {
    name: 'BulkDeleteModal',
    components: {
        ActionModal,
        SrButton,
    },
    props: {
        /**
         * @model is modal open
         */
        value: {
            type: Boolean,
            default: false,
        },
        lineItems: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isOpen: this.value,
            isDeletingLineItems: false,
            errorMessage: null,
        };
    },
    computed: {
        description() {
            const baseDescription =
                'Once you delete a line item, you have no option of retrieving it later. ' +
                'Are you sure you want to delete selected line items?';

            if (this.hasActiveLineItems) {
                return `You have selected active line item(s) to be deleted. ${baseDescription}`;
            }

            return baseDescription;
        },
        hasActiveLineItems() {
            return this.lineItems.some((lineItem) => lineItem.computedStatus === LINE_ITEM_COMPUTED_STATUS.ACTIVE);
        },
    },
    watch: {
        value(newValue) {
            this.isOpen = newValue;
        },
        isOpen() {
            this.emitIsOpenChange();
        },
    },
    methods: {
        emitIsOpenChange() {
            this.$emit('input', this.isOpen);
        },
        cancelBulkDeletion() {
            this.clear();
            this.$emit('cancel');
        },
        handleDeletionSuccess() {
            this.clear();
            this.$emit('completed');
        },
        handleDeletionError() {
            this.errorMessage = 'Could not delete selected line items.';
        },
        async deleteLineItems() {
            this.isDeletingLineItems = true;
            const lineItemIds = this.lineItems.map((item) => item.id);

            try {
                await lineItemsService.bulkDelete(lineItemIds);
                this.handleDeletionSuccess();
            } catch (e) {
                this.handleDeletionError();
            } finally {
                this.isDeletingLineItems = false;
            }
        },
        clear() {
            this.isDeletingLineItems = false;
            this.errorMessage = null;
        },
    },
};
</script>

import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import { Predicate } from '@ads/predicate-model';
import { BackgroundProcessEnqueueResponse } from '@/modules/background-process/types';
import IDealResponse from 'api-contracts/deals/IDealResponse';
import IDealRequest from 'api-contracts/deals/IDealRequest';
import { IEditDealRequest } from '@/modules/deals/types';

export default class DealService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'deals',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async create(deal: IDealRequest): Promise<IDealResponse> {
        const options = this.getRequestOptions({ action: ACTION_NAME.CREATE });

        return this.requestService.post('/deals', deal, options);
    }

    async getById(id: number): Promise<IDealResponse> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/deals/${id}`, options);
    }

    async edit(deal: IEditDealRequest): Promise<IDealResponse> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });

        return this.requestService.put(`/deals/${deal.id}`, deal, options);
    }

    async delete(dealId: number): Promise<BackgroundProcessEnqueueResponse> {
        const options = this.getRequestOptions({ action: ACTION_NAME.REMOVE });

        return this.requestService.delete(`/deals/${dealId}`, options);
    }

    async getAllDeals(filter?: Predicate): Promise<IDealResponse[]> {
        const route = '/deals';
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        if (filter && filter.hasChildren()) {
            const query = btoa(filter.toMinifiedJson());
            return this.requestService.get(`${route}?query=${query}`, options);
        }

        return this.requestService.get(route, options);
    }
}

export const dealService = new DealService(new RequestService({ baseUrl: API_URL }));

/* eslint-disable max-len */
import {
    DiffPrimitiveArrayChanges,
    IChangelogConfig,
    DIFF_TYPE,
    IPrimitiveArrayComponent,
    IComplexArrayComponent,
    DiffPrimitiveArray,
    DiffComplexArray,
    DiffComplexArrayChanges,
    HourFormatter,
    DateFormatter,
    NumberFormatter,
} from '@ads/changelog-component';
import FIELDS from 'api-contracts/line-items/strategies/constants';

const config = (currency = 'USD', timezone = 'UTC'): IChangelogConfig => ({
    domainName: 'programmatic-campaigns',
    resourceType: 'line-item',
    grouping: {
        defaultGroup: {
            priorityProperties: ['name', 'strategyConfig.advertiserBudget'],
            limit: 3,
        },
        customGroups: [
            {
                properties: ['pixels', 'iowCreativeIds'],
                limit: 3,
                customLabels: {
                    newValueLabel: 'Changes',
                },
            },
            {
                propertyPatterns: ['targetings.!(isApp|isWeb)'],
                limit: 3,
                customLabels: {
                    propertyLabel: 'Targetings',
                    newValueLabel: 'Changes',
                },
            },
        ],
    },
    excludedSchemaVersions: [1],
    excludedProperties: ['strategyConfig.targetMargin', 'targetings.contextualCategoryTargeting.internalCpm'],
    propertyLabels: {
        name: 'Line Item Name',
        status: 'Status',
        strategyId: 'Strategy',
        iowCreativeIds: 'Creatives',
        'runtime.startDate': 'Start date',
        'runtime.endDate': 'End date',
        'runtime.timezone': 'Timezone',
        'strategyConfig.advertiserBudget': FIELDS.ADVERTISER_BUDGET.name,
        'strategyConfig.targetCpa': FIELDS.TARGET_CPA.name,
        'strategyConfig.advertiserCpm': FIELDS.ADVERTISER_CPM.name,
        'strategyConfig.targetCtr': FIELDS.TARGET_CTR.name,
        'strategyConfig.targetCvr': FIELDS.TARGET_CVR.name,
        'strategyConfig.dailyBudget': FIELDS.DAILY_BUDGET.name,
        'strategyConfig.advertiserCpcv': FIELDS.ADVERTISER_CPCV.name,
        'strategyConfig.advertiserDailyBudget': FIELDS.ADVERTISER_DAILY_BUDGET.name,
        'strategyConfig.targetCpaPostView': FIELDS.TARGET_CPA_POST_VIEW.name,
        'strategyConfig.targetCpaPostClick': FIELDS.TARGET_CPA_POST_CLICK.name,
        'strategyConfig.maxBidCpm': FIELDS.MAX_BID_CPM.name,
        'strategyConfig.targetCpc': FIELDS.TARGET_CPC.name,
        'strategyConfig.minimumLearningCpm': FIELDS.MINIMUM_LEARNING_CPM.name,
        'strategyConfig.maximumLearningCpm': FIELDS.MAXIMUM_LEARNING_CPM.name,
        isViewabilityTargetingEnabled: 'Has viewability targeting',
        isAdsTxtEnabled: 'Ads.txt',
        isFrequencyCappingEnabled: 'Has frequency capping',
        pixels: 'Pixels',
        'targetings.isApp': 'App targeting enabled',
        'targetings.isWeb': 'Web targeting enabled',
        'targetings.timeTargeting': 'Time targeting',
        'targetings.timeTargeting.0': 'Time targeting (Monday)',
        'targetings.timeTargeting.1': 'Time targeting (Tuesday)',
        'targetings.timeTargeting.2': 'Time targeting (Wednesday)',
        'targetings.timeTargeting.3': 'Time targeting (Thursday)',
        'targetings.timeTargeting.4': 'Time targeting (Friday)',
        'targetings.timeTargeting.5': 'Time targeting (Saturday)',
        'targetings.timeTargeting.6': 'Time targeting (Sunday)',
        'targetings.sspTargetings': 'SSP targeting',
        'targetings.dealTargetings': 'Deal targeting',
        'targetings.deviceTargetings': 'Device targeting',
        'targetings.deviceTypeTargetings': 'Device type targeting',
        'targetings.applicationNameListTargetings': 'Application name list targeting',
        'targetings.domainListTargetings': 'Domain list targeting',
        'targetings.bundleListTargetings': 'Bundle list targeting',
        'targetings.publisherListTargetings': 'Publisher list targeting',
        'targetings.geoTargetings': 'Geo targeting',
        'targetings.locationTargetings': 'Location targeting',
        'targetings.operatingSystemTargetings': 'Operating system targeting',
        'targetings.browserTargetings': 'Browser targeting',
        'targetings.browserLanguageTargetings': 'Browser language targeting',
        'targetings.categoryTargetings': 'Category targeting',
        'targetings.videoPlayerSizeTargetings': 'Video player size targeting',
        'targetings.visibilityTargetings': 'Visibility targeting',
        'targetings.sportCalendarTargetings': 'Sport calendar targeting',
        'targetings.pixelSegmentTargeting': 'Pixel segment targeting',
        'targetings.contextualCategoryTargeting': 'Contextual category targeting',
        'targetings.contextualCategoryTargeting.externalCpm': 'Contextual category targeting - CPM',
        'targetings.contextualCategoryTargeting.categoryId': 'Contextual category targeting - category',
    },
    formattedPropertyPatternValues: {
        'targetings.timeTargeting*': new HourFormatter(window.navigator.language, {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: timezone,
        }),
        'runtime.{startDate,endDate}': new DateFormatter(window.navigator.language, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: timezone,
        }),
        'strategyConfig.{advertiserBudget,targetCpa,minimumLearningCpm,maximumLearningCpm,advertiserCpm,dailyBudget,targetCtr,targetCvr,advertiserDailyBudget,targetCpaPostView,targetCpaPostClick,maxBidCpm,targetCpc,advertiserCpcv}':
            new NumberFormatter(window.navigator.language, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: 'currency',
                currency,
            }),
        viewabilityTargetingThreshold: new NumberFormatter(window.navigator.language, {
            style: 'percent',
            maximumFractionDigits: 0,
        }),
    },
    propertiesToPartition: {
        'targetings.contextualCategoryTargeting': [
            {
                property: 'externalCpm',
                dataType: DIFF_TYPE.PRIMITIVE,
            },
            {
                property: 'categoryId',
                dataType: DIFF_TYPE.PRIMITIVE,
            },
        ],
        'targetings.timeTargeting': [
            {
                property: '0',
                dataType: DIFF_TYPE.PRIMITIVE_ARRAY,
            },
            {
                property: '1',
                dataType: DIFF_TYPE.PRIMITIVE_ARRAY,
            },
            {
                property: '2',
                dataType: DIFF_TYPE.PRIMITIVE_ARRAY,
            },
            {
                property: '3',
                dataType: DIFF_TYPE.PRIMITIVE_ARRAY,
            },
            {
                property: '4',
                dataType: DIFF_TYPE.PRIMITIVE_ARRAY,
            },
            {
                property: '5',
                dataType: DIFF_TYPE.PRIMITIVE_ARRAY,
            },
            {
                property: '6',
                dataType: DIFF_TYPE.PRIMITIVE_ARRAY,
            },
        ],
        runtime: [
            {
                property: 'startDate',
                dataType: DIFF_TYPE.PRIMITIVE,
            },
            {
                property: 'endDate',
                dataType: DIFF_TYPE.PRIMITIVE,
            },
            {
                property: 'timezone',
                dataType: DIFF_TYPE.PRIMITIVE,
            },
        ],
    },
    customComponents: {
        properties: {
            pixels: [
                {
                    dataTypes: [DIFF_TYPE.COMPLEX_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffComplexArray,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isOptimized',
                                    labels: {
                                        positive: 'Optimized',
                                        negative: 'Not Optimized',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['dspId'],
                                limit: 3,
                            },
                        },
                        new: {
                            component: DiffComplexArrayChanges,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isOptimized',
                                    labels: {
                                        positive: 'Optimized',
                                        negative: 'Not Optimized',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['dspId'],
                                limit: 3,
                            },
                        },
                    },
                },
            ],
            iowCreativeIds: [
                {
                    dataTypes: [DIFF_TYPE.PRIMITIVE_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffPrimitiveArray,
                            options: <IPrimitiveArrayComponent>{
                                limit: 5,
                            },
                        },
                        new: {
                            component: DiffPrimitiveArrayChanges,
                            options: <IPrimitiveArrayComponent>{
                                limit: 4,
                            },
                        },
                    },
                },
            ],
            'targetings.deviceTypeTargetings': [
                {
                    dataTypes: [DIFF_TYPE.COMPLEX_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffComplexArray,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['name'],
                                limit: 3,
                            },
                        },
                        new: {
                            component: DiffComplexArrayChanges,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['name'],
                                limit: 3,
                            },
                        },
                    },
                },
            ],
            'targetings.sportCalendarTargetings': [
                {
                    dataTypes: [DIFF_TYPE.COMPLEX_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffComplexArray,
                            options: <IComplexArrayComponent>{
                                itemKey: ['name'],
                                limit: 3,
                            },
                        },
                        new: {
                            component: DiffComplexArrayChanges,
                            options: <IComplexArrayComponent>{
                                itemKey: ['name'],
                                limit: 3,
                            },
                        },
                    },
                },
            ],
            'targetings.geoTargetings': [
                {
                    dataTypes: [DIFF_TYPE.COMPLEX_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffComplexArray,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['cityId', 'countryId', 'subdivision1Id', 'subdivision2Id'],
                                limit: 3,
                            },
                        },
                        new: {
                            component: DiffComplexArrayChanges,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['cityId', 'countryId', 'subdivision1Id', 'subdivision2Id'],
                                limit: 3,
                            },
                        },
                    },
                },
            ],
            'targetings.visibilityTargetings': [
                {
                    dataTypes: [DIFF_TYPE.COMPLEX_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffComplexArray,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['visibility.name'],
                                limit: 3,
                            },
                        },
                        new: {
                            component: DiffComplexArrayChanges,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['visibility.name'],
                                limit: 3,
                            },
                        },
                    },
                },
            ],
            'targetings.contextualCategoryTargeting': [
                {
                    dataTypes: [DIFF_TYPE.COMPLEX_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffComplexArray,
                            options: <IComplexArrayComponent>{
                                itemKey: ['categoryId'],
                                limit: 3,
                            },
                        },
                        new: {
                            component: DiffComplexArrayChanges,
                            options: <IComplexArrayComponent>{
                                itemKey: ['categoryId'],
                                limit: 3,
                            },
                        },
                    },
                },
            ],
            'targetings.operatingSystemTargetings': [
                {
                    dataTypes: [DIFF_TYPE.COMPLEX_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffComplexArray,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['operatingSystemId', 'operatingSystemVersion.name'],
                                limit: 3,
                            },
                        },
                        new: {
                            component: DiffComplexArrayChanges,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['operatingSystemId', 'operatingSystemVersion.name'],
                                limit: 3,
                            },
                        },
                    },
                },
            ],
            'targetings.deviceTargetings': [
                {
                    dataTypes: [DIFF_TYPE.COMPLEX_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffComplexArray,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['deviceManufacturer', 'device.name'],
                                limit: 3,
                            },
                        },
                        new: {
                            component: DiffComplexArrayChanges,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['deviceManufacturer', 'device.name'],
                                limit: 3,
                            },
                        },
                    },
                },
            ],
            'targetings.sspTargetings': [
                {
                    dataTypes: [DIFF_TYPE.COMPLEX_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffComplexArray,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['networkKey'],
                                limit: 3,
                            },
                        },
                        new: {
                            component: DiffComplexArrayChanges,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['networkKey'],
                                limit: 3,
                            },
                        },
                    },
                },
            ],
            'targetings.browserLanguageTargetings': [
                {
                    dataTypes: [DIFF_TYPE.COMPLEX_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffComplexArray,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['browserLanguage.name'],
                                limit: 3,
                            },
                        },
                        new: {
                            component: DiffComplexArrayChanges,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['browserLanguage.name'],
                                limit: 3,
                            },
                        },
                    },
                },
            ],
            'targetings.browserTargetings': [
                {
                    dataTypes: [DIFF_TYPE.COMPLEX_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffComplexArray,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['browser.name'],
                            },
                        },
                        new: {
                            component: DiffComplexArrayChanges,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['browser.name'],
                            },
                        },
                    },
                },
            ],
            'targetings.categoryTargetings': [
                {
                    dataTypes: [DIFF_TYPE.COMPLEX_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffComplexArray,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['code'],
                                limit: 3,
                            },
                        },
                        new: {
                            component: DiffComplexArrayChanges,
                            options: <IComplexArrayComponent>{
                                badge: {
                                    itemKey: 'isExcluded',
                                    isReversed: true,
                                    labels: {
                                        positive: 'Included',
                                        negative: 'Excluded',
                                    },
                                    icons: {
                                        positive: 'mdi-check-bold',
                                        negative: 'mdi-close',
                                    },
                                },
                                itemKey: ['code'],
                                limit: 3,
                            },
                        },
                    },
                },
            ],
        },
        propertyPatterns: {
            'targetings.@(dealTargetings|publisherListTargetings|bundleListTargetings|domainListTargetings|videoPlayerSizeTargetings|applicationNameListTargetings|locationTargetings)':
                [
                    {
                        dataTypes: [DIFF_TYPE.COMPLEX_ARRAY],
                        columnComponents: {
                            old: {
                                component: DiffComplexArray,
                                options: <IComplexArrayComponent>{
                                    badge: {
                                        itemKey: 'isExcluded',
                                        isReversed: true,
                                        labels: {
                                            positive: 'Included',
                                            negative: 'Excluded',
                                        },
                                        icons: {
                                            positive: 'mdi-check-bold',
                                            negative: 'mdi-close',
                                        },
                                    },
                                    itemKey: ['referencedObjectDspId'],
                                    limit: 3,
                                },
                            },
                            new: {
                                component: DiffComplexArrayChanges,
                                options: <IComplexArrayComponent>{
                                    badge: {
                                        itemKey: 'isExcluded',
                                        isReversed: true,
                                        labels: {
                                            positive: 'Included',
                                            negative: 'Excluded',
                                        },
                                        icons: {
                                            positive: 'mdi-check-bold',
                                            negative: 'mdi-close',
                                        },
                                    },
                                    itemKey: ['referencedObjectDspId'],
                                    limit: 3,
                                },
                            },
                        },
                    },
                ],
            'targetings.timeTargeting*': [
                {
                    dataTypes: [DIFF_TYPE.PRIMITIVE_ARRAY],
                    columnComponents: {
                        old: {
                            component: DiffPrimitiveArray,
                            options: { limit: 3 },
                        },
                        new: {
                            component: DiffPrimitiveArrayChanges,
                            options: { limit: 2 },
                        },
                    },
                },
            ],
        },
    },
});

export default config;

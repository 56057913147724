import IFanIdSegmentResponse from 'api-contracts/fan-id/IFanIdSegmentResponse';
import { FanIdState } from '@/modules/fan-id/store/FanIdState';

const mutations = {
    setFanIdSegments(state: FanIdState, fanIdSegments: IFanIdSegmentResponse[]): void {
        state.fanIdSegments = fanIdSegments;
    },
    setIsLoadingFanIdSegments(state: FanIdState, isLoading: boolean): void {
        state.isLoadingFanIdSegments = isLoading;
    },
    setFanIdSegmentsFetchErrorMessage(state: FanIdState, errorMessage: string | null): void {
        state.fanIdSegmentsFetchErrorMessage = errorMessage;
    },
};

export default mutations;

export default {
    startLoading(state) {
        state.isLoading = true;
        state.errorMessage = null;
    },
    setConfig(state, config) {
        state.isLoading = false;
        state.errorMessage = null;
        state.config = config;
    },
    setLoadingFailed(state) {
        state.isLoading = false;
        state.errorMessage = 'Loading of configuration failed.';
    },
};

<template>
    <SelectableTree v-model="selectedTargeting" :source-nodes="visibilities" :mode.sync="mode" :is-loading="isLoading" />
</template>

<script>
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import { NODE_SELECTION_STATUS, SelectedNodeTree, SelectedTree } from '@/components/SelectableTree/SelectedTree';
import SelectableTree from '@/components/SelectableTree/SelectableTree';
import { Node } from '@/components/SelectableTree/Node';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import { visibilityService } from './VisibilityService';

export default {
    name: 'Visibility',
    components: { SelectableTree },
    mixins: [LineItemModel, TargetingCountUpdate],
    data() {
        return {
            selectedTargeting: this.selectedLineItemTargeting(),
            mode: this.getMode(),
            visibilityService,
            visibilities: [],
            isLoading: false,
        };
    },
    watch: {
        selectedTargeting: {
            handler() {
                this.$set(
                    this.lineItem.targetings,
                    'visibilityTargetings',
                    this.transformTargeting(this.selectedTargeting.getSelectedNodeTrees()),
                );
            },
            deep: true,
        },
    },
    async created() {
        this.isLoading = true;
        const fetchedVisibilities = await visibilityService.listVisibilities();
        this.visibilities = fetchedVisibilities.map((visibility) => new Node(visibility));
        this.isLoading = false;
    },
    methods: {
        getMode() {
            if (!this.isEditForm()) {
                return SELECTION_MODE.INCLUDE;
            }

            if (this.value.targetings.visibilityTargetings) {
                if (this.value.targetings.visibilityTargetings[0]?.isExcluded) {
                    return SELECTION_MODE.EXCLUDE;
                }
            }
            return SELECTION_MODE.INCLUDE;
        },
        selectedLineItemTargeting() {
            return new SelectedTree(
                this.value.targetings.visibilityTargetings?.map(
                    ({ visibility, isExcluded }) =>
                        new SelectedNodeTree(
                            visibility.id,
                            visibility.name,
                            isExcluded ? NODE_SELECTION_STATUS.EXCLUDED : NODE_SELECTION_STATUS.INCLUDED,
                        ),
                ),
            );
        },
        transformTargeting(visibilities) {
            return visibilities.map(({ id }) => ({
                visibility: { id },
                isExcluded: this.mode === SELECTION_MODE.EXCLUDE,
            }));
        },
        isEditForm() {
            return !!this.$route.params.id;
        },
    },
};
</script>

import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import {
    Size,
    SizeRange,
    VideoPlayerSizeCreateRequest,
    VideoPlayerSizeDetailResponse,
    VideoPlayerSizeEditRequest,
    VideoPlayerSizeResponse,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/VideoPlayerSize/types';

export default class VideoPlayerSizeService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'line-item',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    createEmptySizeRange(): SizeRange {
        return {
            minWidth: null,
            maxWidth: null,
            minHeight: null,
            maxHeight: null,
        };
    }

    createEmptySize(): Size {
        return {
            width: null,
            height: null,
        };
    }

    async getAll(): Promise<VideoPlayerSizeResponse[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get('/video-player-sizes', options);
    }

    async getById(id: number): Promise<VideoPlayerSizeDetailResponse> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`/video-player-sizes/${id}`, options);
    }

    async create(videoPlayerSizeRequest: VideoPlayerSizeCreateRequest): Promise<void> {
        const options = this.getRequestOptions({ action: ACTION_NAME.CREATE });

        return this.requestService.post('/video-player-sizes', videoPlayerSizeRequest, options);
    }

    async edit(videoPlayerSizeRequest: VideoPlayerSizeEditRequest): Promise<void> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });

        return this.requestService.put(`/video-player-sizes/${videoPlayerSizeRequest.id}`, videoPlayerSizeRequest, options);
    }
}

export const videoPlayerSizeService = new VideoPlayerSizeService(new RequestService({ baseUrl: API_URL }));

<template>
    <ActionModal
        :value="true"
        :title="title"
        :error="deletionError"
        :should-close-on-click-outside-or-esc="false"
        :is-cancel-button-disabled="isDeletingDeal"
        :is-loading="isLoading"
        :max-width="750"
        @cancel="cancelDeletion"
    >
        <template #content>
            <SrNotification
                v-if="dealTargetsActiveLineItemsWarning"
                type="warning"
                title="Warning"
                :description="dealTargetsActiveLineItemsWarning"
            />
            <Table
                v-if="hasLineItems"
                :items-per-page="tableLength"
                :headers="listHeaders"
                :is-loading="isLoadingLineItems"
                :items="lineItems"
                entity-name="LineItem"
                data-testid="DealDeleteModal__line_items_table"
                :title="description"
            >
                <template #name="item">
                    <router-link :to="getLineItemRoute(item)" class="font-weight-bold">
                        {{ item.name }}
                    </router-link>
                </template>
            </Table>
            <VSkeletonLoader v-else-if="isLoadingLineItems" type="image" height="100" />
        </template>
        <template #actions>
            <SrButton type="delete" :disabled="isLoading" data-testid="DealDeleteModal__delete_button" @click="deleteDeal">
                Delete
            </SrButton>
        </template>
    </ActionModal>
</template>

<script>
import ActionModal from '@/components/ActionModal';
import { SrButton, SrNotification } from '@ads/design-system';
import Table from '@/components/Table/Table';
import { lineItemListHeaders } from '@/modules/deals/components/DealDeleteModal/lineItemListHeaders';
import { DealDeleteModalViewModel } from '@/modules/deals/components/DealDeleteModal/DealDeleteModalViewModel';
import { dealDeleteBackgroundService } from '@/modules/deals/services/DealDeleteBackgroundService';
import { listActionRoutes } from '@/modules/line-items/views/list/config';
import { TableActionTypes } from '@/components/types';

export default {
    name: 'DealDeleteModal',
    components: {
        Table,
        ActionModal,
        SrButton,
        SrNotification,
    },
    props: {
        deal: {
            type: Object,
            required: true,
        },
        lineItems: {
            type: Array,
            required: true,
        },
        isLoadingDeal: {
            type: Boolean,
            required: true,
        },
        isLoadingLineItems: {
            type: Boolean,
            required: true,
        },
        tableLength: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            viewModel: new DealDeleteModalViewModel(this.deal, this.lineItems, dealDeleteBackgroundService),
            listHeaders: lineItemListHeaders,
            lineItemRoutes: listActionRoutes,
        };
    },
    computed: {
        title() {
            if (this.isLoadingDeal) {
                return `Are you sure you want to delete the deal?`;
            }
            return `Are you sure you want to delete the deal "${this.deal.name}"?`;
        },
        description() {
            if (this.isLoadingLineItems || !this.hasLineItems) {
                return '';
            }
            return `The following line items are using this deal:`;
        },
        isLoading() {
            return this.isLoadingDeal || this.isDeletingDeal;
        },
        isDeletingDeal() {
            return this.viewModel.isDeletingDeal();
        },
        deletionError() {
            return this.viewModel.deletionError();
        },
        hasLineItems() {
            return this.viewModel.hasLineItems();
        },
        dealTargetsActiveLineItemsWarning() {
            if (!this.viewModel.hasActiveLineItems()) {
                return '';
            }
            return 'This deal is targeted by currently active line items.';
        },
    },
    watch: {
        deal() {
            this.viewModel = new DealDeleteModalViewModel(this.deal, this.lineItems, dealDeleteBackgroundService);
        },
        lineItems() {
            this.viewModel = new DealDeleteModalViewModel(this.deal, this.lineItems, dealDeleteBackgroundService);
        },
    },
    methods: {
        cancelDeletion() {
            this.viewModel.clearError();
            this.$emit('cancel');
        },
        handleDeletionSuccess() {
            this.$emit('completed');
        },
        async deleteDeal() {
            await this.viewModel.deleteDeal(() => this.handleDeletionSuccess());
        },
        getLineItemRoute(lineItem) {
            return {
                name: this.lineItemRoutes[TableActionTypes.EDIT].routeName,
                params: { id: lineItem.id },
            };
        },
    },
};
</script>

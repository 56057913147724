import {
    SPORT_CALENDAR_TARGETING_TYPE,
    SportCalendarEvent,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/SportCalendar/types';
import { TournamentNode } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/SportCalendar/TournamentNode';
import { Node } from '@/components/SelectableTree/Node';

export default class SportCalendarEventTransformer {
    static transformToNodes(events: SportCalendarEvent[], parent: TournamentNode = undefined): Node[] {
        return events.map((event) => SportCalendarEventTransformer.transformToNode(event, parent));
    }

    static transformToNode(event: SportCalendarEvent, parent: TournamentNode = undefined): Node {
        const node: Partial<Node> = {
            id: event.dspId,
            name: SportCalendarEventTransformer.getEventName(event),
            type: SPORT_CALENDAR_TARGETING_TYPE.EVENT,
            details: {
                info: event.startTime,
                tournamentDspId: event.tournament.dspId,
                tournamentName: event.tournament.name,
            },
        };
        if (parent) {
            node.parent = parent;
        }
        return new Node(node);
    }

    static getEventName(event: SportCalendarEvent): string {
        if (!event.competitors || event.competitors.length <= 3) {
            return event.name;
        }

        return event.competitors;
    }
}

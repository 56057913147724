<template>
    <VRow class="ml-1">
        <VCol lg="4" sm="12">
            <VRow>
                <VCol class="pl-0">
                    <SrHeadline level="3" weight="bold"> Details </SrHeadline>
                </VCol>
            </VRow>
            <VRow>
                <VCol class="pa-0" sm="12">
                    <LineItemStrategyDetailsForm v-model="lineItem" :is-valid.sync="isStrategyFormValid" />
                </VCol>
            </VRow>
        </VCol>
        <VCol lg="7" sm="12" offset-lg="1">
            <VRow>
                <FrequencyCappingList
                    :value="lineItem.frequencyCappings"
                    :is-enabled="lineItem.isFrequencyCappingEnabled"
                    @update="onFrequencyCappingListUpdated($event)"
                />
            </VRow>
            <VRow>
                <UnauthorizedSellingOption v-model="lineItem" />
                <ViewabilityTargeting v-model="lineItem" />
            </VRow>
            <VRow>
                <ConversionPixels v-model="lineItem" :is-edit-form="isEditForm" />
            </VRow>
        </VCol>
    </VRow>
</template>

<script>
import { SrHeadline } from '@ads/design-system';
import ConversionPixels from '@/modules/line-items/components/LineItemForm/LineItemFormStepStrategy/ConversionPixels/ConversionPixels';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import FrequencyCappingList from '@/modules/line-items/components/LineItemForm/LineItemFormStepStrategy/FrequencyCappingList';
import FrequencyCappingValidator from '@/modules/line-items/components/LineItemForm/LineItemFormStepStrategy/FrequencyCappingList/FrequencyCappingValidator';
import ViewabilityTargeting from './ViewabilityTargeting';
import UnauthorizedSellingOption from './UnauthorizedSellingOption';
import LineItemStrategyDetailsForm from './LineItemStrategyDetailsForm/LineItemStrategyDetailsForm';

export default {
    name: 'LineItemFormStepStrategy',
    components: {
        ConversionPixels,
        FrequencyCappingList,
        SrHeadline,
        LineItemStrategyDetailsForm,
        UnauthorizedSellingOption,
        ViewabilityTargeting,
    },
    mixins: [LineItemModel],
    props: {
        value: {
            type: Object,
            required: true,
        },
        isEditForm: {
            type: Boolean,
            default: false,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            lineItem: this.value,
            isStrategyFormValid: this.isValid,
            isFrequencyCappingFormValid: this.isValid,
        };
    },
    computed: {
        areFrequencyCappingsValid() {
            return FrequencyCappingValidator.isListValid(this.lineItem.frequencyCappings);
        },
    },
    watch: {
        isFrequencyCappingFormValid: 'emitValidState',
        isStrategyFormValid: 'emitValidState',
    },
    created() {
        this.isFrequencyCappingFormValid = this.validateFrequencyCappings();
    },
    methods: {
        validateFrequencyCappings() {
            return this.areFrequencyCappingsValid || !this.lineItem.isFrequencyCappingEnabled;
        },
        emitValidState() {
            this.$emit('update:isValid', this.isStrategyFormValid && this.isFrequencyCappingFormValid);
        },
        onFrequencyCappingListUpdated(frequencyCappingEvent) {
            this.lineItem.frequencyCappings = frequencyCappingEvent.frequencyCappings;
            this.lineItem.isFrequencyCappingEnabled = frequencyCappingEvent.isEnabled;
            this.isFrequencyCappingFormValid = this.validateFrequencyCappings();
        },
    },
};
</script>

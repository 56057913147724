import './vuetify-loader.conf.js';

import Vue from 'vue';
import DesignSystem, { vuetify } from '@ads/design-system';
import ChangelogPlugin from "@ads/changelog-component";
import '@/scss/main.scss';
import VueRouter from 'vue-router';
import routes from '@/routes';
import singleSpaVue from 'single-spa-vue';
import { MICROFRONTEND_APPLICATION_CONTAINER_ID } from '@/config';
import { ObserveVisibility } from 'vue-observe-visibility';
import App from './App.vue';
import store from './store';

Vue.directive('observe-visibility', ObserveVisibility);
Vue.use(VueRouter);
Vue.use(DesignSystem);
Vue.use(ChangelogPlugin);

const router = new VueRouter({
    mode: 'history',
    base: '/programmatic-campaigns',
    routes,
});

const vueLifeCycles = singleSpaVue({
    Vue,
    appOptions: {
        vuetify,
        render: (h) => h(App),
        store,
        router,
        el: `#${MICROFRONTEND_APPLICATION_CONTAINER_ID}`,
    },
});

export const bootstrap = vueLifeCycles.bootstrap;
export const mount = vueLifeCycles.mount;
export const unmount = vueLifeCycles.unmount;

import { getValidationResult } from '@/shared/validation/index';
import { ValidationResult, ValidationRule } from '@/shared/validation/types';

export default function getLengthValidationRules(
    property: string,
    minNameLength: number,
    maxNameLength: number,
): ValidationRule[] {
    return [
        (value): ValidationResult =>
            getValidationResult(
                minNameLength === 0 || (value != null && value.toString().length >= minNameLength),
                `${property} should have at least ${minNameLength} characters`,
            ),
        (value): ValidationResult =>
            getValidationResult(
                value != null && value.toString().length <= maxNameLength,
                `${property} should have ${maxNameLength} characters or less`,
            ),
    ];
}

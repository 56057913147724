<template>
    <SrExpansionPanels :panels="panels" :targeting-type-count="targetingTypeCount">
        <template v-for="panel in panels" #[panel.name]>
            <div v-if="panel.component" :key="panel.name">
                <component
                    :is="panel.component"
                    v-model="lineItem"
                    :targeting-key="panel.key"
                    :initial-count="initialCount(panel.key)"
                    @currentTargetingCount="(count, key) => currentTargetingCount(count, key || panel.key)"
                    @changed="(status, key) => setChangedStatus(status, key || panel.key)"
                />
            </div>
            <div v-else :key="panel.name">{{ panel.title }}'s content</div>
        </template>
    </SrExpansionPanels>
</template>

<script>
import SrExpansionPanels from '@/components/SrExpansionPanels';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountDelegation from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountDelegation';
import { panelsConfig } from './technology.config';
import Device from './Device/DeviceOverview';
import BrowserOverview from './Browser/BrowserOverview';

export default {
    name: 'TargetingsTechnology',
    components: {
        SrExpansionPanels,
        Device,
        BrowserOverview,
    },
    mixins: [LineItemModel, TargetingCountDelegation],
    data() {
        return {
            panels: panelsConfig,
            targetingCategory: 'technology',
        };
    },
};
</script>

<template>
    <SelectableTree
        v-model="selectedTree"
        :source-nodes="videoPlayerSizeNodes"
        :mode.sync="mode"
        :form-mode="true"
        :disable-mode-selection="true"
        :is-loading="isLoading"
        @create="navigateToCreateForm"
        @edit="navigateToEditForm"
    />
</template>

<script>
import SelectableTree from '@/components/SelectableTree/SelectableTree';
import { SelectedTree } from '@/components/SelectableTree/SelectedTree';
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import { videoPlayerSizeService } from './service/VideoPlayerSizeService';
import { videoPlayerSizeTransformer } from './VideoPlayerSizeTransformer';

export default {
    name: 'VideoPlayerSize',
    components: { SelectableTree },
    mixins: [LineItemModel, TargetingCountUpdate],
    data() {
        return {
            videoPlayerSizeNodes: [],
            isLoading: false,
            mode: SELECTION_MODE.INCLUDE,
            selectedTree: null,
            videoPlayerSizeService,
            videoPlayerSizeTransformer,
        };
    },
    watch: {
        async $route(_, previousRoute) {
            if (previousRoute.name.includes('video-player-size')) {
                await this.fetchVideoPlayerSizes();
            }
        },
        selectedTree: {
            deep: true,
            handler() {
                if (!this.lineItem.targetings) {
                    this.lineItem.targetings = {};
                }

                const selectedNodes = this.selectedTree.getSelectedNodeTrees();
                this.$set(
                    this.lineItem.targetings,
                    'videoPlayerSizeTargetings',
                    this.videoPlayerSizeTransformer.selectedNodesToVideoPlayerSizeTargetings(selectedNodes),
                );
            },
        },
    },
    created() {
        this.fetchVideoPlayerSizes();
        this.selectedTree = new SelectedTree(this.getSelectedVideoPlayerSizes());
    },
    methods: {
        async fetchVideoPlayerSizes() {
            this.isLoading = true;
            try {
                this.videoPlayerSizeNodes = this.videoPlayerSizeTransformer.videoPlayerSizesToSelectableNodes(
                    await this.videoPlayerSizeService.getAll(),
                );
            } finally {
                this.isLoading = false;
            }
        },
        getSelectedVideoPlayerSizes() {
            if (this.lineItem.targetings?.videoPlayerSizeTargetings?.length > 0) {
                return this.videoPlayerSizeTransformer.videoPlayerSizeTargetingsToSelectedNodes(
                    this.lineItem.targetings.videoPlayerSizeTargetings,
                );
            }
            return [];
        },
        navigateToCreateForm() {
            if (this.lineItem.id) {
                return this.$router.push({
                    name: 'existing-video-player-size-create',
                    params: {
                        id: this.lineItem.id,
                    },
                });
            }

            return this.$router.push({
                name: 'video-player-size-create',
            });
        },
        navigateToEditForm(node) {
            if (this.lineItem.id) {
                return this.$router.push({
                    name: 'existing-video-player-size-update',
                    params: {
                        id: this.lineItem.id,
                        videoPlayerSizeId: node.id,
                    },
                });
            }

            return this.$router.push({
                name: 'video-player-size-update',
                params: {
                    videoPlayerSizeId: node.id,
                },
            });
        },
    },
};
</script>

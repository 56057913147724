import { NODE_SELECTION_STATUS, SelectedNodeTree } from '@/components/SelectableTree/SelectedTree';
import {
    Category,
    CategoryResponse,
    CategoryTargetingRequest,
    CategoryTargetingResponse,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/Category/types';

export class CategoryTransformer {
    categoriesToSelectableNodes(categories: CategoryResponse[]): Category[] {
        return categories.map(
            ({ id, name, code, hasChildren }) =>
                new Category({
                    id,
                    name: `${name} (${code})`,
                    children: hasChildren ? [] : undefined,
                }),
        );
    }

    categoryTargetingsToSelectedNodes(categoryTargetings: CategoryTargetingResponse[]): SelectedNodeTree[] {
        return categoryTargetings.map((categoryTargetingResponse) =>
            this.categoryTargetingToSelectedNode(categoryTargetingResponse),
        );
    }

    private categoryTargetingToSelectedNode(categoryTargetingResponse: CategoryTargetingResponse) {
        const { isExcluded } = categoryTargetingResponse;
        let { category } = categoryTargetingResponse;
        let node = new SelectedNodeTree(
            category.id,
            `${category.name} (${category.code})`,
            isExcluded ? NODE_SELECTION_STATUS.EXCLUDED : NODE_SELECTION_STATUS.INCLUDED,
        );

        while (category.parent) {
            category = category.parent;
            node = new SelectedNodeTree(category.id, `${category.name} (${category.code})`, NODE_SELECTION_STATUS.NONE, null, [
                node,
            ]);
        }

        return node;
    }

    selectedNodesToCategoryTargetings(selectedNodes: SelectedNodeTree[]): CategoryTargetingRequest[] {
        return selectedNodes.map((node) => {
            const isExcluded = node.isExcluded();
            const id = node.getId() as number;
            return {
                category: {
                    id,
                },
                isExcluded,
            };
        });
    }
}

export const categoryTransformer = new CategoryTransformer();

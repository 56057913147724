import state from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Technology/Device/store/state';
import getters from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Technology/Device/store/getters';
import mutations from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Technology/Device/store/mutations';
import actions from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Technology/Device/store/actions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

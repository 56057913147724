<template>
    <VCol cols="12" class="dsp-table">
        <VRow class="mb-1">
            <VCol sm="12" md="6" class="topbar-selected-count">
                <div v-if="selected.length" class="selected-count">
                    {{ selectionCountText }}
                </div>
            </VCol>
            <VCol v-if="hasTableTopActions" sm="12" md="6" class="topbar-actions-wrapper">
                <TableTopActions :disabled="disabledTableActions">
                    <slot name="top-actions" />
                </TableTopActions>
            </VCol>
        </VRow>
        <SrTable
            v-bind="$attrs"
            :value="selected"
            :items="items"
            :headers="headers"
            :striped="false"
            :loading="isLoading"
            :options="options"
            :show-select="showSelect"
            checkbox-color="brand-red"
            show-footer
            @input="inputSelected"
            v-on="$listeners"
        >
            <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>
        </SrTable>
    </VCol>
</template>

<script>
import { SrTable } from '@ads/design-system';
import TableTopActions from '@/components/Table/TableTopActions';

export default {
    components: { TableTopActions, SrTable },
    props: {
        items: {
            type: Array,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        options: {
            type: Object,
            default: () => ({
                sortBy: ['id'],
                sortDesc: [true],
            }),
        },
        showSelect: {
            type: Boolean,
            default: false,
        },
        disabledTableActions: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Array,
            default: () => [],
        },
        entityName: {
            type: String,
            default: '',
        },
    },
    computed: {
        hasTableTopActions() {
            return this.showSelect && this.$scopedSlots['top-actions'];
        },
        selectionCountText() {
            const count = this.selected.length;
            const selectionCountText = `${count} ${this.entityName || 'Selection'}`;
            if (count === 1) {
                return selectionCountText;
            }

            return `${selectionCountText}s`;
        },
    },
    methods: {
        inputSelected(selected) {
            this.$emit('update:selected', selected);
        },
    },
};
</script>

<style lang="scss">
@import '~@ads/design-system/src/scss/variables';

.v-application .dsp-table .v-data-table__checkbox {
    .v-icon.mdi-checkbox-marked,
    .v-input--selection-controls__ripple {
        color: var(--v-brand-red-base) !important;
        caret-color: var(--v-brand-red-base) !important;
    }
}

.dsp-table {
    .topbar-actions-wrapper {
        justify-content: flex-end;
        display: flex;
    }

    .topbar-selected-count {
        display: flex;
        align-items: center;
    }

    .selected-count {
        color: $brand-blue;
        font-size: 14px;
    }
}
</style>

/**
 * Rules / Ideas for naming [resource]-[action]
 */
import CampaignRoutes from '@/modules/campaign/routes';
import LineItemsRoutes from '@/modules/line-items/routes';
import DealsRoutes from '@/modules/deals/routes';

export default [
    {
        path: '/',
        redirect: { name: CampaignRoutes[0].name },
    },
    ...CampaignRoutes,
    ...LineItemsRoutes,
    ...DealsRoutes,
];

<template>
    <SrInput
        outlined
        type="number"
        step="0.00001"
        :required="required"
        :label="label"
        :rules="rules"
        v-bind="$attrs"
        :value="valueWithDecimals"
        @change="onChange"
    />
</template>
<script>
import { SrInput } from '@ads/design-system';
import { getValidationRulesAsArray } from './validator';
import { decimalRepresentationOf } from './utils';

export default {
    name: 'SrGeoCoordinateInput',
    components: { SrInput },
    props: {
        min: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: true,
        },
        precision: {
            type: Number,
            required: true,
        },
        value: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            rules: getValidationRulesAsArray(this.min, this.max, this.precision, this.label),
            valueWithDecimals: '',
        };
    },
    watch: {
        value(value) {
            this.valueWithDecimals = decimalRepresentationOf(value);
        },
    },
    methods: {
        onChange(newValue) {
            this.$emit('input', newValue);
        },
    },
};
</script>

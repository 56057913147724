<template>
    <SrSelect :value="value" :items="items" label="Environment" sort-order="ASC" required searchable @change="onValueChange" />
</template>
<script>
import { SrSelect } from '@ads/design-system';
import DealEnvironment from 'api-contracts/deals/DealEnvironment';

export default {
    name: 'DealEnvironmentSelect',
    components: { SrSelect },
    props: {
        value: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const items = Object.values(DealEnvironment);
        const onValueChange = (value) => emit('input', value);
        return {
            items,
            onValueChange,
        };
    },
};
</script>

import { ACTION_NAME, ActionValidationMiddleware, IAction, IMarketingCloudUser, SportradarResourceName } from '@ads/iam-library';
import { createActionValidator } from '@ads/design-system';

export default {
    computed: {
        loggedInUser(): IMarketingCloudUser {
            return this.$root.user;
        },
        isLoggedInAsAdmin(): boolean {
            return this.loggedInUser?.isAdmin() ?? false;
        },
    },
    methods: {
        // for domain-, application-name-, publisher- and bundle-list
        canEditSharedList(): Promise<boolean> {
            return this.canEditList(null);
        },
        // for domain-, application-name-, publisher- and bundle-list
        async canEditList(holdingId: number | null): Promise<boolean> {
            return this.canExecuteAction({
                domainName: 'programmatic-campaigns',
                resourceType: 'line-item',
                holdingId: holdingId || SportradarResourceName.sharedIdentifier,
                businessEntityId: holdingId ? null : SportradarResourceName.sharedIdentifier,
                userId: this.loggedInUser.getCognitoId(),
                actionName: ACTION_NAME.EDIT,
            });
        },
        async canExecuteAction(action: IAction): Promise<boolean> {
            const actionValidator: ActionValidationMiddleware = await createActionValidator(this.loggedInUser);
            return actionValidator.validateAction(action);
        },
    },
};

<template>
    <VDialog
        v-model="isOpen"
        :persistent="shouldPersistOnClickOutside"
        :max-width="maxWidth"
        :attach="selectorForElementToAttachDialogTo"
    >
        <VCard>
            <VProgressLinear v-if="isLoading" indeterminate data-testid="LoadingBar" />

            <VCardTitle>
                <SrHeadline level="1" weight="bold" class="mt-0 mb-0">
                    {{ title }}
                </SrHeadline>
            </VCardTitle>

            <VCardText>
                <VCardText v-if="description" class="m-0 pa-0">
                    {{ description }}
                </VCardText>
                <VContainer class="m-0 pa-0">
                    <slot name="content" />
                </VContainer>
            </VCardText>

            <VCardText v-if="error">
                <ErrorBox class="mb-0" :show="Boolean(error)" :message="error" />
            </VCardText>

            <VDivider />

            <VCardActions>
                <VSpacer />
                <SrButton :disabled="shouldDisableCancelButton" data-testid="ActionModal__cancel_button" @click="handleCancel">
                    Cancel
                </SrButton>
                <slot name="actions" />
            </VCardActions>
        </VCard>
    </VDialog>
</template>

<script>
import { SrButton, SrHeadline } from '@ads/design-system';
import ErrorBox from '@/components/ErrorBox';
import { PROGRAMMATIC_CAMPAIGNS_CONTAINER_ID } from '@/config';

export default {
    name: 'ActionModal',
    components: {
        SrButton,
        SrHeadline,
        ErrorBox,
    },
    props: {
        /**
         * @model is modal open
         */
        value: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        shouldCloseOnClickOutsideOrEsc: {
            type: Boolean,
            default: true,
        },
        isCancelButtonDisabled: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: Number,
            default: 500,
        },
    },
    data() {
        return {
            isOpen: this.value,
            selectorForElementToAttachDialogTo: `#${PROGRAMMATIC_CAMPAIGNS_CONTAINER_ID}`,
        };
    },
    computed: {
        shouldDisableCancelButton() {
            return this.isCancelButtonDisabled || this.isLoading;
        },
        shouldPersistOnClickOutside() {
            return this.isLoading || !this.shouldCloseOnClickOutsideOrEsc;
        },
    },
    watch: {
        value(newValue) {
            this.isOpen = newValue;
        },
        isOpen() {
            this.emitIsOpenChange();
        },
    },
    methods: {
        handleCancel() {
            if (!this.isLoading) {
                this.$emit('cancel');
            }
        },
        emitIsOpenChange() {
            this.$emit('input', this.isOpen);
        },
    },
};
</script>

import { FrequencyCapping } from '@/modules/line-items/types';
import FrequencyCappingValidator from '@/modules/line-items/components/LineItemForm/LineItemFormStepStrategy/FrequencyCappingList/FrequencyCappingValidator';
import { frequencyCappingDefaultPeriod } from '@/components/FrequencyCappingForm/types';

export default class FrequencyCappings {
    private values: FrequencyCapping[];

    constructor(frequencyCappings: FrequencyCapping[] = []) {
        this.values = frequencyCappings?.map((ele) => ({ ...ele }));
    }

    add(frequencyCapping: FrequencyCapping): FrequencyCappings {
        this.values.push(frequencyCapping);
        return this;
    }

    addEmpty(): FrequencyCappings {
        this.add({
            cappingLimit: null,
            timeValue: null,
            timeUnit: frequencyCappingDefaultPeriod,
        });
        return this;
    }

    update(frequencyCapping: FrequencyCapping, index: number): FrequencyCappings {
        this.values.splice(index, 1, frequencyCapping);
        return this;
    }

    remove(index: number): FrequencyCappings {
        this.values.splice(index, 1);
        return this;
    }

    removeInvalid(): FrequencyCappings {
        this.values = this.values.filter((frequencyCapping) => FrequencyCappingValidator.isValid(frequencyCapping));
        return this;
    }

    getCopyOfValues(): FrequencyCapping[] {
        return this.values.map((ele) => ({ ...ele }));
    }

    isEmpty(): boolean {
        return this.values.length === 0;
    }

    getValues(): FrequencyCapping[] {
        return this.values;
    }
}

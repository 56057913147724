import FeatureFlagService from '@/modules/feature-flags/FeatureFlagService';
import { RequestService } from '@ads/iam-library';

export default {
    async loadFeatureFlags({ commit }, requestService: RequestService): Promise<void> {
        const featureFlagService = new FeatureFlagService(requestService);
        const featureFlags = await featureFlagService.loadFeatureFlags();
        commit('setFeatureFlags', featureFlags);
    },

    async initialize({ dispatch }, requestService: RequestService): Promise<void> {
        await dispatch('loadFeatureFlags', requestService);
    },
};

export const availableCreativesHeaders = [
    {
        text: 'DSP ID',
        value: 'id',
        align: 'center',
        sortable: false,
    },
    {
        text: 'Name',
        value: 'name',
        sortable: false,
    },
    {
        text: 'Size',
        value: 'size.name',
        width: '80px',
        sortable: false,
    },
    {
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '80px',
        sortable: false,
    },
];

export const selectedCreativesHeaders = [
    {
        text: 'DSP ID',
        value: 'id',
        align: 'center',
        sortable: true,
    },
    {
        text: 'Name',
        value: 'name',
        sortable: true,
    },
    {
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '80px',
        sortable: false,
    },
];

<template>
    <div>
        <Device v-model="lineItem" />
        <br />
        <DeviceType v-model="lineItem" />
    </div>
</template>

<script>
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import TargetingCountUpdate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/TargetingCountUpdate';
import DeviceType from './DeviceType';
import Device from './Device';

export default {
    name: 'DeviceOverview',
    components: { DeviceType, Device },
    mixins: [LineItemModel, TargetingCountUpdate],
};
</script>

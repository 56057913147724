<template>
    <VForm ref="strategyFieldsForm" :key="lineItem.strategyId" v-model="formIsValid">
        <SrSelect
            v-model="lineItem.strategyId"
            label="Strategy Name"
            :items="selectableStrategies"
            item-value="dspId"
            item-text="name"
            :rules="strategyRules"
            required
            hide-details
            data-testid="StrategyForm__strategy"
        />

        <div v-if="description" class="description-container">
            <p>{{ description }}</p>
        </div>

        <template v-if="hasCurrency">
            <StrategyFormField
                v-for="field in formFields"
                :key="field.key"
                class="strategy-form-field"
                :strategy-config="lineItem.strategyConfig"
                :field="field"
                :currency="currency"
                @input="updateStrategyConfig(field.key, $event)"
            />
        </template>
    </VForm>
</template>

<script>
import StrategyFormField from '@/modules/line-items/components/LineItemForm/LineItemFormStepStrategy/LineItemStrategyDetailsForm/StrategyForm/StrategyFormField';
import { SrSelect } from '@ads/design-system';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import AvailableStrategies from 'api-contracts/line-items/strategies/AvailableStrategies';

export default {
    name: 'StrategyForm',
    components: { SrSelect, StrategyFormField },
    mixins: [LineItemModel],
    props: {
        isValid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formIsValid: this.isValid,
            strategyRules: [(v) => Number.isInteger(v)],
            strategies: this.getAvailableStrategies(),
            firstSelectedStrategy: this.getInitialStrategy(),
        };
    },
    computed: {
        selectableStrategies() {
            if (this.firstSelectedStrategy?.deprecated) {
                return [...this.nonDeprecatedStrategies, this.firstSelectedStrategy];
            }
            return this.nonDeprecatedStrategies;
        },
        nonDeprecatedStrategies() {
            return this.strategies.filter((strategy) => !strategy.deprecated);
        },
        selectedStrategy() {
            return this.strategies.find((strategy) => strategy.dspId === this.lineItem.strategyId);
        },
        description() {
            return this.selectedStrategy?.description ?? '';
        },
        formFields() {
            return this.selectedStrategy?.formFields ?? [];
        },
        currency() {
            if (this.lineItem) {
                return this.lineItem.campaign.currencyCode;
            }
            return null;
        },
        hasCurrency() {
            return this.currency !== null && this.currency !== undefined;
        },
    },
    watch: {
        isValid(isValid) {
            this.formIsValid = isValid && this.hasCurrency;
        },
        formIsValid(isValid) {
            this.emitFormIsValid(isValid);
        },
        'lineItem.strategyId': async function () {
            if (this.selectedStrategy && this.firstSelectedStrategy == null) {
                this.firstSelectedStrategy = this.selectedStrategy;
                return;
            }
            await this.validateStrategyFields();
        },
    },
    mounted() {
        if (this.lineItem.strategyId != null) {
            this.validateStrategyFields();
        }
    },
    methods: {
        emitFormIsValid(isValid) {
            this.$emit('update:isValid', isValid);
        },
        updateStrategyConfig(fieldKey, newFieldValue) {
            this.$set(this.lineItem.strategyConfig, fieldKey, newFieldValue);
        },
        getAvailableStrategies() {
            return AvailableStrategies.getAll().map((strategy) => ({
                ...strategy,
                name: this.getStrategyName(strategy),
            }));
        },
        getInitialStrategy() {
            return AvailableStrategies.getByDspId(this.value?.strategyId);
        },
        getStrategyName(strategy) {
            if (strategy.deprecated) {
                return `${strategy.name} (deprecated)`;
            }
            return strategy.name;
        },
        async validateStrategyFields() {
            await this.$nextTick();
            this.$refs.strategyFieldsForm.validate();
        },
    },
};
</script>

<style scoped>
.description-container {
    background-color: #e4f3ff;
    color: #3046b6;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    padding: 15px 13px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.description-container p {
    margin: 0;
    padding: 0;
}

.strategy-form-field {
    margin-bottom: 10px;
}
</style>

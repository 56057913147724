import { ACTION_NAME } from '@ads/iam-library';

const domainName = 'programmatic-campaigns';

const campaignResourceType = 'campaign';
const lineItemResourceType = 'line-item';
const dealResourceType = 'deal';

const baseCampaignPermission = { domainName, resourceType: campaignResourceType };

export const canCreateCampaigns = { ...baseCampaignPermission, actionName: ACTION_NAME.CREATE };
export const canReadCampaigns = { ...baseCampaignPermission, actionName: ACTION_NAME.READ };
export const canUpdateCampaigns = { ...baseCampaignPermission, actionName: ACTION_NAME.EDIT };
export const canDeleteCampaigns = { ...baseCampaignPermission, actionName: ACTION_NAME.REMOVE };

const baseLineItemPermission = { domainName, resourceType: lineItemResourceType };

export const canCreateLineItems = { ...baseLineItemPermission, actionName: ACTION_NAME.CREATE };
export const canReadLineItems = { ...baseLineItemPermission, actionName: ACTION_NAME.READ };
export const canUpdateLineItems = { ...baseLineItemPermission, actionName: ACTION_NAME.EDIT };
export const canDeleteLineItems = { ...baseLineItemPermission, actionName: ACTION_NAME.REMOVE };

const baseDealPermission = { domainName, resourceType: dealResourceType };

export const canCreateDeals = { ...baseDealPermission, actionName: ACTION_NAME.CREATE };
export const canReadDeals = { ...baseDealPermission, actionName: ACTION_NAME.READ };
export const canUpdateDeals = { ...baseDealPermission, actionName: ACTION_NAME.EDIT };
export const canDeleteDeals = { ...baseDealPermission, actionName: ACTION_NAME.REMOVE };

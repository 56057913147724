<template>
    <div class="node selected-node-item">
        <div class="node-info">
            <div class="node-text">
                <div v-if="selectedNode.isSelected()" class="selection-status">
                    <div :class="['badge', selectedNode.isExcluded() ? 'excluded' : '']">
                        <VIcon v-if="selectedNode.isIncluded()" x-small color="white"> mdi-check-bold </VIcon>
                        <VIcon v-else x-small color="red"> mdi-close </VIcon>
                    </div>
                    <div>
                        {{ selectedNode.isIncluded() ? 'Included' : 'Excluded' }}
                    </div>
                </div>
                <div :class="['node-title', selectedNode.isSelected() ? 'selected' : '']">
                    {{ selectedNode.getName() }}
                </div>
            </div>
            <div v-if="formMode" class="node-edit">
                <VBtn class="edit-application-name-list" icon :disabled="!isEditable" @click="edit">
                    <SrIcon icon="edit" size="xs" :disabled="!isEditable" />
                </VBtn>
            </div>
            <div v-if="selectedNode.isSelected()" class="node-remove">
                <button class="mc-user-input" @click="removeNode()">
                    <VIcon small color="$gray-light"> mdi-close-circle </VIcon>
                </button>
            </div>
        </div>
        <div v-if="selectedNode.getChildren().length" class="node-children">
            <div v-for="child in selectedNode.getChildren()" :key="child.getId()" class="node-child">
                <SelectedTreeNode :selected-node="child" :selected-tree="selectedTree" :mode="mode" />
            </div>
        </div>
    </div>
</template>

<script>
import { SelectedNodeTree } from '@/components/SelectableTree/SelectedTree';
import { SrIcon } from '@ads/design-system';

export default {
    name: 'SelectedTreeNode',
    components: {
        SrIcon,
    },
    props: {
        selectedNode: {
            type: SelectedNodeTree,
            default: () => ({}),
        },
        selectedTree: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            required: true,
        },
        formMode: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isEditable() {
            const isEditable = this.selectedNode.getDetails()?.isEditable;
            if (typeof isEditable === 'boolean') {
                return isEditable;
            }
            return true;
        },
    },
    methods: {
        removeNode() {
            this.selectedTree.deselectNode(this.selectedNode);
        },
        edit() {
            this.$emit('edit', this.selectedNode);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@ads/design-system/src/scss/variables';

.node-text {
    flex: 1 1 auto;
}

.node-edit {
    margin-top: -10px;
    height: 26px;
}
.node-remove {
    margin-left: 10px;
    height: 26px;
}

.node {
    padding: 6px 0 0 28px;
    min-height: 38px;
}

.node-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
}

.selection-status {
    margin-bottom: -5px;
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #a1a1a1;
}

.node-title {
    font-size: 14px;
    color: #a1a1a1;

    &.selected {
        color: $brand-blue;
    }
}

.badge {
    display: flex;
    justify-content: center;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border-width: 1px;
    margin-right: 2px;
    background-color: $blue;

    &.excluded {
        background-color: $red-light;
    }

    ::v-deep .v-icon {
        line-height: 8px;
        font-size: 6px !important;
    }
}
.node-children {
    width: 100%;
}

.edit-application-name-list {
    display: none;
}

.selected-node-item:hover {
    .edit-application-name-list {
        display: block;
    }
}
</style>

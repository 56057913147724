import { fanIdSegmentService } from '@/modules/fan-id/services/FanIdSegmentService';

const actions = {
    async fetchFanIdSegments({ state, commit }): Promise<void> {
        commit('setFanIdSegmentsFetchErrorMessage', null);
        commit('setIsLoadingFanIdSegments', true);
        try {
            if (state.fanIdSegments.length === 0) {
                const fanIdSegments = await fanIdSegmentService.getFanIdSegments();
                commit('setFanIdSegments', fanIdSegments);
            }
        } catch (error) {
            commit(
                'setFanIdSegmentsFetchErrorMessage',
                'Could not load FanID segments. Please try reloading the page and if the issue persists, contact support.',
            );
        } finally {
            commit('setIsLoadingFanIdSegments', false);
        }
    },
};

export default actions;

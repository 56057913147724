import Vue from 'vue';
import Vuex from 'vuex';

import { RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import iam from './modules/iam/store';

import config from './modules/config/store';
import campaign from './modules/campaign/store';
import lineItems from './modules/line-items/store';
import deal from './modules/deals/store';
import ssp from './modules/ssp/store';
import geo from './modules/geo/store';
import contextual from './modules/contextual/store';
import fanId from './modules/fan-id/store';
import featureFlags from './modules/feature-flags/store';

Vue.use(Vuex);

const modules = {
    campaign,
    iam,
    config,
    lineItems,
    deal,
    ssp,
    geo,
    contextual,
    fanId,
    featureFlags,
};

const store = new Vuex.Store({
    modules,
    actions: {
        initialize({ dispatch, commit }): void {
            const requestService = new RequestService({ baseUrl: API_URL });
            for (const moduleName of Object.keys(modules)) {
                if (modules[moduleName].actions?.initialize) {
                    dispatch(`${moduleName}/initialize`, requestService).catch((error) => {
                        console.error(`Failed to initialize module '${moduleName}': ${error}`);
                    });
                    commit('setIsInitialized', true);
                }
            }
        },
    },
});

export default store;

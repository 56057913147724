import { NODE_SELECTION_STATUS, SelectedNodeTree } from '@/components/SelectableTree/SelectedTree';
import IDeviceTargetingResponse from 'api-contracts/line-items/targetings/devices/IDeviceTargetingResponse';

export default class SelectedTreeNodesTransformer {
    transform(deviceTargetings: IDeviceTargetingResponse[]): SelectedNodeTree[] {
        if (!deviceTargetings) {
            return [];
        }
        const selectedManufacturerNodes = this.getSelectedManufacturerNodes(deviceTargetings);
        const selectedDeviceNodes = this.getSelectedDevicesNodes(deviceTargetings);

        return [...selectedManufacturerNodes, ...selectedDeviceNodes];
    }

    private getSelectedManufacturerNodes(deviceTargetings: IDeviceTargetingResponse[]): SelectedNodeTree[] {
        const manufacturers = {};
        deviceTargetings.forEach((deviceTargeting) => {
            const manufacturer = deviceTargeting.deviceManufacturer?.id;
            if (manufacturer) {
                const currentDevices = manufacturers[manufacturer] || [];
                currentDevices.push(deviceTargeting);
                manufacturers[manufacturer] = currentDevices;
            }
        });

        return Object.keys(manufacturers).map(
            (deviceManufacturer) =>
                new SelectedNodeTree(
                    deviceManufacturer,
                    deviceManufacturer,
                    manufacturers[deviceManufacturer][0].isExcluded
                        ? NODE_SELECTION_STATUS.EXCLUDED
                        : NODE_SELECTION_STATUS.INCLUDED,
                    null,
                    [],
                ),
        );
    }

    private getSelectedDevicesNodes(deviceTargetings: IDeviceTargetingResponse[]): SelectedNodeTree[] {
        const devices = {};
        deviceTargetings.forEach((deviceTargeting) => {
            const device = deviceTargeting.device?.deviceManufacturer.id;
            if (device) {
                const currentDevices = devices[device] || [];
                currentDevices.push(deviceTargeting);
                devices[device] = currentDevices;
            }
        });

        return Object.keys(devices).map((deviceManufacturer) => {
            const childTreeNodes = devices[deviceManufacturer].map(
                (deviceTargeting) =>
                    new SelectedNodeTree(
                        deviceTargeting.device.id,
                        deviceTargeting.device.name,
                        deviceTargeting.isExcluded ? NODE_SELECTION_STATUS.EXCLUDED : NODE_SELECTION_STATUS.INCLUDED,
                        null,
                        [],
                    ),
            );
            return new SelectedNodeTree(deviceManufacturer, deviceManufacturer, NODE_SELECTION_STATUS.NONE, null, childTreeNodes);
        });
    }
}

import { ContextualState } from '@/modules/contextual/store/ContextualState';
import IContextualCategoryResponse from 'api-contracts/contextual/IContextualCategoryResponse';

const mutations = {
    setContextualCategories(state: ContextualState, contextualCategories: IContextualCategoryResponse[]): void {
        state.contextualCategories = contextualCategories;
    },
    setIsLoadingContextualCategories(state: ContextualState, isLoading: boolean): void {
        state.isLoadingContextualCategories = isLoading;
    },
    setContextualCategoriesFetchErrorMessage(state: ContextualState, errorMessage: string | null): void {
        state.contextualCategoriesFetchErrorMessage = errorMessage;
    },
};

export default mutations;

import { Runtime } from '@/modules/campaign/types';
import { differenceInDays, formatDuration, intervalToDuration, startOfDay, startOfToday } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { USER_LOCALE } from '@/config';

export default class HumanReadableRuntimeViewModel {
    public formattedEndDate: string;

    private runtime: Runtime;

    constructor(runtime: Runtime) {
        this.initializeWith(runtime);
    }

    public initializeWith(runtime: Runtime): void {
        this.runtime = runtime;
        this.formattedEndDate = this.formatEndDateFrom(this.runtime);
    }

    public isEndingToday(): boolean {
        return this.getDaysRemaining() === 0;
    }

    public isEndingTomorrow(): boolean {
        return this.getDaysRemaining() === 1;
    }

    public daysRemainingInHumanReadableFormat(): string {
        const daysRemainingInMilliseconds = this.getDaysRemaining() * 24 * 60 * 60 * 1000;

        const duration = intervalToDuration({
            start: Date.now(),
            end: Date.now() + daysRemainingInMilliseconds,
        });

        return formatDuration(duration, { format: ['years', 'months', 'days'] });
    }

    private getDaysRemaining(): number {
        const today = startOfToday();
        const endDate = startOfDay(this.getZonedEndDate(this.runtime));

        return differenceInDays(endDate, today);
    }

    private formatEndDateFrom(runtime: Runtime): string {
        const endDate = this.getZonedEndDate(runtime);
        return Intl.DateTimeFormat(USER_LOCALE).format(endDate);
    }

    private getZonedEndDate(runtime: Runtime): Date {
        return utcToZonedTime(runtime.endDate, runtime.timeZone);
    }
}

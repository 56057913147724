import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import IConfigResponse from 'api-contracts/config/IConfigResponse';

export default class ConfigService {
    private readonly requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async loadConfig(): Promise<IConfigResponse> {
        const options: IRequestOptions = {
            domain: 'programmatic-campaigns',
            resource: 'config',
            action: ACTION_NAME.READ,
        };

        return this.requestService.get('/config', options);
    }
}

export default {
    setCampaigns(state, campaigns) {
        state.campaigns = campaigns;
    },
    setChangelog(state, changelog) {
        state.changelog = changelog;
    },
    updateCampaignsBudgetAndRuntime(
        state,
        payload: { campaignId: number; newBudget: number; newEndDate: string; newStatus: string },
    ) {
        const campaignToUpdate = state.campaigns.find((campaign) => campaign.id === payload.campaignId);
        campaignToUpdate.budget.price.value = payload.newBudget;
        campaignToUpdate.runtime.endDate = payload.newEndDate;
        campaignToUpdate.runtime.status = payload.newStatus;
    },
};

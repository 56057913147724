import ISspResponse from 'api-contracts/ssps/ISspResponse';
import INetworkResponse from 'api-contracts/ssps/INetworkResponse';
import { SSPS } from '@/modules/ssp/types';

type SelectableItem = {
    value: number;
    label: string;
};

function transformToSelectableItem(sspOrNetwork: ISspResponse | INetworkResponse): SelectableItem {
    return {
        value: sspOrNetwork.id,
        label: sspOrNetwork.name,
    };
}

export default {
    networks: (state): INetworkResponse[] => Object.values<INetworkResponse[]>(state.networksBySspId).flat(),
    selectableSsps: (state): SelectableItem[] => state.ssps.map(transformToSelectableItem),
    selectableNetworks: (state, getters): SelectableItem[] => getters.networks.map(transformToSelectableItem),
    selectableNetworksOfSsp:
        (state): ((sspId: SSPS) => SelectableItem[]) =>
        (sspId: SSPS): SelectableItem[] =>
            state.networksBySspId[sspId].map(transformToSelectableItem),
};

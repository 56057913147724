<template>
    <DealDeleteModal
        :deal="deal"
        :line-items="lineItems"
        :is-loading-deal="isLoadingDeal"
        :is-loading-line-items="isLoadingLineItems"
        @cancel="goToDealsList"
        @completed="onDeletionCompleted"
    />
</template>

<script>
import { dealService } from '@/modules/deals/services/DealService';
import createEmptyDeal from '@/modules/deals/views/createEmptyDeal';
import DealDeleteModal from '@/modules/deals/components/DealDeleteModal';
import { lineItemsService } from '@/modules/line-items/services/LineItemsService';

export default {
    name: 'DealEdit',
    components: {
        DealDeleteModal,
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            deal: createEmptyDeal(),
            lineItems: [],
            errorMessage: null,
            isLoadingDeal: false,
            isLoadingLineItems: false,
        };
    },
    async created() {
        await Promise.all([this.loadDeal(), this.loadLineItems()]);
    },
    methods: {
        async loadDeal() {
            this.isLoadingDeal = true;
            try {
                this.deal = await dealService.getById(this.id);
            } catch (error) {
                this.errorMessage = error.response.message;
            }
            this.isLoadingDeal = false;
        },
        async loadLineItems() {
            this.isLoadingLineItems = true;
            try {
                this.lineItems = await lineItemsService.getLineItemsByDealId(this.id);
            } catch (error) {
                this.errorMessage = error.response.message;
            } finally {
                this.isLoadingLineItems = false;
            }
        },
        async onDeletionCompleted() {
            this.goToDealsList();
        },
        goToDealsList() {
            this.$router.push({
                name: 'deals-list',
            });
        },
    },
};
</script>

export type Deal = {
    id: number;
    dspId?: number;
    name: string;
    description: string;
    externalId: string;
    environment: string;
    format: string | null;
    source: string;
    holding: { id: number };
    ssp: { id: number };
    network: { id: number };
    countries: { id: number }[];
};

export type DealStoreState = {
    deals: Deal[];
};

export enum DEALS_LOADING_STATES {
    DEALS = 'deals',
}

export default {
    deals: [],
};

<template>
    <SrModalPageForm
        :show.sync="show"
        :steps="stepMetaData"
        :tabs="isEditForm"
        :loading="isLoading"
        :submit-enabled="!Boolean(errorMessage)"
        :show-submit-button="shouldShowSubmitButton"
        @close="close"
        @submit="submit"
        @input="stepChange"
    >
        <SrModalPageStep step="1" :title="steps[0].name">
            <CampaignFormStepBasic v-model="campaign" :steps="steps" :is-valid.sync="steps[0].isValid" />
        </SrModalPageStep>
        <SrModalPageStep step="2" :title="steps[1].name">
            <CampaignFormStepRuntime v-model="campaign" :is-valid.sync="steps[1].isValid" />
        </SrModalPageStep>
        <SrModalPageStep step="3" :title="steps[2].name">
            <CampaignFormStepBudget
                :value="campaign"
                :is-valid.sync="steps[2].isValid"
                :spent-budget="spentBudget"
                @budgetPriceChange="updateBudgetPrice"
                @pacingChange="updatePacing"
            />
        </SrModalPageStep>

        <ErrorBox :show="Boolean(errorMessage)" :message="errorMessage" />
    </SrModalPageForm>
</template>

<script>
import ErrorBox from '@/components/ErrorBox';
import BasicForm from '@/components/Form/BasicForm';
import { createActionValidator, SrModalPageForm, SrModalPageStep } from '@ads/design-system';
import CampaignFormStepBasic from '@/modules/campaign/components/CampaignForm/CampaignFormStepBasic';
import CampaignFormStepBudget from '@/modules/campaign/components/CampaignForm/CampaignFormStepBudget';
import CampaignFormStepRuntime from '@/modules/campaign/components/CampaignForm/CampaignFormStepRuntime';
import { canCreateCampaigns, canUpdateCampaigns } from '@/permissions';
import { formSteps } from './config';

export default {
    name: 'CampaignForm',
    components: {
        ErrorBox,
        CampaignFormStepRuntime,
        CampaignFormStepBudget,
        CampaignFormStepBasic,
        SrModalPageForm,
        SrModalPageStep,
    },
    extends: BasicForm,
    data() {
        return {
            errorMessage: null,
            show: true,
            campaign: this.value,
            steps: formSteps,
            userPermittedToCreateCampaign: true,
            userPermittedToEditCampaign: true,
        };
    },
    computed: {
        stepMetaData() {
            return [
                { name: this.steps[0].name, canContinue: this.steps[0].isValid },
                { name: this.steps[1].name, canContinue: this.steps[1].isValid },
                { name: this.steps[2].name, canContinue: this.steps[2].isValid },
            ];
        },
        shouldShowSubmitButton() {
            return this.isEditForm ? this.userPermittedToEditCampaign : this.userPermittedToCreateCampaign;
        },
        spentBudget() {
            return this.campaign.lineItemsBudgetOverview?.spentByLineItems;
        },
    },
    watch: {
        campaign: {
            handler() {
                this.$emit('input', this.campaign);
                this.errorMessage = null;
            },
            deep: true,
        },
        value(campaign) {
            this.campaign = campaign;
        },
    },
    async created() {
        await this.validateUserPermissions();
    },
    methods: {
        stepChange() {
            this.errorMessage = null;
        },
        updateBudgetPrice(newBudget) {
            this.campaign.budget.price.value = newBudget;
        },
        updatePacing(newPacing) {
            this.campaign.pacing = newPacing;
        },
        async save() {
            return this.$store.dispatch(this.submitAction, this.campaign);
        },
        async validateUserPermissions() {
            const actionValidator = await createActionValidator(this.$root.user);
            this.userPermittedToCreateCampaign = await actionValidator.validateAction({
                ...canCreateCampaigns,
                userId: this.$root.user.getCognitoId(),
            });
            this.userPermittedToEditCampaign = await actionValidator.validateAction({
                ...canUpdateCampaigns,
                userId: this.$root.user.getCognitoId(),
            });
        },
    },
};
</script>

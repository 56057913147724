import KPI_TYPE from 'api-contracts/campaigns/kpis/KpiType';

type KpiTargetDataType = 'currency' | 'percentage';

type KpiType = {
    name: string;
    value: KPI_TYPE;
    targetDataType: KpiTargetDataType;
};

export default class KpiTypes {
    private static kpiTypes: KpiType[] = [
        { value: KPI_TYPE.BET_PLACED, name: 'Bet placed first time', targetDataType: 'currency' },
        { value: KPI_TYPE.CPC, name: 'Cost per click (CPC)', targetDataType: 'currency' },
        { value: KPI_TYPE.CPCV, name: 'Cost per completed view (CPCV)', targetDataType: 'currency' },
        { value: KPI_TYPE.CPI, name: 'Cost per install (CPI)', targetDataType: 'currency' },
        { value: KPI_TYPE.CPM, name: 'Cost per mille (CPM)', targetDataType: 'currency' },
        { value: KPI_TYPE.CTR, name: 'Click-through rate (CTR)', targetDataType: 'percentage' },
        { value: KPI_TYPE.FTD, name: 'First time deposit (FTD)', targetDataType: 'currency' },
        { value: KPI_TYPE.REC_DEP, name: 'Recurring deposit (REC DEP)', targetDataType: 'currency' },
        { value: KPI_TYPE.REGF, name: 'Registration finished (REGF)', targetDataType: 'currency' },
        { value: KPI_TYPE.REGS, name: 'Registration started (REGS)', targetDataType: 'currency' },
        { value: KPI_TYPE.VIEWABILITY, name: 'Viewability', targetDataType: 'percentage' },
        { value: KPI_TYPE.VCR, name: 'Video completion rate (VCR)', targetDataType: 'percentage' },
        { value: KPI_TYPE.NONE, name: 'None', targetDataType: null },
    ];

    public static getAll(): KpiType[] {
        return this.kpiTypes;
    }

    public static getTargetDataTypeOf(kpiType: KPI_TYPE): KpiTargetDataType | null {
        const dataType = this.kpiTypes.find((kpi) => kpi.value === kpiType);
        if (dataType === undefined) {
            return null;
        }
        return dataType.targetDataType;
    }
}

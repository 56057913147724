<template>
    <div class="frequency-capping-row">
        <SrInput
            :value="frequencyCapping.cappingLimit"
            :disabled="disabled"
            :rules="rules.frequencyCappingLimit"
            required
            label="Enter Number"
            type="number"
            class="frequency-capping-limit-input"
            @input="updateCappingLimit($event)"
        />
        <div class="d-flex justify-center pa-0 mt-4 frequency-capping-label">
            <SrHeadline level="3" class="no-wrap"> ads within </SrHeadline>
        </div>
        <SrInput
            :value="frequencyCapping.timeValue"
            :rules="rules.frequencyCappingTimeValue"
            :disabled="disabled"
            required
            label="Enter Number"
            type="number"
            class="frequency-capping-time-value-input mr-4"
            @input="updateTimeValue($event)"
        />
        <SrSelect
            :value="frequencyCapping.timeUnit"
            :disabled="disabled"
            label="Time unit"
            :items="timeUnits"
            class="frequency-capping-time-unit-select"
            required
            @change="updateTimeUnit($event)"
        />
    </div>
</template>

<script>
import { SrHeadline, SrInput, SrSelect } from '@ads/design-system';
import { frequencyCappingDefaultPeriod } from '@/components/FrequencyCappingForm/types';
import FREQUENCY_CAPPING_TIME_UNIT from 'api-contracts/value-objects/FrequencyCappingTimeUnit';

export default {
    components: { SrInput, SrSelect, SrHeadline },
    props: {
        frequencyCapping: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rules: {
                frequencyCappingLimit: [(v) => v >= 1 || 'Capping limit is required'],
                frequencyCappingTimeValue: [(v) => v >= 1 || 'Capping time value is required'],
            },
            timeUnits: Object.values(FREQUENCY_CAPPING_TIME_UNIT),
        };
    },
    created() {
        if (!this.frequencyCapping.timeUnit) {
            this.updateTimeUnit(frequencyCappingDefaultPeriod);
        }
    },
    methods: {
        updateCappingLimit(newCappingLimit) {
            const updatedFrequencyCapping = {
                ...this.frequencyCapping,
                cappingLimit: Number(newCappingLimit),
            };
            this.emitUpdateEvent(updatedFrequencyCapping);
        },
        updateTimeValue(newTimeValue) {
            const updatedFrequencyCapping = {
                ...this.frequencyCapping,
                timeValue: Number(newTimeValue),
            };
            this.emitUpdateEvent(updatedFrequencyCapping);
        },
        updateTimeUnit(newTimeUnit) {
            const updatedFrequencyCapping = {
                ...this.frequencyCapping,
                timeUnit: newTimeUnit,
            };
            this.emitUpdateEvent(updatedFrequencyCapping);
        },
        emitUpdateEvent(updatedFrequencyCapping) {
            this.$emit('update', updatedFrequencyCapping);
        },
    },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.frequency-capping {
    &-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    @media #{map-get($display-breakpoints, 'xl-only')} {
        &-limit-input,
        &-time-value-input {
            flex-basis: 35%;
        }
        &-time-unit-select {
            flex-basis: 20%;
        }
        &-label {
            flex-basis: 10%;
        }
    }

    @media #{map-get($display-breakpoints, 'lg-only')} {
        &-limit-input,
        &-time-value-input {
            flex-basis: 30%;
        }
        &-time-unit-select,
        &-label {
            flex-basis: 20%;
        }
    }

    @media #{map-get($display-breakpoints, 'md-and-down')} {
        &-limit-input,
        &-time-value-input,
        &-time-unit-select,
        &-label {
            flex-basis: 25%;
        }
    }
}
</style>

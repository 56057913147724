import { render, staticRenderFns } from "./PixelSegmentTemplates.vue?vue&type=template&id=24adfbbf"
import script from "./PixelSegmentTemplates.vue?vue&type=script&lang=js"
export * from "./PixelSegmentTemplates.vue?vue&type=script&lang=js"
import style0 from "./PixelSegmentTemplates.vue?vue&type=style&index=0&id=24adfbbf&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VRadioGroup})

import { contextualCategoryService } from '@/modules/contextual/services/ContextualCategoryService';

const actions = {
    async fetchContextualCategories({ state, commit }): Promise<void> {
        commit('setContextualCategoriesFetchErrorMessage', null);
        commit('setIsLoadingContextualCategories', true);
        try {
            if (state.contextualCategories.length === 0) {
                const contextualCategories = await contextualCategoryService.getContextualCategories();
                commit('setContextualCategories', contextualCategories);
            }
        } catch (error) {
            commit(
                'setContextualCategoriesFetchErrorMessage',
                // eslint-disable-next-line max-len
                'Could not load contextual categories. Please try reloading the page and if the issue persists, contact support.',
            );
        } finally {
            commit('setIsLoadingContextualCategories', false);
        }
    },
};

export default actions;

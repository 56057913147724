import { TableActionTypes } from '@/components/types';

export const listHeaders = [
    {
        text: 'ID',
        value: 'id',
        align: 'left',
        sortable: true,
        width: '80px',
    },
    {
        text: 'Name',
        value: 'name',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Deal ID',
        value: 'externalId',
        align: 'left',
        sortable: true,
    },
    {
        text: 'SSP',
        value: 'ssp.name',
        align: 'left',
        width: '120px',
    },
    {
        text: 'Network',
        value: 'network.name',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Environment',
        value: 'environment',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Format',
        value: 'format',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Actions',
        value: 'actions',
        align: 'right',
        sortable: false,
    },
];
export const listActionRoutes = {
    [TableActionTypes.EDIT]: {
        name: 'Edit',
        icon: 'Edit',
        routeName: 'deals-update',
    },
    [TableActionTypes.DELETE]: {
        name: 'Delete',
        icon: 'Trash',
        routeName: 'deals-delete',
    },
};

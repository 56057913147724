export const panelsConfig = [
    {
        name: 'device',
        title: 'Device',
        component: 'Device',
        key: ['devices', 'deviceTypeTargetings'],
    },
    {
        name: 'browser',
        title: 'Browser and Operating System',
        component: 'BrowserOverview',
        key: ['browserTargetings', 'operatingSystems'],
    },
];

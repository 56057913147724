import { TableActionTypes } from '@/components/types';

export const listHeaders = [
    {
        text: 'DSP ID',
        value: 'dspId',
        align: 'left',
        sortable: true,
        width: '90px',
    },
    {
        text: 'Campaign Name',
        value: 'name',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Budget',
        value: 'budget',
        align: 'left',
        sort: (a, b) => a.price.value - b.price.value,
        width: '150px',
    },
    {
        text: 'LI Total',
        value: 'allocatedBudget',
        align: 'left',
        sortable: false,
        width: '150px',
    },
    {
        text: 'Days Remaining',
        value: 'runtime.daysRemaining',
        align: 'left',
        sortable: false,
    },
    {
        text: 'Advertiser',
        value: 'businessEntity.name',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Actions',
        value: 'actions',
        align: 'right',
        sortable: false,
    },
];

export const listActionRoutes = {
    [TableActionTypes.EDIT]: {
        name: TableActionTypes.EDIT,
        icon: 'Edit',
        routeName: 'campaign-update',
    },
    [TableActionTypes.HISTORY]: {
        name: TableActionTypes.HISTORY,
        icon: 'History',
        routeName: 'campaign-changelog',
    },
    [TableActionTypes.SHOW_LINE_ITEMS]: {
        name: TableActionTypes.SHOW_LINE_ITEMS,
        icon: 'Inventory',
        routeName: 'line-items-list',
    },
    [TableActionTypes.DUPLICATE]: {
        name: TableActionTypes.DUPLICATE,
        icon: 'Copy',
        routeName: 'campaign-duplicate',
    },
};

export const partialEditSuccessMessage = () => ({
    type: 'success',
    title: 'Campaign Updated',
    description: '',
});

export const partialEditErrorMessage = (error: string) => ({
    type: 'error',
    title: 'Error while updating campaign budget',
    description: error,
});

export const quickEditDefaultData = {
    campaign: null,
    newBudgetValue: null,
    newEndDateValue: null,
    hasBudgetError: false,
    hasEndDateError: false,
    isSubmitting: false,
};

// Taken from https://stackoverflow.com/a/53749034
export default function getCurrencySymbol(locale: string, currency: string): string {
    return (0)
        .toLocaleString(locale, {
            style: 'currency',
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim();
}

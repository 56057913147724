import ICampaignBudget from 'api-contracts/campaigns/ICampaignBudget';
import BUDGET_TYPE from 'api-contracts/campaigns/BudgetType';

export default function getEmptyCampaignBudget(): ICampaignBudget {
    return {
        price: {
            value: null,
            currencyCode: 'EUR',
        },
        type: BUDGET_TYPE.CAP,
    };
}

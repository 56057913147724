<template>
    <div>
        <SrModalPage type="basic" :show="show" @close="close">
            <template #content>
                <Changelog v-bind="options" />
            </template>
        </SrModalPage>
        <RouterView />
    </div>
</template>

<script>
import { SrModalPage } from '@ads/design-system';
import config from './config';

export default {
    name: 'LineItemHistory',
    components: {
        SrModalPage,
    },
    props: {
        dspId: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            show: true,
            options: {
                resourceId: this.dspId,
                resourceName: this.$route.query.name,
                config: config(this.$route.query.currency, this.$route.query.timezone),
            },
        };
    },
    methods: {
        async close() {
            this.show = false;
            if (this.$router.currentRoute.fullPath !== this.$router.history.current.fullPath) {
                return this.$router.back();
            }
            return this.$router.push({ name: 'line-items-list' });
        },
    },
};
</script>

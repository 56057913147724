import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import { CategoryResponse } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/Category/types';

export default class CategoryService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'line-item',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async getAll(): Promise<CategoryResponse[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get('/content-categories', options);
    }

    async getSubcategories(parentId: number): Promise<CategoryResponse[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });
        const query = new URLSearchParams({ parentId: parentId.toString() });

        return this.requestService.get(`/content-categories?${query}`, options);
    }
}

export const categoryService = new CategoryService(new RequestService({ baseUrl: API_URL }));

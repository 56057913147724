<template>
    <div>
        <SrHeadline level="3" weight="bold"> Available Browsers </SrHeadline>
        <SelectableTree v-model="selectedTree" :source-nodes="browserNodes" :mode.sync="mode" :is-loading="isLoading" />
    </div>
</template>

<script>
import { SrHeadline } from '@ads/design-system';
import SelectableTree from '@/components/SelectableTree/SelectableTree';
import { SelectedTree } from '@/components/SelectableTree/SelectedTree';
import { SELECTION_MODE } from '@/components/SelectableTree/types';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import { browserService } from './service/BrowserService';
import { browserTransformer } from './BrowserTransformer';

export default {
    name: 'Browser',
    components: { SelectableTree, SrHeadline },
    mixins: [LineItemModel],
    data() {
        return {
            browserNodes: [],
            isLoading: false,
            mode: this.getInitialMode(),
            browserService,
            selectedTree: new SelectedTree(this.getSelectedBrowsers()),
        };
    },
    watch: {
        selectedTree: {
            deep: true,
            handler() {
                this.$set(
                    this.lineItem.targetings,
                    'browserTargetings',
                    browserTransformer.selectedNodesToBrowserTargetings(this.selectedTree.getSelectedNodeTrees()),
                );
            },
        },
    },
    created() {
        this.getBrowsers();
    },
    methods: {
        async getBrowsers() {
            this.isLoading = true;
            try {
                const browsers = await this.browserService.getAll();
                this.browserNodes = browserTransformer.browsersToSelectableNodes(browsers);
            } finally {
                this.isLoading = false;
            }
        },
        getSelectedBrowsers() {
            if (this.value.targetings?.browserTargetings?.length > 0) {
                return browserTransformer.browserTargetingsToSelectedNodes(this.value.targetings.browserTargetings);
            }
            return [];
        },
        getInitialMode() {
            if (this.value.targetings?.browserTargetings?.length > 0) {
                return this.value.targetings.browserTargetings[0].isExcluded ? SELECTION_MODE.EXCLUDE : SELECTION_MODE.INCLUDE;
            }
            return SELECTION_MODE.INCLUDE;
        },
    },
};
</script>

import { TargetingCount } from '@/modules/line-items/services/TargetingCount';
import ITargetingsResponse from 'api-contracts/line-items/targetings/ITargetingsResponse';
import IHoursByDay from 'api-contracts/line-items/targetings/time/IHoursByDay';

export type LineItemWithTargetings = { targetings: ITargetingsResponse };

export default class TargetingCountFactory {
    createCountFrom(lineItem: LineItemWithTargetings): TargetingCount {
        return {
            audience: this.createAudienceTargetingsCount(lineItem.targetings),
            content: this.createContentTargetingsCount(lineItem.targetings),
            technology: this.createTechnologyTargetingsCount(lineItem.targetings),
            segments: this.createSegmentTargetingsCount(lineItem.targetings),
        };
    }

    private createAudienceTargetingsCount(targetings?: ITargetingsResponse): TargetingCount['audience'] {
        const countOfGeoTargetings = targetings?.geoTargetings?.length || 0;
        const countOfTimeTargetings = this.countTimeTargeting(targetings?.timeTargeting);
        const countOfLocationTargetings = targetings?.locationTargetings?.length || 0;
        const countOfSportCalendarTargetings = targetings?.sportCalendarTargetings?.length || 0;
        const countOfBrowserLanguageTargetings = targetings?.browserLanguageTargetings?.length || 0;
        return {
            count:
                countOfGeoTargetings +
                countOfTimeTargetings +
                countOfLocationTargetings +
                countOfSportCalendarTargetings +
                countOfBrowserLanguageTargetings,
            types: {
                geoTargetings: {
                    count: countOfGeoTargetings,
                },
                timeTargeting: {
                    count: countOfTimeTargetings,
                },
                locationTargetings: {
                    count: countOfLocationTargetings,
                },
                browserLanguageTargetings: {
                    count: countOfBrowserLanguageTargetings,
                },
                sportCalendarTargetings: {
                    count: countOfSportCalendarTargetings,
                },
            },
        };
    }

    private createContentTargetingsCount(targetings: ITargetingsResponse): TargetingCount['content'] {
        const countOfApplicationNameLists = targetings?.applicationNameLists?.length || 0;
        const countOfBundleListTargetings = targetings?.bundleListTargetings?.length || 0;
        const countOfCategoryTargetings = targetings?.categoryTargetings?.length || 0;
        const countOfDealTargetings = targetings?.dealTargetings?.length || 0;
        const countOfDomainLists = targetings?.domainLists?.length || 0;
        const countOfPublisherListTargetings = targetings?.publisherListTargetings?.length || 0;
        const countOfSspTargetings = targetings?.sspTargetings?.length || 0;
        const countOfVideoPlayerSizeTargetings = targetings?.videoPlayerSizeTargetings?.length || 0;
        const countOfVisibilityTargetings = targetings?.visibilityTargetings?.length || 0;
        const countOfAppOrWebTargeting = this.getCountOfAppOrWebTargeting(targetings);
        const countOfContextualCategoryTargeting = this.getCountOfContextualCategoryTargeting(targetings);
        const totalCount =
            countOfApplicationNameLists +
            countOfBundleListTargetings +
            countOfCategoryTargetings +
            countOfDealTargetings +
            countOfDomainLists +
            countOfPublisherListTargetings +
            countOfSspTargetings +
            countOfVideoPlayerSizeTargetings +
            countOfVisibilityTargetings +
            countOfAppOrWebTargeting +
            countOfContextualCategoryTargeting;

        return {
            count: totalCount,
            types: {
                applicationNameLists: {
                    count: countOfApplicationNameLists,
                },
                bundleListTargetings: {
                    count: countOfBundleListTargetings,
                },
                categoryTargetings: {
                    count: countOfCategoryTargetings,
                },
                dealTargetings: {
                    count: countOfDealTargetings,
                },
                domainLists: {
                    count: countOfDomainLists,
                },
                publisherListTargetings: {
                    count: countOfPublisherListTargetings,
                },
                sspTargetings: {
                    count: countOfSspTargetings,
                },
                videoPlayerSizeTargetings: {
                    count: countOfVideoPlayerSizeTargetings,
                },
                visibilityTargetings: {
                    count: countOfVisibilityTargetings,
                },
                appOrWebTargeting: {
                    count: countOfAppOrWebTargeting,
                },
                contextualCategoryTargeting: {
                    count: countOfContextualCategoryTargeting,
                },
            },
        };
    }

    private getCountOfAppOrWebTargeting(targetings: ITargetingsResponse): number {
        const appOrWebTargeting = targetings?.appOrWebTargeting;
        if (!appOrWebTargeting) {
            return 0;
        }

        const isWebCount = appOrWebTargeting.isWeb ? 1 : 0;
        const isAppCount = appOrWebTargeting.isApp ? 1 : 0;
        return isWebCount + isAppCount;
    }

    private createTechnologyTargetingsCount(targetings?: ITargetingsResponse): TargetingCount['technology'] {
        const countOfDeviceTypeTargetings = targetings?.deviceTypeTargetings?.length || 0;
        const countOfDevices = targetings?.devices?.length || 0;
        const countOfBrowserTargetings = targetings?.browserTargetings?.length || 0;
        const countOfOperatingSystems = targetings?.operatingSystems?.length || 0;
        return {
            count: countOfDevices + countOfDeviceTypeTargetings + countOfBrowserTargetings + countOfOperatingSystems,
            types: {
                devices: {
                    count: countOfDevices,
                },
                deviceTypeTargetings: {
                    count: countOfDeviceTypeTargetings,
                },
                browserTargetings: {
                    count: countOfBrowserTargetings,
                },
                operatingSystems: {
                    count: countOfOperatingSystems,
                },
            },
        };
    }

    private createSegmentTargetingsCount(targetings?: ITargetingsResponse): TargetingCount['segments'] {
        let countOfPixelSegments = 0;
        if (targetings?.pixelSegmentTargeting?.children?.length > 0) {
            countOfPixelSegments++;
        }
        const countOfFanIdSegments = targetings?.fanIdSegmentTargeting ? 1 : 0;
        const totalCount = countOfPixelSegments + countOfFanIdSegments;
        return {
            count: totalCount,
            types: {
                pixelSegmentTargeting: {
                    count: countOfPixelSegments,
                },
                fanIdTargeting: {
                    count: countOfFanIdSegments,
                },
            },
        };
    }

    private countTimeTargeting(timeTargeting?: IHoursByDay): number {
        if (!timeTargeting) {
            return 0;
        }
        return Object.values(timeTargeting).reduce(
            (totalCount: number, time: IHoursByDay[keyof IHoursByDay]) => totalCount + (time?.length || 0),
            0,
        );
    }

    private getCountOfContextualCategoryTargeting(targetings?: ITargetingsResponse): number {
        if (targetings?.contextualCategoryTargeting?.category) {
            return 1;
        }

        return 0;
    }
}

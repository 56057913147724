import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';

export default class BusinessEntityService {
    private readonly requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async listBusinessEntities() {
        const options: IRequestOptions = {
            domain: 'iam',
            resource: 'business-entity',
            action: ACTION_NAME.READ,
        };

        return this.requestService.get('/business-entities', options);
    }
}

<template>
    <SrModalPageForm
        :value="currentStep"
        :show.sync="show"
        :steps="steps"
        :tabs="isEditForm"
        :loading="isLoading"
        :submit-enabled="isSubmitEnabled"
        :show-submit-button="shouldShowSubmitButton"
        @close="close"
        @submit="submit"
        @input="onStepChange"
    >
        <template #content-header>
            <LineItemInvalidSSPTargetingError :line-item="lineItem" />
            <template v-for="notification in notifications">
                <SrNotification
                    v-if="currentStep === LINE_ITEM_FORM_STEPS.STRATEGY"
                    :key="notification.description"
                    :type="notification.type"
                    :description="notification.description"
                    title="Line Item Alert"
                />
            </template>
            <TargetingsOverviewHeader v-if="currentStep === LINE_ITEM_FORM_STEPS.TARGETINGS" :targeting-count="targetingCount" />
        </template>
        <SrModalPageStep step="1" :title="getTitleByStepIndex(0)">
            <LineItemFormStepBasics
                v-if="currentStep === LINE_ITEM_FORM_STEPS.BASICS"
                v-model="lineItem"
                :is-edit-form="isEditForm"
                :is-valid.sync="steps[0].canContinue"
                :is-loading="isLoading"
            />
        </SrModalPageStep>
        <SrModalPageStep step="2" :title="getTitleByStepIndex(1)">
            <LineItemFormStepStrategy
                v-if="currentStep === LINE_ITEM_FORM_STEPS.STRATEGY"
                v-model="lineItem"
                :is-edit-form="isEditForm"
                :is-valid.sync="steps[1].canContinue"
            />
        </SrModalPageStep>
        <SrModalPageStep step="3" :title="getTitleByStepIndex(2)">
            <LineItemFormStepTargetings
                v-if="currentStep === LINE_ITEM_FORM_STEPS.TARGETINGS"
                v-model="lineItem"
                :targeting-count.sync="targetingCount"
                :is-valid.sync="steps[2].canContinue"
            />
        </SrModalPageStep>
        <SrModalPageStep step="4" :title="getTitleByStepIndex(3)">
            <LineItemFormStepCreatives
                v-if="currentStep === LINE_ITEM_FORM_STEPS.CREATIVES"
                v-model="lineItem"
                :selected-creatives.sync="selectedCreatives"
                :filter-config.sync="creativeFilterConfig"
                :is-valid.sync="steps[3].canContinue"
            />
        </SrModalPageStep>
        <div v-if="hasError" slot="footer">
            <ErrorBox :show="hasError" :message="error" />
        </div>
    </SrModalPageForm>
</template>

<script>
import { LINE_ITEM_ALERT_STATUS } from '@/modules/line-items/types';
import { createActionValidator, SrModalPageForm, SrModalPageStep, SrNotification } from '@ads/design-system';
import ErrorBox from '@/components/ErrorBox/ErrorBox';
import BasicForm from '@/components/Form/BasicForm';
import { lineItemsService } from '@/modules/line-items/services/LineItemsService';
import TargetingsOverviewHeader from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/TargetingsOverviewHeader';
import { canCreateLineItems, canUpdateLineItems } from '@/permissions';
import LineItemInvalidSSPTargetingError from '@/modules/line-items/components/LineItemForm/LineItemInvalidSSPTargetingError/LineItemInvalidSSPTargetingError';
import TargetingCountFactory from '@/modules/line-items/services/TargetingCountFactory';
import ReactiveTargetingCountTransformer from '@/modules/line-items/services/ReactiveTargetingCountTransformer';
import ReactiveTargetingValueTransformer from '@/modules/line-items/services/ReactiveTargetingValueTransformer';
import LineItemFormStepBasics from './LineItemFormStepBasics';
import { emptyTargetingCount, lineItemFormSteps } from './config';
import LineItemFormStepStrategy from './LineItemFormStepStrategy/LineItemFormStepStrategy';
import LineItemFormStepTargetings from './LineItemFormStepTargetings';
import LineItemFormStepCreatives from './LineItemFormStepCreatives/LineItemFormStepCreatives';
import { LINE_ITEM_FORM_STEPS } from './types';

export default {
    name: 'LineItemForm',
    components: {
        ErrorBox,
        SrModalPageForm,
        SrModalPageStep,
        LineItemFormStepBasics,
        LineItemFormStepStrategy,
        LineItemFormStepTargetings,
        LineItemFormStepCreatives,
        SrNotification,
        TargetingsOverviewHeader,
        LineItemInvalidSSPTargetingError,
    },
    extends: BasicForm,
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        loadLineItemFormError: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            show: true,
            lineItem: this.value,
            LINE_ITEM_FORM_STEPS,
            currentStep: this.value.id ? LINE_ITEM_FORM_STEPS.STRATEGY : LINE_ITEM_FORM_STEPS.BASICS,
            steps: lineItemFormSteps.map((step) => ({
                name: step.name,
                canContinue: step.isValid,
            })),
            targetingCount: JSON.parse(JSON.stringify(emptyTargetingCount)),
            areTargetingsCounted: false,
            selectedCreatives: this.value.id ? null : [],
            lineItemsService,
            userPermittedToCreateLineItem: true,
            userPermittedToEditLineItem: true,
            creativeFilterConfig: {
                searchValueAvailable: '',
                searchValueSelected: '',
                type: null,
            },
        };
    },
    computed: {
        hasError() {
            const hasLoadLineItemFormError = Boolean(this.loadLineItemFormError);
            const hasSaveLineItemError = Boolean(this.errorMessage);
            return hasLoadLineItemFormError || hasSaveLineItemError;
        },
        isSubmitEnabled() {
            const hasLoadLineItemFormError = Boolean(this.loadLineItemFormError);
            return !hasLoadLineItemFormError;
        },
        error() {
            return this.loadLineItemFormError || this.errorMessage;
        },
        notifications() {
            return this.lineItem.alerts.map((alert) => ({
                type: alert.status === LINE_ITEM_ALERT_STATUS.INFO ? 'success' : alert.status,
                description: alert.description,
            }));
        },
        shouldShowSubmitButton() {
            return this.isEditForm ? this.userPermittedToEditLineItem : this.userPermittedToCreateLineItem;
        },
    },
    watch: {
        lineItem(lineItem) {
            this.$emit('input', lineItem);
            if (lineItem.id && !this.areTargetingsCounted) {
                this.countLineItemTargetings(lineItem);
                this.areTargetingsCounted = true;
            }
        },
        value(lineItem) {
            this.lineItem = lineItem;
            this.selectedCreatives = lineItem.id ? null : [];
        },
    },
    async created() {
        await this.validateUserPermissions();
    },
    methods: {
        countLineItemTargetings(lineItem) {
            const targetingCount = new TargetingCountFactory().createCountFrom(lineItem);
            this.targetingCount = this.transformToReactiveTargetingCount(targetingCount);
        },
        transformToReactiveTargetingCount(targetingCount) {
            return new ReactiveTargetingCountTransformer(new ReactiveTargetingValueTransformer()).transform(targetingCount);
        },
        async save() {
            return lineItemsService[this.submitAction](this.lineItem);
        },
        onStepChange(currentStep) {
            this.currentStep = currentStep;
        },
        getTitleByStepIndexForEdit(index) {
            const stepName = this.steps[index].name;
            if (this.isLoading) {
                return stepName;
            }
            return `${stepName} - ${this.lineItem.dspId} - ${this.lineItem.name}`;
        },
        getTitleByStepIndex(index) {
            if (this.isEditForm) {
                return this.getTitleByStepIndexForEdit(index);
            }
            return this.steps[index].name;
        },
        async validateUserPermissions() {
            const actionValidator = await createActionValidator(this.$root.user);
            this.userPermittedToCreateLineItem = await actionValidator.validateAction({
                ...canCreateLineItems,
                userId: this.$root.user.getCognitoId(),
            });
            this.userPermittedToEditLineItem = await actionValidator.validateAction({
                ...canUpdateLineItems,
                userId: this.$root.user.getCognitoId(),
            });
        },
    },
};
</script>

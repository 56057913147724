/* eslint-disable max-classes-per-file */
import { Node, NodeDetails } from './Node';

export enum NODE_SELECTION_STATUS {
    NONE,
    INCLUDED,
    EXCLUDED,
}

export class SelectedTree {
    protected children: SelectedNodeTree[] = [];

    constructor(selectedBranches: SelectedNodeTree[] = []) {
        selectedBranches.forEach((branch) => this.addBranch(branch));
    }

    toFlatArray(): SelectedNodeTree[] {
        return this.children.flatMap((child) => child.toFlatArray());
    }

    getChildren(): SelectedNodeTree[] {
        return this.children;
    }

    include(node: Node): void {
        this.addNode(node, NODE_SELECTION_STATUS.INCLUDED);
    }

    exclude(node: Node): void {
        this.addNode(node, NODE_SELECTION_STATUS.EXCLUDED);
    }

    includeAllSelected(): void {
        this.setAllSelectedNodeTreesSelectionStatus(NODE_SELECTION_STATUS.INCLUDED);
    }

    excludeAllSelected(): void {
        this.setAllSelectedNodeTreesSelectionStatus(NODE_SELECTION_STATUS.EXCLUDED);
    }

    getSelectedNodeTrees(): SelectedNodeTree[] {
        const flatDescendants = this.toFlatArray();
        return flatDescendants.filter((node) => node.isSelected());
    }

    getIncludedNodes(): SelectedNodeTree[] {
        const flatDescendants = this.toFlatArray();
        return flatDescendants.filter((node) => node.isIncluded());
    }

    getExcludedNodes(): SelectedNodeTree[] {
        const flatDescendants = this.toFlatArray();
        return flatDescendants.filter((node) => node.isExcluded());
    }

    deselectNodeById(nodeId: number | string) {
        const nodeToDeselect = this.toFlatArray().find((selectedNode) => selectedNode.getId() === nodeId);
        if (nodeToDeselect) {
            this.deselectNode(nodeToDeselect);
        }
    }

    deselectNode(selectedNode: SelectedNodeTree): void {
        this.deselectDescendant(selectedNode);
        this.removeBranchesWithNoSelection();
    }

    doesInclude(node: Node): boolean {
        return this.getIncludedNodes().some((includedNode) => includedNode.getId() === node.id);
    }

    doesExclude(node: Node): boolean {
        return this.getExcludedNodes().some((excludedNode) => excludedNode.getId() === node.id);
    }

    /*
     * To minimize resulting data set, the tree disallows including of certain nodes.
     *
     * Firstly, we check if node is in "selected branch", i.e. if it has any ancestor
     * selected (included or excluded). Nodes with nearest selected ancestor included
     * cannot be included explicitly, as they are included implicitly by the ancestor.
     * Node "I" represents explicitly included node, so there is no point of explicitly
     * including any of nodes "N". Node "E" represents excluded node, and its
     * descendants "X" can be included, despite having included ancestor higher in
     * the chain.
     *
     *                        I                         I
     *                      /   \                     /   \
     *                     N     N                   N     E
     *                          / \                       / \
     *                         N   N                     X   X
     *
     *
     * If a node is not part of selected branch, but the tree contains selected nodes
     * in other branches, node can be included only if the first(highest) selected node
     * is also included. If the first selected node is excluded, it makes nodes in all
     * other branches implicitly included.
     * Node "E" is excluded, so for its descendants "N" the rule above applies. Nodes "X"
     * cannot be included, as first selected node in the tree is excluded.
     *
     *                        E               X
     *                      /   \           /   \
     *                     N     N         X     X
     *
     *
     * If there is no selection in the tree, the node can be included.
     */
    canInclude(node: Node): boolean {
        const nearestSelectedAncestor = this.getNearestSelectedAncestorOfNode(node);
        if (nearestSelectedAncestor) {
            return nearestSelectedAncestor.isExcluded();
        }

        const firstSelectedNodeTree = this.getFirstSelectedNodeTree();
        if (!firstSelectedNodeTree) {
            return true;
        }
        return firstSelectedNodeTree.isIncluded();
    }

    /*
     * To minimize resulting data set, the tree disallows excluding of certain nodes.
     *
     * Firstly, we check if node is in "selected branch", i.e. if it has any ancestor
     * selected (included or excluded). Nodes with nearest selected ancestor excluded
     * cannot be excluded explicitly, as they are excluded implicitly by the ancestor.
     * Node "E" represents explicitly excluded node, so there is no point of explicitly
     * excluding any of nodes "N". Node "I" represents included node, and its
     * descendants "X" can be excluded, despite having excluded ancestor higher in
     * the chain.
     *
     *                        E                         E
     *                      /   \                     /   \
     *                     N     N                   N     I
     *                          / \                       / \
     *                         N   N                     X   X
     *
     *
     * If the node is not part of selected branch, but the tree contains selected nodes
     * in other branches, node can be excluded only if the first(highest) selected node
     * is also excluded. If the first selected node is included, it makes nodes in all
     * other branches implicitly excluded.
     * Node "I" is included, so for its descendants "N" the rule above applies. Nodes "X"
     * cannot be excluded, as first selected node in the tree is included.
     *
     *                        I               X
     *                      /   \           /   \
     *                     N     N         X     X
     *
     *
     * If there is no selection in the tree, the node can be excluded.
     */
    canExclude(node: Node): boolean {
        const nearestSelectedAncestor = this.getNearestSelectedAncestorOfNode(node);
        if (nearestSelectedAncestor) {
            return nearestSelectedAncestor.isIncluded();
        }

        const firstSelectedNodeTree = this.getFirstSelectedNodeTree();
        if (!firstSelectedNodeTree) {
            return true;
        }
        return firstSelectedNodeTree.isExcluded();
    }

    updateNodesFrom(sourceNodes: Node[]): void {
        this.getSelectedNodeTrees().forEach((selectedNode) => {
            const sourceNode = sourceNodes.find((node) => node.id === selectedNode.getId());
            if (sourceNode) {
                selectedNode.updateFrom(sourceNode);
            }
        });
    }

    private setAllSelectedNodeTreesSelectionStatus(selectionStatus: NODE_SELECTION_STATUS): void {
        this.getSelectedNodeTrees().forEach((selectedNode) => selectedNode.setSelectionStatus(selectionStatus));
    }

    private addNode(node: Node, selectionStatus: NODE_SELECTION_STATUS): void {
        const branch = node.toBranch();
        const selectedBranch = this.nodeToSelectedBranch(branch, selectionStatus);
        this.addBranch(selectedBranch);
        this.removeBranchesWithNoSelection();
    }

    private nodeToSelectedBranch(node: Node, selectionStatus: NODE_SELECTION_STATUS): SelectedNodeTree {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return new SelectedNodeTree(
            node.id,
            node.name,
            node.isLeaf() ? selectionStatus : NODE_SELECTION_STATUS.NONE,
            node.type,
            node.children ? node.children.map((child) => this.nodeToSelectedBranch(child, selectionStatus)) : [],
            node.details,
        );
    }

    private addBranch(node: SelectedNodeTree) {
        const childNodeWithSameId = this.children.find((child) => child.getId() === node.getId());

        if (childNodeWithSameId) {
            if (node.isSelected()) {
                childNodeWithSameId.setSelectionStatus(node.getSelectionStatus());
                const childDescendantWithSameStatus = childNodeWithSameId
                    .getDescendantsAsFlatArray()
                    .filter((descendant) => descendant.getSelectionStatus() === node.getSelectionStatus());
                childDescendantWithSameStatus.forEach((descendant) => descendant.setSelectionStatus(NODE_SELECTION_STATUS.NONE));
            }
            node.children.forEach((child) => childNodeWithSameId.addBranch(child));
            return;
        }
        this.children.push(node);
    }

    private getFirstSelectedNodeTree(): SelectedNodeTree | null {
        if (this.children.length === 0) {
            return null;
        }
        const firstSelectedChild = this.children.find((child) => child.isSelected());
        if (firstSelectedChild) {
            return firstSelectedChild;
        }
        const indirectDescendants = this.children.flatMap((child) => child.getFirstSelectedNodeTree());
        const firstSelectedIndirectDescendant = indirectDescendants.find((descendant) => descendant !== null);
        return firstSelectedIndirectDescendant;
    }

    private getNearestSelectedAncestorOfNode(node: Node): SelectedNodeTree | null {
        const ancestors = node.getAncestors();
        const selectedNodes = this.getSelectedNodeTrees();

        for (const ancestor of ancestors) {
            const nearestSelectedAncestor = selectedNodes.find((selectedNode) => selectedNode.getId() === ancestor.id);
            if (nearestSelectedAncestor) {
                return nearestSelectedAncestor;
            }
        }

        return null;
    }

    private removeBranchesWithNoSelection() {
        this.children.forEach((child) => child.removeBranchesWithNoSelection());

        this.children = this.children.filter((child) => child.isSelected() || child.children.length > 0);
    }

    private deselectChild(child: SelectedNodeTree) {
        this.children.splice(this.children.indexOf(child), 1);
    }

    private deselectDescendant(selectedNode: SelectedNodeTree): void {
        const nodeToDeselect = this.children.find((child) => child.getId() === selectedNode.getId());

        if (nodeToDeselect) {
            this.deselectChild(nodeToDeselect);
        } else {
            this.children.forEach((child) => child.deselectDescendant(selectedNode));
        }
    }
}

export class SelectedNodeTree extends SelectedTree {
    private readonly id: string | number;

    private name: string;

    private readonly type: string | null;

    private details: NodeDetails;

    private selectionStatus: NODE_SELECTION_STATUS;

    constructor(
        id: string | number,
        name: string,
        selectionStatus: NODE_SELECTION_STATUS,
        type: string | null = null,
        selectedBranches: SelectedNodeTree[] = [],
        details: NodeDetails = null,
    ) {
        super(selectedBranches);
        this.id = id;
        this.name = name;
        this.type = type;
        this.selectionStatus = selectionStatus;
        this.details = details;
    }

    getId(): string | number {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getType(): string {
        return this.type;
    }

    getDetails(): NodeDetails {
        return this.details;
    }

    getSelectionStatus(): NODE_SELECTION_STATUS {
        return this.selectionStatus;
    }

    setSelectionStatus(status: NODE_SELECTION_STATUS): void {
        this.selectionStatus = status;
    }

    isExcluded(): boolean {
        return this.selectionStatus === NODE_SELECTION_STATUS.EXCLUDED;
    }

    isIncluded(): boolean {
        return this.selectionStatus === NODE_SELECTION_STATUS.INCLUDED;
    }

    isSelected(): boolean {
        return this.selectionStatus !== NODE_SELECTION_STATUS.NONE;
    }

    isSelectedBranch() {
        return this.isSelected() || this.children.some((child) => child.isSelectedBranch());
    }

    getDescendantsAsFlatArray(): SelectedNodeTree[] {
        return this.children.flatMap((child) => child.toFlatArray());
    }

    toFlatArray(): SelectedNodeTree[] {
        return [this as SelectedNodeTree].concat(this.getDescendantsAsFlatArray());
    }

    updateFrom(sourceNode: Node) {
        this.name = sourceNode.name;
        this.details = sourceNode.details;
    }
}

import { FrequencyCapping } from '@/modules/line-items/types';

export default class FrequencyCappingValidator {
    static isValid(frequencyCapping: FrequencyCapping): boolean {
        return (
            frequencyCapping.cappingLimit != null &&
            frequencyCapping.cappingLimit > 0 &&
            frequencyCapping.timeValue != null &&
            frequencyCapping.timeValue > 0 &&
            frequencyCapping.timeUnit != null
        );
    }

    static isListValid(frequencyCappings: FrequencyCapping[]): boolean {
        return !!frequencyCappings?.every((frequencyCapping) => this.isValid(frequencyCapping));
    }
}

import { getLengthValidationRules } from '@/shared/validation';

export enum DEAL_SOURCE {
    SSP = 'SSP',
    PUBLISHER = 'Publisher',
}

export const sources = Object.values(DEAL_SOURCE);
export const rules = {
    name: getLengthValidationRules('Deal name', 3, 128),
    id: getLengthValidationRules('Deal ID', 0, 2047),
    description: getLengthValidationRules('Deal description', 0, 50_000),
    multiplier: [
        (v) => {
            if (!!v && v < 0.5) {
                return 'Multiplier must be at least 0.5';
            }
            if (!!v && v > 5) {
                return 'Multiplier must be smaller than 5';
            }
            if (!!v && v.toString().length > 6) {
                return 'Multiplier must have less than 4 decimals';
            }

            return true;
        },
    ],
};

import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import URLPathBuilder from '@/modules/line-items/services/URLPathBuilder';
import {
    SportCalendarCountry,
    SportCalendarEvent,
    SportCalendarSport,
    SportCalendarTournament,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/SportCalendar/types';

const SPORT_CALENDAR_EVENTS_ROUTE = '/sport-calendar-events/';
const SPORT_CALENDAR_TOURNAMENTS_ROUTE = '/sport-calendar-tournaments/';
const SPORT_CALENDAR_COUNTRIES_ROUTE = '/sport-calendar-countries/';
const SPORT_CALENDAR_SPORTS_ROUTE = '/sport-calendar-sports/';

const SPORT_ID_PARAMETER_NAME = 'sportId';
const COUNTRY_ID_PARAMETER_NAME = 'countryId';
const TOURNAMENT_ID_PARAMETER_NAME = 'tournamentId';

export default class SportCalendarService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'line-item',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async getEvents(sportId: number, countryId: number, tournamentId: number): Promise<SportCalendarEvent[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        const urlPathBuilder = new URLPathBuilder(SPORT_CALENDAR_EVENTS_ROUTE);
        const urlPath = urlPathBuilder
            .setParam(SPORT_ID_PARAMETER_NAME, sportId)
            .setParam(COUNTRY_ID_PARAMETER_NAME, countryId)
            .setParam(TOURNAMENT_ID_PARAMETER_NAME, tournamentId)
            .build();

        return this.requestService.get(urlPath, options);
    }

    async getTournaments(sportId: number, countryId: number): Promise<SportCalendarTournament[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        const urlPathBuilder = new URLPathBuilder(SPORT_CALENDAR_TOURNAMENTS_ROUTE);
        const urlPath = urlPathBuilder
            .setParam(SPORT_ID_PARAMETER_NAME, sportId)
            .setParam(COUNTRY_ID_PARAMETER_NAME, countryId)
            .build();

        return this.requestService.get(urlPath, options);
    }

    async getCountries(sportId: number): Promise<SportCalendarCountry[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        const urlPathBuilder = new URLPathBuilder(SPORT_CALENDAR_COUNTRIES_ROUTE);
        const urlPath = urlPathBuilder.setParam(SPORT_ID_PARAMETER_NAME, sportId).build();

        return this.requestService.get(urlPath, options);
    }

    async getSports(): Promise<SportCalendarSport[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        const urlPathBuilder = new URLPathBuilder(SPORT_CALENDAR_SPORTS_ROUTE);
        const urlPath = urlPathBuilder.build();

        return this.requestService.get(urlPath, options);
    }
}

export const sportCalendarService = new SportCalendarService(new RequestService({ baseUrl: API_URL }));

import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import IDeviceResponse from 'api-contracts/line-items/targetings/devices/IDeviceResponse';
import IDevicesByManufacturerResponse from 'api-contracts/line-items/targetings/devices/IDevicesByManufacturerResponse';

export default class DeviceService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'line-item',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async getAll(): Promise<IDeviceResponse[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get('/devices', options);
    }

    async getAllByManufacturer(): Promise<IDevicesByManufacturerResponse[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get('/devices-by-manufacturer', options);
    }
}

export const deviceService = new DeviceService(new RequestService({ baseUrl: API_URL }));

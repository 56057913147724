export default {
    holdings: (state) => state.holdings,
    businessEntities: (state) => state.businessEntities,
    selectableBusinessEntities: (state) =>
        state.businessEntities.map(({ id, name }) => ({
            value: id,
            label: name,
        })),
    selectableHoldings: (state) =>
        state.holdings.map(({ id, name }) => ({
            value: id,
            label: name,
        })),
};

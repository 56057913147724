import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { API_URL } from '@/config';
import { City, Country, Subdivision1, Subdivision2 } from '@/modules/geo/types';

export default class GeoService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'geo',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    async getCountries(): Promise<Country[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });
        const countries = await this.requestService.get<Record<string, unknown>[]>('/countries', options);

        return countries.map((country) => new Country(country));
    }

    async getCountryChildren(country: Country): Promise<(Subdivision1 | City)[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });
        const children = (await this.requestService.get(`/countries/${country.id}/children`, options)) as Partial<
            Subdivision1 | City
        >[];

        return children.map((child) => {
            if (child.type === 'subdivision1') {
                return new Subdivision1(child as Partial<Subdivision1>, country);
            }
            return new City(child as Partial<City>, country);
        });
    }

    async getSubdivision1Children(subdivision1: Subdivision1): Promise<(Subdivision2 | City)[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        const subdivision1Response = await this.requestService.get<{ children: (Partial<Subdivision2> | Partial<City>)[] }>(
            `/countries/${subdivision1.getCountryId()}/children/${subdivision1.id}`,
            options,
        );

        const children = subdivision1Response.children;

        return children.map((child) => {
            if (child.children) {
                return new Subdivision2(child, subdivision1);
            }
            return new City(child, subdivision1);
        });
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }
}

export const geoService = new GeoService(new RequestService({ baseUrl: API_URL }));

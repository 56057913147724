import { NODE_SELECTION_STATUS, SelectedNodeTree, SelectedTree } from '@/components/SelectableTree/SelectedTree';
import { Node } from '@/components/SelectableTree/Node';
import PublisherListService from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/PublisherList/services/PublisherListService';
import ApplicationNameListService from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/ApplicationNameList/services/ApplicationNameListService';
import DomainListService from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/DomainList/services/DomainListService';
import BundleListService from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Content/BundleList/services/BundleListService';

type List = 'applicationNameList' | 'domainList' | 'bundleList' | 'publisherList';
type ListTargeting = {
    isExcluded: boolean;
    id: number;
};

export default {
    methods: {
        async initializeTree(targetingKey: string, listKey: List): Promise<SelectedTree> {
            const listTargetings: ListTargeting[] = this.value.targetings?.[targetingKey];
            if (!listTargetings) {
                return new SelectedTree();
            }
            const selectedNodes = await Promise.all(
                listTargetings?.map(async (listTargeting) => {
                    const list = listTargeting[listKey];
                    const isEditable = await this.canEditList(list.holdingId);
                    return new SelectedNodeTree(
                        list.id,
                        list.name,
                        listTargeting.isExcluded ? NODE_SELECTION_STATUS.EXCLUDED : NODE_SELECTION_STATUS.INCLUDED,
                        null,
                        [],
                        {
                            targetId: listTargeting.id,
                            isEditable,
                        },
                    );
                }),
            );
            return new SelectedTree(selectedNodes);
        },
        async getLists(
            listService: ApplicationNameListService | PublisherListService | DomainListService | BundleListService,
        ): Promise<Node[]> {
            this.isLoading = true;
            const lists = await listService.getByCampaignId(this.lineItem.campaign.id);
            const listNodes: Node[] = await Promise.all(
                lists.map(async (publisherList) => {
                    const isEditable = await this.canEditList(publisherList.holdingId);
                    return new Node({
                        ...publisherList,
                        details: { isEditable },
                    });
                }),
            );
            this.isLoading = false;
            return listNodes;
        },
    },
};

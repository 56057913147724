import { BusinessEntity } from '@/modules/iam/types';
import PIXEL_SOURCE from 'api-contracts/pixels/PixelSource';

export class Pixel {
    id: number;

    dspId: number;

    source: PIXEL_SOURCE;

    name: string;

    isOptimized: boolean;

    containerId: number;

    businessEntity: BusinessEntity;

    pixelConversionType: string;

    postViewDays: number;

    postClickDays: number;

    expirationDays: number;
}

export type PIXEL_CONVERSION_TYPE =
    | 'ROS'
    | 'Login'
    | 'Reg_finished'
    | 'FTD'
    | 'Deposit'
    | 'Install'
    | 'Event'
    | 'Popunder'
    | 'Other'
    | 'Bet_placed'
    | 'Valuable_page_visit'
    | 'Retargeting'
    | 'Reg_started';

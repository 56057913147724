<template>
    <div>
        <VCol md="12" sm="12" class="pl-0 ma-0">
            <StrategyForm v-model="lineItem" :is-valid.sync="formIsValid" />
        </VCol>
    </div>
</template>

<script>
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import StrategyForm from './StrategyForm';

export default {
    name: 'LineItemStrategyDetailsForm',
    components: { StrategyForm },
    mixins: [LineItemModel],
    props: {
        isValid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formIsValid: this.isValid,
        };
    },
    watch: {
        isValid(isValid) {
            this.formIsValid = isValid;
        },
        formIsValid(isValid) {
            this.$emit('update:isValid', isValid);
        },
    },
};
</script>

import { Pixel } from '@/modules/pixels/types';
import { PIXEL_SEGMENT_TEMPLATE } from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Segment/PixelSegments/types';
import { Predicate } from '@ads/predicate-model';
import IPixelSegmentTemplate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Segment/PixelSegments/Templates/IPixelSegmentTemplate';
import RosToRegStartTemplate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Segment/PixelSegments/Templates/RosToRegStartTemplate';
import RegStartToRegistrationTemplate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Segment/PixelSegments/Templates/RegStartToRegistrationTemplate';
import RegistrationToFtdTemplate from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Segment/PixelSegments/Templates/RegistrationToFtdTemplate';
import InvalidTemplateError from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Segment/PixelSegments/Templates/errors/InvalidTemplateError';
import PIXEL_SOURCE from 'api-contracts/pixels/PixelSource';

export default class PixelSegmentTemplateService {
    private readonly pixels: Pixel[];

    constructor(pixels: Pixel[]) {
        this.pixels = this.prioritizePixelManagerPixels(pixels);
    }

    createSegment(pixelSegmentTemplate: PIXEL_SEGMENT_TEMPLATE): Predicate {
        if (pixelSegmentTemplate === PIXEL_SEGMENT_TEMPLATE.NONE) {
            return new Predicate({ children: [] });
        }

        let template: IPixelSegmentTemplate;
        if (pixelSegmentTemplate === PIXEL_SEGMENT_TEMPLATE.ROS__TO__REG_START) {
            template = new RosToRegStartTemplate(this.pixels);
        }
        if (pixelSegmentTemplate === PIXEL_SEGMENT_TEMPLATE.REG_START__TO__REGISTRATION) {
            template = new RegStartToRegistrationTemplate(this.pixels);
        }
        if (pixelSegmentTemplate === PIXEL_SEGMENT_TEMPLATE.REGISTRATION__TO__FTD) {
            template = new RegistrationToFtdTemplate(this.pixels);
        }

        if (!template) {
            throw new InvalidTemplateError(pixelSegmentTemplate);
        }

        return template.toPredicate();
    }

    private prioritizePixelManagerPixels(pixels: Pixel[]): Pixel[] {
        return [...pixels].sort((a) => {
            if (a.source === PIXEL_SOURCE.PIXEL_MANAGER) {
                return -1;
            }
            return 1;
        });
    }
}

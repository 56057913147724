import { Campaign } from '@/modules/campaign/types';
import CAMPAIGN_STATUS from 'api-contracts/campaigns/Status';
import getEmptyCampaignBudget from '@/modules/campaign/views/getEmptyCampaignBudget';
import getEmptyCampaignRuntime from '@/modules/campaign/views/getEmptyCampaignRuntime';

export default function getEmptyCampaign(): Campaign {
    return {
        name: '',
        persistentId: null,
        dspId: null,
        holding: null,
        businessEntity: null,
        status: CAMPAIGN_STATUS.INACTIVE,
        budget: getEmptyCampaignBudget(),
        runtime: getEmptyCampaignRuntime(),
        frequencyCapping: null,
        pacing: null,
    };
}

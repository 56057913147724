export type Browser = {
    id: number;
    name: string;
};

export type BrowserTargeting = {
    browser: Browser;
    isExcluded: boolean;
};

export enum OPERATING_SYSTEM_TARGETING_TYPE {
    OPERATING_SYSTEM = 'operating-system',
    OPERATING_SYSTEM_VERSION = 'operating-system-version',
}

export type OperatingSystemVersion = {
    id: number;
    name: string;
};

export type OperatingSystem = {
    id: string;
    versions: OperatingSystemVersion[];
};

export type OperatingSystemTargetingRequest = {
    operatingSystem?: {
        id: string;
    };
    operatingSystemVersion?: {
        id: number;
    };
    isExcluded: boolean;
};
export type OperatingSystemTargetingResponse = {
    operatingSystem?: {
        id: string;
    };
    operatingSystemVersion?: {
        id: number;
        name: string;
        operatingSystem: {
            id: string;
        };
    };
    isExcluded: boolean;
};

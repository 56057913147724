<template>
    <VRow no-gutters>
        <VCol sm="12" md="2">
            <SrHeadline level="3" class="pb-3" weight="bold"> Targeting Type </SrHeadline>
            <Switcher :buttons="targetings" :value="selectedTargeting" @click="onSwitchClick" />
        </VCol>
        <VCol sm="12" md="8" offset-md="1">
            <SrHeadline level="3" class="pb-3" weight="bold">
                {{ selectedTargetingTypeLabel }}
            </SrHeadline>
            <keep-alive>
                <component
                    :is="selectedTargetingTypeComponent"
                    v-model="lineItem"
                    :is-valid.sync="validStates[selectedTargeting]"
                    :targeting-type-count="targetingTypeCount"
                    @categoryCount="setCategoryCount"
                    @statusChanged="setStatus"
                />
            </keep-alive>
        </VCol>
    </VRow>
</template>

<script>
import { SrHeadline } from '@ads/design-system';
import Switcher from '@/components/Switcher';
import TargetingsAudience from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Audience/TargetingsAudience';
import TargetingsTechnology from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Technology/TargetingsTechnology';
import TargetingsSegment from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Segment/TargetingsSegment';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import emitValidStateMixin from '../../../mixins/emitValidStateMixin';
import TargetingsContent from './Targetings/Content/TargetingsContent';

export default {
    name: 'LineItemFormStepTargetings',
    components: {
        TargetingsAudience,
        TargetingsContent,
        TargetingsTechnology,
        TargetingsSegment,
        Switcher,
        SrHeadline,
    },
    mixins: [emitValidStateMixin, LineItemModel],
    props: {
        isValid: {
            type: Boolean,
            default: false,
        },
        targetingCount: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            validStates: {
                audience: this.isValid,
                content: this.isValid,
                technology: this.isValid,
                segments: this.isValid,
            },
            selectedTargeting: 'audience',
        };
    },
    computed: {
        targetingTypeCount() {
            if (!this.targetingCount[this.selectedTargeting]) {
                return {};
            }
            return this.targetingCount[this.selectedTargeting].types;
        },
        targetings() {
            return [
                {
                    label: 'Audience',
                    value: 'audience',
                    count: this.targetingCount?.audience?.count,
                    currentCount: this.targetingCount?.audience?.updateCount,
                    statusChanged: this.targetingCount?.audience?.wasUpdated,
                    component: 'TargetingsAudience',
                    hasError: !this.validStates.audience,
                },
                {
                    label: 'Content',
                    value: 'content',
                    count: this.targetingCount?.content?.count,
                    currentCount: this.targetingCount?.content?.updateCount,
                    statusChanged: this.targetingCount?.content?.wasUpdated,
                    component: 'TargetingsContent',
                    hasError: !this.validStates.content,
                },
                {
                    label: 'Technology',
                    value: 'technology',
                    count: this.targetingCount?.technology?.count,
                    currentCount: this.targetingCount?.technology?.updateCount,
                    statusChanged: this.targetingCount?.technology?.wasUpdated,
                    component: 'TargetingsTechnology',
                    hasError: !this.validStates.technology,
                },
                {
                    label: 'Segments',
                    value: 'segments',
                    count: this.targetingCount?.segments?.count,
                    currentCount: this.targetingCount?.segments?.updateCount,
                    statusChanged: this.targetingCount?.segments?.wasUpdated,
                    component: 'TargetingsSegment',
                    hasError: !this.validStates.segments,
                },
            ];
        },
        selectedTargetingType() {
            return this.targetings.find((b) => b.value === this.selectedTargeting);
        },
        selectedTargetingTypeLabel() {
            return this.selectedTargetingType?.label;
        },
        selectedTargetingTypeComponent() {
            return this.selectedTargetingType?.component;
        },
        isEveryTargetingValid() {
            return Object.values(this.validStates).every((validState) => Boolean(validState) === true);
        },
    },
    watch: {
        isEveryTargetingValid: 'emitValidState',
    },
    methods: {
        onSwitchClick(value) {
            this.selectedTargeting = value;
        },
        emitValidState() {
            this.$emit('update:isValid', this.isEveryTargetingValid);
        },
        setCategoryCount(category, count, key) {
            const targetingCount = { ...this.targetingCount };
            targetingCount[category].types[key].updateCount = count;
            targetingCount[category].updateCount = Object.values(targetingCount[category].types).reduce((sum, targeting) => {
                const currentCount = targeting.updateCount || 0;
                return sum + currentCount;
            }, 0);
            this.$emit('update:targetingCount', targetingCount);
        },
        setStatus(category, status, key) {
            const targetingCount = { ...this.targetingCount };
            targetingCount[category].types[key].wasUpdated = status;
            targetingCount[category].wasUpdated = Object.values(targetingCount[category].types).some(
                (currentStatus) => currentStatus.wasUpdated === true,
            );
            this.$emit('update:targetingCount', targetingCount);
        },
    },
};
</script>

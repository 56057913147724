<template>
    <CampaignForm v-model="campaign" submit-action="campaign/create" after-submit-route="campaign-list" />
</template>

<script>
import CampaignForm from '@/modules/campaign/components/CampaignForm';
import getEmptyCampaign from '@/modules/campaign/views/getEmptyCampaign';

export default {
    components: {
        CampaignForm,
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            campaign: {
                ...getEmptyCampaign(),
                persistentId: undefined,
            },
        };
    },
};
</script>

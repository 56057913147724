import { NODE_SELECTION_STATUS, SelectedNodeTree, SelectedTree } from '@/components/SelectableTree/SelectedTree';
import store from '@/store';
import { Node } from '@/components/SelectableTree/Node';
import { SELECTION_MODE } from '@/components/SelectableTree/types';

type SspTargeting = {
    id?: number;
    networkId: number | string;
    isExcluded: boolean;
    name: string;
};

type Network = {
    name: string;
    id: number;
};

class SspTargetingViewModel {
    getAllSspNetworks(): Network[] {
        const networks = store.getters['ssp/networks'];
        const sortByName = (networkA: Network, networkB: Network): number => {
            if (networkA.name.toLowerCase() > networkB.name.toLowerCase()) {
                return 1;
            }
            return -1;
        };
        return networks.sort(sortByName).map((network) => new Node(network));
    }

    createSspTargetingsFrom(selectedTree: SelectedTree): SspTargeting[] {
        const selectedNodes = selectedTree.getSelectedNodeTrees();
        return selectedNodes.map((node) => ({
            name: node.getName(),
            isExcluded: node.isExcluded(),
            networkId: node.getId() as number,
        }));
    }

    createSelectedTreeFrom(sspTargeting: SspTargeting[] | null): SelectedTree {
        if (!sspTargeting) {
            return new SelectedTree();
        }
        const selectedNodes = sspTargeting.map(
            ({ networkId, name, isExcluded }) =>
                new SelectedNodeTree(
                    networkId,
                    name,
                    isExcluded ? NODE_SELECTION_STATUS.EXCLUDED : NODE_SELECTION_STATUS.INCLUDED,
                ),
        );
        return new SelectedTree(selectedNodes);
    }

    getInitialTreeModeFrom(sspTargetings: SspTargeting[] | null): SELECTION_MODE {
        if (sspTargetings?.length) {
            if (sspTargetings[0].isExcluded) {
                return SELECTION_MODE.EXCLUDE;
            }
        }
        return SELECTION_MODE.INCLUDE;
    }
}

export default SspTargetingViewModel;

import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';

export default class FeatureFlagService {
    private readonly requestService: RequestService;

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    loadFeatureFlags(): Promise<Record<string, boolean>> {
        const options: IRequestOptions = {
            domain: 'programmatic-campaigns',
            resource: 'feature-flag',
            action: ACTION_NAME.READ,
        };

        return this.requestService.get('/feature-flags', options);
    }
}

import { DEAL_SOURCE } from '@/modules/deals/components/DealForm/config';
import DealEnvironment from 'api-contracts/deals/DealEnvironment';

export default function createEmptyDeal() {
    return {
        id: null,
        externalId: '',
        environment: DealEnvironment.Other,
        name: '',
        dealMultiplyCoefficient: 1,
        description: '',
        source: DEAL_SOURCE.SSP,
        holding: { id: null },
        ssp: { id: null },
        network: { id: null },
        countries: [],
    };
}

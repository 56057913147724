import { Node } from '@/components/SelectableTree/Node';
import { NODE_SELECTION_STATUS, SelectedNodeTree } from '@/components/SelectableTree/SelectedTree';
import {
    OPERATING_SYSTEM_TARGETING_TYPE,
    OperatingSystem,
    OperatingSystemTargetingRequest,
    OperatingSystemTargetingResponse,
} from '@/modules/line-items/components/LineItemForm/LineItemFormStepTargetings/Targetings/Technology/Browser/types';

export class OperatingSystemTransformer {
    operatingSystemsToSelectableNodes(operatingSystems: OperatingSystem[]): Node[] {
        return operatingSystems.map(
            ({ id, versions }) =>
                new Node({
                    id,
                    name: id,
                    type: OPERATING_SYSTEM_TARGETING_TYPE.OPERATING_SYSTEM,
                    children: versions.map(
                        (version) =>
                            new Node({
                                ...version,
                                type: OPERATING_SYSTEM_TARGETING_TYPE.OPERATING_SYSTEM_VERSION,
                            }),
                    ),
                }),
        );
    }

    operatingSystemTargetingsToSelectedNodes(operatingSystemTargetings: OperatingSystemTargetingResponse[]): SelectedNodeTree[] {
        return operatingSystemTargetings.map((operatingSystemTargeting) => {
            if (operatingSystemTargeting.operatingSystem) {
                return new SelectedNodeTree(
                    operatingSystemTargeting.operatingSystem.id,
                    operatingSystemTargeting.operatingSystem.id,
                    operatingSystemTargeting.isExcluded ? NODE_SELECTION_STATUS.EXCLUDED : NODE_SELECTION_STATUS.INCLUDED,
                    OPERATING_SYSTEM_TARGETING_TYPE.OPERATING_SYSTEM,
                );
            }
            return new SelectedNodeTree(
                operatingSystemTargeting.operatingSystemVersion.operatingSystem.id,
                operatingSystemTargeting.operatingSystemVersion.operatingSystem.id,
                NODE_SELECTION_STATUS.NONE,
                OPERATING_SYSTEM_TARGETING_TYPE.OPERATING_SYSTEM,
                [
                    new SelectedNodeTree(
                        operatingSystemTargeting.operatingSystemVersion.id,
                        operatingSystemTargeting.operatingSystemVersion.name,
                        operatingSystemTargeting.isExcluded ? NODE_SELECTION_STATUS.EXCLUDED : NODE_SELECTION_STATUS.INCLUDED,
                        OPERATING_SYSTEM_TARGETING_TYPE.OPERATING_SYSTEM_VERSION,
                    ),
                ],
            );
        });
    }

    selectedNodesToOperatingSystemTargetings(selectedNodes: SelectedNodeTree[]): OperatingSystemTargetingRequest[] {
        return selectedNodes.map((node) => {
            const operatingSystemTargeting = {
                isExcluded: node.isExcluded(),
            };
            if (node.getType() === OPERATING_SYSTEM_TARGETING_TYPE.OPERATING_SYSTEM) {
                return {
                    ...operatingSystemTargeting,
                    operatingSystem: {
                        id: node.getId() as string,
                    },
                };
            }
            return {
                ...operatingSystemTargeting,
                operatingSystemVersion: {
                    id: node.getId() as number,
                },
            };
        });
    }
}

export const operatingSystemTransformer = new OperatingSystemTransformer();

import { ContextualCategory } from '@/modules/contextual/store/getters';
import IContextualCategoryResponse from 'api-contracts/contextual/IContextualCategoryResponse';

export class ContextualCategoryFactory {
    public static createFromResponse(response: IContextualCategoryResponse): ContextualCategory {
        if (response.expiresAt === null) {
            return {
                ...response,
                expiresAt: null,
            };
        }
        return {
            ...response,
            expiresAt: ContextualCategoryFactory.convertToDate(response.expiresAt),
        };
    }

    private static convertToDate(timestampString: string): Date {
        const timestamp = new Date(timestampString);
        if (isNaN(timestamp.getTime())) {
            throw new Error(`Invalid date ${timestampString}.`);
        }
        return timestamp;
    }
}

export const lineItemListHeaders = [
    {
        text: 'DSP ID',
        value: 'dspId',
        align: 'left',
        sortable: true,
        width: '100px',
    },
    {
        text: 'Name',
        value: 'name',
        align: 'left',
        sortable: true,
    },
    {
        text: 'Status',
        value: 'computedStatus',
        align: 'left',
        sortable: true,
    },
];

export default {
    props: {
        targetingTypeCount: {
            type: Object,
            required: true,
        },
    },
    methods: {
        initialCount(key): number {
            if (Array.isArray(key)) {
                return key.reduce(
                    (multiCount, currentKey) => ({
                        ...multiCount,
                        [currentKey]: this.targetingTypeCount[currentKey]?.count || 0,
                    }),
                    {},
                );
            }
            return this.targetingTypeCount[key]?.count || 0;
        },
        currentTargetingCount(count, key): void {
            this.$emit('categoryCount', this.targetingCategory, count, key);
        },
        setChangedStatus(status, key): void {
            this.$emit('statusChanged', this.targetingCategory, status, key);
        },
    },
};

export default {
    props: {
        lineItem: {
            type: Object,
            required: true,
        },
    },
    computed: {
        // public
        lineItemHoldingId() {
            if (this.campaign) {
                return this.campaign.businessEntity.holding.id;
            }
            return null;
        },
        // private
        campaign() {
            return this.$store.getters['campaign/campaignById'](this.lineItem.campaign.id);
        },
    },
};

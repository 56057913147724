import { API_URL } from '@/config';
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { BackgroundProcessStatusResponse } from '@/modules/background-process/types';

export default class BackgroundProcessService {
    private readonly basePath = '/background-process';

    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'background-process',
    };

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async getBackgroundProcess(backgroundProcessId: string): Promise<BackgroundProcessStatusResponse> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        return this.requestService.get(`${this.basePath}/${backgroundProcessId}`, options);
    }
}

export const backgroundProcessService = new BackgroundProcessService(new RequestService({ baseUrl: API_URL }));

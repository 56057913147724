<template>
    <ActionModal v-model="isOpen" title="Archive Line Items" :description="description" @cancel="cancel">
        <template #actions>
            <SrButton type="secondary" @click="confirm"> Archive </SrButton>
        </template>
    </ActionModal>
</template>

<script>
import ActionModal from '@/components/ActionModal';
import { SrButton } from '@ads/design-system';

export default {
    name: 'BulkArchiveModal',
    components: {
        ActionModal,
        SrButton,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: this.value,
            description:
                'You have selected active line item(s) to be archived. ' +
                'By archiving a line item, it becomes inactive and its status automatically changes to "Archive".' +
                'Are you sure you want to archive selected line item(s)?',
        };
    },
    watch: {
        value(newValue) {
            this.isOpen = newValue;
        },
        isOpen() {
            this.emitIsOpenChange();
        },
    },
    methods: {
        emitIsOpenChange() {
            this.$emit('input', this.isOpen);
        },
        confirm() {
            this.$emit('confirm');
        },
        cancel() {
            this.$emit('cancel');
        },
    },
};
</script>

<template>
    <ActionModal
        v-model="isOpen"
        :title="title"
        :description="description"
        :error="errorMessage"
        :is-loading="isArchivingLineItem"
        @cancel="cancelArchiving"
    >
        <template #actions>
            <SrButton type="secondary" :loading="isArchivingLineItem" :disabled="isArchivingLineItem" @click="archiveLineItem">
                Archive
            </SrButton>
        </template>
    </ActionModal>
</template>

<script>
import ActionModal from '@/components/ActionModal';
import { SrButton } from '@ads/design-system';
import { lineItemsService } from '@/modules/line-items/services/LineItemsService';
import LINE_ITEM_STATUS from 'api-contracts/line-items/statuses/Status';

export default {
    name: 'SingleArchiveModal',
    components: {
        ActionModal,
        SrButton,
    },
    props: {
        /**
         * @model is modal open
         */
        value: {
            type: Boolean,
            default: false,
        },
        lineItem: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isOpen: this.value,
            isArchivingLineItem: false,
            errorMessage: null,
        };
    },
    computed: {
        title() {
            return `Archive${this.isActive ? ' Active ' : ' '}Line Item?`;
        },
        description() {
            const autoChangeText =
                'By archiving a line item, it becomes inactive and its status automatically changes to "Archive".';
            if (this.isActive) {
                return (
                    'The line item you have selected to archive is active. ' +
                    `${autoChangeText} ` +
                    `Are you sure you want to archive active ${this.lineItemName}?`
                );
            }

            return `${autoChangeText} Are you sure you want to archive ${this.lineItemName}?`;
        },
        isActive() {
            return this.lineItem.status === LINE_ITEM_STATUS.ACTIVE;
        },
        lineItemName() {
            return `line item ${this.lineItem.name}`;
        },
    },
    watch: {
        value(newValue) {
            this.isOpen = newValue;
        },
        isOpen() {
            this.emitIsOpenChange();
        },
    },
    methods: {
        emitIsOpenChange() {
            this.$emit('input', this.isOpen);
        },
        cancelArchiving() {
            this.clear();
            this.$emit('cancel');
        },
        handleArchivingSuccess(archivedLineItem) {
            this.clear();
            this.$emit('completed', archivedLineItem);
        },
        handleArchivingError() {
            this.errorMessage = `Could not archive ${this.lineItemName}.`;
        },
        async archiveLineItem() {
            this.isArchivingLineItem = true;
            this.errorMessage = null;
            try {
                const archivedLineItem = await lineItemsService.partialEdit(this.lineItem.id, LINE_ITEM_STATUS.ARCHIVE);
                this.handleArchivingSuccess(archivedLineItem);
            } catch (error) {
                this.handleArchivingError();
            } finally {
                this.isArchivingLineItem = false;
            }
        },
        clear() {
            this.isArchivingLineItem = false;
            this.errorMessage = null;
        },
    },
};
</script>

import { ValidationRule } from '@/shared/validation/types';

type SrGeoCoordinateValidationRules = {
    min: ValidationRule;
    max: ValidationRule;
    precision: ValidationRule;
};

export const getValidationRules = (
    min: number,
    max: number,
    precision: number,
    label: string,
): SrGeoCoordinateValidationRules => ({
    min: (value) => Number(value) >= min || `You need to enter a number larger than or equal to ${min}`,
    max: (value) => Number(value) <= max || `You need to enter a number smaller than or equal to ${max}`,
    precision: (value) => {
        if (value !== null) {
            const values = value.toString().split('.');

            if (values.length > 1 && values[1].length > precision) {
                return `${label} can only have ${precision} decimals`;
            }
            return true;
        }
        return true;
    },
});

export const getValidationRulesAsArray = (min: number, max: number, precision: number, label: string): ValidationRule[] => {
    const {
        min: minValidator,
        max: maxValidator,
        precision: precisionValidator,
    } = getValidationRules(min, max, precision, label);
    return [minValidator, maxValidator, precisionValidator];
};

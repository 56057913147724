import { ValidationResult, ValidationRule, ValidationValue } from '@/shared/validation/types';

export default function getValidationRuleResult(
    rule: ValidationRule | ValidationResult,
    value: ValidationValue,
): ValidationResult {
    if (typeof rule === 'function') {
        return rule(value);
    }
    return rule;
}

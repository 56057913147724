import { ValidationResult } from '@/shared/validation/types';
import getCountOfDecimals from '@/components/NumberInput/validation/getCountOfDecimals';

export default class StringifiedNumberValidator {
    private readonly precision: number;

    constructor(precision: number) {
        this.precision = precision;
    }

    public isValid(stringifiedNumber: string): boolean {
        const validationResults = this.validate(stringifiedNumber);
        return validationResults.every((result) => result === true);
    }

    public validate(stringifiedNumber: string): ValidationResult[] {
        return [this.validateNumberFormat(stringifiedNumber), this.validatePrecision(stringifiedNumber)];
    }

    private validateNumberFormat(stringifiedNumber: string): ValidationResult {
        const regex = /^\d+([.,]\d+)?$/;
        const isNumberFormatValid = regex.test(stringifiedNumber);
        return isNumberFormatValid || 'Invalid number format';
    }

    private validatePrecision(stringifiedNumber: string): ValidationResult {
        const numberWithDotDecimalSeparator = stringifiedNumber.replaceAll(',', '.');
        const numberOfDecimals = getCountOfDecimals(numberWithDotDecimalSeparator);

        if (this.precision === 0 && numberOfDecimals > 0) {
            return 'Decimals are not allowed';
        }

        if (this.precision === 1 && numberOfDecimals > 1) {
            return 'Only 1 or no decimals are allowed';
        }

        if (numberOfDecimals > this.precision) {
            return `Only ${this.precision} decimals or less are allowed`;
        }

        return true;
    }
}

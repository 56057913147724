<template>
    <table class="changelog-changes">
        <tr class="changelog-header">
            <th>Property</th>
            <th>Old Value</th>
            <th>New Value</th>
        </tr>
        <tr v-for="change in changes" :key="change.id">
            <td>
                {{ change.affectedColumnName }}
            </td>

            <td>
                {{ String(change.oldValue) }}
            </td>

            <td>
                {{ String(change.newValue) }}
            </td>
        </tr>
    </table>
</template>

<script>
export default {
    props: {
        changes: {
            type: Array,
            required: true,
        },
    },
};
</script>
<style lang="scss">
.changelog-changes {
    border: none !important;
    width: 100%;

    th {
        width: 33.33%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-decoration: underline;
    }

    td {
        padding-left: 1rem;
    }
}
</style>

<template>
    <div class="d-flex align-center computed-status">
        <SrIcon v-if="isScheduled" icon="calendar" size="xs" class="mr-2" />
        <SrSwitch v-if="isInactive || isActive" v-model="isActive" :disabled="isLoading" :loading="isLoading" color="red" />
        <p class="status" :class="statusTextClass">
            {{ lineItem.computedStatus }}
        </p>
    </div>
</template>

<script>
import { SrIcon, SrSwitch } from '@ads/design-system';
import LINE_ITEM_COMPUTED_STATUS from 'api-contracts/line-items/statuses/ComputedStatus';
import { lineItemsService } from '@/modules/line-items/services/LineItemsService';
import LineItemModel from '@/modules/line-items/components/LineItemForm/LineItemModel';
import LINE_ITEM_STATUS from 'api-contracts/line-items/statuses/Status';

export default {
    name: 'ComputedStatus',
    components: {
        SrSwitch,
        SrIcon,
    },
    mixins: [LineItemModel],
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        isActive: {
            get() {
                return this.lineItem.computedStatus === LINE_ITEM_COMPUTED_STATUS.ACTIVE;
            },
            set(value) {
                return this.updateLineItemStatus(value);
            },
        },
        isScheduled() {
            return this.lineItem.computedStatus === LINE_ITEM_COMPUTED_STATUS.SCHEDULED;
        },
        isInactive() {
            return this.lineItem.computedStatus === LINE_ITEM_COMPUTED_STATUS.INACTIVE;
        },
        statusTextClass() {
            return {
                'color-active': this.isActive,
                'color-inactive': this.isInactive,
            };
        },
    },
    methods: {
        async updateLineItemStatus(switchValue) {
            this.isLoading = true;
            try {
                const updatedLineItem = await lineItemsService.partialEdit(
                    this.lineItem.id,
                    switchValue ? LINE_ITEM_STATUS.ACTIVE : LINE_ITEM_STATUS.INACTIVE,
                );
                this.lineItem.computedStatus = updatedLineItem.computedStatus;
            } catch ({ response }) {
                console.error(response.message);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@ads/design-system/src/scss/variables';

.computed-status::v-deep {
    .v-input {
        margin-top: 0 !important;
        padding-top: 0 !important;
    }

    .v-input__control {
        height: 24px !important;
    }

    .v-input__slot {
        margin-bottom: 0 !important;
    }

    .status {
        margin: 0 !important;

        &.color-active {
            color: $green;
        }

        &.color-inactive {
            color: $gray-border;
        }
    }
}
</style>

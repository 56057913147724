/* eslint-disable max-classes-per-file */
export type NodeDetails = {
    [key: string]: string | number | boolean;
};

export class Node {
    id: number | string;

    name: string;

    isLoadingChildren = false;

    parent?: Node;

    children?: Node[];

    type?: string;

    details: NodeDetails = null;

    isDisabled = false;

    constructor(node: Partial<Node>) {
        Object.assign(this, node);
        if (this.children) {
            this.children.forEach((childNode) => {
                childNode.parent = this;
            });
        }
    }

    async fetchChildren(): Promise<Node[]> {
        return Promise.resolve(this.children);
    }

    async loadChildren(): Promise<Node[]> {
        if (!this.children || this.children.length > 0) {
            return;
        }
        this.isLoadingChildren = true;
        try {
            this.children = await this.fetchChildren();
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoadingChildren = false;
        }
    }

    isLeaf(): boolean {
        return !this.children || this.children.length === 0;
    }

    getAncestors(): Node[] {
        let parent = this.parent;
        const ancestors = [];

        while (parent) {
            ancestors.push(parent);
            parent = parent.parent;
        }

        return ancestors;
    }

    toBranch(): Node {
        let node = new Node({
            id: this.id,
            name: this.name,
            parent: this.parent,
            type: this.type,
            details: this.details,
        });
        while (node.parent) {
            node.parent = new Node({
                id: node.parent.id,
                name: node.parent.name,
                parent: node.parent.parent,
                children: [node],
                type: node.parent.type,
                details: node.parent.details,
            });
            node = node.parent;
        }
        return node;
    }
}

export class DummyLazyNode extends Node {
    lazyLoadedChildren: DummyLazyNode[];

    constructor(node: Partial<DummyLazyNode>) {
        super(node);
        if (this.lazyLoadedChildren) {
            this.lazyLoadedChildren.forEach((n) => {
                n.parent = this;
            });
        }
    }

    private async sleep(seconds: number): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(resolve, seconds * 1000);
        });
    }

    async fetchChildren(): Promise<DummyLazyNode[]> {
        await this.sleep(1);
        return Promise.resolve(this.lazyLoadedChildren);
    }
}

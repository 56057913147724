export default {
    props: {
        lineItem: {
            type: Object,
            required: true,
        },
    },
    computed: {
        closeRouteParams(): { id?: string } {
            if (!this.lineItem.id) {
                return {};
            }
            return { id: this.lineItem.id.toString() };
        },
        afterSubmitParams(): { id?: string } {
            return this.closeRouteParams;
        },
        closingRoute(): string {
            if (!this.lineItem.id) {
                return 'line-items-create';
            }
            return 'line-items-update';
        },
    },
};

import Changelog from '@/components/Changelog';
import { RouteConfig } from 'vue-router';
import CampaignCreate from './views/create';
import CampaignDuplicateView from './views/duplicate';
import CampaignEdit from './views/edit';
import CampaignList from './views/list';

const routes: RouteConfig[] = [
    {
        name: 'campaign-list',
        path: '/campaigns',
        component: CampaignList,
        children: [
            {
                name: 'campaign-create',
                path: 'create',
                component: CampaignCreate,
            },
            {
                name: 'campaign-update',
                path: ':id',
                component: CampaignEdit,
                props: true,
            },
            {
                name: 'campaign-changelog',
                path: ':id/changelog',
                component: Changelog,
                props({ params }) {
                    return {
                        id: Number(params.id),
                        entity: 'campaign',
                    };
                },
            },
            {
                name: 'campaign-duplicate',
                path: ':id/duplicate',
                component: CampaignDuplicateView,
                props: true,
            },
        ],
    },
];

export default routes;

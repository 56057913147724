import { API_URL } from '@/config';
import { ACTION_NAME, IRequestOptions, RequestService } from '@ads/iam-library';
import { LineItem } from '@/modules/line-items/types';
import { Predicate } from '@ads/predicate-model';
import ITableOptions from '@/components/Table/ITableOptions';
import URLPathBuilder from '@/modules/line-items/services/URLPathBuilder';
import ILineItemDuplicationInfo from 'api-contracts/line-items/ILineItemDuplicationInfo';
import LINE_ITEM_STATUS from 'api-contracts/line-items/statuses/Status';

export type PaginatedLineItemResult = {
    results: LineItem[];
    totalCount: number;
};

export default class LineItemsService {
    private readonly requestService: RequestService;

    private readonly defaultRequestOptions: Partial<IRequestOptions> = {
        domain: 'programmatic-campaigns',
        resource: 'line-items',
    };

    private static BASE_PATH = '/line-items';

    constructor(requestService: RequestService) {
        this.requestService = requestService;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return { ...this.defaultRequestOptions, ...options } as IRequestOptions;
    }

    async create(lineItem: LineItem): Promise<LineItem> {
        const options = this.getRequestOptions({ action: ACTION_NAME.CREATE });

        const urlPathBuilder = new URLPathBuilder(LineItemsService.BASE_PATH);
        const urlPath = urlPathBuilder.prependPath(`/campaigns/${lineItem.campaign.id}`).build();
        return this.requestService.post(urlPath, lineItem, options);
    }

    async edit(lineItem: LineItem): Promise<LineItem> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });

        const urlPathBuilder = new URLPathBuilder(LineItemsService.BASE_PATH);
        const urlPath = urlPathBuilder.appendPath(`/${lineItem.id}`).build();
        return this.requestService.put(urlPath, lineItem, options);
    }

    async partialEdit(lineItemId: number, status: LINE_ITEM_STATUS): Promise<LineItem> {
        const [lineItem] = await this.bulkPartialEdit([lineItemId], status);
        return lineItem;
    }

    async bulkPartialEdit(lineItemIds: number[], status: LINE_ITEM_STATUS): Promise<LineItem[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.EDIT });
        const urlPathBuilder = new URLPathBuilder(LineItemsService.BASE_PATH);
        return this.requestService.patch(urlPathBuilder.build(), { lineItemIds, status }, options);
    }

    async delete(lineItem: LineItem): Promise<string> {
        const options = this.getRequestOptions({ action: ACTION_NAME.REMOVE });

        const urlPathBuilder = new URLPathBuilder(LineItemsService.BASE_PATH);
        const urlPath = urlPathBuilder.appendPath(`/${lineItem.id}`).build();
        return this.requestService.delete(urlPath, options);
    }

    async bulkDelete(ids: number[]): Promise<string> {
        const options = this.getRequestOptions({ action: ACTION_NAME.REMOVE });

        const urlPathBuilder = new URLPathBuilder(LineItemsService.BASE_PATH);
        const urlPath = urlPathBuilder.setParam('ids', JSON.stringify(ids)).build();
        return this.requestService.delete(urlPath, options);
    }

    async getById(id: number): Promise<LineItem> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        const urlPathBuilder = new URLPathBuilder(LineItemsService.BASE_PATH);
        const urlPath = urlPathBuilder.appendPath(`/${id}`).build();
        return this.requestService.get(urlPath, options);
    }

    async list(tableOptions: ITableOptions, filter: Predicate = null): Promise<PaginatedLineItemResult> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        const urlPathBuilder = new URLPathBuilder(LineItemsService.BASE_PATH);
        const urlPath = urlPathBuilder
            .setParam('sortBy', String(tableOptions.sortBy))
            .setParam('sortDesc', String(tableOptions.sortDesc))
            .setParam('page', String(tableOptions.page))
            .setParam('limit', String(tableOptions.itemsPerPage))
            .setConditionalParam('query', () => btoa(filter.toMinifiedJsonString()), Boolean(filter))
            .build();

        return this.requestService.get(urlPath, options);
    }

    async getLineItemsByDealId(id: number): Promise<LineItem[]> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        const response = await this.requestService.get<PaginatedLineItemResult>(`/line-items?dealId=${id}`, options);
        return response.results;
    }

    async duplicate(lineItemId: number): Promise<void> {
        const options = this.getRequestOptions({ action: ACTION_NAME.CREATE });

        const urlPathBuilder = new URLPathBuilder(LineItemsService.BASE_PATH);
        const urlPath = urlPathBuilder.appendPath(`/${lineItemId}/duplicate`).build();
        await this.requestService.post(urlPath, null, options);
    }

    async getDuplicationInfo(lineItemId: number): Promise<ILineItemDuplicationInfo> {
        const options = this.getRequestOptions({ action: ACTION_NAME.READ });

        const urlPathBuilder = new URLPathBuilder(LineItemsService.BASE_PATH);
        const urlPath = urlPathBuilder.appendPath(`/${lineItemId}/duplicate-info`).build();
        return this.requestService.get<ILineItemDuplicationInfo>(urlPath, options);
    }
}

export const lineItemsService = new LineItemsService(new RequestService({ baseUrl: API_URL }));

export default function getCountOfDecimals(numberAsString: string): number {
    const partsOfNumber = numberAsString.split('.');

    const hasNoDecimalPart = partsOfNumber.length === 1;
    if (hasNoDecimalPart) {
        return 0;
    }

    return partsOfNumber[1].length;
}
